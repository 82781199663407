import React from 'react';

interface InternalState {
  activeNav: any;
  chartExample1Data: any;
}

interface Props {}

class ContentItem extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    return <></>;
  }
}

export default ContentItem;
