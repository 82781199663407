import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { ContentEvent, ContentType } from 'enums/contentTree.enum';

interface InternalProps {
  handleAddClick: (
    ContentType,
    ContentEvent,
    stackId,
    setNewItemId,
    setIsAddingNewContent?,
  ) => void;
  rowType: ContentType;
  setIsAddingNewContent: any;
  setNewItemId: any;
  stackId: number;
}

function AddRow(props: InternalProps): JSX.Element {
  const {
    handleAddClick,
    rowType,
    setIsAddingNewContent,
    setNewItemId,
    stackId,
  } = props;

  const [title, setTitle] = useState('');

  useEffect(() => {
    if (rowType === ContentType.COLLECTION) {
      setTitle('Collections');
    } else {
      setTitle('Items');
    }
  }, [rowType]);

  return (
    <>
      <div className="add-content-row">
        <p>{title}</p>
        <p>
          <Button
            className="btn-light"
            size="sm"
            onClick={(): void => {
              handleAddClick(
                rowType,
                ContentEvent.NEW,
                stackId,
                setNewItemId,
                setIsAddingNewContent,
              );
            }}
          >
            new
          </Button>
          <Button
            className="btn-light"
            size="sm"
            onClick={(): void => {
              handleAddClick(
                rowType,
                ContentEvent.EXISTING,
                stackId,
                setNewItemId,
              );
            }}
          >
            existing
          </Button>
        </p>
      </div>
    </>
  );
}

export default AddRow;
