import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { DomainStoreModel } from '../models/domain.model';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export async function publishAllManifests(domainStore: DomainStoreModel): Promise<any>{
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    includeMeta: true,
    method: EdgeRequestMethods.PUT,
    url: EdgeUrls.Publish,
  });
  return resp;
}
