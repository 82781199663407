import React, { useEffect, useState } from 'react';
import { IMeta, IPreviewProps } from 'react-dropzone-uploader';
import { contentStore, domainStore, uiStore } from 'stores';

import { ContentItem } from '@storyslab/storyslab.common.models';
import ContentRow from 'components/Content/ContentRow/ContentRow';
import GhostLoader from 'components/Indicators/GhostLoader';

interface CustomMeta extends IMeta {
  item: ContentItem;
}

interface Props extends IPreviewProps {
  meta: CustomMeta;
}

function UploadPreview(props: Props): JSX.Element {
  const [willStoreUpdate, setWillStoreUpdate] = useState(true);
  const [isDoneUploading, setIsDoneUploading] = useState(false);

  useEffect(() => {
    if (props.meta.percent === 100) {
      setIsDoneUploading(true);
      contentStore.fetchItems({ domainStore }).then(() => {
        setWillStoreUpdate(false);
      });
    }
  }, [props.meta.percent]);

  if (isDoneUploading) {
    return (
      <>
        {willStoreUpdate && (
          <GhostLoader style={{ height: '54.8px' }} width={'100%'} />
        )}
        {!willStoreUpdate && (
          <ContentRow
            contentItem={contentStore.items.get(props.meta.item.id)}
            context="detail"
          />
        )}
      </>
    );
  }

  return (
    <>
      <div
        style={{
          ...styles.row,
          background: `linear-gradient(to right, ${uiStore.common.colors.primary} ${props.meta.percent}%, transparent 0)`,
        }}
      >
        Uploading... {Math.floor(props.meta.percent)}%
      </div>
    </>
  );
}

const styles: any = {
  row: {
    alignItems: 'center',
    borderRadius: '8px',
    boxShadow: '0 0 15px #999',
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0',
    padding: '1rem',
    width: '100%',
  },
};

export default UploadPreview;
