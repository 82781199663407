import React from 'react';
import { CardFooter, DropdownItem } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { BaseStore } from 'stores/base.store';

import RowContents from '../../components/Content/DrawerModalRowContents';
import GridTable from '../../components/GridTable';
import GridTableCardHeader from '../../components/GridTable/CardHeader';
import CheckboxList from '../../components/GridTable/FilterDropdowns/CheckboxList';
import DoubleDatePicker from '../../components/GridTable/FilterDropdowns/DoubleDatePicker';
import PaginationComponent from '../../components/GridTable/Pagination';
import { Action, ActionTypes } from '../../models/action.model';
import { BaseProps } from '../../models/base.model';
import { HeaderColumnConfig } from '../../models/grid-table.model';
import Checkbox from '../Forms/Checkbox';

interface InternalState {
  shouldShowItemsWithinGroup: boolean;
  shouldShowItemsNotWithinGroup: boolean;
  isDirty: boolean;
}

interface Props extends BaseProps {
  count: number;
  id: number;
  parentModalStore: BaseStore<any>;
}

@inject('simpleListsStore')
@inject('contentStore')
@inject('domainStore')
@inject('uiStore')
@observer
class DrawerModalContents extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];

  public state = {
    isDirty: false,
    shouldShowItemsNotWithinGroup: false,
    shouldShowItemsWithinGroup: true,
  };

  constructor(props: Props) {
    super(props);

    if (props.count === 0) {
      this.state = {
        ...this.state,
        shouldShowItemsNotWithinGroup: true,
        shouldShowItemsWithinGroup: true,
      };
    }

    this.handleFilter = this.handleFilter.bind(this);

    this.initHeaderColumnConfigs();
  }

  private initHeaderColumnConfigs(): void {
    this.headerColumnConfigs = [
      {
        colProps: {
          lg: '8',
          md: '6',
        },
        handleAction: (action: Action): void => {
          this.props.contentStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'name',
        store: this.props.contentStore,
        title: 'Display Name',
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <CheckboxList
            itemKey={'categoryIds'}
            simpleList={this.props.simpleListsStore.categories}
            store={this.props.contentStore.filters}
            handleAction={(action: Action): void => {
              this.props.contentStore.handleTableAction(this.props, action);
            }}
          />
        ),
        isFilterable: true,
        itemKey: 'categoryIds',
        store: this.props.contentStore,
        title: 'Categories',
      },
      {
        colProps: {
          lg: '2',
          md: '3',
        },
        filterDropdown: (
          <DoubleDatePicker
            itemKey={'updated'}
            store={this.props.contentStore.filters}
            handleAction={(action: Action): void => {
              this.props.contentStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.contentStore.handleTableAction(this.props, action);
        },
        isFilterable: true,
        isSortable: true,
        itemKey: 'updated',
        store: this.props.contentStore,
        title: 'Last Updated',
      },
    ];
  }

  private toggleShowItemsWithinGroup(): void {
    const { shouldShowItemsNotWithinGroup } = this.state;

    if (shouldShowItemsNotWithinGroup) {
      this.setState(
        (prevState: InternalState) => ({
          shouldShowItemsWithinGroup: !prevState.shouldShowItemsWithinGroup,
        }),
        () => {
          this.handleFilter();
        },
      );
    }
  }

  private toggleShowItemsNotWithinGroup(): void {
    const { shouldShowItemsWithinGroup } = this.state;

    if (shouldShowItemsWithinGroup) {
      this.setState(
        (prevState: InternalState) => ({
          shouldShowItemsNotWithinGroup:
            !prevState.shouldShowItemsNotWithinGroup,
        }),
        () => {
          this.handleFilter();
        },
      );
    }
  }

  private handleFilter(): void {
    const { shouldShowItemsWithinGroup, shouldShowItemsNotWithinGroup } =
      this.state;

    let idArray: Array<number | string> = [];

    this.props.contentStore.clearFilters();

    if (shouldShowItemsWithinGroup && shouldShowItemsNotWithinGroup) {
      this.props.contentStore.fetchItems(this.props);
    } else {
      if (this.state.shouldShowItemsWithinGroup) {
        idArray = [...idArray, this.props.id];
      }

      if (this.state.shouldShowItemsNotWithinGroup) {
        idArray = [`!${this.props.id}`];
      }

      this.props.contentStore.handleTableAction(this.props, {
        payload: {
          groupIds: {
            ids: idArray,
            selected: true,
          },
        },
        type: ActionTypes.FILTER,
      });
    }
  }

  private renderActionButtonTitle(): string {
    const { shouldShowItemsWithinGroup, shouldShowItemsNotWithinGroup } =
      this.state;
    if (shouldShowItemsWithinGroup && shouldShowItemsNotWithinGroup) {
      return `Showing all content files`;
    }

    if (shouldShowItemsWithinGroup) {
      return `Showing content files within group`;
    }

    if (shouldShowItemsNotWithinGroup) {
      return `Showing content files not within group`;
    }
  }

  public componentDidMount(): void {
    const { contentStore, count } = this.props;
    if (count === 0) {
      this.props.contentStore.fetchItems(this.props);
    } else {
      contentStore.handleTableAction(this.props, {
        payload: {
          groupIds: {
            id: this.props.id,
            selected: true,
          },
        },
        type: ActionTypes.FILTER,
      });
    }
  }

  public async componentWillUnmount(): Promise<void> {
    const { contentStore, parentModalStore, id, domainStore } = this.props;

    contentStore.clearFilters();

    if (parentModalStore && this.state.isDirty) {
      parentModalStore.fetchItemById(id, { domainStore });
    }
  }

  public render(): JSX.Element {
    const { contentStore } = this.props;
    return (
      <>
        <GridTableCardHeader
          actionButtonIcon={'fa-chevron-down'}
          isActionButtonIconSuffix={true}
          actionButtonTitle={this.renderActionButtonTitle()} // "Showing Content Files Within Group"
          ActionDropdownMenu={(): JSX.Element => (
            <>
              <DropdownItem
                onClick={(): void => {
                  this.toggleShowItemsWithinGroup();
                }}
              >
                <Checkbox selected={this.state.shouldShowItemsWithinGroup} />
                <span>Show content files within group</span>
              </DropdownItem>
              <DropdownItem
                onClick={(): void => {
                  this.toggleShowItemsNotWithinGroup();
                }}
              >
                <Checkbox selected={this.state.shouldShowItemsNotWithinGroup} />
                <span>Show content files not within group</span>
              </DropdownItem>
            </>
          )}
          store={contentStore}
        />

        <GridTable
          store={contentStore}
          idKey={'id'}
          headerColumnConfigs={this.headerColumnConfigs}
          items={Array.from(contentStore?.items?.values())}
          RowContents={(props): JSX.Element => (
            <RowContents {...props} parentModalId={this.props.id} />
          )}
          isSimpleRow={true}
        />

        <CardFooter className="py-4">
          <PaginationComponent
            store={contentStore}
            handleAction={(params): Promise<void> =>
              this.props.contentStore.handleTableAction(this.props, params)
            }
          />
        </CardFooter>
      </>
    );
  }
}

export default DrawerModalContents;
