import React from 'react';
import { Container } from 'reactstrap';
import { observer } from 'mobx-react';

import { RenderKeyHelper } from '../../helpers/render-key.helper';
import {
  ExpandedRowContentsProps,
  HeaderColumnConfig,
} from '../../models/grid-table.model';
import LoadingOpacity from '../Global/LoadingOpacity';
import GridTableRow from '../GridTable/Row';
import SimpleGridTableRow from '../GridTable/SimpleRow';

import Header from './Header';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper('GridTable');

interface InternalState {}

interface Props {
  ExpandedRowContents?: React.ElementType<ExpandedRowContentsProps>;
  headerColumnConfigs: Array<HeaderColumnConfig>;
  headerRowProps?: {
    [key: string]: any;
  };
  idKey: string;
  items: any;
  RowContents: any;
  store: any;
  isSimpleRow?: boolean;
  isJustHeaders?: boolean;
}

@observer
class GridTable extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const {
      ExpandedRowContents,
      headerColumnConfigs,
      headerRowProps,
      isSimpleRow,
      items,
      RowContents,
      store,
      isJustHeaders,
    } = this.props;

    const TableRow: any = isSimpleRow ? SimpleGridTableRow : GridTableRow;

    return (
      <Container className="m-0 p-0 pb-3 no-max-width grid-table-container">
        <Header columnConfigs={headerColumnConfigs} rowProps={headerRowProps} />

        {!isJustHeaders && (
          <LoadingOpacity store={store}>
            {items &&
              items.map((item: any, index) => {
                return (
                  <TableRow
                    tableNode={this}
                    ExpandedRowContents={ExpandedRowContents}
                    RowContents={RowContents}
                    store={store}
                    item={item}
                    index={index}
                    key={renderKeyHelper.generate(
                      {
                        id: item.id,
                        index,
                      },
                      'TableRow',
                    )}
                  />
                );
              })}
          </LoadingOpacity>
        )}
      </Container>
    );
  }
}

export default GridTable;
