import React, { Dispatch, SetStateAction, useState } from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { observer } from 'mobx-react';
import { collectionsStore, domainStore } from 'stores';

import DetailSelect from 'components/Collections/CollectionRow/DetailSelect';

interface Props {
  collection: any;
}

interface InternalProps extends Props {
  activeMenu: string;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  isDropped: boolean;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
}

const BasicFields: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const [title, setTitle] = useState(props.collection.name);

    const debounceTitle: DebouncedFunc<(name: any) => void> = React.useMemo(
      () =>
        debounce((name) => {
          collectionsStore.updateItem({
            body: { name },
            domainStore,
            id: props.collection.id,
          });
        }, 750),
      [props.collection.id],
    );

    const handleChange: (e) => void = React.useCallback(
      (e) => {
        setTitle(e.target.value);
        debounceTitle(e.target.value);
      },
      [debounceTitle],
    );

    return (
      <div className="content-contain">
        <div>
          <div className="form-field-contain">
            <div className="form-field-label">display name</div>
            <input type="text" value={title} onChange={handleChange} />
          </div>
          <div className="form-field-contain">
            <div className="form-field-label">belongs to...</div>
            <DetailSelect
              {...props}
              idList={'collectionIds'}
              listType={'collections'}
            />
          </div>
        </div>
        <div>
          <div className="form-field-contain">
            <div className="form-field-label">visible for...</div>
            <DetailSelect {...props} idList={'groupIds'} listType={'groups'} />
          </div>

          <div className="form-field-contain">
            <div className="form-field-label">categorized as...</div>
            <DetailSelect
              {...props}
              idList={'categoryIds'}
              listType={'categories'}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default BasicFields;
