import React from 'react';
import { inject, observer } from 'mobx-react';

import { CognitoProvider } from '@storyslab/storyslab.common.models';

import { getAuthUrl } from '../../helpers/url.helper';
import { DomainStoreModel } from '../../models/domain.model';
import { ProviderLoginButton } from '../Auth/ProviderLoginButton';

interface Props {
  domainStore?: DomainStoreModel;
  providers: Array<CognitoProvider>;
}

@inject('domainStore')
@observer
export class ProviderButtonList extends React.Component<Props, null> {
  private renderButton(provider: CognitoProvider, index: number): JSX.Element {
    return (
      <ProviderLoginButton
        key={index}
        title={provider.displayName}
        iconKey={provider.iconKey}
        iconUrl={provider.iconUrl}
        url={getAuthUrl(
          provider.cognitoName,
          +this.props.domainStore.tenantId,
        )}
      />
    );
  }

  public render(): Array<JSX.Element> {
    if (this.props.providers && this.props.providers.length > 0) {
      return this.props.providers.map(
        (value: CognitoProvider, index: number) => {
          return this.renderButton(value, index);
        },
      );
    }

    return null;
  }
}
