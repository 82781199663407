import React from 'react';
import { ILayoutProps } from 'react-dropzone-uploader';

const Layout: (props: ILayoutProps) => JSX.Element = (props: ILayoutProps) => {
  const {
    input,
    previews,
    submitButton,
    dropzoneProps,
    files,
    extra: { maxFiles },
  } = props;

  const { onDragEnter, onDragLeave, onDragOver, onDrop } = dropzoneProps;

  return (
    <>
      <div
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onDrop}
        style={styles.wrapper}
      >
        {previews}

        {files.length < maxFiles && input}
      </div>

      <div style={styles.buttonsContain}>
        {files.length > 0 && submitButton}
      </div>
    </>
  );
};

const styles: any = {
  buttonsContain: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
  },
};

export default Layout;
