/* eslint-disable multiline-comment-style */
/* eslint-disable capitalized-comments */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import * as AppFeatureService from 'services/appFeatures.service';
import * as AppMetaService from 'services/appMeta.service';
import { domainStore, uiStore } from 'stores';

import Loader from 'components/Indicators/Loader';
import DynamicFeatureCard from 'components/SettingsAndFeatures/DynamicFeatureCard/DynamicFeatureCard';

import * as FeatureCardConfigs from './DynamicFeatureCard/FeatureCardConfigs';
import { FeatureCardConfig } from './DynamicFeatureCard/FeatureCardModels';

//import FeatureCard from './FeatureCard';
//import * as TestFeatureContents from './ModalContents/TestContents';

interface Props {}

const cardConfigs: any = [
  FeatureCardConfigs.Authentication,
  FeatureCardConfigs.AppIdentity,
  FeatureCardConfigs.ClientApp,
  FeatureCardConfigs.ColorAndStyling,
  FeatureCardConfigs.Share,
  FeatureCardConfigs.TestFeature,
];

const SettingsAndFeatures: any = (props: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  const [changedFeatures, setChangedFeatures] = useState<{
    [key: string]: boolean;
  }>({});
  const [changedSettings, setChangedSettings] = useState<{
    [key: string]: { value: any; isPublic: boolean };
  }>({});

  const [features, setFeatures] = useState<{ [key: string]: boolean }>();
  const [settings, setSettings] =
    useState<{ [key: string]: { value: any; isPublic: boolean } }>();

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  function handleFeatureToggle(toggledState: boolean, field: string): void {
    changedFeatures[field] = toggledState;
    setChangedFeatures({ ...changedFeatures });
    features[field] = toggledState;
    setFeatures({ ...features });
    setHasUnsavedChanges(true);
  }

  function handleAppMetaSettingChange(
    newValue: any,
    field: string,
    isPublic: boolean,
  ): void {
    changedSettings[field] = { isPublic: isPublic, value: newValue };
    setChangedSettings({ ...changedSettings });
    settings[field] = { isPublic: isPublic, value: newValue };
    setSettings({ ...settings });
    setHasUnsavedChanges(true);
  }

  function saveAllChanges(): void {
    setIsLoading(true);
    const featuresPayload: any = Object.keys(changedFeatures).map((key) => {
      return { featureKey: key, isEnabled: changedFeatures[key] };
    });
    const settingsPayload: any = Object.keys(changedSettings).map((key) => {
      return {
        isPublic: changedSettings[key].isPublic,
        metaKey: key,
        metaValue: changedSettings[key].value,
      };
    });
    const metaPromise: Promise<any> = AppMetaService.updateAppMeta(
      domainStore,
      settingsPayload,
    );
    const featurePromise: Promise<any> = AppFeatureService.updateAppFeature(
      domainStore,
      featuresPayload,
    );

    setIsLoading(true);

    Promise.all([metaPromise, featurePromise]).then(() => {
      reset();
    });
  }

  async function reset(): Promise<void> {
    setIsLoading(true);
    await Promise.all([fetchFeatures(), fetchSettings()]).then(() => {
      setHasUnsavedChanges(false);
      setIsLoading(false);
    });
  }

  async function fetchFeatures(): Promise<any> {
    // note: need to do this to force rerender cards to get main toggle switch to rerender with correct state and not trigger onChange
    setFeatures(null);
    return AppFeatureService.fetchAllAppFeatures(domainStore).then((result) => {
      const formatted: any = {};
      result.data.forEach((item) => {
        formatted[item.featureKey] = item.isEnabled;
      });
      setFeatures(formatted);
      setChangedFeatures({});
    });
  }

  async function fetchSettings(): Promise<any> {
    return AppMetaService.fetchAllAppMeta(domainStore).then((result) => {
      const formatted: any = {};
      result.data.forEach((item) => {
        formatted[item.metaKey] = {
          isPublic: item.isPublic,
          value: item.metaValue,
        };
      });
      setSettings(formatted);
      setChangedSettings({});
    });
  }

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={styles.wrapper}>
        <div>
          <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
            Settings & Features
          </h4>
        </div>

        {isLoading && <Loader isFullPage={true} />}

        {hasUnsavedChanges === true && (
          <div style={styles.saveButtonWrapper}>
            <Button
              className="btn-default"
              style={styles.saveButton}
              onClick={saveAllChanges}
            >
              SAVE ALL CHANGES
            </Button>
            <Button
              className="btn-default"
              style={styles.saveButton}
              onClick={reset}
            >
              CANCEL
            </Button>
          </div>
        )}

        <div style={styles.contain}>
          {/*Build cards dynamically*/}
          {cardConfigs.map((config: FeatureCardConfig, i: number) => {
            return (
              <DynamicFeatureCard
                key={`feature-card-${config.featureKey}-${i}`}
                title={config.title}
                description={config.description}
                icon={config.icon}
                hasToggle={config.hasToggle}
                featureKey={config.featureKey}
                appMetaPrefix={config.appMetaPrefix}
                subFeatureSections={config.subFeatureSections}
                features={features}
                settings={settings}
                handleFeatureToggle={handleFeatureToggle}
                handleSettingsChange={handleAppMetaSettingChange}
              />
            );
          })}

          {/*Build Cards Manually - TESTING - Leave for now*/}
          {/*
            <FeatureCard
              title={TestFeatureContents.TitleText}
              description={TestFeatureContents.Description}
              icon={TestFeatureContents.IconName}
              hasToggle={true}
              featureKey='testFeature'
              features={features}
              handleFeatureToggle={handleFeatureToggle}
              modalContents={
                <TestFeatureContents.Contents
                  features={features}
                  settings={settings}
                  handleFeatureToggle={handleFeatureToggle}
                  handleSettingsChange={handleAppMetaSettingChange}
                />
            }/>
          */}
        </div>
      </div>
    </>
  );
};

const styles: any = {
  contain: {
    display: 'grid',
    gridColumnGap: '16px',
    gridRowGap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(414px, 1fr))',
  },
  saveButton: {
    borderRadius: 0,
    fontSize: '.85em',
  },
  saveButtonWrapper: {
    bottom: 0,
    position: 'fixed',
    right: 0,
    zIndex: 1,
  },
  wrapper: {
    padding: '24px 30px',
  },
};

export default SettingsAndFeatures;
