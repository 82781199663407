/* eslint-disable max-lines-per-function */
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import dayjs from 'dayjs';
import { Recipients } from 'helpers/shareBundle.helper';
import { domainStore } from 'stores';

import { Bundle } from '@storyslab/storyslab.common.models';
import GhostLoader from 'components/Indicators/GhostLoader';

interface Props {
  bundle: Bundle;
  isLoading: boolean;
}

const BundleDetail: any = (props: Props) => {
  const { bundle, isLoading } = props;

  return (
    <>
      <div style={styles.wrapper}>
        <Row className="mb-4">
          <Col md="6" lg="6" style={styles.row}>
            <div style={{ ...styles.flex1, ...styles.iconGroup }}>
              <div style={{ ...styles.flex1, ...styles.iconGroup }}>
                <i className="fas fa-envelope" style={styles.icon} />
                <div>
                  <div style={styles.label}>sender</div>
                  <div style={styles.text1}>
                    {isLoading ? (
                      <GhostLoader />
                    ) : (
                      `${bundle.userData?.lastName}, ${bundle.userData?.firstName}`
                    )}
                  </div>
                  <div style={styles.text2}>
                    {isLoading ? <GhostLoader /> : bundle.userData?.email}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ ...styles.flex1, ...styles.iconGroup }}>
              <div style={{ ...styles.flex1, ...styles.iconGroup }}>
                <i className="fas fa-users" style={styles.icon} />
                <div>
                  <div style={styles.label}>recipient(s)</div>
                  <div style={styles.text2}>
                    {isLoading ? (
                      <GhostLoader />
                    ) : (
                      <Recipients recipients={bundle.recipients} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="6" style={styles.row}>
            <div style={{ ...styles.flex1, ...styles.iconGroup }}>
              <i className="fas fa-calendar-check" style={styles.icon} />
              <div>
                <div style={styles.label}>sent on</div>
                <div style={styles.text1}>
                  {isLoading ? (
                    <GhostLoader />
                  ) : (
                    dayjs(bundle.created).format('MM / DD / YYYY')
                  )}
                </div>
              </div>
            </div>
            <div style={{ ...styles.flex1, ...styles.iconGroup }}>
              <i className="fas fa-hourglass-end" style={styles.icon} />
              <div>
                <div style={styles.label}>expires on</div>
                <div style={styles.text1}>
                  {isLoading ? (
                    <GhostLoader />
                  ) : (
                    dayjs(bundle.expiration).format('MM / DD / YYYY')
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="6" lg="6" style={styles.row} className="mb-3">
            <div style={{ ...styles.flex1 }}>
              <Button
                className="storyslab-button"
                onClick={(): void => {
                  let url: string = 'https://';
                  url += domainStore.subdomain;
                  url += '.share-';
                  if (process.env.REACT_APP_STAGE === 'local') {
                    url += 'dev';
                  } else {
                    url += process.env.REACT_APP_STAGE;
                  }
                  url += `.storyslab.com/${bundle.shortCode}`;
                  window.open(url, '_blank');
                }}
              >
                <i
                  className="fas fa-external-link-square-alt"
                  style={styles.icon2}
                />
                Open Share+ Bundle
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

const styles: any = {
  flex1: {
    flex: 1,
  },
  icon: {
    fontSize: '1.5rem',
    marginRight: '7px',
    marginTop: '7px',
    textAlign: 'center',
    width: '30px',
  },
  icon2: {
    fontSize: '12px',
    marginRight: '.5rem',
  },
  iconGroup: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  label: {
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  row: {
    alignItems: 'flex-start',
    display: 'flex',
  },
  text1: {
    fontSize: '16px',
    fontWeight: 700,
    marginLeft: '5px',
  },
  text2: {
    fontSize: '10px',
    fontWeight: 300,
    marginLeft: '5px',
  },
  wrapper: {
    backgroundColor: '#4d4d4d',
    color: '#fff',
    padding: '1rem 5rem 2rem',
    position: 'relative',
    zIndex: -1,
  },
};

export default BundleDetail;
