import { NavItemViewService } from 'services/NavItemViewService';
import { BaseStore } from 'stores/base.store';

export class NavItemViewsStore extends BaseStore<any> {
  constructor() {
    super(new NavItemViewService(), 'CollectionViewsStore', 'CollectionViews');
  }
}

export const navItemViewsStore: NavItemViewsStore = new NavItemViewsStore();
