import React from 'react';
import { inject, observer } from 'mobx-react';
import { DomainStoreModel } from '../../models/domain.model';

import { getSignedUrl } from '../../services/asset.service';

interface InternalState {
  signedUrl: string;
}

interface Props {
  className?: string;
  domainStore?: DomainStoreModel;
  s3Bucket: string;
  s3Key: string;
  style?: any;
}

@inject('domainStore')
@observer
class S3Image extends React.Component<Props, InternalState> {
  public state: InternalState = {
    signedUrl: null,
  };

  constructor(props: Props) {
    super(props);

    this.getSetSignedUrl(props.s3Bucket, props.s3Key);
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.s3Key !== this.props.s3Key ||
      prevProps.s3Bucket !== this.props.s3Bucket
    ) {
      this.getSetSignedUrl(this.props.s3Bucket, this.props.s3Key);
    }
  }

  private async getSetSignedUrl(bucket: string, key: string) {
    const signedUrl: string = await getSignedUrl({
      bucket: bucket,
      key: key,
      s3ClientConfig: {
        ...this.props.domainStore.temporaryCredentials,
        Region: (this.props.domainStore.temporaryCredentials as any).region,
      },
    } as any);

    this.setState({ signedUrl: signedUrl });
  }

  public render(): JSX.Element {
    const { signedUrl } = this.state;

    if (signedUrl) {
      return (
        <img
          style={this.props.style}
          src={signedUrl}
          className={this.props.className}
        />
      );
    } else {
      return <></>;
    }
  }
}

export default S3Image;
