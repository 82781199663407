import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { ActionTypes } from 'models/action.model';
import { categoriesStore, domainStore, simpleListsStore } from 'stores';

import { Category } from '@storyslab/storyslab.common.models';
import BasicFields from 'components/Categories/BasicFields';

interface Props {
  category: Category;
}

interface InternalProps extends Props {
  isDropped: boolean;
  isMenuOpen: boolean;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

function HamburgerMenu(props: {
  children: JSX.Element | Array<JSX.Element>;
  collectionId: number;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <>
      <div
        className="backsplash"
        onClick={(): void => {
          props.setIsMenuOpen(false);
        }}
      ></div>
      <div className="hamburger-menu-card">{props.children}</div>
    </>
  );
}

function DeleteOption(props: { categoryId: number }): JSX.Element {
  return (
    <>
      <div
        className="menu-option"
        onClick={(): void => {
          window.siteWideComponent.show({
            approve: () => {
              categoriesStore
                .deleteItem({
                  domainStore,
                  id: props.categoryId,
                })
                .then(() => {
                  categoriesStore.handleTableAction(
                    { domainStore },
                    {
                      payload: { page: categoriesStore.page.toString() },
                      type: ActionTypes.PAGINATE,
                    },
                  );
                });
            },
            body: `Are you sure you want to delete category ${
              simpleListsStore.categories.get(props.categoryId).name
            } ?`,
            title: 'Delete Confirmation',
          });
        }}
      >
        Delete
      </div>
    </>
  );
}

function RowHeader(props: InternalProps): JSX.Element {
  const { category, isDropped, isMenuOpen, setIsDropped, setIsMenuOpen } =
    props;

  return (
    <>
      <div
        className={`detail-row-wrapper ${props.isDropped && 'active'}`}
        onClick={(): void => {
          setIsDropped(!isDropped);
        }}
      >
        <div className="detail-row-body">
          <div className="col-1">
            <i
              className={`fas fa-${
                category.icon ? category.icon : 'shapes'
              } p-1`}
              style={styles.icon}
            />
          </div>
          <div className="detail-row-title col-4">
            <span>{category.name}</span>
          </div>

          {!isDropped && (
            <>
              <div className="detail-row-title col-4">
                <span>{category.description ? category.description : '-'}</span>
              </div>
              <div className="detail-row-title col-3" style={styles.updated}>
                {dayjs(category.updated).format('MM / DD / YYYY')}
              </div>
            </>
          )}

          {isDropped && <div style={styles.doneText}>Done</div>}

          <div>
            <div
              className="hamburger-menu-card-contain pl-3"
              onClick={(e): void => {
                e.stopPropagation();
              }}
            >
              <i
                className="fal fa-ellipsis-v"
                onClick={(e): void => {
                  e.stopPropagation();
                  setIsMenuOpen(!isMenuOpen);
                }}
              ></i>
              {isMenuOpen && (
                <HamburgerMenu
                  collectionId={props.category.id}
                  setIsMenuOpen={setIsMenuOpen}
                >
                  <DeleteOption categoryId={props.category.id} />
                </HamburgerMenu>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const DroppedContent: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const { isDropped } = props;

    return (
      <>
        <div
          className={`detail-row-dropped-content ${isDropped && ' dropped'}`}
          tabIndex={0}
        >
          <BasicFields {...props} />
        </div>
      </>
    );
  },
);

const CategoryRow: any = observer((props: Props) => {
  const [isDropped, setIsDropped] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [hasDroppedClass, setHasDroppedClass] = useState(true);

  useEffect(() => {
    if (isDropped) {
      setHasDroppedClass(true);
    } else {
      setTimeout((): void => {
        setHasDroppedClass(false);
      }, 400);
    }
  }, [isDropped]);

  return (
    <>
      {isDropped && (
        <div
          className="detail-backsplash"
          onClick={(): void => {
            setIsDropped(false);
          }}
        ></div>
      )}
      <div
        className={`detail-view-wrapper ${hasDroppedClass && 'dropped'} ${
          isMenuOpen && 'hamburger-open'
        }`}
        tabIndex={0}
        onKeyUp={(e): void => {
          if (e.key === 'Enter') {
            if ((e.target as any).className.includes('dropped')) {
              setIsDropped(false);
            }
          }
          if (e.key === 'Escape') {
            setIsDropped(false);
          }
        }}
      >
        <RowHeader
          {...props}
          isDropped={isDropped}
          isMenuOpen={isMenuOpen}
          setIsDropped={setIsDropped}
          setIsMenuOpen={setIsMenuOpen}
        />
        <DroppedContent
          {...props}
          isDropped={isDropped}
          isMenuOpen={isMenuOpen}
          setIsDropped={setIsDropped}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </>
  );
});

const styles: any = {
  doneText: {
    color: '#4d4d4d',
    flex: 1,
    fontSize: '16px',
    fontWeight: 800,
    textAlign: 'right',
  },
  icon: {
    fontSize: '1.5rem',
  },
  updated: {
    textAlign: 'right',
  },
};

export default CategoryRow;
