export enum TreeState {
  ACTIVE_LIST = 'activeList',
  ACTIVE_VIEW_NAME = 'activeViewName',
  ROOT_VIEW = 'rootView',
  ROOT_VIEW_ITEMS = 'rootViewItems',
}

export enum ContentType {
  ITEM = 'ITEM',
  COLLECTION = 'COLLECTION',
}

export enum ContentApiType {
  ITEM = 'contentItem',
  COLLECTION = 'collection',
}

export enum ContentEvent {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export enum ManageCollection {
  ADD = 'add',
  REMOVE = 'remove',
}
