import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Input, Spinner } from 'reactstrap';

interface InternalState {
  isFocused: boolean;
  isLoading: boolean;
  inputValue: string;
}

interface Props {
  defaultValue: string;
  handleUpdate: (value: string) => void | Promise<any>;
}

class TapToEditText extends React.Component<Props, InternalState> {
  public state: InternalState = {
    inputValue: null,
    isFocused: false,
    isLoading: false,
  };

  private handleChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState({ inputValue: e.target.value });
  }

  private handleClick(): void {
    this.setState({ isFocused: true });
  }

  private handleBlur(): void {
    this.handleSaveEvent();
  }

  private handleKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
    if (e.key === 'Enter') {
      this.handleSaveEvent();
    }
  }

  private handleSaveEvent(): void {
    if (
      this.state.inputValue &&
      this.props.defaultValue !== this.state.inputValue
    ) {
      this.props.handleUpdate(this.state.inputValue);
    }

    this.setState({ isFocused: false });
  }

  private renderFocusedState(): JSX.Element {
    return (
      <Input
        autoFocus
        type="text"
        defaultValue={this.props.defaultValue}
        onBlur={(): void => this.handleBlur()}
        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
          this.handleChange(e)
        }
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void =>
          this.handleKeyDown(e)
        }
      />
    );
  }

  private renderBlurredState(): JSX.Element {
    return (
      <>
        <h5
          className="text-input-editable clickable"
          onClick={(): void => this.handleClick()}
        >
          {this.props.defaultValue}
        </h5>
        <i
          className="fas fa-pencil-alt ml-3 clickable"
          onClick={(): void => this.handleClick()}
        />
      </>
    );
  }

  public render(): JSX.Element {
    if (this.state.isLoading) {
      return (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      );
    }

    return (
      <>
        <div>
          {this.state.isFocused
            ? this.renderFocusedState()
            : this.renderBlurredState()}
        </div>
      </>
    );
  }
}

export default TapToEditText;
