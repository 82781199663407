import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import dayjs from 'dayjs';
import Routes from 'enums/routes.enum';
import { Recipients } from 'helpers/shareBundle.helper';

import { Bundle } from '@storyslab/storyslab.common.models';

interface Props {
  bundle: Bundle;
}

const BundleRow: any = (props: Props) => {
  const { bundle } = props;

  return (
    <>
      <div className="detail-view-wrapper">
        <Link
          to={Routes.EngagementBundle.replace(':shortCode', bundle.shortCode)}
        >
          <div className="detail-row-wrapper">
            <div className="detail-row-body">
              <Col md="3">
                <div style={styles.colSentBy}>
                  <div style={styles.font1}>{bundle.userData.email}</div>
                  <div style={styles.font2}>
                    {bundle.userData.lastName}, {bundle.userData.firstName}
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div style={{ ...styles.colRecipients, ...styles.font1 }}>
                  <Recipients recipients={bundle.recipients} />
                </div>
              </Col>
              <Col md="1">
                <div style={{ ...styles.colFiles, ...styles.font2 }}>
                  {bundle.itemCount}
                </div>
              </Col>
              <Col md="2">
                <div style={{ ...styles.colSentOn, ...styles.font2 }}>
                  {dayjs(bundle.sentOn).format('MM / DD / YYYY')}
                </div>
              </Col>
              <Col md="2">
                <div style={{ ...styles.colExpiration, ...styles.font2 }}>
                  {dayjs(bundle.expiration).format('MM / DD / YYYY')}
                </div>
              </Col>
              <Col md="1"></Col>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

const styles: any = {
  colExpiration: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  colFiles: {
    display: 'flex',
    justifyContent: 'center',
  },
  colRecipients: {},
  colSentBy: {
    display: 'flex',
    flexDirection: 'column',
  },
  colSentOn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  font1: {
    color: '#808080',
    fontSize: '10px',
    fontStyle: 'italic',
    fontWeight: 400,
  },
  font2: {
    color: '#808080',
    fontSize: '14px',
    fontWeight: 700,
  },
};

export default BundleRow;
