import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { collectionsStore, domainStore } from 'stores';

import CollectionRow from 'components/Collections/CollectionRow/CollectionRow';
import Loader from 'components/Indicators/Loader';

interface InternalProps {
  closeDrawer: () => void;
  newCollectionID: number;
  shouldStopPropagation?: boolean;
}
const Modal: any = observer((props: InternalProps) => {
  const { newCollectionID, closeDrawer, shouldStopPropagation } = props;

  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    collectionsStore
      .fetchItemById(newCollectionID, { domainStore })
      .then((): void => setIsLoading(false));
  }, [newCollectionID]);

  return (
    <div
      className="tree-modal-wrapper"
      onClick={(e): void => {
        if (shouldStopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      <div
        className="tree-uploader-bg"
        onClick={(): void => {
          closeDrawer();
        }}
      ></div>

      <div className="tree-modal-container">
        {isLoading && <Loader isFullPage={false} />}

        {!isLoading && (
          <>
            <CollectionRow
              collection={collectionsStore.items.get(newCollectionID)}
              isDroppedOnLoad={true}
              closeDrawer={closeDrawer}
            />
          </>
        )}
      </div>
    </div>
  );
});

export default Modal;
