/* eslint-disable react/no-find-dom-node */
import React, { KeyboardEvent } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import ReactDOM from 'react-dom';
import { Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { RowProps } from 'models/grid-table.model';

import SaveStatus from '../Indicators/SaveStatus';

interface InternalState {
  isEditing: boolean;
  showWarning: boolean;
  shouldWarn: boolean;
}

interface Props extends RowProps {}

@inject('domainStore')
@observer
class GridRow extends React.Component<Props, InternalState> {
  private alertRef: any = React.createRef();
  private rowRef: any = React.createRef();

  public state: InternalState = {
    isEditing: false,
    shouldWarn: false,
    showWarning: false,
  };

  public updated: string = null;

  constructor(props: Props) {
    super(props);
    this.handleExternalClick = this.handleExternalClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    if (this.isNew()) {
      this.state.isEditing = true;
    }

    this.updated = this.props.item.updated;
  }

  private isNew(): boolean {
    return this.props.item.id === this.props.store.newItemId;
  }

  private handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
    if (e.key === 'Enter') {
      this.handleRowPress(e);

      /*
       * Leaving this here incase we change our minds...
       * Enter will close row...
       * const htmlElement: HTMLElement = ReactDOM.findDOMNode(
       * this.props.tableNode,
       * ) as HTMLElement;
       * htmlElement.click();
       */
    }

    if (e.key === 'Escape') {
      this.handleDoneEditing();
    }
  }

  private handleExternalClick(event): void {
    const alertDomNode: Element | Text = ReactDOM.findDOMNode(
      this.alertRef.current,
    );
    const domNode: Element | Text = ReactDOM.findDOMNode(this);
    const parentDomNode: Element | Text = ReactDOM.findDOMNode(
      this.props.tableNode,
    );

    if (!domNode || parentDomNode.contains(event.target)) {
      if (domNode.contains(event.target)) {
        return;
      }

      if (alertDomNode && alertDomNode.contains(event.target)) {
        return;
      }

      this.handleDoneEditing();
      return;
    }
  }

  private handleRowPress(e: React.SyntheticEvent<EventTarget>): void {
    /*
     * Save this so that if we ever go back to want to close on row click we have the logic
     * const tagName: string = (e.target as HTMLElement).tagName.toLowerCase();
     */
    let clickTarget: any = e.target;
    while (clickTarget.classList !== undefined) {
      if (clickTarget.classList.contains('ignore-handle-click')) {
        return;
      }
      clickTarget = clickTarget.parentNode;
    }
    if (
      !this.state.isEditing
      /*
       * &&
       * (tagName === 'div' || (!this.state.isEditing && tagName === 'h5'))
       */
    ) {
      this.setState((prevState: InternalState) => ({
        isEditing: !prevState.isEditing,
      }));
    }
  }

  private handleDoneEditing(clearWarning?: boolean): void {
    if (!clearWarning && this.state.shouldWarn && this.state.isEditing) {
      this.setState({
        shouldWarn: false,
        showWarning: true,
      });
      return;
    }

    if (this.state.isEditing === true) {
      const { item, store, domainStore }: Props = this.props;

      if (this.isNew() && !this.isDirty()) {
        store.deleteItem({ domainStore, id: item.id });
      } else {
        store.removeNewId();
        this.setState({ isEditing: false });
      }
    }

    this.setState({ shouldWarn: false });
  }

  private isDirty(): boolean {
    return this.updated !== this.props.item.updated;
  }

  public componentDidMount(): void {
    document.addEventListener('click', this.handleExternalClick, true);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('click', this.handleExternalClick, true);
  }

  public render(): JSX.Element {
    const { item, store, ExpandedRowContents, RowContents }: Props = this.props;

    return (
      <>
        <div
          className={`item-row-wrapper mx-3${this.isNew() ? ' is-new' : ''}`}
          tabIndex={0}
          ref={this.rowRef}
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void =>
            this.handleKeyDown(e)
          }
        >
          <Row
            className={`mx-1 py-1 invert-child-colors-on-new${
              !this.state.isEditing ? ' clickable' : ''
            }`}
            onClick={(e): void => this.handleRowPress(e)}
          >
            <RowContents {...this.props} isEditing={this.state.isEditing} />
          </Row>

          {/* {!this.isNew() && (
            <Row className="mx-1 py-3" onClick={() => this.handleRowPress()}>
              <RowContents {...this.props} isEditing={this.state.isEditing} />
            </Row>
          )} */}
          {this.state.isEditing && (
            <>
              <div className="indicator-save-status-wrapper right">
                <SaveStatus saving={store.isLoadingItem} />
              </div>

              <ExpandedRowContents
                rowRef={this.rowRef}
                handleShouldWarn={(shouldWarn): void => {
                  this.setState({
                    shouldWarn: shouldWarn,
                  });
                }}
                handleDone={(clearWarning): void =>
                  this.handleDoneEditing(clearWarning)
                }
                isOpen={this.state.isEditing}
                item={item}
                store={store}
              />
            </>
          )}
        </div>
        {this.state.showWarning && (
          <ReactBSAlert
            warning
            showCancel
            ref={this.alertRef}
            style={{ display: 'block' }}
            title="Keep editing?"
            onConfirm={(): void => {
              this.setState({
                shouldWarn: true,
                showWarning: false,
              });
            }}
            onCancel={(): void => {
              this.setState({
                isEditing: false,
                showWarning: false,
              });
            }}
            confirmBtnBsStyle="warning"
            confirmBtnText="Yes, keep editing!"
            cancelBtnText="No, discard changes"
            btnSize=""
          >
            It looks like you were in the middle of making changes to something
            important.
          </ReactBSAlert>
        )}
      </>
    );
  }
}

export default GridRow;
