/* eslint-disable max-lines-per-function */
import React, { Dispatch, SetStateAction, useState } from 'react';
import ReactDOM from 'react-dom';
import { contentStore, domainStore, simpleListsStore } from 'stores';

import { PayloadActions } from '@storyslab/storyslab.common.models';

interface Props {
  activeMenu: string;
  contentItem: any;
  idList: string;
  isDropped: boolean;
  listType: string;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
}

function RenderOption(props: {
  checked: boolean;
  contentItem: any;
  id: number;
  idList: string;
  listType: string;
}): JSX.Element {
  const isChecked: boolean = props.checked;

  function renderIcon(): JSX.Element {
    return (
      <>
        {/* TODO put thumbnail src in store */}
        <i className="fas fa-sitemap"></i>
      </>
    );
  }

  return (
    <>
      <div
        className="detail-checkbox"
        onClick={(): void => {
          contentStore.updateItem({
            body: {
              [props.idList]: {
                action: isChecked ? PayloadActions.REMOVE : PayloadActions.ADD,
                ids: props.id.toString(),
              },
            },
            domainStore,
            id: props.contentItem.id,
          });
        }}
      >
        <div className="input-contain">
          {isChecked && <i className="fas fa-check"></i>}
        </div>
        {renderIcon()}
        <span>{simpleListsStore[props.listType].get(props.id).name}</span>
      </div>
    </>
  );
}

function getLabel(ids: Array<number>, listType: string): string {
  if (ids.length > 1) {
    return `${ids.length} ${listType}`;
  } else if (ids.length === 1) {
    return simpleListsStore[listType].get(ids[0])?.name;
  }
  return '---';
}

function Search(props: {
  listType: string;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}): JSX.Element {
  return (
    <>
      <div className="detail-search">
        <i className="fas fa-search pl-2 pr-2"></i>
        <input
          autoFocus={true}
          type="text"
          placeholder={`search ${props.listType}...`}
          value={props.searchTerm}
          onChange={(e): void => {
            props.setSearchTerm(e.target.value.toLowerCase());
          }}
        />
      </div>
    </>
  );
}

function DetailSelect(props: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  function Dropdown(props: Props): JSX.Element {
    const root: any = document.getElementById('root');
    const el: any = document.createElement('div');
    root.appendChild(el);

    const rect: any = document
      .getElementById(`${props.listType}-select`)
      .getBoundingClientRect();

    return ReactDOM.createPortal(
      <>
        <div
          className="backsplash"
          onClick={(): void => {
            setIsOpen(false);
          }}
        ></div>
        <div
          className="detail-dropdown"
          style={{
            left: rect.right - (rect.right - rect.left),
            top: rect.top - 10,
            width: rect.right - rect.left,
          }}
          tabIndex={1}
        >
          <div className="detail-header">
            <Search
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              listType={props.listType}
            />
          </div>
          <div className="detail-body">
            {props.contentItem[props.idList].map((id, index) => (
              <RenderOption
                checked={true}
                id={id}
                idList={props.idList}
                key={index}
                listType={props.listType}
                contentItem={props.contentItem}
              />
            ))}
            <div className="detail-separator"></div>
            {Array.from(simpleListsStore[props.listType].keys())
              .filter((id: number) =>
                props.contentItem[props.idList]
                  ? props.contentItem[props.idList].indexOf(id) === -1
                  : true,
              )
              .filter((id: number) => {
                return simpleListsStore[props.listType]
                  .get(id)
                  .name.toLowerCase()
                  .indexOf(searchTerm) === -1
                  ? false
                  : true;
              })
              .map((id: number, index) => (
                <RenderOption
                  checked={false}
                  contentItem={props.contentItem}
                  id={id}
                  idList={props.idList}
                  key={index}
                  listType={props.listType}
                />
              ))}
          </div>
        </div>
      </>,
      el,
    );
  }

  return (
    <>
      <div className="storyslab-multiselect" id={`${props.listType}-select`}>
        <div className="button" onClick={(): void => setIsOpen(!isOpen)}>
          <span className="multi-edit-button-title">
            {getLabel(props.contentItem[props.idList], props.listType)}
          </span>
          <i className={`fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>
        </div>
        {isOpen && <Dropdown {...props} />}
      </div>
    </>
  );
}

export default DetailSelect;
