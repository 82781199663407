import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { domainStore, uiStore } from 'stores';
import { BaseStore } from 'stores/base.store';

import Loader from 'components/Indicators/Loader';

interface Props {
  createMeta: any;
  home: string;
  layoutImage: any;
  redirection?: string;
  store: BaseStore<any>;
  title: string;
}

const ViewLayoutSelector: (props: Props) => JSX.Element = (props: Props) => {
  const { createMeta, home, layoutImage, redirection, store, title } = props;

  const [viewId, setViewId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  function createView(): void {
    setIsCreating(true);
    store
      .createItem({
        domainStore,
        meta: createMeta,
      })
      .then(() => {
        setViewId(store.newItemId);
      });
  }

  return (
    <>
      {isCreating && <Loader isFullPage={true} />}
      {viewId && <Redirect to={redirection.replace(':id', viewId)} />}
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <Link to={home}>
            <i className="fas fa-stream" /> {title}
          </Link>{' '}
          : Choose Layout
        </h4>
        <div>
          Right now we only have one primary template, but many more will be on
          their way! If the main template does not suit your needs get in touch,
          we can craft what you need.
        </div>
        <div style={styles.container}>
          <div style={styles.side}>
            <img
              style={styles.preview}
              alt="standard layout"
              src={layoutImage}
              onClick={(): void => {
                createView();
              }}
            />
          </div>
          <div style={styles.side}></div>
        </div>
      </Container>
    </>
  );
};

const styles: any = {
  container: {
    display: 'flex',
    marginTop: '3rem',
  },
  preview: {
    cursor: 'pointer',
    width: '100%',
  },
  side: {
    flex: 1,
    marginRight: '.5rem',
    overflow: 'hidden',
  },
};

export default ViewLayoutSelector;
