import React, { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import Routes from 'enums/routes.enum';

import SimpleToggle from 'components/Forms/SimpleToggle';

interface Props {
  components: any;
  defaultKey: string;
  setKey: Dispatch<SetStateAction<string>>;
  toggleVisibility: (value, index) => void;
  viewId: string;
}

const ComponentList: (props: Props) => JSX.Element = (props: Props) => {
  return (
    <>
      {props.components.map((item, index) => {
        return (
          <div
            key={`component-${item.key}`}
            style={styles.wrapper}
            className={`component-control-wrapper ${
              item.visibility ? '' : 'notVisible'
            }`}
            onMouseOver={(): void => {
              props.setKey(item.previewS3key);
            }}
            onMouseOut={(): void => {
              props.setKey(props.defaultKey);
            }}
          >
            <Link
              to={Routes.ViewConfigurationComponentEdit.replace(
                ':id',
                props.viewId,
              ).replace(':key', item.key)}
              style={{ width: '100%' }}
            >
              <div style={styles.contain} className="component-control-contain">
                {item.name}{' '}
                {item.target && (
                  <i className="fas fa-check" style={styles.icon} />
                )}
              </div>
            </Link>
            <SimpleToggle
              didCheckOnLoad={item.visibility}
              handleChange={(value): void => {
                props.toggleVisibility(value, index);
              }}
            />
          </div>
        );
      })}
    </>
  );
};

const styles: any = {
  contain: {
    alignItems: 'center',
    borderRadius: '8px',
    display: 'flex',
    flex: 1,
    fontSize: '16px',
    fontWeight: 700,
    height: '70px',
    justifyContent: 'space-between',
    marginRight: '14px',
    padding: '20px 25px',
  },
  icon: {
    fontSize: '30px',
  },
  wrapper: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    margin: '.5rem',
  },
};

export default ComponentList;
