import React from 'react';

const EmptyStack: any = () => {
  return (
    <div>
      <div className="empty-stack-text">Nothing to see here!</div>
      <div className="empty-stack-text">Click below to get started</div>
    </div>
  );
};

export default EmptyStack;
