import { domainStore } from '../stores/domain.store';

export function setSubdomain(): string {
  const subDomainAsArray: Array<string> = window.location.host.split('.');

  const subdomain: string = subDomainAsArray[1] ? subDomainAsArray[0] : null;

  if (!subdomain) {
    domainStore.clearSubdomain();
  }

  if (subdomain && subdomain !== 'auth') {
    domainStore.setSubdomain(subdomain);
  }

  return subdomain;
}
