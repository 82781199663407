import React from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
} from 'reactstrap';
import moment from 'moment';

import { PayloadActions } from '@storyslab/storyslab.common.models';

import EditableTextColumn from '../../components/GridTable/RowColumns/EditableText';
import { RowProps } from '../../models/grid-table.model';

interface Props extends RowProps {
  isEditing: boolean;
  parentModalId: number;
  handleUpdate?: () => void;
}

class RowContents extends React.Component<Props, null> {
  private handleAddRequest(): void {
    this.handleUpdate(PayloadActions.ADD);
  }

  private handleRemoveRequest(): void {
    this.handleUpdate(PayloadActions.REMOVE);
  }

  private handleUpdate(payloadAction: PayloadActions): void {
    const {
      handleUpdate,
      store,
      item,
      domainStore,
      parentModalId,
    } = this.props;

    store.updateItem({
      body: {
        groupIds: {
          action: payloadAction,
          ids: parentModalId.toString(),
        },
      },
      domainStore,
      id: item.id,
    });

    if (store.hasFilters) {
      store.deleteItem({
        domainStore: domainStore,
        id: item.id,
        isLocalOnly: true,
      });
    }

    handleUpdate && handleUpdate();
  }

  private renderDropdown(): JSX.Element {
    return (
      <UncontrolledDropdown nav className="float-right">
        <DropdownToggle className="nav-link pr-0" color="" tag="a">
          <Media>
            <i className="fas fa-ellipsis-v ml-3" />
          </Media>
        </DropdownToggle>
        <DropdownMenu right>
          <Link to={''}>
            <DropdownItem>
              <span>More Info</span>
            </DropdownItem>
          </Link>
          <Link to={''}>
            <DropdownItem>
              <span>Preview</span>
            </DropdownItem>
          </Link>
          {/* <DropdownItem divider /> */}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }

  public render(): JSX.Element {
    const { item, store, isEditing, parentModalId }: Props = this.props;

    const isAddable: boolean =
      !item?.groupIds || item?.groupIds?.indexOf(parentModalId) === -1;

    return (
      <>
        <EditableTextColumn
          canEdit={isEditing}
          columnProps={{ lg: '8', md: '7' }}
          id={item.id}
          store={store}
          value={item.name}
          Icon={(): JSX.Element => (
            <i className={`far fa-file-pdf text-xl ml-1 item-row-icon`}></i>
          )}
        />

        <Col md="2" lg="2">
          <Button
            className="btn-icon-only rounded-circle"
            color="default"
            type="button"
          >
            <span className="btn-inner--icon">
              <i className="fas fa-folder-tree" />
            </span>
          </Button>
        </Col>
        <Col md="3" lg="2" style={{ textAlign: 'right' }}>
          <h5>
            <TimeAgo date={moment(item.updated).toDate()} minPeriod="30" />
          </h5>

          <div className="float-right ml-4">
            {isAddable ? (
              <button
                className="addButton"
                onClick={(): void => this.handleAddRequest()}
              >
                <i className="fas fa-plus text-lg"></i>
              </button>
            ) : (
              <button
                className="removeButton"
                onClick={(): void => this.handleRemoveRequest()}
              >
                <i className="fas fa-times text-lg"></i>
              </button>
            )}
          </div>

          {this.renderDropdown()}
        </Col>
      </>
    );
  }
}

export default RowContents;
