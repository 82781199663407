import React from 'react';

import S3CmsDataImage from 'components/Assets/S3CmsDataImage';

interface Props {
  activePreviewKey: string;
  components: any;
  defaultKey: string;
}

const LayoutPreview: (props: Props) => JSX.Element = (props: Props) => {
  const { activePreviewKey, defaultKey } = props;

  return (
    <>
      <S3CmsDataImage
        style={{
          ...styles.preview,
          display: [activePreviewKey === defaultKey ? 'block' : 'none'],
        }}
        s3Key={props.defaultKey}
      />
      {props.components.map((item, index) => {
        return (
          <S3CmsDataImage
            key={`preview-${item.key}`}
            style={{
              ...styles.preview,
              display: [
                activePreviewKey === item.previewS3key ? 'block' : 'none',
              ],
            }}
            s3Key={item.previewS3key}
          />
        );
      })}
    </>
  );
};

const styles: any = {
  preview: {
    width: '100%',
  },
};

export default LayoutPreview;
