/* eslint-disable multiline-comment-style */
/* eslint-disable capitalized-comments */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import {CmsFieldLinkingOption, Props as ViewComponentProp}  from '@storyslab/storyslab.common.models';
import HexCodePicker from 'components/Forms/HexCodePicker';
import SimpleSelect, { SimpleSelectOption } from 'components/Forms/SimpleSelect';
import SimpleTextInput from 'components/Forms/SimpleTextInput';
import SimpleToggle from 'components/Forms/SimpleToggle';
import StandardContentContain from 'components/SettingsAndFeatures/elements/StandardContentContain';

import ViewAssetUploader from './ViewAssetUploader';

interface Props {
  item: ViewComponentProp;
  keyName: string;
  persistChange: (localItem, keyName) => void;
}

const ComponentItemProp: (props: Props) => JSX.Element = (props: Props) => {
  const { item, keyName, persistChange } = props;

  const [localItem, setLocalItem] = useState(item);

  const [shouldShowInputElement, setShouldShowInputElement] = useState<boolean>(true);
  const [shouldShowLinkOptions, setShouldShowLinkOptions] = useState<boolean>(false);
  const [defaultLinkOption, setDefaultLinkOption] = useState<string|number|boolean>(item.value);

  const updateLocalItem: (value: string) => void = (value: string) => {
    const newLocalItem: ViewComponentProp = _.cloneDeep(localItem);
    newLocalItem.value = value;
    setLocalItem(newLocalItem);
  };

  useEffect(() => {
    // TODO: this makes a put request everytime a field is changed or character typed.. pretty heavy load on edge (getView, pushS3Config, updateView, getS3Config)
    // TODO: Maybe some kind of debouncing on localItem or only trigger on change onBlur? Or only push changes when clicked save all? Or maybe it doesn't even matter
    persistChange(localItem, keyName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localItem]);

  useEffect(()=>{
    const hasLinkOptions:boolean = typeof(item.cmsFieldLinkingOptions) !== "undefined";
    setShouldShowLinkOptions(hasLinkOptions);

    if (hasLinkOptions){
      let isItemValueInLinkOptions: boolean = false;
      item.cmsFieldLinkingOptions.forEach((option: CmsFieldLinkingOption):void=>{
        if (option.value === item.value){
          isItemValueInLinkOptions = true;
          return;
        }
      })
      const newDefaultOption: any = isItemValueInLinkOptions ? item.value : "static"
      setDefaultLinkOption(newDefaultOption)
      setShouldShowInputElement(newDefaultOption === "static")
    }
  },[item])


  function renderLinkingOptionsSelect(): JSX.Element{
    return (<div><SimpleSelect
      initialValue={defaultLinkOption}
      options={item.cmsFieldLinkingOptions.map((option: CmsFieldLinkingOption)=>{
        return {text:option.displayName, value: option.value} as SimpleSelectOption
      })}
      handleChange={(value:any):void=>{
        if (value === "static"){
          setShouldShowInputElement(true);
          updateLocalItem(null);
        }
        else {
          // TODO: If type == "imageUpload" then delete the asset so it's not orphaned???
          setShouldShowInputElement(false);
          updateLocalItem(value);
        }
      }}
    /></div>)
  }

  let element: JSX.Element;
  switch (localItem.type) {
    case 'boolean':
      element = (
        <SimpleToggle
          didCheckOnLoad={localItem.value as boolean}
          handleChange={(value): void => {
            updateLocalItem(value);
          }}
        />
      );
      break;
    case 'asset':
      //TODO: Switching from an uploaded asset to linked asset will *guarantee* the asset is orphaned in postgres since changes are persisted instantly
     element = (
      <ViewAssetUploader
        initialAssetId={localItem.value as number}
        onFailed={(event:any):void =>{console.error(event);}}
        onComplete={(assetId:any, s3Key:any):void=>{
          updateLocalItem(assetId);
        }}/>
     )
      break;
    case 'string':
      element = (
        <SimpleTextInput
          initialValue={localItem.value as string}
          handleChange={(value): void => {
            updateLocalItem(value);
          }}
        />
      );
      break;
    case 'integer':
      element = (
        <SimpleTextInput
          initialValue={localItem.value as string}
          handleChange={(value): void => {
            updateLocalItem(value);
          }}
          regex={new RegExp('\\D+')}
        />
      );
      break;
    case 'color':
      element = (
        <HexCodePicker
          colorCode={localItem.value as string}
          handleChange={(value): void => {
            updateLocalItem(value);
          }}
        />
      );
      break;
    default:
      element = <></>;
      break;
  }

  return (
    <StandardContentContain bg="none">
      <div>
        <div className="settings-subtitle">{item.name}</div>
        {item.description}
      </div>
      <div>
        {shouldShowLinkOptions && renderLinkingOptionsSelect()}
        {shouldShowInputElement && element}
      </div>
    </StandardContentContain>
  );
};

export default ComponentItemProp;
