import React, { useEffect, useState } from 'react';

interface Props {
  handleChange: (boolean, field?: string) => void;
  initialValue: string;
  isDisabled?: boolean;
  regex?: RegExp;
}

export default function SimpleTextInput(props: Props): JSX.Element {
  const { handleChange, initialValue, isDisabled, regex } = props;
  const [value, setValue] = useState(initialValue);
  const [isPageLoad, setIsPageLoad] = useState(true);

  useEffect(() => {
    if (isPageLoad) {
      setIsPageLoad(false);
    } else {
      handleChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <input
        onChange={(e): void => {
          if (regex) {
            setValue(e.target.value.replace(regex, ''));
          } else {
            setValue(e.target.value);
          }
        }}
        type="text"
        size={(value?.length || 0) + 1}
        style={{
          background: 'none',
          border: 'none',
          borderBottom: '1px solid #4d4d4d',
          height: '20px',
          textAlign: 'center',
        }}
        value={value || ""}
        disabled={isDisabled}
      />
    </>
  );
}
