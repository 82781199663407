import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ChangePassword from 'pages/Auth/ChangePassword';
import ForgotPage from 'pages/Auth/ForgotPassword';

import Login from '../pages/Auth/Login';
import { LoginCode } from '../pages/Auth/LoginCode';

export default class Unauthenticated extends React.Component {
  public render(): JSX.Element {
    return (
      <Switch>
        <Route
          path="/auth/login/change-password/:code"
          render={(props: any): JSX.Element => <ChangePassword />}
        />
        <Route
          path="/auth/login/forgot"
          render={(props: any): JSX.Element => <ForgotPage {...props} />}
        />
        <Route
          path="/auth/login"
          render={(props: any): JSX.Element => <Login {...props} />}
        />
        <Route
          path="/auth/code"
          render={(props: any): JSX.Element => <LoginCode {...props} />}
        />
        <Redirect from="*" to="/auth/login" />
      </Switch>
    );
  }
}
