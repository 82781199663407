import React from 'react';

const Input: any = (props: any) => {
  const {
    className,
    labelClassName,
    style,
    labelStyle,
    labelWithFilesStyle,
    getFilesFromEvent,
    accept,
    multiple,
    disabled,
    onFiles,
    files,
  } = props;

  return (
    <label
      className={files.length > 0 ? 'btn storyslab-button' : labelClassName}
      style={files.length > 0 ? labelWithFilesStyle : labelStyle}
    >
      {files.length > 0 && (
        <>
          <span className="btn-inner--icon mr-1">
            <i className="fal fa-plus text-lg"></i>
          </span>
          <span className="btn-inner--text">Upload file(s) from computer</span>
        </>
      )}

      {files.length === 0 && (
        <span className="text-center dropzone-button-wrapper">
          <p>Drop Files to Upload</p>
          <p>or</p>
          <span className="btn-neutral btn-icon btn btn-default">
            <span className="btn-inner--text">
              Upload file(s) from computer
            </span>
          </span>
        </span>
      )}

      <input
        className={className}
        style={style}
        type="file"
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={async (e): Promise<any> => {
          const target: any = e.target;
          const chosenFiles: any = await getFilesFromEvent(e);
          onFiles(chosenFiles);
          target.value = null;
        }}
      />
    </label>
  );
};

export default Input;
