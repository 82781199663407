/* eslint-disable max-lines-per-function */
import 'react-dropzone-uploader/dist/styles.css';

import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone-uploader';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { ManageCollection } from 'enums/contentTree.enum';
import { DropzoneContexts } from 'enums/dropzone.enum';
import { EdgeUrls } from 'enums/edge.enum';
import { knownFileTypes } from 'helpers/files.helper';
import { ManageCollectionProps } from 'interfaces';
import { inject, observer } from 'mobx-react';
import { DomainStoreModel } from 'models/domain.model';
import { UiStoreModel } from 'models/ui.model';
import { standardPost } from 'services/edge.service';
import { getAutoGroups } from 'services/groups.service';
import { manageCollection } from 'services/tree.service';
import { collectionsStore, contentStore, domainStore } from 'stores';

import {
  ContentItemCreate,
  ContentItemType,
  PayloadActions,
} from '@storyslab/storyslab.common.models';
import InlineInput from 'components/Dropzone/InlineInput';
import InlineLayout from 'components/Dropzone/InlineLayout';
import UploadPreview from 'components/Dropzone/UploadPreview';

import DropzoneLayout from './DropzoneLayout';
import DropzoneTreeLayout from './DropzoneTreeLayout';
import StorySlabInputContent from './StorySlabInputContent';

interface InternalState {
  isShowingToast: boolean;
}

interface Props {
  activeList?: number;
  closeModal?: () => void;
  context: DropzoneContexts;
  domainStore?: DomainStoreModel;
  isInline?: boolean;
  uiStore?: UiStoreModel;
  urlId?: number;
}

interface StatusParams {
  meta: { item: { id: number } };
}

const ErrMessage: any = (props: any) => {
  const { close } = props;
  const [animateUp, setAnimateUp] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setAnimateUp('animate-up');
    }, 150);
    setTimeout(() => {
      setAnimateUp('');
    }, 5000);
    setTimeout(() => {
      close({ isShowingToast: false });
    }, 7000);
  }, [close]);

  return (
    <>
      <div
        className={`toast-container ${animateUp}`}
        onClick={(): void => {
          close({ isShowingToast: false });
        }}
      >
        <Toast>
          <ToastHeader>Bad file input</ToastHeader>
          <ToastBody>{`We can't process that file.`}</ToastBody>
        </Toast>
      </div>
    </>
  );
};

@inject('domainStore')
@inject('uiStore')
@observer
class ContentItemsAddSourceComputer extends React.Component<
  Props,
  InternalState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowingToast: false,
    };
  }

  private async handleGetUploadParams({
    meta,
  }: {
    meta: ContentItemCreate;
  }): Promise<void> {
    const { urlId } = this.props;

    let url: string;
    let postBody: ContentItemCreate = meta;

    switch (this.props.context) {
      case DropzoneContexts.ContentItemsPage:
        url = EdgeUrls.ContentItems;
        postBody = {
          name: meta.name,
          target: meta,
          type: ContentItemType.ASSET,
        };
        break;
      case DropzoneContexts.ContentTree:
        url = EdgeUrls.ContentItems;
        postBody = {
          name: meta.name,
          target: meta,
          type: ContentItemType.ASSET,
        };
        break;
      case DropzoneContexts.CollectionAddBackground:
        url = EdgeUrls.CollectionImageUpload.replace(':id', urlId.toString());
        break;
      case DropzoneContexts.CollectionAddThumbnail:
        url = EdgeUrls.CollectionThumbnailUpload.replace(
          ':id',
          urlId.toString(),
        );
        break;
      case DropzoneContexts.ContentItemAddThumbnail:
        url = EdgeUrls.ContentItemThumbnailUpload.replace(
          ':id',
          urlId.toString(),
        );
        break;
      default:
        url = '';
    }

    return await standardPost({
      body: postBody,
      url,
    })
      .then((response) => {
        let item: any;

        switch (this.props.context) {
          case DropzoneContexts.ContentItemsPage:
          case DropzoneContexts.ContentTree:
            item = response.contentItem;
            contentStore.setItem(item);
            contentStore.updateItem({
              body: {
                groupIds: {
                  action: PayloadActions.ADD,
                  ids: getAutoGroups(),
                },
              },
              domainStore,
              id: response.contentItem.id,
            });
            break;
          case DropzoneContexts.ContentItemAddThumbnail:
            item = response.contentItem;
            break;
          case DropzoneContexts.CollectionAddBackground:
          case DropzoneContexts.CollectionAddThumbnail:
            item = response.collection;
            break;
          default:
            item = {};
        }
        return { ...response.s3PresignedPost, meta: { item } };
      })
      .catch((error) => {
        this.setState({ isShowingToast: true });
      });
  }

  public handleChangeStatus(params: StatusParams, status: unknown): void {
    if (status === 'done') {
      const { activeList, closeModal, urlId } = this.props;

      switch (this.props.context) {
        case DropzoneContexts.CollectionAddBackground:
        case DropzoneContexts.CollectionAddThumbnail:
          collectionsStore.fetchItemById(urlId, { domainStore });
          closeModal();
          break;
        case DropzoneContexts.ContentItemAddThumbnail:
          contentStore.fetchItemById(urlId, { domainStore });
          closeModal();
          break;
        default:
          break;
      }

      if (activeList) {
        const apiParams: ManageCollectionProps = {
          action: ManageCollection.ADD,
          activeList,
          targetID: params.meta.item.id,
          url: EdgeUrls.ContentItem.replace(
            ':id',
            params.meta.item.id.toString(),
          ),
        };
        manageCollection(apiParams);
      }
    }
  }

  public render(): JSX.Element {
    let layoutComponent: any = DropzoneLayout;
    let inputComponent: any = StorySlabInputContent;

    if (this.props.activeList) {
      layoutComponent = DropzoneTreeLayout;
    }

    if (this.props.isInline) {
      inputComponent = InlineInput;
      layoutComponent = InlineLayout;
    }

    return (
      <>
        <Dropzone
          getUploadParams={this.handleGetUploadParams.bind(this)}
          accept={knownFileTypes()}
          canCancel={false}
          canRemove={false}
          onChangeStatus={this.handleChangeStatus.bind(this)}
          InputComponent={inputComponent}
          LayoutComponent={layoutComponent}
          PreviewComponent={UploadPreview as any}
        />
        {this.state.isShowingToast && (
          <ErrMessage close={this.setState.bind(this)} />
        )}
      </>
    );
  }
}

export default ContentItemsAddSourceComputer;
