import React from 'react';
import { Button, CardBody, Form, Spinner, UncontrolledAlert } from 'reactstrap';
import { capitalize } from 'lodash';
import { inject, observer } from 'mobx-react';

import { createColorCss } from '../../helpers/component-color.helper';
import { DomainStoreModel } from '../../models/domain.model';
import { UiStoreModel } from '../../models/ui.model';
import {
  authenticateAppUser,
  login,
  setJwtToDomainStore,
} from '../../services/auth.service';
import { SSFormGroup } from '../Forms/FormGroup';

interface InternalState {
  error: string;
  focusedEmail?: boolean;
  focusedPassword?: boolean;
  isLoading: boolean;

  primaryColor: string;
  updatedAttributes: { [key: string]: any };
}

interface Props {
  code?: string;
  username: string;
  password?: string;
  domainStore?: DomainStoreModel;
  uiStore?: UiStoreModel;
  requiredAttributes: Array<string>;
}

@inject('domainStore')
@inject('uiStore')
@observer
export class RequiredAttributes extends React.Component<Props, InternalState> {
  public state: InternalState = {
    error: null,
    focusedEmail: false,
    focusedPassword: false,
    isLoading: false,
    primaryColor: '',
    updatedAttributes: {},
  };

  private renderUpdateButton(): JSX.Element {
    return (
      <div className="text-center">
        <Button
          className="my-4"
          color="info"
          style={createColorCss(this.props.uiStore, [
            ['backgroundColor', 'primary'],
          ])}
          type="button"
          onClick={(): Promise<void> => this.handlePressUpdate()}
          disabled={
            this.state.updatedAttributes.new_password !==
            this.state.updatedAttributes.confirm_password
          }
        >
          {this.state.isLoading ? (
            <>
              {' '}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{' '}
            </>
          ) : null}
          Update
        </Button>
      </div>
    );
  }

  private async handlePressUpdate(): Promise<void> {
    if (!this.isFormValid()) {
      this.setState({
        error: 'It looks like some of the fields are missing information',
      });
    } else {
      this.setState({ isLoading: true }, async () => {
        const [resp, error] = await login({
          ...this.state.updatedAttributes,
          applicationId: this.props.domainStore.applicationId,
          [this.props.password ? 'password' : 'code']: [
            this.props.password ? this.props.password : this.props.code,
          ],
          tenantId: this.props.domainStore.tenantId,
          username: this.props.username,
        });

        if (!error && resp.data.access_token) {
          setJwtToDomainStore(resp.data);
          const [, appAuthErr] = await authenticateAppUser();
          if (appAuthErr) {
            console.error(appAuthErr);
            this.setState({ error: appAuthErr, isLoading: false });
          }
          this.setState({ isLoading: false });
        } else {
          this.setState({ error, isLoading: false });
        }
      });
    }
  }

  private buildPlaceholder(value: string): string {
    switch (value) {
      case 'userAttributes.family_name':
        return 'Last name';
      case 'userAttributes.given_name':
        return 'First name';
      case 'userAttributes.email':
        return 'Email';
      default:
        return capitalize(value.split('_').join(' '));
    }
  }

  private isFormValid(): boolean {
    for (let i: number = 0; i < this.props.requiredAttributes.length; i++) {
      const attribute: string = this.props.requiredAttributes[i];
      if (
        !this.state.updatedAttributes[attribute] ||
        this.state.updatedAttributes[attribute].length < 3
      ) {
        return false;
      }
    }

    return true;
  }

  private renderField(value: string): JSX.Element {
    return (
      <>
        <SSFormGroup
          placeholder={this.buildPlaceholder(value)}
          keyName={value}
          onChange={(itemValue: string, keyName: string): void => {
            this.setState((prevState: InternalState) => ({
              updatedAttributes: {
                ...prevState.updatedAttributes,
                [keyName]: itemValue,
              },
            }));
          }}
          type={value.indexOf('password') !== -1 ? 'password' : 'text'}
        />
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <CardBody className="px-lg-5 py-lg-5">
        <div className="text-center text-muted mb-4">
          <small>
            To finish up your enrollment please update the items below.
          </small>
        </div>

        <UncontrolledAlert
          isOpen={!!this.state.error}
          color="danger"
          onClick={(): void => this.setState({ error: null })}
        >
          <span className="alert-text">{this.state.error}</span>
        </UncontrolledAlert>
        <Form role="form">
          {this.props.requiredAttributes.includes('userAttributes.given_name') &&
            this.renderField('userAttributes.given_name')}
          {this.props.requiredAttributes.includes('userAttributes.family_name') &&
            this.renderField('userAttributes.family_name')}
          {this.props.requiredAttributes.includes('userAttributes.email') &&
            this.renderField('userAttributes.email')}
          {this.props.requiredAttributes.includes('new_password') && (
            <>
              {this.renderField('new_password')}
              {this.renderField('confirm_password')}
            </>
          )}

          <div className="text-center text-muted mb-4">
            <small>
              Password must contain eight characters, a number, an uppercase,
              and a symbol.
            </small>
          </div>

          {this.renderUpdateButton()}
        </Form>
      </CardBody>
    );
  }
}
