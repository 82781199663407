/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Card, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import CheckboxList from 'components/GridTable/FilterDropdowns/CheckboxList';
import AddUsersModal from 'components/Users/AddUsersModal';
import UsersRow from 'components/Users/UsersRow';

import GridTable from '../../components/GridTable';
import GridTableCardFooter from '../../components/GridTable/CardFooter';
import GridTableCardHeader from '../../components/GridTable/CardHeader';
import DoubleDatePicker from '../../components/GridTable/FilterDropdowns/DoubleDatePicker';
import ExpandedRowContents from '../../components/Users/ExpandedRowContents';
import RowContents from '../../components/Users/RowContents';
import { Action } from '../../models/action.model';
// Core components
import { BaseProps } from '../../models/base.model';
import { HeaderColumnConfig } from '../../models/grid-table.model';

// const lastThreeMonths: string = `${moment()
//   .subtract(2, 'months')
//   .format('MMM')}\u00a0\u00a0\u00a0\u00a0\u00a0${moment()
//   .subtract(1, 'months')
//   .format('MMM')}\u00a0\u00a0\u00a0\u00a0\u00a0${moment().format('MMM')}`;

interface InternalState {
  isAddingUser: boolean;
}

interface Props extends BaseProps {}

@inject('simpleListsStore')
@inject('usersStore')
@inject('domainStore')
@inject('uiStore')
@observer
class Users extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];
  constructor(props: Props) {
    super(props);
    this.state = {
      isAddingUser: false,
    };

    this.headerColumnConfigs = [
      {
        colProps: {
          md: '3',
        },
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'first_name', // Todo: map to firstName in edge
        store: this.props.usersStore,
        title: 'Name',
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <CheckboxList
            itemKey={'roleIds'} // TODO: roleIds ?
            simpleList={this.props.simpleListsStore.userRoles}
            store={this.props.usersStore.filters}
            handleAction={(action: Action): void => {
              this.props.usersStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isCentered: true,
        isFilterable: true, // TODO: Can't filter on virtual fields - [sc2931]
        isSortable: false, // TODO: This sorts by the actual ID not the resolved role name
        itemKey: 'role_ids',
        store: this.props.usersStore,
        title: 'ROLE',
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <CheckboxList
            itemKey={'status'}
            simpleList={this.props.simpleListsStore.userStatuses}
            store={this.props.usersStore.filters}
            handleAction={(action: Action): void => {
              this.props.usersStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isCentered: true,
        isFilterable: true,
        itemKey: 'status',
        store: this.props.usersStore,
        title: 'Status',
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <CheckboxList
            itemKey={'versions'}
            simpleList={[]}
            store={this.props.usersStore.filters}
            handleAction={(action: Action): void => {
              this.props.usersStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isCentered: true,
        isFilterable: false, //TODO: false in order to hide icon, re-enable when version exists on User
        itemKey: 'version',
        store: this.props.usersStore,
        title: 'VERSION', //TODO: re-add 'version' label when it exists on User
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <DoubleDatePicker
            itemKey={'last_seen'} // TODO: edge should map from camel case / Can't filter on virtual fields - [sc2931]
            store={this.props.usersStore.filters}
            handleAction={(action: Action): void => {
              this.props.usersStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isCentered: true,
        isFilterable: false,
        isSortable: true,
        itemKey: 'last_seen',
        store: this.props.usersStore,
        title: 'LAST SEEN',
      },
      {
        colProps: { md: '1' },
        title: '',
      },
    ];
  }

  public async componentDidMount(): Promise<void> {
    this.props.usersStore.clearFilters();
    this.props.usersStore.fetchItems(this.props);
  }

  public render(): JSX.Element {
    const { usersStore } = this.props;

    return (
      <>
        {this.state.isAddingUser && (
          <AddUsersModal setState={this.setState.bind(this)} />
        )}
        <Container
          className="mt-4"
          fluid
          style={{ position: 'relative', zIndex: 1 }}
        >
          <h4
            className="mb-4"
            style={{ color: this.props.uiStore.common.colors.primary }}
          >
            <i className="fas fa-users" /> Users
          </h4>
          <Row>
            <div className="col">
              <Card>
                <GridTableCardHeader
                  actionButtonTitle="Add User"
                  actionButtonIcon="fa-plus"
                  actionButtonHandler={(): void => {
                    this.setState({ isAddingUser: true });
                  }}
                  store={usersStore}
                />

                <GridTable
                  headerColumnConfigs={this.headerColumnConfigs}
                  headerRowProps={{ className: 'mr-2 ml-2 mx-0 pl-4 pr-3' }}
                  idKey={'contentId'}
                  items={Array.from(usersStore?.items?.values())}
                  store={usersStore}
                  RowContents={RowContents}
                  ExpandedRowContents={ExpandedRowContents}
                  isJustHeaders={true}
                />

                {Array.from(usersStore?.items?.values()).map((item, index) => {
                  return (
                    <UsersRow
                      key={`user-row-${item.id}-${index}`}
                      user={item}
                    />
                  );
                })}

                <GridTableCardFooter
                  store={usersStore}
                  storeInstance={this.props.usersStore}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Users;
