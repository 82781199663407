import { Action } from 'models/action.model';
import { bundlesStore } from 'stores';

export const headerColumnConfig: any = [
  {
    colProps: {
      lg: '3',
      md: '3',
    },
    handleAction: (action: Action): void => {
      console.log('action');
    },
    isFilterable: true,
    isSortable: false,
    itemKey: 'name',
    store: bundlesStore,
    title: 'Sent By',
  },
  {
    colProps: {
      lg: '3',
      md: '3',
    },
    isFilterable: false,
    isSortable: false,
    store: bundlesStore,
    title: 'Recipient(s)',
  },
  {
    colProps: {
      lg: '1',
      md: '1',
    },
    isCentered: true,
    isFilterable: false,
    isSortable: false,
    store: bundlesStore,
    title: 'Content Files',
  },
  {
    colProps: {
      lg: '2',
      md: '2',
    },
    handleAction: (action: Action): void => {
      console.log('action');
    },
    isFilterable: true,
    isLast: true,
    isSortable: true,
    store: bundlesStore,
    title: 'Sent On',
  },
  {
    colProps: {
      lg: '2',
      md: '2',
    },
    isFilterable: false,
    isLast: true,
    isSortable: false,
    store: bundlesStore,
    title: 'Expiration',
  },
  {
    colProps: {
      lg: '1',
      md: '1',
    },
    isFilterable: false,
    isSortable: false,
    store: bundlesStore,
    title: '',
  },
];
