import { Intervals } from 'enums/contentSource.enum';

export function buttonTitle(text: string): string {
  const textParts: Array<string> = text.split(/(?=[A-Z])/);
  return textParts.join(' ').toLowerCase();
}

export function getInterval(interval: number): string {
  switch (parseInt(interval.toString())) {
    case Intervals.WEEKLY:
      return 'Weekly';
    case Intervals.DAILY:
      return 'Daily';
    case Intervals.HOURLY:
      return 'Hourly';
    default:
      return 'No interval set';
  }
}
