import React from 'react';

import BundlesTable from 'components/Engagement/Bundles/Table/BundlesTable';

interface Props {}

function ShareBundlesPage(props: Props): JSX.Element {
  return <BundlesTable />;
}

export default ShareBundlesPage;
