import { collectionsStore, simpleListsStore } from 'stores';

import { PayloadActions } from '@storyslab/storyslab.common.models';

import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { DomainStoreModel } from '../models/domain.model';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';
import { getAutoGroups } from './groups.service';
import { standardFetch } from './tree.service';

export class CollectionsService extends BaseService {
  private newItemDefaultName: string = 'New Collection';

  constructor() {
    super({
      create: EdgeUrls.Collections,
      delete: EdgeUrls.Collection,
      fetch: EdgeUrls.Collections,
      update: EdgeUrls.Collection,
    });
  }

  public async create({
    domainStore,
  }: // Hold - meta,
  {
    meta?: { [key: string]: any };
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      body: { name: this.newItemDefaultName },
      method: EdgeRequestMethods.POST,
      url: EdgeUrls.Collections,
    }).then((value: { id: number }) => {
      simpleListsStore.populateSimpleList({
        domainStore,
        key: 'collections',
        url: EdgeUrls.CollectionSimpleLists,
      });
      collectionsStore.fetchItemById(value.id, { domainStore }).then(() => {
        collectionsStore.updateItem({
          body: {
            groupIds: {
              action: PayloadActions.ADD,
              ids: getAutoGroups(),
            },
          },
          domainStore,
          id: value.id,
        });
      });
      return value;
    });
  }
}

export async function uploadCollectionThumbnail({
  collectionId,
  meta,
  domainStore,
}: {
  collectionId: number;
  meta: { [key: string]: any };
  domainStore: DomainStoreModel;
}): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: meta,
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.CollectionThumbnailUpload.replace(
      ':id',
      collectionId.toString(),
    ),
  });
  return resp;
}

export async function uploadCollectionImage({
  collectionId,
  meta,
  domainStore,
}: {
  collectionId: number;
  meta: { [key: string]: any };
  domainStore: DomainStoreModel;
}): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: meta,
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.CollectionImageUpload.replace(':id', collectionId.toString()),
  });
  return resp;
}

export async function fetchCollectionChildren(id: number): Promise<any> {
  const resp: any = await standardFetch({
    url: EdgeUrls.CollectionChildren.replace(':id', id.toString()),
  });
  return resp;
}
