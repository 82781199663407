/* eslint-disable max-lines */
import { SortOrder } from 'enums/grid-table.enum';
import debounce from 'lodash/debounce';
import { action, autorun, computed, observable, ObservableMap } from 'mobx';
import { Action, ActionTypes } from 'models/action.model';
import { DomainStoreModel } from 'models/domain.model';

import { resolve } from '@storyslab/storyslab.common.helpers';
import { RestResponse } from '@storyslab/storyslab.common.models';

import { normalizeActionPayloadInState } from '../helpers/action-payload.helper';
import { buildCollationParams } from '../helpers/collate.helper';
import { getStoredVersion } from '../helpers/store.helper';
import { mapItemsToObservableMap } from '../maps/store.map';
import { BaseProps } from '../models/base.model';
import { RehydrationTypes } from '../models/store.model';
import { BaseService } from '../services/base.service';

const defaultPageSize: number = 10;

interface BaseItem {
  view?: any;
  id?: number;
  isNew?: boolean;
}

export class BaseStore<T extends BaseItem> {
  protected localStorageName: string;
  protected renderRootKey: string;
  protected defaultPageSize: number = 10;

  protected service: BaseService;

  protected debouncedFetchItems: any = debounce(
    this.fetchItems.bind(this),
    500,
    { leading: true },
  );

  @observable public count: number = null;
  @observable public drawerModals: {
    [key: string]: { count: number; id: number; title: string };
  } = {};
  @observable public filters: Map<string, Array<string>>;
  @observable public isLoading: boolean = false;
  @observable public isLoadingItem: boolean = false;
  @observable public items: ObservableMap<number, T>;
  @observable public itemsAsRows: Array<T>;
  @observable public newItemId: number;
  @observable public page: number;
  @observable public pageSize: number;
  @observable public search: string;
  @observable public sort: Map<string, SortOrder>;

  constructor(
    service: BaseService,
    localStorageName: string,
    renderRootKey?: string,
  ) {
    this.service = service;
    this.localStorageName = localStorageName;

    if (renderRootKey) {
      this.renderRootKey = renderRootKey;
    }

    this.createItem = this.createItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.updateItem = this.updateItem.bind(this);

    // This must go last in the constructor
    this.init();
  }

  @action
  private init(): void {
    this.initValues();
    this.initAutoRun();
  }

  @action private handleFilter(
    payload: { [key: string]: any },
    key: string,
  ): void {
    if (payload[key] === null) {
      this.filters.delete(key);
    } else {
      if (payload[key].selected !== undefined) {
        if (payload[key].selected) {
          if (this.filters.has(key)) {
            const values: Array<string> = this.filters.get(key);
            this.filters.set(key, [
              ...values,
              ...(payload[key].ids ? payload[key].ids : [payload[key].id]),
            ]);
          } else {
            this.filters.set(key, payload[key].ids || [payload[key].id]);
          }
        } else {
          const valuesAsSet: Set<string> = new Set(this.filters.get(key));
          valuesAsSet.delete(payload[key].id);

          if (valuesAsSet.size === 0) {
            this.filters.delete(key);
          } else {
            this.filters.set(key, Array.from(valuesAsSet.values()));
          }
        }
      } else if (
        payload[key].startDate !== undefined &&
        payload[key].endDate !== undefined
      ) {
        this.filters.set(key, payload[key]);
      }
    }
  }

  @action
  public closeAllDrawerModals(): void {
    this.drawerModals = {};
  }

  @action
  public closeDrawerModal(key: string): void {
    this.drawerModals = {
      [key]: null,
    };
  }

  @action
  public openDrawerModal({
    key,
    params,
  }: {
    key: string;
    params: { title: string; id: number; count: number };
  }): void {
    this.drawerModals = {
      [key]: params,
    };
  }

  @action
  public setNewItemId(params: { id: number }): void {
    this.newItemId = params.id;
  }

  @action
  public async createItem(params: {
    domainStore: DomainStoreModel;
    meta?: { [key: string]: any };
    defaultObj?: { [key: string]: any };
  }): Promise<any> {
    if (!this.service.create) {
      throw new Error('Create function is required');
    }

    this.isLoadingItem = true;

    const [createResponse, err]: [T, any] = await resolve<T>(
      this.service.create(params),
    );

    if (!err && createResponse) {
      if (createResponse.view?.id) {
        this.newItemId = createResponse.view.id;
      } else if ((createResponse as any).contentItem) {
        this.newItemId = (createResponse as any).contentItem.id;
      } else {
        this.newItemId = createResponse.id;
      }

      this.items = observable.map(
        new Map([
          [
            createResponse.id,
            params.defaultObj
              ? { ...params.defaultObj, ...createResponse }
              : createResponse,
          ],
          ...Array.from(this.items.entries()).slice(0, this.pageSize),
        ]),
      );

      this.count = this.count + 1;
    }

    this.isLoadingItem = false;
  }

  @action
  public async fetchItems(props: BaseProps): Promise<void> {
    const { history } = props;

    if (history) {
      history.push({
        search: buildCollationParams(this),
      });
    }

    if (!this.service.fetch) {
      throw new Error('Fetch function is required');
    }

    this.isLoading = true;
    const [itemsResponse, err]: [RestResponse<Array<T>>, any] = await resolve<
      RestResponse<Array<T>>
    >(
      this.service.fetch &&
        this.service.fetch(props.domainStore, buildCollationParams(this)),
    );

    if (!err && itemsResponse) {
      this.page = itemsResponse?.meta?.page || 0;
      this.pageSize = itemsResponse?.meta?.pageSize || defaultPageSize;
      this.count = itemsResponse?.meta?.count || 0;

      this.items = mapItemsToObservableMap<T>(itemsResponse.data);
    }

    this.isLoading = false;
  }

  @action
  public async fetchItemById(
    id: number,
    props: { domainStore: DomainStoreModel },
  ): Promise<void> {
    if (!this.service.fetch) {
      throw new Error('Fetch function is required');
    }

    this.isLoadingItem = true;

    const [itemResponse, err]: [RestResponse<T>, any] = await resolve<
      RestResponse<T>
    >(
      this.service.fetch &&
        this.service.fetch(props.domainStore, buildCollationParams(this), id),
    );

    if (!err && itemResponse) {
      this.items.set(itemResponse.data.id, itemResponse.data);
    }

    this.isLoadingItem = false;
  }

  @action
  public clearItems(): void {
    this.items = mapItemsToObservableMap<T>([]);
  }

  @action
  public async handleTableAction(
    props: BaseProps,
    action: Action,
  ): Promise<void> {
    if (!action) {
      return;
    }

    const payload: { [key: string]: any } = action.payload as {
      [key: string]: any;
    };

    switch (action.type) {
      case ActionTypes.FILTER:
        Object.keys(payload).forEach((key: string) => {
          this.handleFilter(payload, key);
        });
        break;
      case ActionTypes.PAGINATE:
        if (payload.page !== undefined) {
          /**
           * For some reason the pagination component always sends an action on load.
           * So filter it out here.
           */

          if (this.page === payload.page) {
            return;
          }

          this.page = payload.page;
        }
        if (payload.pageSize !== undefined) {
          this.pageSize = payload.pageSize;
        }
        break;
      case ActionTypes.SEARCH:
        if (typeof action.payload === 'string') {
          this.search = action.payload;

          // When someone searches, we need to reset the pagination.
          this.page = 0;
        }
        break;
      case ActionTypes.SORT:
        Object.keys(payload).forEach((key: string) => {
          if (payload[key] === null) {
            this.sort.delete(key);
          } else {
            // There can only ever be one sort. So clear it.
            this.sort.clear();
            this.sort.set(key, payload[key]);
          }
        });
        break;

      default:
        break;
    }

    this.debouncedFetchItems(props);
  }

  @computed public get hasFilters(): boolean {
    return this.filters.size > 0;
  }

  @action
  public clearFilters(props?: BaseProps): void {
    this.filters.clear();
    if (props) {
      this.debouncedFetchItems(props);
    }
  }

  @action
  public setItem(item: T): void {
    this.items.set(item.id, item);
  }

  @action
  public setPageSize(size: number): void {
    this.pageSize = size;
  }

  @action
  public removeNewId(): void {
    this.newItemId = null;
  }

  @action
  public async updateItem(
    params: {
      body: any;
      domainStore: DomainStoreModel;
      id: number;
      includeResponse?: boolean;
    },
    silent?: boolean,
  ): Promise<any> {
    const { body, id } = params;

    const item: T = this.items.get(id);

    this.items.set(id, {
      ...item,
      ...normalizeActionPayloadInState<T, any>(item, body),
      updated: new Date(),
    });

    if (!this.service.update) {
      throw new Error('Update function is required');
    }

    if (!silent) {
      this.isLoadingItem = true;
    }
    if (params.includeResponse) {
      this.isLoadingItem = false;
      return await this.service.update(params);
    }

    await this.service.update(params);

    this.isLoadingItem = false;
  }

  @action
  public async deleteItem(params: {
    domainStore: DomainStoreModel;
    id: number;
    isLocalOnly?: boolean;
  }): Promise<any> {
    const { id, isLocalOnly } = params;

    this.items.delete(id);
    this.count = this.count - 1;

    if (!this.service.delete) {
      throw new Error('Delete function is required');
    }

    if (!isLocalOnly) {
      await this.service.delete(params);
    }
  }

  private initValues(): void {
    if (!this.localStorageName) {
      throw new Error('localStorageName is required');
    }

    this.filters = getStoredVersion(
      this.localStorageName,
      'filters',
      new Map(),
      RehydrationTypes.MAP_WITH_MOMENT,
    );

    this.page = getStoredVersion(this.localStorageName, 'page', 0);
    this.pageSize = getStoredVersion(
      this.localStorageName,
      'pageSize',
      this.defaultPageSize,
    );

    this.search = getStoredVersion(this.localStorageName, 'search', '');

    this.sort = getStoredVersion(
      this.localStorageName,
      'sort',
      new Map(),
      RehydrationTypes.MAP,
    );

    this.items = getStoredVersion(
      this.localStorageName,
      'contentItems',
      new Map(),
      RehydrationTypes.MAP,
    );
  }

  private initAutoRun(): void {
    autorun(() => {
      localStorage.setItem(
        this.localStorageName,
        JSON.stringify({
          count: this.count,
          filters: this.filters,
          items: this.items,
          page: this.page,
          pageSize: this.pageSize,
          search: this.search,
          sort: this.sort,
        }),
      );
    });
  }
}
