import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
} from 'reactstrap';

import EditableTextColumn from '../../components/GridTable/RowColumns/EditableText';
import TextColumn from '../../components/GridTable/RowColumns/Text';
import { RowProps } from '../../models/grid-table.model';

interface InternalState {}

interface Props extends RowProps {
  isEditing: boolean;
}

class RowContents extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { item, store, isEditing }: Props = this.props;

    return (
      <>
        {item.isEditable ? (
          <EditableTextColumn
            canEdit={isEditing}
            columnProps={{ md: '7' }}
            id={item.id}
            store={store}
            value={item.name}
          />
        ) : (
          <TextColumn columnProps={{ md: '7' }} title={item.name} />
        )}

        <TextColumn
          columnProps={{ md: '2' }}
          isCentered={true}
          title={item.entitlementsCount}
        />

        <TextColumn
          columnProps={{ md: '2' }}
          isCentered={true}
          title={item.usersCount}
        />

        <Col md="1" style={{ textAlign: 'right' }}>
          <UncontrolledDropdown nav className="float-right">
            <DropdownToggle className="nav-link pr-0" color="" tag="a">
              <Media>
                <i className="fas fa-ellipsis-v ml-3" />
              </Media>
            </DropdownToggle>
            <DropdownMenu right>
              <Link to={''}>
                <DropdownItem>
                  <span>More Info</span>
                </DropdownItem>
              </Link>
              <DropdownItem
                onClick={(): Promise<void> =>
                  store.deleteItem({
                    domainStore: this.props.domainStore,
                    id: item.id,
                  })
                }
              >
                <span>Delete</span>
              </DropdownItem>
              {/* <DropdownItem divider /> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </>
    );
  }
}

export default RowContents;
