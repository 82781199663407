import React from 'react';
import { Spinner } from 'reactstrap';

interface Props {
  isFullPage?: boolean;
  shouldFill?: boolean;
  style?: any;
}

function Loader(props: Props): JSX.Element {
  const { isFullPage, style } = props;

  let compositeStyles: any = {};
  if (style) {
    compositeStyles = { ...compositeStyles, ...style };
  }

  return (
    <div
      className={`make-center ${isFullPage && 'make-full'} loader-wrapper`}
      style={compositeStyles}
    >
      <Spinner
        style={isFullPage === true ? { height: '5rem', width: '5rem' } : {}}
        className="storyslab-text-primary"
      />
    </div>
  );
}

export default Loader;
