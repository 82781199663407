import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Props {
  approve: () => void;
  body: string;
  close: () => void;
  isOpen: boolean;
  title: string;
}

function SiteWideAlert(props: Props): JSX.Element {
  const { approve, body, close, isOpen, title } = props;

  return (
    <div style={styles.wrapper}>
      <Modal isOpen={isOpen} toggle={close}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button
            className="storyslab-button"
            onClick={(): void => {
              approve();
              close();
            }}
          >
            yes
          </Button>
          <Button
            className="storyslab-button light"
            onClick={(): void => {
              close();
            }}
          >
            no
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

const styles: any = {
  wrapper: {
    position: 'fixed',
    zIndex: 1051,
  },
};

export default SiteWideAlert;
