/* eslint-disable max-lines-per-function */
import React, {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ContentTreeBranchesProps } from 'interfaces/treeInterfaces';
import { debounce } from 'lodash';

import { TreeContext } from './ContentTree';
import ContentTreeCardStack from './ContentTreeCardStack';

const ContentTreeBranches: any = (props: ContentTreeBranchesProps) => {
  const { hasLink } = props;
  const {
    activeView,
    branchStack,
    branchesRendered,
    maxColumns,
    setActiveView,
    setMaxColumns,
    setBranchStack,
    setBranchesRendered,
    rootViews,
  }: any = useContext(TreeContext);

  const [deepLinkViewId, setDeepLinkViewId] = useState('deepLink');
  const [isDeepLinkInitialized, setIsDeepLinkInitialized] = useState(false);
  const [localActiveView, setLocalActiveView] = useState(activeView.name);
  const ref: MutableRefObject<any> = useRef(null);

  const gutterWidth: number = 16;
  const maxColumnSize: number = 260;

  const handleResize: () => void = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    setMaxColumns(
      Math.floor(
        (ref.current.offsetWidth + gutterWidth) / (maxColumnSize + gutterWidth),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (branchStack.length > maxColumns) {
      setBranchesRendered(branchStack.slice(-maxColumns));
    } else {
      setBranchesRendered(branchStack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxColumns]);

  useEffect(() => {
    if (hasLink) {
      let urlParts: string | Array<string> = window.location.pathname.replace(
        '/content/tree/',
        '',
      );
      urlParts = urlParts.split('/');
      const sanitizedParts: Array<string | number> = [];
      for (const view of rootViews) {
        if (view.name === urlParts[0]) {
          setActiveView(view);
          setDeepLinkViewId(urlParts[0]);
          break;
        }
      }
      for (let count: number = 0; count < urlParts.length; count++) {
        if (count === 0) {
          sanitizedParts.push(urlParts[count]);
        } else {
          sanitizedParts.push(parseInt(urlParts[count]));
        }
      }

      setBranchStack(sanitizedParts);
    }
    handleResize();
    window.addEventListener(
      'resize',
      debounce(() => {
        handleResize();
      }, 500),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLink]);

  useEffect(() => {
    if (hasLink) {
      if (!isDeepLinkInitialized) {
        // Catch all state changes until they are in sync. Then initialize deep link.
        if (
          deepLinkViewId === localActiveView &&
          deepLinkViewId === activeView.name
        ) {
          setIsDeepLinkInitialized(true);
        } else {
          setLocalActiveView(deepLinkViewId);
        }
        return;
      }
    }
    if (localActiveView === activeView.viewId) {
      return;
    }
    setBranchStack([activeView.name]);
    setLocalActiveView(activeView.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView, localActiveView]);

  useEffect(() => {
    const buildURL: string = branchStack.join('/');
    window.history.pushState({}, document.title, '/content/tree/' + buildURL);
    setBranchesRendered(branchStack.slice(-maxColumns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchStack]);

  const handleBranching: any = (item, depth) => {
    if (branchStack.length === depth) {
      // Clicked rightmost card, add to branchesRendered
      setBranchStack((branchStack) => [...branchStack, item.id]);
    } else {
      // Clicked a nested branch, prune and append clicked id
      const prunedBranches: any = [...branchStack].slice(0, depth);
      prunedBranches.push(item.id);
      setBranchStack(prunedBranches);
    }
  };

  if (localActiveView !== activeView.name) {
    return <></>;
  }

  return (
    <>
      <div className="branches-wrapper" ref={ref}>
        {branchesRendered.map((stackId: number, index: number) => {
          return (
            <ContentTreeCardStack
              activeView={activeView}
              depth={branchStack.indexOf(stackId) + 1}
              handleBranching={handleBranching}
              isRoot={branchStack[0] === branchesRendered[0] && index === 0}
              key={`card-stack-${index}`}
              stackId={stackId}
            />
          );
        })}
      </div>
    </>
  );
};

export default ContentTreeBranches;
