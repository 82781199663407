import React from 'react';
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { isEmpty } from 'helpers/util.helper';
import { inject, observer } from 'mobx-react';
import { ActionTypes } from 'models/action.model';
import { UiStoreModel } from 'models/ui.model';

import { SortOrder } from '../../enums/grid-table.enum';
import { HeaderColumnConfig } from '../../models/grid-table.model';

interface InternalState {
  filter: any;
}

interface Props extends HeaderColumnConfig {
  uiStore?: UiStoreModel;
}

const sortIconMap: { [key in SortOrder]: string } = {
  ASC: 'fa-sort-up',
  DESC: 'fa-sort-down',
};

@inject('uiStore')
@observer
class HeaderColumn extends React.Component<Props, InternalState> {
  public state: InternalState = {
    filter: null,
  };

  private handleSortClick(): void {
    const sort: SortOrder = this.props.store.sort.get(this.props.itemKey);
    let newSortOrder: SortOrder = null;

    switch (sort) {
      case SortOrder.ASC:
        newSortOrder = SortOrder.DESC;
        break;
      case SortOrder.DESC:
        newSortOrder = null;
        break;
      default:
        newSortOrder = SortOrder.ASC;
        break;
    }

    if (typeof this.props.handleAction === 'function') {
      this.props.handleAction({
        payload: { [this.props.itemKey]: newSortOrder },
        type: ActionTypes.SORT,
      });
    }
  }

  private generateSortIcon(isActive: boolean): JSX.Element {
    const { primary } = this.props.uiStore.common.colors;
    const sort: string = this.props.store.sort.get(this.props.itemKey);

    return (
      <i
        className={`fas ${sortIconMap[sort] || 'fa-sort'} px-2 clickable`}
        style={
          isActive
            ? {
                color: primary,
              }
            : {}
        }
        onClick={(): void => this.handleSortClick()}
      ></i>
    );
  }

  private generateFilterIcon(
    numOfFilters: number,
    isActive?: boolean,
  ): JSX.Element {
    const { filterDropdown, uiStore } = this.props;
    const { primary } = uiStore.common.colors;

    return (
      <>
        <UncontrolledDropdown>
          <DropdownToggle color="" tag="span">
            <span
              className="px-2 clickable"
              style={
                isActive
                  ? {
                      color: primary,
                    }
                  : {}
              }
            >
              {numOfFilters}
              <i className="fas fa-filter"></i>
            </span>
          </DropdownToggle>
          <DropdownMenu right className="scrollable-dropdown-menu">
            <>{filterDropdown}</>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }

  public render(): JSX.Element {
    const {
      colProps,
      isCentered,
      isFilterable,
      isLast,
      isSortable,
      itemKey,
      store,
      subtitle,
      title,
      uiStore,
    } = this.props;

    const { primary } = uiStore.common.colors;

    let numOfFilters: number;
    let isFilterActive: boolean;
    let isSortActive: boolean;
    if (isFilterable) {
      const filters: Map<string, any> = store.filters;
      const filter: any = filters.get(itemKey);
      if (Array.isArray(filter)) {
        numOfFilters = filters ? filters.get(itemKey)?.length : 0;
      }
      isFilterActive = !isEmpty(filter);
    }

    if (isSortable) {
      isSortActive = !!store.sort.get(itemKey);
    }

    return (
      <>
        <Col
          {...colProps}
          className={`header-column${
            isCentered ? ' header-column-centered' : ''
          }${isLast ? ' header-column-last mr-0 pr-0' : ''}`}
        >
          <h5 {...(isLast && { className: 'float-right' })}>
            <span
              style={
                isFilterActive || isSortActive
                  ? {
                      color: primary,
                    }
                  : {}
              }
            >
              {title}
            </span>
            {isSortable && this.generateSortIcon(isSortActive)}
            {isFilterable &&
              this.generateFilterIcon(numOfFilters, isFilterActive)}
          </h5>
          {subtitle && <h6>{subtitle}</h6>}
        </Col>
      </>
    );
  }
}

export default HeaderColumn;
