import React from 'react';

interface Props {
  bg?: string;
}

const StandardContentContain: any = (props: React.PropsWithChildren<Props>) => {
  return (
    <div
      className="standardContentContain"
      style={{ ...styles.contain, backgroundColor: props.bg }}
    >
      <div style={styles.text}>{props.children[0]}</div>
      <div style={styles.control}>{props.children[1]}</div>
    </div>
  );
};

const styles: any = {
  contain: {
    backgroundColor: '#f1f1f1',
    borderTop: '1px solid #d2d1d1',
    cursor: 'default',
    display: 'flex',
    padding: '18px 24px',
  },
  control: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  text: {
    color: '#808080',
    fontSize: '10px',
    fontWeight: 400,
  },
};

export default StandardContentContain;
