import React from 'react';
import { DropdownToggle } from 'reactstrap';

interface InternalState {}

interface Props {
  title: string;
  isActive?: boolean;
}

class MultiEditButton extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    return (
      <>
        <DropdownToggle className="multi-edit-button clickable" color="" tag="div">
          <span className="multi-edit-button-title">{this.props.title}</span>
          <i
            className={`fas ${
              this.props.isActive ? 'fa-angle-up' : 'fa-angle-down'
            } float-right`}
          ></i>
        </DropdownToggle>
      </>
    );
  }
}

export default MultiEditButton;
