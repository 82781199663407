import React from 'react';
import ReactPaginate from 'react-paginate';
import { observer } from 'mobx-react';
import { BaseStore } from 'stores/base.store';

import ItemsPerPage from 'components/GridTable/ItemsPerPage';

import { ActionTypes } from '../../models/action.model';

interface InternalState {}

interface Props {
  store: BaseStore<any>;
  styles?: any;
  handleAction?: any;
}

@observer
class Pagination extends React.Component<Props, InternalState> {
  private handlePageClick(e: { selected: number }): void {
    this.props.handleAction({
      payload: { page: e.selected },
      type: ActionTypes.PAGINATE,
    });
  }

  public renderShowingLabel(): JSX.Element {
    const store: BaseStore<any> = this.props.store;
    const x: number = store.page * store.pageSize + 1;
    const y: number = Math.min(x + store.pageSize - 1, store.count);

    return (
      <div style={styles.xOfX}>
        {x} - {y} of {store.count}
      </div>
    );
  }

  public render(): JSX.Element {
    const { store } = this.props;
    const pageCount: number = Math.ceil(store.count / store.pageSize);

    return (
      <>
        <div style={{ ...styles.wrapper, ...this.props.styles }}>
          <div style={styles.container}>
            <ReactPaginate
              activeClassName={'active'}
              breakClassName={'break-me'}
              breakLabel={'...'}
              containerClassName={'pagination mb-0'}
              disabledClassName={'disabled'}
              forcePage={store.page}
              initialPage={0}
              marginPagesDisplayed={1}
              nextClassName={'page-item'}
              nextLabel={
                <>
                  <i className="fas fa-angle-right" aria-hidden="true"></i>
                  <span className="sr-only">Next</span>
                </>
              }
              nextLinkClassName={'page-link'}
              onPageChange={this.handlePageClick.bind(this)}
              pageCount={pageCount}
              pageRangeDisplayed={3}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              previousLabel={
                <>
                  <i className="fas fa-angle-left" aria-hidden="true"></i>
                  <span className="sr-only">Previous</span>
                </>
              }
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              subContainerClassName={'pages pagination'}
            />
            {this.renderShowingLabel()}
          </div>
          <ItemsPerPage store={store} />
        </div>
      </>
    );
  }
}

const styles: any = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  wrapper: {
    marginBottom: '1rem',
    position: 'relative',
  },
  xOfX: {
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '1rem',
  },
};

export default Pagination;
