import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import { domainStore } from 'stores';

import { EdgeUrls } from '../enums/edge.enum';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export class BundlesService extends BaseService {
  constructor() {
    super({
      create: EdgeUrls.Bundles,
      delete: EdgeUrls.Bundle,
      fetch: EdgeUrls.Bundles,
      update: EdgeUrls.Bundle,
    });
  }
}

export async function getByShortCode(shortCode: string): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.BundleShortCode.replace(':id', shortCode),
  });
}
