import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InternalState {}

interface Props {
  title: string;
  value: number;
  icon: string;
}

class Number extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { title, value, icon } = this.props;
    return (
      <>
        <Card className="card-stats mb-2">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="mb-0">
                  {title}
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {value?.toLocaleString() || 0}
                </span>
              </div>
              <Col className="col-auto align-vertical">
                <Button
                  className="close-btn btn-icon-only rounded-circle"
                  color="default"
                  type="button"
                >
                  <span className="btn-inner--icon">
                    <i className={`fas ${icon}`} />
                  </span>
                </Button>
              </Col>
            </Row>
            {/* <p className="mt-3 mb-0 text-sm">
              <span className="text-success mr-2">
                <i className="fa fa-arrow-up" /> 3.48%
              </span>
              <span className="text-nowrap">Since last month</span>
            </p> */}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Number;
