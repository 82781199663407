import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { createColorCss } from 'helpers/component-color.helper';
import { resetPassword } from 'services/auth.service';
import { domainStore, uiStore } from 'stores';

interface Props {
  onChange: (target, value) => void;
  username: string;
}

const ForgotPassword: any = (props: Props) => {
  const { onChange } = props;

  const [username, setUsername] = useState('');

  return (
    <>
      <FormGroup className={'mb-3'}>
        <InputGroup className="input-group-merge input-group-alternative login-fields">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            defaultValue={username}
            placeholder="Email"
            type="text"
            onChange={(e): void => {
              setUsername(e.target.value);
            }}
          />
        </InputGroup>
      </FormGroup>
      <Button
        className="login-flow-button mb-5"
        color="info"
        style={createColorCss(uiStore, [['backgroundColor', 'primary']])}
        type="button"
        onClick={(): void => {
          onChange('isLoading', true);
          onChange('titleText', 'Sign In');
          resetPassword(
            domainStore.applicationId,
            username,
            domainStore.tenantId,
            onChange,
          );
        }}
      >
        send email
      </Button>
    </>
  );
};
export default ForgotPassword;
