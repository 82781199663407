import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import { DomainStoreModel } from 'models/domain.model';
import { domainStore } from 'stores';

import { EdgeUrls } from '../enums/edge.enum';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export class UsersService extends BaseService {
  constructor() {
    super({
      create: EdgeUrls.Users,
      delete: EdgeUrls.User,
      fetch: EdgeUrls.Users,
      update: EdgeUrls.User,
    });
  }

  public async deleteForce({
    id,
    domainStore,
  }: {
    id: number;
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      method: EdgeRequestMethods.DELETE,
      url: `${EdgeUrls.User.replace(':id', id.toString())}?forceDelete=true`,
    });
  }
}

export async function createOrResolve(body: { email: string }): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: { email: body.email },
    includeMeta: true,
    method: EdgeRequestMethods.POST,
    url: `${EdgeUrls.UserCreate}`,
  });
}

export async function addUserToApp(body: unknown): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body,
    method: EdgeRequestMethods.POST,
    url: `${EdgeUrls.UserAddToApp}`,
  });
}

export async function sendInvite(id: string): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.PUT,
    url: `${EdgeUrls.UserSendInvite.replace(':id', id)}`,
  });
}

export async function reSendInvite(id: string): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url: `${EdgeUrls.UserResendInvite.replace(':id', id)}`,
  });
}
