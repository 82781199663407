import { UiStoreModel } from '../models/ui.model';
export function createColorCss(
  uiStore: UiStoreModel,
  targets: Array<[string, string]>,
): React.CSSProperties {
  const newObj: any = {};

  const colors: { [key: string]: string } = uiStore.common.colors;

  for (let i: number = 0; i < targets.length; i++) {
    const target: any = targets[i];
    newObj[target[0]] = colors[target[1]];
  }

  return newObj;
}

export function createBackgroundImageCss(
  imageUrl: string,
): React.CSSProperties {
  return imageUrl ? { backgroundImage: 'url(' + imageUrl + ')' } : null;
}
