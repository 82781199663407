import React from 'react';
import { useParams } from 'react-router-dom';
import Routes from 'enums/routes.enum';
import { navItemViewsStore } from 'stores';

import LayoutMetaEditor from 'components/ApplicationNavigation/Configuration/LayoutMetaEditor';
import ViewConfigurator from 'components/ViewConfigurator';

interface Props {}

const AppNavigationViewConfigurator: (props: Props) => JSX.Element = (
  props: Props,
) => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <ViewConfigurator
        customHeader={<LayoutMetaEditor viewId={id} />}
        home={Routes.ApplicationNavigation}
        store={navItemViewsStore}
        title={'Navigation Item'}
      />
    </>
  );
};

export default AppNavigationViewConfigurator;
