import React, { useState } from 'react';

import IsAddingContainer from './IsAddingContainer';

interface InternalProps {
  stackId: number;
}

function AddButton(props: InternalProps): JSX.Element {
  const { stackId } = props;

  const [isAdding, setIsAdding] = useState(false);

  return (
    <div tabIndex={1} className="add-button-wrapper">
      <i
        className={`fas ${isAdding && 'fa-minus-circle'} ${
          !isAdding && 'fa-plus-circle'
        } add-button-icon`}
        onClick={(): void => setIsAdding(!isAdding)}
      ></i>

      {isAdding && <IsAddingContainer stackId={stackId} />}
    </div>
  );
}

export default AddButton;
