import React from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import moment from 'moment';
import { UsersStore } from 'stores';

import { UserStatus } from '@storyslab/storyslab.common.models';
import MultiValueTextColumn from 'components/GridTable/RowColumns/MultiValueTextColumn';

import BadgeColumn from '../../components/GridTable/RowColumns/Badge';
import TextColumn from '../../components/GridTable/RowColumns/Text';
import { RowProps } from '../../models/grid-table.model';
import StorySlabAlert from '../Alert/StorySlabAlert';

interface InternalState {
  showWarning: boolean;
}

interface Props extends RowProps {
  isEditing: boolean;
  store: UsersStore;
}

class RowContents extends React.Component<Props, InternalState> {
  constructor(props: undefined) {
    super(props);
    this.state = {
      showWarning: false,
    };
  }

  public hideAlert(): void {
    this.setState({ showWarning: false });
  }

  public deleteUser(): void {
    const { item, store }: Props = this.props;
    store.deleteItem({
      domainStore: this.props.domainStore,
      id: item.id,
    });
  }

  public render(): JSX.Element {
    const { item }: Props = this.props;

    return (
      <>
        {this.state.showWarning && (
          <StorySlabAlert
            title={'Delete Confirmation'}
            onApprove={(): void => {
              this.hideAlert();
              this.deleteUser();
            }}
            onDeny={(): void => {
              this.hideAlert();
            }}
          >
            Are you sure you want to delete this user?
          </StorySlabAlert>
        )}

        <TextColumn
          columnProps={{ md: '3' }}
          title={`${item.firstName}${item.firstName?.length > 0 ? ', ' : ''} ${
            item.lastName
          }`}
          subtitle={item.email}
        />

        <MultiValueTextColumn
          columnProps={{ md: '2' }}
          isCentered={true}
          values={item.roleIds}
          item={item}
        />

        <BadgeColumn columnProps={{ md: '2' }} status={item.status} />

        <TextColumn columnProps={{ md: '2' }} title={item.version} />

        {/* <TextColumn
          isCentered={true}
          columnProps={{ md: '2' }}
          title={`${
            item.recentActivity
              ? item.recentActivity.join('\u00a0\u00a0\u00a0\u00a0\u00a0')
              : ''
          }`}
        /> */}

        <Col md="2">
          <h5 style={{ display: 'block', textAlign: 'center' }}>
            {item.lastSeen === undefined ? (
              '-'
            ) : (
              <TimeAgo date={moment(item.lastSeen).toDate()} minPeriod="30" />
            )}
          </h5>
        </Col>

        <Col md="1" style={{ textAlign: 'right' }}>
          <UncontrolledDropdown nav className="float-right">
            <DropdownToggle className="nav-link pr-0" color="" tag="a">
              <i className="fas fa-ellipsis-v ml-3" />
            </DropdownToggle>
            <DropdownMenu right>
              <Link to={''}>
                <DropdownItem>
                  <span>More Info</span>
                </DropdownItem>
              </Link>
              {item.status !== UserStatus.DELETED && (
                <DropdownItem
                  onClick={(): void => {
                    this.setState({ showWarning: true });
                  }}
                >
                  <span>Delete</span>
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </>
    );
  }
}

export default RowContents;
