import React from 'react';

import SettingsAndFeatures from 'components/SettingsAndFeatures/SettingsAndFeatures';

const SettingsAndFeaturesPage: any = () => {
  return (
    <>
      <SettingsAndFeatures />
    </>
  );
};

export default SettingsAndFeaturesPage;
