import React from 'react';

interface InternalState {}

interface Props {
  selected: boolean;
  handleChange?: (value: boolean) => void;
}

class Checkbox extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    // TODO: Tech Debt - change selected to isSelected
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { handleChange, selected } = this.props;

    return (
      <>
        <i
          className={`fal ${selected ? 'fa-check-square' : 'fa-square'}`}
          onClick={(): void => {
            handleChange && handleChange(!selected);
          }}
        />
      </>
    );
  }
}

export default Checkbox;
