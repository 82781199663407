import React, { Dispatch, SetStateAction } from 'react';
import { contentStore, domainStore } from 'stores';

import DetailSelect from 'components/Content/ContentRow/DetailSelect';
import SimpleToggle from 'components/Forms/SimpleToggle';

interface Props {
  contentItem: any;
}

interface InternalProps extends Props {
  activeMenu: string;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  isDropped: boolean;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
}

function AdditionalFields(props: InternalProps): JSX.Element {
  function updateBooleanField(value: boolean, field: string): void {
    contentStore.updateItem({
      body: { [field]: value },
      domainStore,
      id: props.contentItem.id,
    });
  }

  return (
    <div className="content-contain">
      <div>
        <div className="form-field-contain">
          <div className="form-field-label">Tags</div>
          <DetailSelect {...props} idList={'tagIds'} listType={'tags'} />
        </div>
        <div className="form-field-contain">
          <div className="form-field-label">Devices</div>
          <DetailSelect
            {...props}
            idList={'deviceTypeIds'}
            listType={'deviceTypes'}
          />
        </div>
      </div>
      <div>
        <div className="boolean-form-field-contain">
          <div className="form-field-label">password protected</div>
          <SimpleToggle
            didCheckOnLoad={props.contentItem.isPasswordProtected}
            handleChange={updateBooleanField}
            field={'isPasswordProtected'}
          />
        </div>
        <div className="boolean-form-field-contain">
          <div className="form-field-label">unsearchable</div>
          <SimpleToggle
            didCheckOnLoad={props.contentItem.isSearchable}
            handleChange={updateBooleanField}
            field={'isSearchable'}
          />
        </div>
        <div className="boolean-form-field-contain">
          <div className="form-field-label">shareable</div>
          <SimpleToggle
            didCheckOnLoad={props.contentItem.isShareable}
            handleChange={updateBooleanField}
            field={'isShareable'}
          />
        </div>
        <div className="boolean-form-field-contain">
          <div className="form-field-label">downloadable</div>
          <SimpleToggle
            didCheckOnLoad={props.contentItem.isDownloadable}
            handleChange={updateBooleanField}
            field={'isDownloadable'}
          />
        </div>
      </div>
    </div>
  );
}

export default AdditionalFields;
