import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Form, FormGroup, Input, Spinner } from 'reactstrap';

interface InternalState {
  isLoading: boolean;
  inputValue: string;
}

interface Props {
  inputProps?: any;
  defaultValue: string;
  handleUpdate?: (value: string) => void | Promise<any>;
  handleChange?: (value: string) => void | Promise<any>;
  title?: string;
  width?: string;
}

class TapToEditText extends React.Component<Props, InternalState> {
  public state: InternalState = {
    inputValue: null,
    isLoading: false,
  };

  private handleChange(e: ChangeEvent<HTMLInputElement>): void {
    this.props.handleChange && this.props.handleChange(e.target.value);
    this.setState({ inputValue: e.target.value });
  }

  private handleBlur(): void {
    this.handleSaveEvent();
  }

  private handleKeyDown(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      this.handleSaveEvent();
    }
  }

  private handleSaveEvent(): void {
    if (
      this.state.inputValue &&
      this.props.defaultValue !== this.state.inputValue
    ) {
      this.props.handleUpdate && this.props.handleUpdate(this.state.inputValue);
    }
  }

  public render(): JSX.Element {
    if (this.state.isLoading) {
      return (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      );
    }

    const { inputProps, title, defaultValue, width } = this.props;

    return (
      <>
        <Form {...(width && { style: { width: width } })}>
          <FormGroup>
            {title && (
              <label
                className="form-control-label"
                htmlFor="exampleFormControlInput1"
              >
                {title}
              </label>
            )}
            <Input
              {...(inputProps as any)}
              type="text"
              defaultValue={defaultValue}
              onBlur={(): void => this.handleBlur()}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                this.handleChange(e)
              }
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>): void =>
                this.handleKeyDown(e)
              }
            />
          </FormGroup>
        </Form>
      </>
    );
  }
}

export default TapToEditText;
