import React, { ChangeEvent, FocusEvent } from 'react';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import classnames from 'classnames';

interface InternalState {
  isFocused: boolean;
}

interface Props {
  type: string;
  keyName: string;
  iconName?: string;
  placeholder: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (
    value: string,
    keyName?: string,
    e?: ChangeEvent<HTMLInputElement>,
  ) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
}

export class SSFormGroup extends React.Component<Props, InternalState> {
  public state: InternalState = {
    isFocused: false,
  };

  private handleOnBlur(e: FocusEvent<HTMLInputElement>): void {
    this.props &&
      typeof this.props.onBlur === 'function' &&
      this.props.onBlur(e);
    this.setState({ isFocused: false });
  }

  private handleOnChange(e: ChangeEvent<HTMLInputElement>): void {
    this.props &&
      typeof this.props.onChange === 'function' &&
      this.props.onChange(e.target.value, this.props.keyName, e);
  }

  private handleOnFocus(e: FocusEvent<HTMLInputElement>): void {
    this.props &&
      typeof this.props.onFocus === 'function' &&
      this.props.onFocus(e);
    this.setState({ isFocused: true });
  }

  public render(): JSX.Element {
    return (
      <FormGroup
        className={classnames('mb-3', {
          focused: this.state.isFocused,
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              {this.props.iconName ? (
                <i
                  className={`ni ni-${this.props.iconName.toLowerCase()}-83`}
                />
              ) : null}
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={this.props.placeholder}
            type={this.props.type as InputType}
            onBlur={(e: FocusEvent<HTMLInputElement>): void =>
              this.handleOnFocus(e)
            }
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              this.handleOnChange(e)
            }
            onFocus={(e: FocusEvent<HTMLInputElement>): void =>
              this.handleOnBlur(e)
            }
          />
        </InputGroup>
      </FormGroup>
    );
  }
}
