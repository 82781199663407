/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Dropzone, {
  IFileWithMeta,
  IUploadParams,
} from 'react-dropzone-uploader';
import { knownImageFileTypes } from 'helpers/files.helper';

import S3Image from 'components/Assets/S3Image';
import Layout from 'components/Content/Source/Layout';
import DropzoneSimpleInputButton from 'components/Dropzone/DropzoneSimpleInputButton';
import Loader from 'components/Indicators/Loader';

interface Props {
  bucket: string;
  initialS3Key?: string;
  onComplete?: (s3Key: string) => void;
  onFailed?: (event: any) => void;
  handleGetUploadParams: (params: IFileWithMeta)=>Promise<any>;
}

const AppMetaFileUpload: (props: Props) => JSX.Element = (props: Props) => {
  const { bucket, initialS3Key, handleGetUploadParams } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [pendingS3Key, setPendingS3Key] = useState(null);
  const [s3Key, setS3Key] = useState(initialS3Key);

  useEffect(()=>{
    setS3Key(initialS3Key)
  },[initialS3Key])

  function handleChangeStatus(params: any, status: string): any {
    if (status === 'done') {
      setIsLoading(false);
      const newKey: string = pendingS3Key;
      setS3Key(newKey);
      setPendingS3Key(null);
      params.remove();
      props.onComplete && props.onComplete(newKey);
    } else {
      switch (status) {
        case 'exception_upload':
        case 'error_upload':
        case 'error_upload_params':
        case 'error_validation':
        case 'error_file_size':
          setPendingS3Key(null);
          setIsLoading(false);
          console.error(status);
          props.onFailed && props.onFailed(params);
          break;
        default:
          //no op
          break;
      }
    }
  }

  async function internalHandleGetUploadParams(
    params: IFileWithMeta,
  ): Promise<IUploadParams> {

    const presignedPostPromise: Promise<any> = handleGetUploadParams(params);

    return presignedPostPromise
      .then((response): IUploadParams => {
        setPendingS3Key(response?.data?.s3PresignedPost?.fields?.key);
        return { ...response.data.s3PresignedPost };
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  }

  function renderImage(){
    return (<S3Image
      style={styles.uploadImage}
      s3Bucket={bucket}
      s3Key={s3Key}/>)
  }

  return (
    <>
    <div style={styles.uploadWrapper}>
      {s3Key && !isLoading && renderImage()}
      {!isLoading && (
        <Dropzone
          inputContent={s3Key ? "Change File" : "Upload File"}
          accept={knownImageFileTypes()}
          autoUpload={true}
          maxFiles={1}
          multiple={false}
          getUploadParams={internalHandleGetUploadParams}
          onChangeStatus={handleChangeStatus}
          InputComponent={DropzoneSimpleInputButton}
          LayoutComponent={Layout}
          SubmitButtonComponent={null}
          PreviewComponent={(): any => {
            return <>Uploading..</>;
          }}
        />
      )}
      {isLoading && <Loader isFullPage={false} />}
      </div>
    </>
  );
};

const styles: any = {
  uploadImage: {
    maxHeight: 60,
    maxWidth: 60,
    objectFit: "contain"
  },
  uploadWrapper: {
    display: "flex",
    flexDirection: "row",
  },
}

export default AppMetaFileUpload;
