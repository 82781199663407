import { observable, ObservableMap } from 'mobx';
import { DomainStoreModel } from 'models/domain.model';
import { RehydrationTypes } from 'models/store.model';
import moment from 'moment';

type ArrayOfIds = Array<number>;

export function getStoredVersion(
  storeName: string,
  key: string,
  defaultValue: any = null,
  rehydrationType?: RehydrationTypes,
): any {
  try {
    let parsedObj: any = JSON.parse(localStorage.getItem(storeName))[key];

    if (parsedObj === null || parsedObj === undefined) {
      parsedObj = defaultValue;
    }

    if (rehydrationType) {
      switch (rehydrationType) {
        case RehydrationTypes.MAP:
          parsedObj = observable.map(parsedObj);
          break;
        case RehydrationTypes.MAP_WITH_MOMENT:
          Object.keys(parsedObj).forEach((key: string) => {
            if (parsedObj[key].startDate && parsedObj[key].endDate) {
              parsedObj = {
                ...parsedObj,
                [key]: {
                  endDate: moment(parsedObj[key].endDate),
                  startDate: moment(parsedObj[key].startDate),
                },
              };
            }
          });
          parsedObj = observable.map(parsedObj);
          break;

        case RehydrationTypes.OBJECT_MAP_CHILDREN:
          Object.keys(parsedObj).forEach((key: string) => {
            parsedObj[key] = observable.map(parsedObj[key]);
          });
          break;
        case RehydrationTypes.OBJECT_SET_CHILDREN:
          Object.keys(parsedObj).forEach((key: string) => {
            parsedObj[key] = new Set(parsedObj[key]);
          });
          break;
        default:
          break;
      }
    }
    return parsedObj;
  } catch (err) {
    return defaultValue;
  }
}

export function generateUpdatedItemIdArray(
  currentIds: ArrayOfIds,
  params: {
    itemId: number;
    addedIds?: Array<number>;
    removedIds?: Array<number>;
    domainStore: DomainStoreModel;
  },
): ArrayOfIds {
  const { addedIds, removedIds } = params;

  if (!currentIds) {
    currentIds = [];
  }

  if (addedIds) {
    currentIds = [...currentIds, ...addedIds];
  }

  if (removedIds) {
    currentIds = [
      ...currentIds.filter((id: number) => removedIds.indexOf(id) === -1),
    ];
  }

  return currentIds;
}

export function generateEmptyObservableMap(): ObservableMap {
  return observable.map(new Map());
}
