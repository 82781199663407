/* eslint-disable multiline-comment-style */
import { appNavigationStore, domainStore } from 'stores';

import { PayloadActions } from '@storyslab/storyslab.common.models';

import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { DomainStoreModel } from '../models/domain.model';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';
import { getAutoGroups } from './groups.service';

export class AppNavigationService extends BaseService {
  private newItemDefaultName: string = 'New Nav Item';

  constructor() {
    super({
      create: EdgeUrls.NavItems,
      delete: EdgeUrls.NavItem,
      fetch: EdgeUrls.NavItems,
      update: EdgeUrls.NavItem,
    });
  }

  public async create({
    domainStore,
  }: // Hold - meta,
  {
    meta?: { [key: string]: any };
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      body: {
        name: this.newItemDefaultName,
      },
      method: EdgeRequestMethods.POST,
      url: EdgeUrls.NavItems,
    }).then((newNavItem: { id: number }) => {
      appNavigationStore.setItem(newNavItem);
      appNavigationStore.updateItem({
        body: {
          groupIds: {
            action: PayloadActions.ADD,
            ids: getAutoGroups(),
          },
        },
        domainStore,
        id: newNavItem.id,
      });
      return newNavItem;
    });
  }
}

export async function getView(body: { url: string }): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url: body.url,
  });
}

export async function persistViewConfiguration(params: {
  url: string;
  viewConfig: any;
}): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: params.viewConfig,
    method: EdgeRequestMethods.PUT,
    url: params.url,
  });
}
