import React from 'react';
import { Button, CardBody } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import StorySlabLogo from '../../assets/img/brand/logo-storyslab.png';
import { getStorySlabLoginUrl } from '../../helpers/url.helper';
import { DomainStoreModel } from '../../models/domain.model';

interface StorySlabLoginI {
  domainStore?: DomainStoreModel;
}

@inject('domainStore')
@observer
export class StorySlabLogin extends React.Component<StorySlabLoginI> {
  private handleButtonClick(): void {
    window.location.href = getStorySlabLoginUrl(
      this.props.domainStore.subdomain,
      +this.props.domainStore.tenantId,
    );
  }

  public render(): JSX.Element {
    return (
      <CardBody className="px-lg-5 py-lg-5">
        <div className="btn-wrapper text-center">
          <Button
            className="btn-neutral btn-icon"
            color="default"
            onClick={(): void => this.handleButtonClick()}
          >
            <span className="btn-inner--icon mr-1">
              <img alt="StorySlab Logo" src={StorySlabLogo} />
            </span>
            <span className="btn-inner--text">StorySlab Employee</span>
          </Button>
        </div>
      </CardBody>
    );
  }
}
