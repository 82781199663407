/* eslint-disable max-lines */
/* eslint-disable sort-keys */
import { FeatureCardConfig } from "./FeatureCardModels"

// TODO: Maybe break these out into different files?

export const ClientApp: FeatureCardConfig = {
  "title": "Client App",
  "description" : "Client app desc- TODO: Save all has no effect because they aren't in app_features table - default false - nothing to update - app spinup default features??.. should these be greyed out/locked???",
  "icon": "fal fa-laptop",
  "hasToggle": false,
  "appMetaPrefix": "style",
  "featureKey": "client",
  "subFeatureSections": [
    {
      "title": "iOS App",
      "description": "",
      "featureKey": "client.ios",
      "hasToggle": true,
    },
    {
      "title": "Android App",
      "description": "",
      "featureKey": "client.android",
      "hasToggle": true,
    },
    {
      "title": "Windows App",
      "description": "",
      "featureKey": "client.windows",
      "hasToggle": true,
    },
    {
      "title": "Web App",
      "description": "",
      "featureKey": "client.web",
      "hasToggle": true,
    }
  ]
}

export const ColorAndStyling: FeatureCardConfig = {
  "title": "Colors & Styling",
  "description" : "Application Color and style",
  "icon": "fal fa-paint-brush",
  "hasToggle": false,
  "featureKey": "style",
  "appMetaPrefix": "style",
  "subFeatureSections": [
    {
      "title": "Basic CMS Styling",
      "description": "",
      "hasToggle": false,
      "featureKey": "style.cms",
      "fields": [
        {
          "title": "CMS theme",
          "description": "These are hardcoded options",
          "type": "select",
          "options": ['light','dark'],
          "metaKey": "style.cms.theme",
          "isPublic": true,
        },
        {
          "title": "Primary CMS Color",
          "description": "mic 1",
          "type": "color",
          "metaKey": "style.cms.primaryColor",
          "isPublic": true,
        },
        {
          "title": "Secondary CMS Color",
          "description": "mic 2",
          "type": "color",
          "metaKey": "style.cms.secondaryColor",
          "isPublic": true,
        }
      ],
    },
    {
      "title": "Basic App Styling",
      "description": "Generic application assets used to represent your app",
      "hasToggle": false,
      "featureKey": "style.app",
      "fields": [
        {
          "title": "App Tint Color",
          "description": "",
          "type": "color",
          "metaKey": "style.app.primaryColor",
          "isPublic": true,
        }
      ],
    },
    {
      "title": "TODO: Navigation View Colors & Styling",
      "description": "",
      "featureKey": "style.navigation",
      "hasToggle": false,
    },
    {
      "title": "TODO: Collection View Colors & Styling",
      "description": "",
      "featureKey": "style.collection",
      "hasToggle": false,
    },
    {
      "title": "TODO: Share+ Colors & Styling",
      "description": "style.share",
      "hasToggle": false,
    },
    {
      "title": "TODO: Login Styling",
      "description": "style.login",
      "hasToggle": false,
    }
  ]
}

export const AppIdentity: FeatureCardConfig = {
  "title": "App Identity",
  "description" : "Application Identity Description",
  "icon": "fal fa-fingerprint",
  "hasToggle": false,
  "featureKey": "appIdentity", // TODO: Change to appIdentity
  "appMetaPrefix": "appIdentity",
  "subFeatureSections": [
    {
      "title": "Names",
      "description": "Company and application names as they appear to your users",
      "hasToggle": false,
      "featureKey": "appIdentity.names",
      "fields": [
        {
          "title": "Company/Entity Name",
          "description": "",
          "type": "text",
          "metaKey": "appIdentity.entityName",
          "isPublic": true,
        },
        {
          "title": "Application Name",
          "description": "",
          "type": "text",
          "metaKey": "appIdentity.applicationName",
          "isPublic": true,
        }
      ],
    },
    {
      "title": "Images",
      "description": "Generic application assets used to represent your app",
      "hasToggle": false,
      "featureKey":"appIdentity.images",
      "fields": [
        {
          "title": "Square Logo",
          "description": "",
          "type": "image",
          "metaKey": "appIdentity.squareLogo",
          "isPublic": true,
        }
      ],
    }
  ]
}

export const Share: FeatureCardConfig = {
  "title": "Share+",
  "description" : "Same as above - do we lock subfeatures too ??",
  "icon": "fal fa-user-lock",
  "hasToggle": true,
  "featureKey": "share",
  "appMetaPrefix": "share",
  "subFeatureSections": [
    {
      "title": "Basic Settings",
      "description": "Basic settings & defaults for system-wide Share+ bundles",
      "hasToggle": false,
      "featureKey": "share.basic",
      "fields": [
        {
          "title": "Default Expiration",
          "description": "",
          "type": "timeInterval",
          "metaKey": "share.defaultExpiration",
          "isPublic": true,
        },
        {
          "title": "Default Sitelet Layout",
          "description": "",
          "type": "shareLayoutSelect",
          "metaKey": "share.defaultSiteletLayout",
          "isPublic": true,
        },
        {
          "title": "Default Identity Action",
          "description": "",
          "type": "bundleIdentityActionSelect",
          "metaKey": "share.defaultIdentityAction",
          "isPublic": true,
        }
      ]
    },
    {
      "title": "Admin Dashboard",
      "description": "Grants access to the application-wide share bundle dashboard in the CMS",
      "hasToggle": true,
      "featureKey": "share.dashboard.admin",
    },
    {
      "title": "User Dashboard",
      "description": "Allows a user to access their personal Share+ dashboard in the Apps",
      "hasToggle": true,
      "featureKey": "share.dashboard.user"
    },
    {
      "title": "allowExpirationOverride",
      "description": "",
      "hasToggle": true,
      "featureKey": "share.create.allowExpirationOverride"
    },
    {
      "title": "allowNonExpiringBundles",
      "description": "",
      "hasToggle": true,
      "featureKey": "share.create.allowNonExpiringBundles"
    },
    {
      "title": "allowIdentityActionOverride",
      "description": "",
      "hasToggle": true,
      "featureKey": "share.create.allowIdentityActionOverride"
    },
    {
      "title": "allowSiteletOverrides",
      "description": "",
      "hasToggle": true,
      "featureKey": "share.create.allowSiteletOverrides"
    }
  ]
}

export const Authentication: FeatureCardConfig = {
  "title": "Authentication",
  "description" : "",
  "icon": "fal fa-key",
  "hasToggle": false,
  "appMetaPrefix": "authenticate",
  "featureKey": "authenticate",
  "subFeatureSections": [
    {
      "title": "Basic Login",
      "description": "",
      "hasToggle": false,
      "featureKey": "authenticate.basicLogin",
      "fields": [
        {
          "title": "Basic Login Enabled",
          "description": "",
          "type": "toggle",
          "metaKey": "authenticate.basicLogin.enabled",
          "isPublic": true,
        },
        {
          "title": "Login prompt",
          "description": "",
          "type": "text",
          "metaKey": "authenticate.basicLogin.prompt",
          "isPublic": true,
        }
      ],
    },
    {
      "title": "Login Page Styling",
      "description": "",
      "hasToggle": false,
      "featureKey": "authenticate.loginPageStyling",
      "fields": [
        {
          "title": "Logo Image (square)",
          "description": "Square image used on the public login page",
          "type": "image",
          "metaKey": "authenticate.style.squareLogo",
          "isPublic": true,
        },
        {
          "title": "Background Image (large)",
          "description": "Full screen background image used on the public login page",
          "type": "image",
          "metaKey": "authenticate.style.backgroundImage",
          "isPublic": true,
        }
      ],
    },
  ]
}

export const TestFeature: FeatureCardConfig = {
  "title": "TESTING",
  "description" : "TESTING DESCRIPTION",
  "icon": "fal fa-flask",
  "hasToggle": true,
  "featureKey": "testFeature",
  "appMetaPrefix": "testFeature", // TODO: not sure if deprecated.. depends on per-card save vs page wide save
  "subFeatureSections": [
    {
      "title": "Subfeature 1",
      "description": "",
      "hasToggle": true,
      "featureKey": "testFeature.subFeature1",
      "fields": [
        {
          "title": "Test Setting",
          "description": "",
          "type": "text",
          "metaKey": "testFeature.testSetting",
          "isPublic": false,
        },
        {
          "title": "Test Toggle",
          "description": "why does this toggle not have cursor:pointer ?",
          "type": "toggle",
          "metaKey": "testFeature.testToggle",
          "isPublic": false,
        },
        {
          "title": "Test Color",
          "description": "",
          "type": "color",
          "metaKey": "testFeature.testColor",
          "isPublic": false,
        },
        {
          "title": "Test Image",
          "description": "",
          "type": "image",
          "metaKey": "testFeature.testImage",
          "isPublic": false,
        },
        {
          "title": "Test Generic Select",
          "description": "",
          "type": "select",
          "options": ['thin','normal','solid'],
          "metaKey": "testFeature.testSelect",
          "isPublic": false,
        },
        {
          "title": "Test time interval",
          "description": "",
          "type": "timeInterval",
          "metaKey": "testFeature.timeInterval",
          "isPublic": false,
        },
        {
          "title": "test share layout select",
          "description": "",
          "type": "shareLayoutSelect",
          "metaKey": "testFeature.shareLayoutSelect",
          "isPublic": false,
        },
        {
          "title": "test bundle identity action select",
          "description": "",
          "type": "bundleIdentityActionSelect",
          "metaKey": "testFeature.bundleIdentityActionSelect",
          "isPublic": false,
        },
      ]
    },
    {
      "title": "Subfeature 2",
      "description": "",
      "hasToggle": true,
      "featureKey": "testFeature.subFeature2",
    }
  ]
}

