/* eslint-disable max-lines-per-function */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ContentApiType, ManageCollection } from 'enums/contentTree.enum';
import { EdgeUrls } from 'enums/edge.enum';
import Routes from 'enums/routes.enum';
import { ManageCollectionProps } from 'interfaces';
import { manageCollection } from 'services/tree.service';
import { collectionsStore, domainStore, simpleListsStore } from 'stores';

import { TreeContext } from 'components/ContentTree/ContentTree';

import ModalWrapper from './AddTools/ModalWrapper';

interface Props {
  item: any;
  parentId?: number;
}

function StackItemMenu(props: Props): JSX.Element {
  const { item, parentId } = props;

  const { forceUpdate }: any = useContext(TreeContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isOpen && isEditing && (
        <ModalWrapper
          closeDrawer={(): void => {
            setIsEditing(false);
            forceUpdate();
          }}
          newCollectionID={item.id}
          shouldStopPropagation={true}
        />
      )}
      <i
        className={`fas fa-ellipsis-v stack-item-menu-icon ${
          isOpen && 'is-open'
        }`}
        onClick={(e): void => {
          e.stopPropagation();
          setIsOpen(!isOpen);
          setIsEditing(false);
        }}
      ></i>
      {isOpen && (
        <>
          <span
            className="stack-item-menu-backsplash"
            onClick={(e): void => {
              e.stopPropagation();
              setIsOpen(false);
              setIsEditing(false);
            }}
          ></span>
          <div className="stack-item-menu-wrapper">
            <div
              className="menu-item"
              onClick={(e): void => {
                e.stopPropagation();
                if (item.type !== 'contentItem') {
                  setIsEditing(true);
                }
              }}
            >
              {item.type === 'contentItem' ? (
                <Link
                  to={Routes.ContentItemsDetail.replace(':id', item.id)}
                  style={{ color: 'white', display: 'block' }}
                >
                  Edit
                </Link>
              ) : (
                'Edit'
              )}
            </div>
            <div
              className="menu-item"
              onClick={(e): void => {
                e.stopPropagation();
                window.siteWideComponent.show({
                  approve: () => {
                    const url: string =
                      item.type === ContentApiType.ITEM
                        ? EdgeUrls.ContentItem.replace(
                            ':id',
                            item.id.toString(),
                          )
                        : EdgeUrls.Collection.replace(
                            ':id',
                            item.id.toString(),
                          );
                    const params: ManageCollectionProps = {
                      action: ManageCollection.REMOVE,
                      activeList: parentId,
                      targetID: item.id,
                      url,
                    };
                    manageCollection(params).then(() => {
                      forceUpdate();
                    });
                  },
                  body: `Are you sure you want to remove collection ${
                    simpleListsStore.collections.get(props.item.id).name
                  } from ${simpleListsStore.collections.get(parentId)?.name}?`,
                  title: 'Remove Confirmation',
                });
              }}
            >
              Remove from {simpleListsStore.collections.get(parentId)?.name}
            </div>
            {item.type === ContentApiType.COLLECTION && (
              <div
                className="menu-item"
                onClick={(e): void => {
                  e.stopPropagation();
                  window.siteWideComponent.show({
                    approve: () => {
                      collectionsStore.deleteItem({
                        domainStore,
                        id: props.item.id,
                      });
                      forceUpdate();
                    },
                    body: `Are you sure you want to delete collection ${
                      simpleListsStore.collections.get(props.item.id).name
                    }?`,
                    title: 'Delete Confirmation',
                  });
                }}
              >
                Delete collection
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default StackItemMenu;
