import React from 'react';
import { Button, Col, Collapse, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { EntitlementsStore, UserRolesStore, UsersStore } from 'stores';

import { ExpandedRowContentsProps } from '../../models/grid-table.model';
import Number from '../Cards/Number';

interface InternalState {}

interface Props extends ExpandedRowContentsProps {
  usersStore: UsersStore;
  entitlementsStore: EntitlementsStore;
  userRolesStore: UserRolesStore;
}

@inject('domainStore')
@inject('simpleListsStore')
@inject('usersStore')
@inject('entitlementsStore')
@inject('userRolesStore')
@observer
class ExpandedRowContents extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { item, usersStore, entitlementsStore } = this.props;
    return (
      <>
        <Collapse isOpen={this.props.isOpen}>
          {this.props.isOpen && (
            <Container className="m-0 p-0 no-max-width">
              <Row className="grid-item-edit-drawer mt-2 ml-4 mr-4 pb-3">
                <Col md="2" className="pl-0" />
                <Col md="4" className="pl-0">
                  <Number
                    title="Users"
                    value={item.usersCount}
                    icon={'fa-user-friends'}
                  />
                  <Button
                    block
                    size="lg"
                    type="button"
                    className="edit-button"
                    onClick={(): void => {
                      usersStore.openDrawerModal({
                        key: 'UserRoles',
                        params: {
                          count: item.usersCount,
                          id: item.id,
                          title: item.name,
                        },
                      });
                    }}
                  >
                    Edit Users
                  </Button>
                </Col>
                <Col md="4">
                  <Number
                    title="Entitlements"
                    value={item.entitlementsCount}
                    icon={'fa-user-lock'}
                  />
                  <Button
                    block
                    size="lg"
                    type="button"
                    className="edit-button"
                    disabled={!item.isEditable}
                    onClick={(): void => {
                      entitlementsStore.openDrawerModal({
                        key: 'UserRoles',
                        params: {
                          count: item.entitlementsCount,
                          id: item.id,
                          title: item.name,
                        },
                      });
                    }}
                  >
                    Edit Entitlements
                  </Button>
                </Col>
                <Col md="2" className="pr-0" />
              </Row>
            </Container>
          )}
        </Collapse>
      </>
    );
  }
}

export default ExpandedRowContents;
