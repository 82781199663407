import React, { useEffect, useState } from 'react';
import NavigationIndicator from 'assets/svg/navigation-indicator';
import { observer } from 'mobx-react';
import { appNavigationStore, domainStore } from 'stores';

import AddNavItemButton from 'components/ApplicationNavigation/Home/AddNavItemButton';
import NavItemList from 'components/ApplicationNavigation/Home/NavItemList';
import Loader from 'components/Indicators/Loader';

const AppNavHome: any = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [navItemIds, setNavItemIds] = useState([]);

  useEffect(() => {
    appNavigationStore
      .fetchItems({ appNavigationStore, domainStore })
      .then((): void => {
        const initialNavItemIds: Array<string> = [];
        for (const [key] of appNavigationStore.items) {
          initialNavItemIds.push(key.toString());
        }
        setNavItemIds(initialNavItemIds);
        setIsLoading(false);
      });
  }, []);

  function refreshList(): any {
    appNavigationStore
      .fetchItems({ appNavigationStore, domainStore })
      .then((): void => {
        const initialNavItemIds: Array<string> = [];
        for (const [key] of appNavigationStore.items) {
          initialNavItemIds.push(key.toString());
        }
        setNavItemIds(initialNavItemIds);
        setIsLoading(false);
      });
  }

  return (
    <>
      <div style={styles.wrapper}>
        <div style={styles.leftContain}>
          <div style={styles.blurb}>
            When you click on the StorySlab icon within the app this is the area
            where the different areas of the app show up.
          </div>
          <NavigationIndicator />
        </div>
        <div style={styles.rightContain}>
          {isLoading && <Loader />}
          {!isLoading && (
            <NavItemList
              navItems={navItemIds}
              refreshList={refreshList}
              setIsLoading={setIsLoading}
            />
          )}
          <AddNavItemButton
            refreshList={refreshList}
            setIsLoading={setIsLoading}
          />
        </div>
      </div>
    </>
  );
});

const styles: any = {
  blurb: {
    color: '#666',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '44px',
  },
  leftContain: {
    flex: 4,
  },
  rightContain: {
    flex: 5,
    padding: '50px',
  },
  wrapper: {
    display: 'flex',
  },
};

export default AppNavHome;
