import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Routes from 'enums/routes.enum';

interface InternalProps {}

function NoNavItems(props: InternalProps): JSX.Element {
  return (
    <>
      <div style={styles.wrapper}>
        <div style={styles.title}>
          Nothing to see here. Create a navigation view before using this page!
        </div>
        <Link to={Routes.ApplicationNavigation}>
          <Button className="btn-main">Create a navigation view</Button>
        </Link>
      </div>
    </>
  );
}

const styles: any = {
  title: {
    color: '#4d4d4d',
    fontSize: '36px',
    fontWeight: 800,
    marginBottom: '20px',
    maxWidth: '906px',
    textAlign: 'center',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '200px',
  },
};

export default NoNavItems;
