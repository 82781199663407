enum Routes {
  ApplicationNavigation = '/application/navigation',
  ApplicationLayout = '/application/layout/:id',
  ApplicationConfiguration = '/application/configuration',
  ApplicationConfigurationEdit = '/application/configuration/:id',
  ApplicationSettingsFeatures = '/application/settings-features',
  Content = '/content',
  ContentCategories = '/content/categories',
  ContentCollections = '/content/collections',
  ContentCollectionsDetail = '/content/collections/detail/:id',
  ContentCollectionViewLayoutConfiguration = '/content/collection-view-layouts/:id',
  ContentCollectionViewLayoutDetailEditComponent = '/content/collection-view-layouts/:id/:key',
  ContentCollectionViewLayouts = '/content/collection-view-layouts',
  ContentCollectionViewLayoutsSelector = '/content/collection-view-layouts/selector',
  ContentItem = '/content/items/item',
  ContentItems = '/content/items',
  ContentItemsDetail = '/content/items/detail/:id',
  ContentItemsAdd = '/content/items/add',
  ContentItemsAddSourceComputer = '/content/items/add/source-computer',
  ContentItemsAddSourceDropbox = '/content/items/add/source-dropbox',
  ContentItemsAddSourceGoogleDrive = '/content/items/add/source-google-drive',
  ContentItemsAddSourceLink = '/content/items/add/source-link',
  ContentItemsAddSourceUrl = '/content/items/add/source-url',
  ContentTreeDeepLink = '/content/tree/:hasLink',
  ContentTree = '/content/tree',
  Engagement = '/engagement',
  EngagementBundle = '/engagement/bundles/:shortCode',
  EngagementBundles = '/engagement/bundles',
  Groups = '/users/groups',
  Users = '/users',
  UserRoles = '/users/roles',
  ViewConfigurationComponentEdit = '/view/configuration/:id/:key',
}

export default Routes;
