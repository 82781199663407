import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ActionTypes } from 'models/action.model';
import { domainStore } from 'stores';
import { BaseStore } from 'stores/base.store';

interface Props {
  store: BaseStore<any>;
}

interface OptionProps {
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function Option(props: OptionProps): JSX.Element {
  const { count, setCount, setIsOpen } = props;

  return (
    <>
      <div
        className="items-per-page-option"
        style={styles.option}
        onClick={(): void => {
          setCount(count);
          setIsOpen(false);
        }}
      >
        {count}
      </div>
    </>
  );
}

const ItemsPerPage: any = observer((props: Props) => {
  const { store } = props;

  const [isInital, setIsInitial] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(store.pageSize);

  useEffect(() => {
    if (isInital) {
      setIsInitial(false);
    } else {
      store.setPageSize(count);
      store.handleTableAction(
        { domainStore },
        { payload: { page: '0' }, type: ActionTypes.PAGINATE },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    setCount(store.pageSize);
  }, [store.pageSize]);

  return (
    <>
      <div
        onBlur={(): void => {
          setIsOpen(false);
        }}
        style={styles.wrapper}
        tabIndex={0}
      >
        Items per page
        <div
          className="items-per-page-dropdown"
          onClick={(): void => {
            setIsOpen(!isOpen);
          }}
          style={styles.dropdownWrapper}
        >
          {count} <i className={`fas fa-angle-${isOpen ? 'up' : 'down'}`} />
        </div>
        {isOpen && (
          <div style={styles.menu}>
            <Option count={5} setIsOpen={setIsOpen} setCount={setCount} />
            <Option count={10} setIsOpen={setIsOpen} setCount={setCount} />
            <Option count={20} setIsOpen={setIsOpen} setCount={setCount} />
            <Option count={50} setIsOpen={setIsOpen} setCount={setCount} />
            <Option count={100} setIsOpen={setIsOpen} setCount={setCount} />
          </div>
        )}
      </div>
    </>
  );
});

const styles: any = {
  dropdownWrapper: {
    border: '1px solid #151515',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: 700,
    marginLeft: '.5rem',
    padding: '.5rem 1rem',
    textAlign: 'center',
    width: '80px',
  },
  menu: {
    backgroundColor: '#fff',
    border: '1px solid #151515',
    borderRadius: '8px',
    bottom: '105%',
    fontWeight: 700,
    padding: '.5rem 0 .5rem 0',
    position: 'absolute',
    right: 0,
    width: '80px',
  },
  option: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#151515',
    display: 'flex',
    fontSize: '14px',
    position: 'absolute',
    right: 0,
    top: 0,
  },
};

export default ItemsPerPage;
