import { ApplicationFeatureCreateOrUpdate } from '@storyslab/storyslab.common.models';

import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { DomainStoreModel } from '../models/domain.model';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export async function fetchAllAppFeatures(domainStore: DomainStoreModel): Promise<any>{
  return await fetchAppFeaturesByKey(domainStore, "")
}

export async function fetchAppFeaturesByKey(domainStore: DomainStoreModel, featureKey?: string): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    includeMeta: true,
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.AppFeatures + `/${featureKey || ""}`,
  });
  return resp;
}

export async function updateAppFeature(domainStore: DomainStoreModel, payload: Array<any>): Promise<any>{
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: payload,
    includeMeta: true,
    method: EdgeRequestMethods.PUT,
    url: EdgeUrls.AppFeatures,
  });
  return resp;
}

// NOTE: Not sure if these are necessary in the cms right now.. in future endpoint will require proper entitlements

export async function createAppFeature(domainStore: DomainStoreModel, payload: Array<ApplicationFeatureCreateOrUpdate>): Promise<any>{
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: payload,
    includeMeta: true,
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.AppFeatures,
  });
  return resp;
}

export async function deleteAppFeature(domainStore: DomainStoreModel, featureKey: string): Promise<any>{
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    includeMeta: true,
    method: EdgeRequestMethods.DELETE,
    url: EdgeUrls.AppFeatures + `/${featureKey || ""}`,
  });
  return resp;
}
