import React from 'react';
import { Button, Col, Collapse, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { ExpandedRowContentsProps } from 'models/grid-table.model';
import { CollectionsStore, GroupsStore, UsersStore } from 'stores';

import Number from '../Cards/Number';

interface InternalState {}

interface Props extends ExpandedRowContentsProps {
  collectionsStore: CollectionsStore;
  contentStore: GroupsStore;
  usersStore: UsersStore;
}

@inject('collectionsStore')
@inject('contentStore')
@inject('usersStore')
@inject('domainStore')
@observer
class ExpandedRowContents extends React.Component<Props, InternalState> {
  private renderEditUsersButton(): JSX.Element {
    const { item, usersStore } = this.props;

    return (
      <Button
        block
        size="lg"
        type="button"
        className="edit-button"
        onClick={(): void => {
          usersStore.openDrawerModal({
            key: 'Groups',
            params: {
              count: item.usersCount,
              id: item.id,
              title: item.name,
            },
          });
        }}
      >
        Edit Users
      </Button>
    );
  }

  private renderEditCollections(): JSX.Element {
    const { item, collectionsStore } = this.props;

    return (
      <Button
        block
        size="lg"
        type="button"
        className="edit-button"
        onClick={(): void => {
          collectionsStore.openDrawerModal({
            key: 'Groups',
            params: {
              count: item.collectionsCount,
              id: item.id,
              title: item.name,
            },
          });
        }}
      >
        Edit Collections
      </Button>
    );
  }

  private renderEditContentItemsButton(): JSX.Element {
    const { item, contentStore } = this.props;

    return (
      <Button
        block
        size="lg"
        type="button"
        className="edit-button"
        onClick={(): void => {
          contentStore.openDrawerModal({
            key: 'Groups',
            params: {
              count: item.contentItemsCount,
              id: item.id,
              title: item.name,
            },
          });
        }}
      >
        Edit Content Items
      </Button>
    );
  }

  public render(): JSX.Element {
    const { item } = this.props;
    return (
      <>
        <Collapse isOpen={this.props.isOpen}>
          {this.props.isOpen && (
            <Container className="m-0 p-0 no-max-width">
              <Row className="grid-item-edit-drawer mt-2 ml-4 mr-4 pb-3">
                <Col md="4" className="pl-0">
                  <Number
                    title="Users"
                    value={item.usersCount}
                    icon={'fa-user-friends'}
                  />
                  {this.renderEditUsersButton()}
                </Col>
                <Col md="4">
                  <Number
                    title="Collections"
                    value={item.collectionsCount}
                    icon={'fa-sitemap'}
                  />
                  {this.renderEditCollections()}
                </Col>
                <Col md="4" className="pr-0">
                  <Number
                    title="Content Items"
                    value={item.contentItemsCount}
                    icon={'fa-photo-video'}
                  />
                  {this.renderEditContentItemsButton()}
                </Col>
              </Row>
            </Container>
          )}
        </Collapse>
      </>
    );
  }
}

export default ExpandedRowContents;
