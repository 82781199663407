import React from 'react';

import CategoriesTable from 'components/Categories/CategoriesTable';

interface Props {}

function CategoriesPage(props: Props): JSX.Element {
  return <CategoriesTable />;
}

export default CategoriesPage;
