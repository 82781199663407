import React from 'react';
import { observer } from 'mobx-react';

interface InternalState {}

interface Props {
  store: any;
  opacity?: number;
}

@observer
class LoadingOpacity extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { children, opacity, store } = this.props;

    return (
      <div
        style={
          store && store.isLoading ? { opacity: opacity ? opacity : 0.5 } : {}
        }
      >
        {children}
      </div>
    );
  }
}

export default LoadingOpacity;
