/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import Dropzone, {
  IFileWithMeta,
  IUploadParams,
} from 'react-dropzone-uploader';
import { Button } from 'reactstrap';
import { knownFileTypes } from 'helpers/files.helper';
import { contentStore, domainStore } from 'stores';

import { ContentItemType } from '@storyslab/storyslab.common.models';
import DropzoneInput from 'components/Content/ContentRow/DropzoneInput';
import Layout from 'components/Content/Source/Layout';
import PreviewComponent from 'components/Content/Source/PreviewComponent';
import SubmitButton from 'components/Content/Source/SubmitButton';
import Loader from 'components/Indicators/Loader';

interface Props {
  itemId: number;
  reset: () => void;
}

const FileUpload: (props: Props) => JSX.Element = (props: Props) => {
  const [hasFiles, setHasFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleChangeStatus(params: IFileWithMeta, status: unknown): void {
    if (status === 'ready') {
      setHasFiles(true);
    }
  }

  function handleGetUploadParams(
    params: IFileWithMeta,
  ): Promise<IUploadParams> {
    const { file } = params;
    const { name, type } = file;

    setIsLoading(true);
    return contentStore
      .updateItem({
        body: {
          target: {
            name,
            syncInterval: undefined,
            syncParams: undefined,
            syncType: undefined,
            type,
          },
          type: ContentItemType.ASSET,
        },
        domainStore,
        id: props.itemId,
        includeResponse: true,
      })
      .then((response): IUploadParams => {
        setIsLoading(false);
        props.reset();
        return { ...response.s3PresignedPost };
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  return (
    <>
      {!isLoading && (
        <>
          <div style={styles.iconContain}>
            <i className={`fas fa-upload`} style={styles.icon} />
            <span style={styles.text}>Upload File</span>
          </div>

          <Dropzone
            accept={knownFileTypes()}
            autoUpload={false}
            maxFiles={1}
            multiple={false}
            getUploadParams={(test): Promise<IUploadParams> =>
              handleGetUploadParams(test)
            }
            InputComponent={DropzoneInput}
            LayoutComponent={Layout}
            onChangeStatus={(params, status): void =>
              handleChangeStatus(params, status)
            }
            onSubmit={(files): void => files[0].restart()}
            PreviewComponent={PreviewComponent}
            SubmitButtonComponent={SubmitButton}
          />
          {!hasFiles && (
            <div style={styles.buttonsContain}>
              <Button
                className="storyslab-button light"
                onClick={(): void => {
                  props.reset();
                }}
              >
                cancel
              </Button>
            </div>
          )}
        </>
      )}
      {isLoading && <Loader isFullPage={false} />}
    </>
  );
};

const styles: any = {
  buttonsContain: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    color: '#4d4d4d',
    fontSize: '26px',
    marginRight: '10px',
  },
  iconContain: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '15px',
  },
  text: {
    fontSize: '12px',
    fontWeight: 400,
  },
};

export default FileUpload;
