import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { ContentApiType, ManageCollection } from 'enums/contentTree.enum';
import { EdgeUrls } from 'enums/edge.enum';
import { getDefaultIconForItem } from 'helpers/icon.helper';
import { ManageCollectionProps } from 'interfaces';
import { observer } from 'mobx-react-lite';
import { fetchCollectionChildren } from 'services/collections.service';
import { manageCollection } from 'services/tree.service';
import { simpleListsStore } from 'stores';

import StorySlabAlert from 'components/Alert/StorySlabAlert';
import S3TenantAssetImage from 'components/Assets/S3TenantAssetImage';
import Loader from 'components/Indicators/Loader';

interface Props {
  collectionId: number;
  togglePreview: Dispatch<SetStateAction<boolean>>;
}

interface MenuProps {
  item: any;
  parentId: number;
  setShouldHide: Dispatch<SetStateAction<boolean>>;
}

function Menu(props: MenuProps): JSX.Element {
  const { item, parentId, setShouldHide } = props;
  const [shouldConfirm, setShouldConfirm] = useState(false);

  function deleteRelation(): void {
    const url: string =
      item.type === ContentApiType.ITEM
        ? EdgeUrls.ContentItem.replace(':id', item.id.toString())
        : EdgeUrls.Collection.replace(':id', item.id.toString());
    const params: ManageCollectionProps = {
      action: ManageCollection.REMOVE,
      activeList: parentId,
      targetID: item.id,
      url,
    };
    manageCollection(params);
  }

  return (
    <>
      <div
        className="preview-menu"
        onClick={(e): void => {
          e.stopPropagation();
          setShouldConfirm(true);
        }}
      >
        {shouldConfirm && (
          <StorySlabAlert
            title={'Delete Confirmation'}
            onApprove={(): void => {
              deleteRelation();
              setShouldConfirm(false);
              setShouldHide(true);
            }}
            onDeny={(): void => {
              setShouldConfirm(false);
            }}
          >
            Are you sure you want to delete this view?
          </StorySlabAlert>
        )}
        Remove {props.item.name} from{' '}
        {simpleListsStore.collections.get(props.parentId).name}
      </div>
    </>
  );
}

function ChildItem(props: { item: any; parentId: number }): JSX.Element {
  const { item } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [shouldHide, setShouldHide] = useState(false);

  function renderIcon(): JSX.Element {
    if (item.thumbnail === undefined) {
      return <></>;
    }

    if (item.type === ContentApiType.COLLECTION) {
      if (item.thumbnail.s3Key !== '') {
        return (
          <div className="thumbnail-container">
            <S3TenantAssetImage s3Key={item.thumbnail.s3Key} />
          </div>
        );
      }
      return <i className="fas fa-sitemap" />;
    }

    return <i className={`fas ${getDefaultIconForItem(item)}`} />;
  }

  return (
    <div
      className="preview-item"
      // Observer is acting weird again, won't update card
      style={{ display: shouldHide ? 'none' : 'flex' }}
    >
      <div className="preview-icon">{renderIcon()}</div>
      <div className="item-name">{props.item.name}</div>
      <div
        className={`preview-item-menu-contain`}
        onClick={(): void => {
          setIsOpen(!isOpen);
        }}
      >
        <i className={`fas fa-ellipsis-v ${isOpen && 'open'}`} />
        {isOpen && (
          <>
            <div className="backsplash"></div>
            <Menu
              item={props.item}
              parentId={props.parentId}
              setShouldHide={setShouldHide}
            />
          </>
        )}
      </div>
    </div>
  );
}

const Preview: any = observer((props: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [collectionChildren, setCollectionChildren] = useState([]);

  useEffect((): void => {
    fetchCollectionChildren(props.collectionId).then((response) => {
      setCollectionChildren(response);
      setIsLoading(false);
    });
  }, [props.collectionId]);

  return (
    <>
      <div
        className="backsplash"
        onClick={(e): void => {
          e.stopPropagation();
          props.togglePreview(false);
        }}
      ></div>
      <Card
        inverse
        className="preview-stack-list"
        onClick={(e): void => {
          e.stopPropagation();
        }}
      >
        <CardBody className="p-0 py-2">
          {isLoading && <Loader />}
          {!isLoading &&
            collectionChildren.map((item, index) => {
              return (
                <ChildItem
                  key={index}
                  item={item}
                  parentId={props.collectionId}
                />
              );
            })}
          {!isLoading && collectionChildren.length === 0 && (
            <div className="preview-item">Empty collection...</div>
          )}
        </CardBody>
      </Card>
    </>
  );
});

export default Preview;
