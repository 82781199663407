import React, { ChangeEvent } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import classnames from 'classnames';
import { SimpleList, SimpleListItem } from 'models/simple-lists.model';

import {
  ActionPayload,
  PayloadActions,
} from '@storyslab/storyslab.common.models';

import LabeledCheckbox from './LabeledCheckBox';

type ActiveIds = Array<number>;

interface InternalState {
  searchTerm: string;
}

interface Props {
  theme?: string;
  activeIds: ActiveIds;
  items: SimpleList;
  icon: string;
  handleUpdate: (params: { id: number; payload: ActionPayload }) => void;
  parentId: number;
}

class MultiEditDropdownMenu extends React.Component<Props, InternalState> {
  public state: InternalState = {
    searchTerm: '',
  };

  public defaultProps: {
    theme: 'light';
  };

  private getTitle(items: SimpleList, id: number): string {
    const itemValue: SimpleListItem = items?.get(id);
    return typeof itemValue === 'string' ? itemValue : itemValue?.name || '';
  }

  private getIcon(items: SimpleList, id: number): string {
    const itemValue: SimpleListItem = items?.get(id);
    return typeof itemValue === 'string' ? itemValue : itemValue?.icon || '';
  }

  private filterArrayBySearchTerm(
    searchTerm: string,
    id: number,
    items: SimpleList,
  ): boolean {
    searchTerm = searchTerm.toLowerCase();
    const title: string = this.getTitle(items, id);

    if (!title) {
      return false;
    }

    return title?.toLowerCase().indexOf(searchTerm) !== -1;
  }

  private renderRow(
    id: number,
    icon: string,
    items: SimpleList,
    selected: boolean,
    index: number,
  ): JSX.Element {
    const label: string = this.getTitle(items, id);
    const iconName: string = this.getIcon(items, id);
    return (
      <LabeledCheckbox
        key={id}
        {...{
          handleChange: (): void => {
            this.props.handleUpdate({
              id: this.props.parentId,
              payload: {
                action: selected ? PayloadActions.REMOVE : PayloadActions.ADD,
                ids: id.toString(),
              },
            });
          },
          icon,
          iconName,
          id,
          index,
          label,
          selected,
        }}
      />

      /*
       * <div className="labeled-checkbox" key={`${id}-${index}`}>
       *   <Label check>
       *     <Input
       *       type="checkbox"
       *       checked={selected}
       *       onChange={() => {
       *         this.props.handleUpdate({
       *           ids: this.props.parentId,
       *           ...(selected ? { removedIds: [id] } : { addedIds: [id] }),
       *         });
       *       }}
       *     />
       *     {icon && icon.length > 0 && (
       *       <i className={`labeled-checkbox-icon fas fa-${icon}`}></i>
       *     )}
       *     <span className="labeled-checkbox-label">{rowTitle}</span>
       *   </Label>
       * </div>
       */
    );
  }

  private renderCardBody(
    searchTerm: string,
    activeIds: ActiveIds,
    icon: string,
    items: SimpleList,
  ): JSX.Element {
    if (!items) {
      return null;
    }

    return (
      <>
        {/* TODO: [Tech Debt] This is ugly AF - rewrite and optimize */}
        <div>
          {activeIds
            ?.filter((id) =>
              this.filterArrayBySearchTerm(searchTerm, id, items),
            )
            .map((id, index) => this.renderRow(id, icon, items, true, index))}
        </div>
        <DropdownItem divider />

        <div>
          {/* Someday make sure you add an if statement to remove filter if there are no active items */}
          {Array.from(items.keys())
            .filter((id) => this.filterArrayBySearchTerm(searchTerm, id, items))
            .filter((id: number) =>
              activeIds ? activeIds?.indexOf(id) === -1 : true,
            )
            ?.map((id: number, index) =>
              this.renderRow(id, icon, items, false, index),
            )}
        </div>

        {/* END: [Tech Debt] */}
      </>
    );
  }

  private handleSearchInput(e: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      searchTerm: e.target.value,
    });
  }

  private renderNumberSelected({
    activeIds,
    items,
  }: {
    activeIds: ActiveIds;
    items: SimpleList;
  }): JSX.Element {
    const intersectionArray: Array<number> = Array.from(items.keys()).filter(
      (x) => activeIds.includes(x),
    );

    return <span>{intersectionArray?.length} Selected</span>;
  }

  private renderSearch(): JSX.Element {
    return (
      <Form
        className={classnames(
          'navbar-search form-inline',
          {
            'navbar-search-light': this.props.theme === 'dark',
          },
          {
            'navbar-search-dark': this.props.theme === 'light',
          },
        )}
      >
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Search"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                this.handleSearchInput(e)
              }
            />
          </InputGroup>
        </FormGroup>
        <button
          aria-label="Close"
          className="close"
          type="button"
          // OnClick={this.closeSearch}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </Form>
    );
  }

  public render(): JSX.Element {
    const { activeIds, icon, items } = this.props;
    return (
      <>
        <DropdownMenu className="multi-edit-dropdown-menu" right>
          <Card>
            <CardHeader className="p-0">
              <div className="p-2">{this.renderSearch()}</div>

              {activeIds?.length > 0 && (
                <div className="selected-notice">
                  {this.renderNumberSelected({ activeIds, items })}
                </div>
              )}
            </CardHeader>
            <CardBody>
              {this.renderCardBody(
                this.state?.searchTerm,
                activeIds,
                icon,
                items,
              )}
            </CardBody>
          </Card>
        </DropdownMenu>
      </>
    );
  }
}

export default MultiEditDropdownMenu;
