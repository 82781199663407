import { detect as detectBrowser } from 'detect-browser';
import { DomainStoreModel } from 'models/domain.model';
import { nanoid } from 'nanoid';
import { AuthPayload } from 'services/edge.service';

export function getUrlParameter(name: string): string {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex: RegExp = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results: RegExpExecArray = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function getDeviceString(): string {
  // Gets or generates a string in localstorage to uniquely identify browser
  const name: string = 'storyslab-device-string';
  let deviceString: any = localStorage.getItem(name);
  if (!deviceString) {
    const info: any = detectBrowser();
    const uid: string = nanoid();
    deviceString = [
      info?.type || 'unknown',
      info?.name || 'unknown',
      info?.os || 'unknown',
      uid,
    ].join('-');
  }
  localStorage.setItem(name, deviceString);
  return deviceString;
}

export function generateHeaders({
  applicationId,
  idToken,
  accessToken,
  tenantId,
  userDeviceId,
}: {
  applicationId: number;
  idToken: string;
  accessToken: string;
  tenantId: number;
  userDeviceId?: number;
}): {
  'storyslab-application-id': string;
  'storyslab-id-token': string;
  Authorization: string;
  'storyslab-tenant-id': string;
  'storyslab-user-device-id': string;
} {
  const headers: any = {
    Authorization: `Bearer ${accessToken}`,
    'storyslab-application-id': applicationId?.toString(),
    'storyslab-id-token': idToken,
    'storyslab-tenant-id': tenantId?.toString(),
  };
  if (userDeviceId) {
    headers['storyslab-user-device-id'] = userDeviceId?.toString();
  }
  return headers;
}

export function extractAuthDetailsFromDomainStore(
  domainStore: DomainStoreModel,
): AuthPayload {
  if (!domainStore) {
    throw new Error('Domain Store is required');
  }
  return {
    accessToken: domainStore?.auth.accessToken,
    applicationId: domainStore?.applicationId,
    idToken: domainStore?.auth.idToken,
    tenantId: domainStore?.tenantId,
    userDeviceId: domainStore?.user?.deviceId,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isEmpty(target: any): boolean {
  if (typeof target === 'undefined') {
    return true;
  }

  if (target && target.size !== undefined) {
    return target.size === 0;
  }

  if (target.constructor && target.constructor === Object) {
    return Object.entries(target).length === 0;
  }

  if (Array.isArray(target) || typeof target === 'string') {
    return target.length === 0;
  }
}

export function validateEmail(email: string): boolean {
  const re: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result: RegExpExecArray =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        b: parseInt(result[3], 16),
        g: parseInt(result[2], 16),
        r: parseInt(result[1], 16),
      }
    : null;
}

// WARNING: THIS IS NON-CRYPTOGRAPHIC. <-- Seriously.
export function simpleHash(
  datum: string | { [key: string]: number | string | boolean },
): string {
  let datumAsString: string = '';
  if (typeof datum === 'string') {
    datumAsString = datum;
  } else {
    for (const key in datum) {
      datumAsString = `${datumAsString}:${key}:${datum[key]}:`;
    }
  }

  let hash: number = 0;
  if (datumAsString.length === 0) {
    return hash.toString();
  }
  for (let i: number = 0; i < datumAsString.length; i++) {
    const char: number = datumAsString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash).toString();
}
