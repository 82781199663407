import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Admin from 'layouts/Admin';
import { inject, observer } from 'mobx-react';
import { SimpleListFetchConfig } from 'models/simple-lists.model';
import { refreshToken } from 'services/auth.service';
import { domainStore } from 'stores';

import { resolve } from '@storyslab/storyslab.common.helpers';

import { simpleListsFetchConfig } from '../config/simple-lists.config';
import { DomainStoreModel } from '../models/domain.model';
import { fetchTemporaryCredentials } from '../services/cognito.service';
import { simpleListsStore } from '../stores/simple-lists.store';

interface InternalState {
  isExpiring: boolean;
}

interface Props {
  domainStore?: DomainStoreModel;
}

@inject('domainStore')
@observer
class Authenticated extends React.Component<Props, InternalState> {
  private async bootstrap(): Promise<void> {
    this.refreshToken();

    setInterval(() => {
      this.refreshToken();
    }, 300000);

    if (domainStore.entitlements) {
      await Promise.all(
        simpleListsFetchConfig.map((config: SimpleListFetchConfig) =>
          simpleListsStore.populateSimpleList({
            ...config,
            domainStore: this.props.domainStore,
          }),
        ),
      );
    } else {
      console.warn('User has no entitlements');
    }

    const [credentials, credentialsError] = await resolve(
      fetchTemporaryCredentials(this.props.domainStore),
    );

    if (!credentialsError) {
      domainStore.setTemporaryCredentials(credentials);
    }
  }

  private refreshToken(): void {
    refreshToken({
      grant_type: 'refresh_token',
      identity_provider: domainStore.identityProvider,
      redirect_uri: `${process.env.REACT_APP_API_GATEWAY_AUTH}/redirect`,
      refresh_token: domainStore.auth.refreshToken,
      tenant_id: domainStore.tenantId,
    }).then(async () => {
      const [credentials, credentialsError] = await resolve(
        fetchTemporaryCredentials(this.props.domainStore),
      );

      if (!credentialsError) {
        domainStore.setTemporaryCredentials(credentials);
      }
    });
  }

  public async componentDidMount(): Promise<void> {
    this.bootstrap();
  }

  public render(): JSX.Element {
    return (
      <Switch>
        <Route
          path="*"
          render={(props: any): JSX.Element => <Admin {...props} />}
        />
        {/* <Redirect from="/" to="/auth/login" /> */}
      </Switch>
    );
  }
}

export default Authenticated;
