import React, { useState } from 'react';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { createColorCss } from 'helpers/component-color.helper';
import { uiStore } from 'stores';

interface Props {
  username: string;
}

const RequestCredentials: any = (props: Props) => {
  const [username, setUsername] = useState(props.username);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  return (
    <>
      <FormGroup className={'mb-3'}>
        <InputGroup className="input-group-merge input-group-alternative login-fields mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            onChange={(e): void => {
              setUsername(e.target.value);
            }}
            placeholder="Username"
            type="text"
            value={username}
          />
        </InputGroup>
        <InputGroup className="input-group-merge input-group-alternative login-fields mb-3">
          <div className="login-icon-spacer"></div>
          <Input
            onChange={(e): void => {
              setFirstName(e.target.value);
            }}
            placeholder="First Name"
            type="text"
            value={firstName}
          />
        </InputGroup>
        <InputGroup className="input-group-merge input-group-alternative login-fields">
          <div className="login-icon-spacer"></div>
          <Input
            onChange={(e): void => {
              setLastName(e.target.value);
            }}
            placeholder="Last Name"
            type="text"
            value={lastName}
          />
        </InputGroup>
      </FormGroup>
      <Button
        className="login-flow-button mb-5"
        color="info"
        style={createColorCss(uiStore, [['backgroundColor', 'primary']])}
        type="button"
        onClick={(): void => {
          console.log('requesting');
        }}
      >
        send request
      </Button>
    </>
  );
};
export default RequestCredentials;
