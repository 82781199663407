export enum EdgeUrls {
  Asset = '/assets/:id',
  AssetUpload = '/assets/upload',

  AppMeta = '/application/meta',
  AppMetaAsset = '/application/meta/s3File',

  AppFeatures = '/application/features',

  Bundle = '/share',
  Bundles = '/share/bundles',
  BundleShortCode = '/share/bundles/:id',

  Categories = '/categories',
  Category = '/categories/:id',

  CategorySimpleLists = '/categories/simple-list',

  CognitoIdentityCredentials = '/cognito-identity-credentials',

  Collection = '/collections/:id',
  CollectionChildren = '/collections/:id/children',
  Collections = '/collections',
  CollectionThumbnailUpload = '/collections/:id/thumbnail',
  CollectionImageUpload = '/collections/:id/image',

  CollectionSimpleLists = '/collections/simple-list',

  CollectionViews = 'views/collection',

  ContentItem = '/content/items/:id',
  ContentItemCategories = '/content/items/:id/categories',
  ContentItemCollections = '/content/items/:id/collections',
  ContentItemDeviceTypes = '/content/items/:id/device-types',
  ContentItemTags = '/content/items/:id/tags',
  ContentItems = '/content/items',
  ContentItemThumbnailUpload = '/content/items/:id/thumbnail',
  ContentItemSyncSource = '/content/items/:id/sync-asset/source',

  ContentItemStatusesSimpleList = '/statuses/simple-list/contentItem',

  ContentTreeRootView = '/views/:id/root-items',

  DeviceTypeSimpleList = '/devices/types/simple-list',

  Entitlements = '/entitlements/',

  Group = '/groups/:id',
  Groups = '/groups',
  GroupSimpleList = '/groups/simple-list',

  NavItem = '/navigation-items/:id',
  NavItems = '/navigation-items',
  NavItemViews = 'views/navigationItem',

  Publish = '/publish',

  Users = '/users',
  User = '/users/:id',

  UserAddToApp = '/users/addUserToApplication',
  UserCreate = '/users/createOrResolveCognitoIdentity',
  UserEntitlementsSimpleList = '/entitlements/simple-list',
  UserRoleSimpleList = '/user-roles/simple-list',
  UserRoles = '/user-roles',
  UserRole = '/user-roles/:id',
  UserSendInvite = '/users/send-invite/:id',
  UserResendInvite = '/users/resend-invite/:id',

  UserStatusesSimpleList = '/statuses/simple-list/user',

  TagsSimpleLists = '/tags/simple-list',

  View = '/views/:id',
  Views = '/views',
}
