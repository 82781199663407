import { Dispatch, SetStateAction } from 'react';
import { EdgeUrls } from 'enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import _ from 'lodash';
import { EdgeRequestMethods, fetchFromEdge } from 'services/edge.service';
import { domainStore } from 'stores';

import { fetchParams, ManageCollectionProps } from '../interfaces/index';

export async function standardFetch(params: fetchParams): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url: params.url,
  })
    .then((response: Response) => {
      return response;
    })
    .then((result) => {
      return result;
    });
}

export async function getRootViewItems(id: number): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.ContentTreeRootView.replace(':id', id.toString()),
  })
    .then((response: Response) => {
      return response;
    })
    .then((result) => {
      return result;
    });
}

export async function getCollectionChildren(id: number): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.CollectionChildren.replace(':id', id.toString()),
  })
    .then((response: Response) => {
      return response;
    })
    .then((result) => {
      return result;
    });
}

export async function manageCollection(
  params: ManageCollectionProps,
): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: {
      collectionIds: {
        action: params.action,
        ids: params.activeList.toString(),
      },
    },
    method: EdgeRequestMethods.PUT,
    url: params.url,
  });
}

export function getApplicationViews(
  setAppViews: Dispatch<SetStateAction<Array<any>>>,
): Promise<any> {
  return standardFetch({ url: EdgeUrls.CollectionViews }).then((result) => {
    setAppViews(
      _.orderBy([...result, { id: 0, name: 'Inherit from parent' }], ['name']),
    );
  });
}
