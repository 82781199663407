import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CardBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  UncontrolledAlert,
} from 'reactstrap';
import classnames from 'classnames';
import { createColorCss } from 'helpers/component-color.helper';
import { inject, observer } from 'mobx-react';
import { DomainStoreModel } from 'models/domain.model';
import { UiStoreModel } from 'models/ui.model';
import {
  authenticateAppUser,
  login,
  setJwtToDomainStore,
} from 'services/auth.service';
import SimpleReactValidator from 'simple-react-validator';
import { domainStore } from 'stores';

interface InternalState {
  error: string;
  focusedEmail?: boolean;
  focusedPassword?: boolean;
  isLoading: boolean;
  primaryColor: string;
  rememberMe: boolean;
}

interface Props {
  onChange?: (target: string, value: any) => void;
  domainStore?: DomainStoreModel;
  uiStore?: UiStoreModel;
  username: string;
  password: string;
}

@inject('domainStore')
@inject('uiStore')
@observer
export class GenericLogin extends React.Component<Props, InternalState> {
  private validator: SimpleReactValidator;

  constructor(props: Props) {
    super(props);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.state = {
      error: null,
      focusedEmail: false,
      focusedPassword: false,
      isLoading: false,
      primaryColor: '',
      rememberMe: true,
    };
  }

  private async handlePressLogin(): Promise<void> {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ isLoading: true }, async () => {
      const [resp, error] = await login({
        applicationId: this.props.domainStore.applicationId,
        password: this.props.password,
        tenantId: this.props.domainStore.tenantId,
        username: this.props.username,
      });

      if (!error) {
        if (resp.data.access_token) {
          setJwtToDomainStore(resp.data);
          domainStore.setIdentityProvider('generic');
          const [, appAuthErr] = await authenticateAppUser();
          if (appAuthErr) {
            console.error(appAuthErr);
            this.setState({ error: appAuthErr.toString(), isLoading: false });
            return;
          }
          this.setState({ isLoading: false });

          //TODO: setting plain text user/password here ok?
          domainStore.setCredentials(this.props.username, this.props.password);
        } else {
          try {
            this.props.onChange(
              'requiredAttributes',
              resp.meta.requiredAttributes,
            );
          } catch (err) {
            console.log(err);
          }
          this.setState({ isLoading: false });
        }
      } else {
        this.setState({
          error: error.toString(),
          isLoading: false,
        });
      }
    });
  }

  private renderFormGroupUserName(): JSX.Element {
    return (
      <FormGroup
        className={classnames('mb-3', {
          focused: this.state.focusedEmail,
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative login-fields">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-email-83" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            defaultValue={this.props.username}
            placeholder="Username"
            type="text"
            onFocus={(): void =>
              this.setState({ error: null, focusedEmail: true })
            }
            onBlur={(): void => this.setState({ focusedEmail: false })}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              this.props.onChange('username', e.target.value)
            }
          />
        </InputGroup>
        <p>
          {this.validator.message(
            'username',
            this.props.username,
            'required|email',
          )}
        </p>
      </FormGroup>
    );
  }

  private renderFormGroupPassword(): JSX.Element {
    return (
      <FormGroup
        className={classnames({
          focused: this.state.focusedPassword,
        })}
      >
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-lock-circle-open" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            defaultValue={this.props.password}
            placeholder="Password"
            type="password"
            onFocus={(): void =>
              this.setState({ error: null, focusedPassword: true })
            }
            onBlur={(): void => this.setState({ focusedPassword: false })}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              this.props.onChange('password', e.target.value)
            }
          />
        </InputGroup>
        <p>
          {this.validator.message('password', this.props.password, 'required')}
        </p>
      </FormGroup>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <CardBody className="">
          <Form role="form">
            {this.renderFormGroupUserName()}
            {this.renderFormGroupPassword()}

            <UncontrolledAlert
              isOpen={!!this.state.error}
              color="danger"
              onClick={(): void => this.setState({ error: null })}
            >
              <span className="alert-text">
                {this.state.error || 'It looks like something went wrong.'}
              </span>
            </UncontrolledAlert>

            <Button
              className="login-flow-button"
              color="info"
              style={createColorCss(this.props.uiStore, [
                ['backgroundColor', 'primary'],
              ])}
              type="button"
              onClick={(): Promise<void> => this.handlePressLogin()}
            >
              {this.state.isLoading ? (
                <>
                  {' '}
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                </>
              ) : null}
              Log in
            </Button>
            <div className="login-meta">
              <Link to="login/forgot" style={{ margin: 'auto' }}>
                <span
                  onClick={(): void => {
                    this.props.onChange('isForgotPassword', true);
                    this.props.onChange('titleText', 'Forgot your password?');
                  }}
                >
                  Forgot your password?
                </span>
              </Link>
            </div>
          </Form>
        </CardBody>
      </>
    );
  }
}
