import React, { useEffect, useState } from 'react';
import { Card, Container } from 'reactstrap';
import { SortOrder } from 'enums/grid-table.enum';
import { ActionTypes } from 'models/action.model';
import { bundlesStore, domainStore, uiStore } from 'stores';

import { headerColumnConfig } from 'components/Engagement/Bundles/Table/HeaderColumnConfig';
import EmptyTable from 'components/Forms/EmptyTable';
import GridTableCardHeader from 'components/GridTable/CardHeader';
import Header from 'components/GridTable/Header';
import Loader from 'components/Indicators/Loader';

import BundleRow from './BundleRow';

interface Props {}

function BundlesTable(props: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    bundlesStore.fetchItems({ domainStore }).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <i className="fas fa-paper-plane" /> Share+ Bundles
        </h4>

        <Card className="p-1 pb-5">
          <GridTableCardHeader
            actionButtonTitle=""
            actionButtonIcon=""
            actionButtonHandler={(): void => {
              //
            }}
            shouldNotPaginate={true}
            store={bundlesStore}
          />

          {bundlesStore.filters.size > 0 && (
            <div className="clear-filter-contain">
              <div
                onClick={(): void => {
                  bundlesStore.clearFilters(this.props);
                  bundlesStore.handleTableAction(this.props, {
                    payload: { updated: SortOrder.ASC },
                    type: ActionTypes.SEARCH,
                  });
                  bundlesStore.handleTableAction(this.props, {
                    payload: '',
                    type: ActionTypes.SORT,
                  });
                }}
              >
                <i className="fas fa-times"></i>{' '}
                {`Remove ${bundlesStore.filters.size} filter${
                  bundlesStore.filters.size === 1 ? '' : 's'
                }`}
              </div>
            </div>
          )}

          {isLoading && <Loader style={{ margin: '2rem' }} />}

          {!isLoading && bundlesStore.count === 0 && (
            <EmptyTable
              title={'No bundles yet...'}
              text={'Start by adding bundles in app!'}
            />
          )}

          {/* Need empty div to get alt row coloring right */}
          <div></div>

          {!isLoading && bundlesStore.count > 0 && (
            <>
              <Header columnConfigs={headerColumnConfig} />
              {Array.from(bundlesStore.items.values()).map((bundle) => {
                return (
                  <BundleRow
                    bundle={bundle}
                    key={`bundle-row-${bundle.shortCode}`}
                  />
                );
              })}
            </>
          )}
        </Card>
      </Container>
    </>
  );
}

export default BundlesTable;
