import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
} from 'reactstrap';
import { RowProps } from 'models/grid-table.model';
import { domainStore } from 'stores';

import EditableTextColumn from 'components/GridTable/RowColumns/EditableText';
import TextColumn from 'components/GridTable/RowColumns/Text';
import Type from 'components/GridTable/RowColumns/Type';

const groupTypeMap: { [key: number]: string } = {
  1: 'core',
  2: 'org',
  3: 'content',
};

interface InternalState {}

interface Props extends RowProps {
  isEditing: boolean;
}

class RowContents extends React.Component<Props, InternalState> {
  private mapTypeToId(id: number): string {
    return groupTypeMap[id] || null;
  }

  public render(): JSX.Element {
    const { item, store, isEditing }: Props = this.props;

    return (
      <>
        <EditableTextColumn
          canEdit={isEditing}
          columnProps={{ md: '3' }}
          id={item.id}
          store={store}
          value={item.name}
        />

        <Type colProps={{ md: '6' }} type={this.mapTypeToId(item.typeId)} />

        <TextColumn
          columnProps={{ md: '2' }}
          isCentered={true}
          title={item.usersCount}
        />

        <Col md="1" style={{ textAlign: 'right' }}>
          <UncontrolledDropdown nav className="float-right">
            <DropdownToggle className="nav-link pr-0" color="" tag="a">
              <Media>
                <i className="fas fa-ellipsis-v ml-3" />
              </Media>
            </DropdownToggle>
            <DropdownMenu right>
              <Link to={''}>
                <DropdownItem>
                  <span>More Info</span>
                </DropdownItem>
              </Link>
              <DropdownItem
                onClick={(): void => {
                  window.siteWideComponent.show({
                    approve: () => {
                      store.deleteItem({
                        domainStore,
                        id: item.id,
                      });
                    },
                    body: `Are you sure you want to delete this group?`,
                    title: 'Delete Confirmation',
                  });
                }}
              >
                <span>Delete</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </>
    );
  }
}

export default RowContents;
