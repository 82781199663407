import bg from 'assets/img/brand/default-bg.png';
import logo from 'assets/img/brand/logo-on-white.png';
import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import { DomainStoreModel } from 'models/domain.model';
import { domainStore as domainStoreInstance } from 'stores';

import { BootstrapDetails } from '@storyslab/storyslab.common.models';

import { EdgeUrls } from '../enums/edge.enum';
import { uiStore } from '../stores/ui.store';

import { getPublicUrlFromS3Key } from './asset.service';
import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export async function fetchPublicDetails(subdomain: string): Promise<any> {
  return fetch(
    process.env.REACT_APP_PUBLIC_DETAILS_JSON.replace(
      '{{subdomain}}',
      subdomain,
    ).replace(
      '{{environment}}',
      process.env.REACT_APP_STAGE === 'local'
        ? 'dev'
        : process.env.REACT_APP_STAGE,
    ),
  )
    .then((response: Response) => response.json())
    .then((response: BootstrapDetails) => {
      setUiDetails(response);
      setDomainDetails(response);
    });
}

export async function fetchSimpleList({
  domainStore,
  url,
}: {
  domainStore: DomainStoreModel;
  url: EdgeUrls;
}): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url,
  });
}

function setDomainDetails(data: BootstrapDetails): void {
  domainStoreInstance.setApplicationId(data.applicationId);
  domainStoreInstance.setTenantId(data.tenantId);
}

function setUiDetails(data: BootstrapDetails): void {
  uiStore.setPrimaryColor(data?.appMeta?.style?.cms?.primaryColor || '#000000');
  uiStore.setSecondaryColor(
    data?.appMeta?.style?.cms?.secondaryColor || '#ffffff',
  );
  uiStore.toggleGenericLogin(
    data?.appMeta?.authenticate?.basicLogin?.enabled || false,
  );
  uiStore.setCognitoProviders(data?.identityProviders || []);

  if (data?.appMeta?.authenticate?.style?.squareLogo === undefined) {
    uiStore.setLogoUrl(logo);
  } else {
    const url: string = getPublicUrlFromS3Key(
      data?.appMeta?.authenticate?.style?.squareLogo,
    );
    uiStore.setLogoUrl(url);
  }
  if (data?.appMeta?.authenticate?.style?.backgroundImage === undefined) {
    uiStore.setBackgroundUrl(bg);
  } else {
    const url: string = getPublicUrlFromS3Key(
      data?.appMeta?.authenticate?.style?.backgroundImage,
    );
    uiStore.setBackgroundUrl(url);
  }
}
