/* eslint-disable capitalized-comments */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { stringify } from 'querystring';

import {
  extractAuthDetailsFromDomainStore,
  generateHeaders,
  getDeviceString,
} from 'helpers/util.helper';

import {
  ApplicationTypes,
  DevicePlatforms,
  DeviceTypes,
} from '@storyslab/storyslab.common.models';

import {
  name as packageName,
  version as packageVersion,
} from '../../package.json';
import { domainStore } from '../stores/domain.store';
import { uiStore } from '../stores/ui.store';

export function toggleStorySlabLogin(force?: boolean): void {
  uiStore.toggleStorySlabLogin(force);
}

// Generic login user authentication & set user
export async function login(params: {
  [key: string]: string | number;
}): Promise<any> {
  return await fetch(`${process.env.REACT_APP_API_GATEWAY_AUTH}/login`, {
    body: stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'post',
  })
    .then((response: Response) => {
      return response.json();
    })
    .then((result: any) => {
      if (result.error) {
        throw result.error.message;
      }

      if (result?.meta?.user) {
        domainStore.setUser(result?.meta?.user);
      }

      return [result, null];
    })
    .catch((err: unknown) => {
      return [null, err];
    });
}
// SSO / Oauth user authentication & set user
export async function getJWT({
  code,
  grantType,
  identityProvider,
  refreshToken,
  tenantId,
}: {
  code?: string;
  grantType: string;
  identityProvider: string;
  refreshToken?: string;
  tenantId: number;
}): Promise<Array<any>> {
  let body: any = {
    grant_type: grantType,
    identity_provider: identityProvider,
    redirect_uri: `${window.location.origin}/auth/code`,
    tenant_id: +tenantId,
  };

  if (code) {
    body = { ...body, code };
  }

  if (refreshToken) {
    body = { ...body, refresh_token: refreshToken };
  }

  return await fetch(`${process.env.REACT_APP_API_GATEWAY_AUTH}/token`, {
    body: stringify(body),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'post',
  })
    .then((response: Response) => response.json())
    .then((result: any) => {
      if (result.error) {
        throw result.error.message;
      }
      if (result?.meta?.user) {
        domainStore.setUser(result?.meta?.user);
      }
      return [result.data, null];
    })
    .catch((err: unknown) => {
      return [null, err];
    });
}

// Authenticate user tokens w/ app & set userId + entitlements
export async function authenticateAppUser(): Promise<any> {
  return await fetch(`${process.env.REACT_APP_API_GATEWAY_AUTH}/app`, {
    body: stringify({
      applicationTypeId: ApplicationTypes.CMS,
      applicationVersion: `${packageName}-${packageVersion}`,
      devicePlatformId: DevicePlatforms.BROWSER,
      deviceTypeId: DeviceTypes.WEB,
      deviceUniqueString: getDeviceString(),
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...generateHeaders(extractAuthDetailsFromDomainStore(domainStore)),
    },
    method: 'post',
  })
    .then((response: Response) => response.json())
    .then((result: any) => {
      if (result?.error) {
        throw result.error.message;
      }
      if (result?.data?.user) {
        // NOTE: If you modify stores with data needed for authenticated request, you must update `Injected.tsx isAuthenticated`
        domainStore.setEntitlements(result?.data?.user?.entitlements);
        domainStore.setUserId(result.data?.user?.id);
        domainStore.setUserDeviceId(result.data?.user?.deviceId);
        return [result.data.user, null];
      }
    })
    .catch((err: unknown) => {
      return [null, err];
    });
}

export function setJwtToDomainStore(payload: any): void {
  domainStore.setOauthTokens(payload);
}

export function logout(): void {
  domainStore.clearForLogout();
}

export function resetPassword(
  applicationId: number,
  email: string,
  tenantId: number,
  changeState: (string, any) => void,
): void {
  fetch(`${process.env.REACT_APP_API_GATEWAY_AUTH}/forgot-password`, {
    body: JSON.stringify({ applicationId, email, tenantId }),
    method: 'POST',
  })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      if (response.error) {
        changeState('isError', true);
        changeState('titleText', 'Error');
      } else {
        changeState('isReset', true);
        changeState('titleText', 'Check your email!');
      }
      return response;
    })
    .catch(() => {
      changeState('isError', true);
    })
    .finally(() => {
      changeState('isLoading', false);
    });
}

export async function refreshToken(params: {
  [key: string]: string | number;
}): Promise<void> {
  // console.log('will refresh: ', params);
  return await fetch(`${process.env.REACT_APP_API_GATEWAY_AUTH}/token`, {
    body: stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      if (result.error) {
        throw result.error.message;
      }
      if (result.message === 'Internal server error') {
        throw result.message;
      }
      // console.log('did refresh');
      domainStore.setOauthTokens({
        ...result.data,
        refresh_token: domainStore.auth.refreshToken,
      });
    })
    .catch((error) => {
      console.error('Problem refreshing token');
      throw error;
    });
}
