import { EntitlementsService } from '../services/entitlements.service';

import { BaseStore } from './base.store';

export class EntitlementsStore extends BaseStore<any> {
  constructor() {
    super(new EntitlementsService(), 'EntitlementsStore', 'Entitlements');
  }
}

export const entitlementsStore: EntitlementsStore = new EntitlementsStore();
