import React, { KeyboardEvent } from 'react';
import { Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { RowProps } from '../../models/grid-table.model';

interface InternalState {}

interface Props extends RowProps {}

@inject('domainStore')
@observer
class GridRow extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  private handleKeyDown(e: KeyboardEvent<HTMLDivElement>): void {
    if (e.key === 'Enter') {
      this.handleRowPress();
    }

    if (e.key === 'Escape') {
      // TBD
    }
  }

  private handleRowPress(): void {
    // TBD
  }

  public render(): JSX.Element {
    const { RowContents }: Props = this.props;
    return (
      <>
        <div
          className={`item-row-wrapper mx-3`}
          tabIndex={0}
          onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void =>
            this.handleKeyDown(e)
          }
        >
          <Row className="mx-1 py-3">
            <RowContents {...this.props} />
          </Row>
        </div>
      </>
    );
  }
}

export default GridRow;
