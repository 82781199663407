import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EdgeUrls } from 'enums/edge.enum';
import { standardGet } from 'services/edge.service';

import ContentTree from 'components/ContentTree/ContentTree';
import NoNavItems from 'components/ContentTree/NoNavItems';
import Loader from 'components/Indicators/Loader';

const ContentTreePage: any = () => {
  const { hasLink } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [rootViews, setRootViews] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    standardGet(EdgeUrls.NavItems).then((result) => {
      if (result.length === 0) {
        setIsEmpty(true);
      } else {
        let hasValidNav: boolean = false;
        for (const navItem of result) {
          if (navItem.viewId) {
            hasValidNav = true;
            break;
          }
        }
        if (hasValidNav) {
          setRootViews(result);
        } else {
          setIsEmpty(true);
        }
      }
      setIsLoading(false);
    });
  }, []);

  if (isEmpty) {
    return <NoNavItems />;
  }

  return (
    <>
      {isLoading && <Loader isFullPage={true} />}
      {!isLoading && (
        <ContentTree hasLink={hasLink ? true : false} rootViews={rootViews} />
      )}
    </>
  );
};

export default ContentTreePage;
