import React from 'react';
import dayjs from 'dayjs';

import { ContentItemType } from '@storyslab/storyslab.common.models';
import ActionButtons from 'components/Content/Source/ActionButtons';
import SourceInput from 'components/Content/Source/SourceInput';
import SyncFields from 'components/Content/Source/SyncFields';

interface Props {
  contentItem: any;
  isDropped: boolean;
}

function SourceFields(props: Props): JSX.Element {
  const { contentItem, isDropped } = props;

  const syncParams: any = props.contentItem.target.syncParams;

  return (
    <div className="content-contain">
      <div>
        <div className="form-field-contain">
          <div className="form-field-label">Source</div>
          <div style={styles.wrapper}>
            <SourceInput contentItem={contentItem} />
            <ActionButtons item={contentItem} isDropped={isDropped} />
          </div>

          {contentItem.target.s3Key && (
            <div style={styles.labelContain}>
              <div style={styles.label}>Filename</div>
              <div style={styles.value}>
                {contentItem.target.s3Key.split('/').at(-1)}
              </div>
            </div>
          )}
          <div style={styles.labelContain}>
            <div style={styles.label}>Set On</div>
            <div style={styles.value}>
              {dayjs(contentItem.created).format('MM / DD / YYYY')}
            </div>
          </div>
          {contentItem.type === ContentItemType.LINK && (
            <div style={{ ...styles.labelContain, ...styles.forceWrap }}>
              <div style={styles.label}>URL</div>
              <div style={styles.value}>{contentItem.target.link}</div>
            </div>
          )}

          {syncParams !== null && syncParams !== undefined && (
            <SyncFields contentItem={contentItem} />
          )}
        </div>
      </div>
    </div>
  );
}

const styles: any = {
  buttonWrapper: {
    display: 'inline-block',
  },
  forceWrap: {
    overflow: 'unset',
  },
  label: {
    color: '#808080',
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
    width: '150px',
  },
  labelContain: {
    fontSize: '12px',
    fontWeight: 400,
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  value: {
    display: 'inline',
  },
  wrapper: {
    display: 'flex',
    flex: 1,
  },
};

export default SourceFields;
