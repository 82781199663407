import { action, observable } from 'mobx';

import { CognitoProvider } from '@storyslab/storyslab.common.models';

import { AuthUiModel, CommonUiModel } from '../models/ui.model';

class UiStore {
  @observable
  public auth: AuthUiModel = {
    backgroundUrl: null,
    logoUrl: null,
    providers: null,
    shouldShowGenericLogin: false,
    shouldShowStorySlabLogin: false,
  };

  @observable
  public common: CommonUiModel = {
    colors: {
      primary: '',
      secondary: '',
    },
  };

  // Auth
  @action public toggleGenericLogin(shouldShow: boolean): void {
    this.auth.shouldShowStorySlabLogin = false;

    this.auth.shouldShowGenericLogin = shouldShow;
  }

  @action public toggleStorySlabLogin(force?: boolean): void {
    this.auth.shouldShowGenericLogin = false;

    this.auth.shouldShowStorySlabLogin =
      force !== undefined ? force : !this.auth.shouldShowStorySlabLogin;
  }

  @action public setBackgroundUrl(url?: string): void {
    this.auth.backgroundUrl = url;
  }

  @action public setLogoUrl(url?: string): void {
    this.auth.logoUrl = url;
  }

  @action public setCognitoProviders(providers?: Array<CognitoProvider>): void {
    this.auth.providers = providers;
  }

  @action public setPrimaryColor(color?: string): void {
    if (color.indexOf('#') === -1) {
      color = `#${color}`;
    }
    this.common.colors.primary = color;
  }

  @action public setSecondaryColor(color?: string): void {
    if (color.indexOf('#') === -1) {
      color = `#${color}`;
    }
    this.common.colors.secondary = color;
  }
}

export const uiStore: UiStore = new UiStore();
