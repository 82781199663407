/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */

import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, NavLink as NavLinkRRD } from 'react-router-dom';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';
import Routes from 'enums/routes.enum';

interface InternalState {
  [key: string]: { collapsed: boolean };
}
interface Props {
  logo: {
    innerLink: string;
    imgSrc: string;
    imgAlt: string;
  };
  location: any;
  sidenavOpen: boolean;
  toggleSidenav: () => void;
  openSidenav: () => void;
  closeSidenav: () => void;
}

class Sidebar extends React.Component<Props, InternalState> {
  public state: InternalState = {
    navItemApplication: {
      collapsed: !window.location.pathname.startsWith('/application'),
    },
    navItemContent: {
      collapsed: !window.location.pathname.startsWith('/content'),
    },
    navItemEngagement: {
      collapsed: !window.location.pathname.startsWith('/engagement'),
    },
    navItemUsers: {
      collapsed: !window.location.pathname.startsWith('/users'),
    },
  };

  private activeRoute(route: string, isExact?: boolean): boolean {
    const { location } = this.props;
    const pathname: string = location.pathname;

    if (pathname.indexOf(route) !== -1) {
      if (isExact) {
        return pathname.length === route.length;
      }
      return true;
    }
    return false;
  }

  private renderScrollBarInner(): JSX.Element {
    const { logo } = this.props;

    const navbarBrandProps: { to: string; tag: any } = {
      tag: Link,
      to: logo.innerLink,
    };

    return (
      <>
        <div className="scrollbar-inner">
          <div className="sidenav-header d-flex align-items-center">
            {logo ? (
              <NavbarBrand {...navbarBrandProps}>
                <img
                  alt={logo.imgAlt}
                  className="navbar-brand-img"
                  src={logo.imgSrc}
                />
              </NavbarBrand>
            ) : null}
          </div>
          <div className="navbar-inner" id="main-navigation">
            <Collapse navbar isOpen={true}>
              <Nav className="mb-md-3" navbar>
                {/* Application */}
                <NavItem>
                  <NavLink
                    data-toggle="collapse"
                    aria-expanded={!this.state.navItemApplication.collapsed}
                    className={classnames({
                      active:
                        this.activeRoute('/application/navigation') ||
                        this.activeRoute('/application/layout') ||
                        this.activeRoute('/application/configuration'),
                    })}
                    onClick={(): void => {
                      this.setState((prevState: InternalState) => {
                        return {
                          navItemApplication: {
                            collapsed: !prevState.navItemApplication.collapsed,
                          },
                        };
                      });
                    }}
                  >
                    <i className="fad fa-phone-laptop"></i>
                    <span className="nav-link-text">Application</span>
                  </NavLink>
                  <Collapse isOpen={!this.state.navItemApplication.collapsed}>
                    <Nav className="nav-sm flex-column">
                      <NavItem>
                        <NavLink
                          to={Routes.ApplicationNavigation}
                          className={classnames({
                            active: this.activeRoute(
                              Routes.ApplicationNavigation,
                            ),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-stream"></i>
                            </span>
                            <span className="sidenav-normal">Navigation</span>
                          </>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to={Routes.ApplicationSettingsFeatures}
                          className={classnames({
                            active: this.activeRoute(
                              Routes.ApplicationSettingsFeatures,
                            ),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-cog"></i>
                            </span>
                            <span className="sidenav-normal">
                              Settings & Features
                            </span>
                          </>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>

                {/* Content */}
                <NavItem>
                  <NavLink
                    data-toggle="collapse"
                    aria-expanded={!this.state.navItemContent.collapsed}
                    className={classnames({
                      active: this.activeRoute('/content'),
                    })}
                    onClick={(): void => {
                      this.setState((prevState: InternalState) => {
                        return {
                          navItemContent: {
                            collapsed: !prevState.navItemContent.collapsed,
                          },
                        };
                      });
                    }}
                  >
                    <i className="fad fa-briefcase"></i>
                    <span className="nav-link-text">Content</span>
                  </NavLink>
                  <Collapse isOpen={!this.state.navItemContent.collapsed}>
                    <Nav className="nav-sm flex-column">
                      <NavItem>
                        <NavLink
                          to={Routes.ContentTree}
                          className={classnames({
                            active: this.activeRoute(Routes.ContentTree),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-folder-tree"></i>
                            </span>
                            <span className="sidenav-normal">Content Tree</span>
                          </>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          to={Routes.ContentCollections}
                          className={classnames({
                            active: this.activeRoute(Routes.ContentCollections),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-sitemap"></i>
                            </span>
                            <span className="sidenav-normal">Collections</span>
                          </>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          to={Routes.ContentCollectionViewLayouts}
                          className={classnames({
                            active: this.activeRoute(
                              Routes.ContentCollectionViewLayouts,
                            ),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-columns"></i>
                            </span>
                            <span className="sidenav-normal">
                              Collection View Layout
                            </span>
                          </>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          to={Routes.ContentItems}
                          className={classnames({
                            active: this.activeRoute(Routes.ContentItems),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-photo-video"></i>
                            </span>
                            <span className="sidenav-normal">
                              Content Items
                            </span>
                          </>
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          to={Routes.ContentCategories}
                          className={classnames({
                            active: this.activeRoute(Routes.ContentCategories),
                          })}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-shapes"></i>
                            </span>
                            <span className="sidenav-normal">Categories</span>
                          </>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>

                {/* Users */}
                <NavItem>
                  <NavLink
                    data-toggle="collapse"
                    aria-expanded={!this.state.navItemUsers.collapsed}
                    className={classnames({
                      active: this.activeRoute(Routes.Users),
                    })}
                    onClick={(): void => {
                      this.setState((prevState: InternalState) => {
                        return {
                          navItemUsers: {
                            collapsed: !prevState.navItemUsers.collapsed,
                          },
                        };
                      });
                    }}
                  >
                    <i className="fad fa-user-friends"></i>
                    <span className="nav-link-text">Users</span>
                  </NavLink>
                  <Collapse isOpen={!this.state.navItemUsers.collapsed}>
                    <Nav className="nav-sm flex-column">
                      <NavItem>
                        <NavLink
                          to={Routes.Users}
                          className={classnames({
                            'active-override': !this.activeRoute(
                              Routes.Users,
                              true,
                            ),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-users"></i>
                            </span>
                            <span className="sidenav-normal">Users</span>
                          </>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to={'/users/roles'}
                          className={classnames({
                            active: this.activeRoute(Routes.UserRoles),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-user-crown"></i>
                            </span>
                            <span className="sidenav-normal">Roles</span>
                          </>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          to={'/users/groups'}
                          className={classnames({
                            active: this.activeRoute('/users/groups'),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-users-class"></i>
                            </span>
                            <span className="sidenav-normal">Groups</span>
                          </>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>

                {/* Engagement */}
                <NavItem>
                  <NavLink
                    data-toggle="collapse"
                    aria-expanded={!this.state.navItemEngagement.collapsed}
                    className={classnames({
                      active: this.activeRoute(Routes.Engagement),
                    })}
                    onClick={(): void => {
                      this.setState((prevState: InternalState) => {
                        return {
                          navItemEngagement: {
                            collapsed: !prevState.navItemEngagement.collapsed,
                          },
                        };
                      });
                    }}
                  >
                    <i className="fad fa-handshake"></i>
                    <span className="nav-link-text">Engagement</span>
                  </NavLink>
                  <Collapse isOpen={!this.state.navItemEngagement.collapsed}>
                    <Nav className="nav-sm flex-column">
                      <NavItem>
                        <NavLink
                          to={Routes.EngagementBundles}
                          className={classnames({
                            'active-override': !this.activeRoute(
                              Routes.EngagementBundles,
                              true,
                            ),
                          })}
                          // OnClick={this.closeSidenav}
                          tag={NavLinkRRD}
                        >
                          <>
                            <span className="sidenav-mini-icon">
                              <i className="fas fa-paper-plane"></i>
                            </span>
                            <span className="sidenav-normal">
                              Share+ Bundles
                            </span>
                          </>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </NavItem>

                {/* Analytics */}
                <NavItem>
                  <NavLink
                    to={'/analytics'}
                    data-toggle="collapse"
                    className={classnames({
                      active: this.activeRoute('/analytics'),
                    })}
                    // OnClick={this.closeSidenav}
                    tag={NavLinkRRD}
                  >
                    <i className="fad fa-chart-line"></i>
                    <span className="nav-link-text">Analytics</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <Navbar
          className={
            'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white fixed-left'
          }
          onClick={this.props.openSidenav}
          onMouseEnter={this.props.openSidenav}
          onMouseLeave={this.props.closeSidenav}
        >
          {navigator.platform.indexOf('Win') > -1 ? (
            <PerfectScrollbar>{this.renderScrollBarInner()}</PerfectScrollbar>
          ) : (
            this.renderScrollBarInner()
          )}
        </Navbar>
      </>
    );
  }
}

export default Sidebar;
