import React from 'react';

import { Bundle } from '@storyslab/storyslab.common.models';
import BundleItems from 'components/Engagement/Bundles/Detail/BundleItems';
import EmptyTable from 'components/Forms/EmptyTable';

interface Props {
  bundle: Bundle;
  contentSet: Array<Bundle>;
}

const Contents: any = (props: Props) => {
  const { bundle, contentSet } = props;

  return (
    <>
      {contentSet.length === 0 && (
        <EmptyTable
          title={'No items to show...'}
          text={'The contents of this bundle are no longer available.'}
        />
      )}
      {contentSet.length > 0 && (
        <BundleItems bundle={bundle} contentSet={contentSet} />
      )}
    </>
  );
};

export default Contents;
