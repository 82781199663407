import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import Routes from 'enums/routes.enum';
import { observer } from 'mobx-react';
import { contentStore, domainStore, uiStore } from 'stores';

import ContentRow from 'components/Content/ContentRow/ContentRow';
import GhostLoader from 'components/Indicators/GhostLoader';
import Loader from 'components/Indicators/Loader';

interface InternalProps {}

const ContentItemDetailPage: any = observer((props: InternalProps) => {
  const { id } = useParams<{ id: string }>();

  const [isLoading, setIsLoading] = useState(true);

  useEffect((): void => {
    contentStore.fetchItemById(parseInt(id), { domainStore }).then(() => {
      setIsLoading(false);
    });
  }, [id]);

  return (
    <>
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          {isLoading ? (
            <>
              <GhostLoader inline={true} /> : <GhostLoader />
            </>
          ) : (
            <>
              <Link to={Routes.ContentItems}>
                <i className="fas fa-photo-video" />
                Content Items
              </Link>{' '}
              : {contentStore.items.get(parseInt(id)).name}
            </>
          )}
        </h4>
        {isLoading && <Loader isFullPage={true} />}
        {!isLoading && (
          <ContentRow
            contentItem={contentStore.items.get(parseInt(id))}
            isDroppedOnLoad={true}
            context="detail"
          />
        )}
      </Container>
    </>
  );
});

export default ContentItemDetailPage;
