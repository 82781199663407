/**
 * Collate: To gather and arrange in their proper sequence
 * This helper is used to break up an item state into the expected collation params
 * for consumption by the edge.
 */

import { stringify } from 'querystring';

import { isEmpty } from './util.helper';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function buildCollationParams(state: any): string {
  const { filters, page, pageSize, search, sort } = state;

  let collationPayload: any = {
    ...(page !== undefined && page !== 0 ? { Page: page } : {}),
    ...(pageSize !== undefined && pageSize !== 10
      ? { PageSize: pageSize }
      : {}),
  };

  if (!isEmpty(filters)) {
    collationPayload = {
      ...collationPayload,
      Filter: mapObjectToCollationString(filters),
    };
  }

  if (!isEmpty(search)) {
    collationPayload = {
      ...collationPayload,
      Search: encodeURIComponent(search),
    };
  }

  if (!isEmpty(sort)) {
    collationPayload = {
      ...collationPayload,
      Sort: mapObjectToCollationString(sort),
    };
  }

  return stringify(collationPayload);
}

function mapObjectToCollationString(map: Map<string, string>): string {
  return Array.from(map.keys())
    .map((key) => {
      const value: any = map.get(key);
      if (typeof value !== 'string') {
        // This is pretty much all of the other filters
        if (Array.isArray(value)) {
          return `${key}::${value.join('|')}`;
        }

        // This is the start/end date format used by the date filter
        if (value.startDate && value.endDate) {
          return `${key}::${value.startDate.toISOString()}|${value.endDate.toISOString()}`;
        }
      }
      return `${key}::${value}`;
    })
    .join(',');
}
