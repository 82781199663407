import React from 'react';
import { Row } from 'reactstrap';

import { RenderKeyHelper } from '../../helpers/render-key.helper';
import { HeaderColumnConfig } from '../../models/grid-table.model';

import HeaderColumn from './HeaderColumn';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper('GridTableHeader');

interface InternalState {}

interface Props {
  columnConfigs?: Array<HeaderColumnConfig>;
  rowProps?: { [key: string]: any };
}

class Header extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { columnConfigs, rowProps } = this.props;

    return (
      <>
        <Row {...(rowProps ? rowProps : { className: 'mx-3 pr-3' })}>
          {columnConfigs.map(
            (columnConfig: HeaderColumnConfig, index: number) => {
              if (columnConfigs.length - 1 === index) {
                columnConfig = {
                  ...columnConfig,
                  isLast: true,
                };
              }

              return (
                <HeaderColumn
                  {...columnConfig}
                  key={renderKeyHelper.generate(
                    {
                      index,
                      title: columnConfig.title,
                    },
                    'Column',
                  )}
                ></HeaderColumn>
              );
            },
          )}
        </Row>
      </>
    );
  }
}

export default Header;
