import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logo from 'assets/img/brand/logo.png';
import { createBackgroundImageCss } from 'helpers/component-color.helper';
import { observer } from 'mobx-react-lite';
import { login } from 'services/auth.service';
import { domainStore, uiStore } from 'stores';

import { RequiredAttributes } from 'components/Auth/RequiredAttributes';

interface Props {}

const ChangePassword: any = observer((props: Props) => {
  const { code } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [requiredAttributes, setRequiredAttributes] = useState(null);
  const [titleText, setTitleText] = useState('Authenticating...');
  const [userName, setUserName] = useState('');

  useEffect((): void => {
    login({
      applicationId: domainStore.applicationId,
      code,
      tenantId: domainStore.tenantId,
    })
      .then(([response]) => {
        setRequiredAttributes(response.meta.requiredAttributes);
        setTitleText('Change Password');
        setUserName(response.data.email);
      })
      .catch(() => {
        setTitleText('Error');
        setHasError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [code]);

  return (
    <>
      <div
        className="login-code"
        style={createBackgroundImageCss(uiStore.auth.backgroundUrl)}
      >
        <div className="login-card-wrapper">
          <div className="login-card">
            <div className="brand-icon-wrapper">
              <img className="brand-icon" src={uiStore.auth.logoUrl} alt="" />
            </div>

            <div className="login-card-title">{titleText}</div>

            {isLoading && (
              <img
                src={logo}
                className="storyslab-logo"
                alt="logo"
                style={{ height: '75px', margin: '1rem 0', width: '75px' }}
              />
            )}

            {requiredAttributes && (
              <RequiredAttributes
                requiredAttributes={requiredAttributes}
                username={userName}
                code={code}
              />
            )}
            {hasError && (
              <div className="mb-3">
                {`There was a problem with your access code.`}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default ChangePassword;
