/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { getSignedUrl } from 'services/asset.service';
import { contentStore, domainStore } from 'stores';

import { getContentItemTargetType } from '@storyslab/storyslab.common.helpers';
import {
  ContentItem,
  ContentItemTargetType,
} from '@storyslab/storyslab.common.models';
import ContentItemPreview from 'components/Content/Preview/ContentItemPreview';
import GhostLoader from 'components/Indicators/GhostLoader';

interface Props {
  isDropped: boolean;
  item: ContentItem;
}

const ActionButtons: (props: Props) => JSX.Element = (props: Props) => {
  const { isDropped, item } = props;

  const [canDownload, setCanDownload] = useState<boolean>();
  const [canPreview, setCanPreview] = useState<boolean>();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [itemType, setItemType] = useState<ContentItemTargetType>();
  const [didCanDownloadResolve, setDidCanDownloadResolve] = useState<boolean>();
  const [didCanPreviewResolve, setDidCanPreviewResolve] = useState(false);
  const [signedURL, setSignedURL] = useState('');

  // Component will mount
  useEffect(() => {
    contentStore.fetchItemById(item.id, { domainStore }).then(() => {
      setItemType(getContentItemTargetType(contentStore.items.get(item.id)));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If downloadable, get signed url
  useEffect(() => {
    // Filter initial state change on load
    if (canDownload === undefined) {
      return;
    }
    if (canDownload) {
      getSignedUrl({
        bucket: process.env.REACT_APP_TENANT_ASSETS_BUCKET,
        key: item.target.s3Key,
        s3ClientConfig: {
          ...domainStore.temporaryCredentials,
          Region: (domainStore.temporaryCredentials as any).region,
        },
      } as any).then((result) => {
        setSignedURL(result);
        setDidCanDownloadResolve(true);
      });
    } else {
      setDidCanDownloadResolve(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canDownload]);

  // Set visibility for preview and download buttons based on type
  useEffect(() => {
    // Type can't be resolved until fetch from store is complete
    if (itemType === undefined) {
      return;
    }

    switch (itemType) {
      case ContentItemTargetType.IMAGE:
      case ContentItemTargetType.PDF:
      case ContentItemTargetType.VIDEO:
        setCanDownload(true);
        setCanPreview(true);
        break;
      case ContentItemTargetType.LINK:
        setCanDownload(false);
        setCanPreview(true);
        break;
      default:
        setCanDownload(false);
        setCanPreview(false);
        break;
    }
    setDidCanPreviewResolve(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemType]);

  // Various states for different item types. Wait until they resolve before rendering.
  useEffect(() => {
    if (didCanDownloadResolve && didCanPreviewResolve) {
      setIsLoading(false);
    }
  }, [didCanDownloadResolve, didCanPreviewResolve]);

  // Show preview. Overflow issues if preview is still rendered while row isn't dropped.
  if (isPreviewing && isDropped) {
    return (
      <>
        <ContentItemPreview
          contentItem={item}
          setIsPreviewOpen={setIsPreviewing}
        />
      </>
    );
  }

  return (
    <>
      <div style={styles.wrapper}>
        {isLoading && (
          <>
            <GhostLoader
              style={{
                borderRadius: '1rem',
                display: 'flex',
                height: '34px',
                marginLeft: '2rem',
                marginRight: '10px',
              }}
            />
            <GhostLoader
              style={{ borderRadius: '1rem', display: 'flex', height: '34px' }}
            />
          </>
        )}
        {!isLoading && (
          <>
            {canPreview && (
              <>
                <Button
                  className="storyslab-button light"
                  style={styles.buttonWrapper}
                  onClick={(): void => {
                    setIsPreviewing(true);
                  }}
                  tabIndex={0}
                >
                  Preview
                </Button>
              </>
            )}
            {canDownload && (
              <>
                <a
                  className="storyslab-button light btn-secondary"
                  href={signedURL}
                  target="_blank"
                  style={{
                    ...styles.buttonWrapper,
                    border: '1px solid transparent',
                    color: '#151515',
                    fontWeight: 600,
                  }}
                  download
                  rel="noreferrer"
                >
                  Download
                </a>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

const styles: any = {
  buttonWrapper: {
    alignSelf: 'flex-start',
    marginLeft: '2rem',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    marginLeft: '.5rem',
  },
};

export default ActionButtons;
