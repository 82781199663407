import React from 'react';

import CollectionViewSelector from 'components/CollectionViews/CollectionViewSelector';

interface Props {}

function CollectionViewSelectorPage(props: Props): JSX.Element {
  return <CollectionViewSelector />;
}

export default CollectionViewSelectorPage;
