/* eslint-disable multiline-comment-style */
/* eslint-disable capitalized-comments */
import React from 'react';

import HexCodePicker from 'components/Forms/HexCodePicker';
import SimpleSelect from 'components/Forms/SimpleSelect';
import SimpleTextInput from 'components/Forms/SimpleTextInput';
import SimpleToggle from 'components/Forms/SimpleToggle';
import AppMetaFileUpload from 'components/SettingsAndFeatures/AppMetaFileUpload';
import StandardContentContain from 'components/SettingsAndFeatures/elements/StandardContentContain';

import { AppSettingsFieldConfig } from './FeatureCardModels';

interface Props extends AppSettingsFieldConfig {
  initialValue: any,
  onChange?: (newValue:any, fieldName?: string, isPublic?: boolean)=>void
}

const AppFeatureSettingsField: any = (props: Props) => {

  const { title, description, initialValue, metaKey, type, isPublic, options, onChange } = props;

  function renderText(): JSX.Element{
   return <SimpleTextInput
    handleChange={(value:any):void => {
      onChange && onChange(value, metaKey, isPublic)}
    }
    initialValue={initialValue}/>
  }

  function renderToggle(): JSX.Element{
    return <SimpleToggle
      didCheckOnLoad={initialValue}
      field={metaKey}
      handleChange={(newValue:any, field:string):void=>{
        onChange && onChange(newValue, field, isPublic)}
      }/>;
  }

  function renderImageUploader(): JSX.Element{
    return <>
      <AppMetaFileUpload
        initialS3Key={initialValue}
        uploadName={metaKey}
        isPublic={isPublic}
        onFailed={(event:any):void =>{console.error(event);}}
        onComplete={(s3Key:any):void=>{
          onChange && onChange(s3Key, metaKey, isPublic)
        }}/>
    </>
  }

  function renderColorPicker(): JSX.Element{
    return <HexCodePicker
      colorCode={initialValue || "#000000"}
      handleChange={(color:any):void=>{
        onChange && onChange(color, metaKey, isPublic)}
      }
    />
  }

  function renderSelect(): JSX.Element{
    return <SimpleSelect
      initialValue={initialValue}
      handleChange={(newValue): void => {
        onChange && onChange(newValue, metaKey, isPublic)
      }}
      options={options || []}
      propStyles={{ backgroundColor: '#f1f1f1' }}
    />
  }

  function renderTimeInterval(): JSX.Element{
    return <p>TODO: timeInterval</p>
  }

  function renderShareLayoutSelect(): JSX.Element{
    return <p>TODO: shareLayoutSelect</p>
  }

  function renderBundleIdentityActionSelectSelect(): JSX.Element{
    return <p>TODO: bundleIdentityActionSelect</p>
  }

  return (
    <StandardContentContain>
      <div>
        <div className="settings-subtitle">{title}</div>
        {description}
      </div>
      <div>
        {type === "text" && renderText()}
        {type === "toggle" && renderToggle()}
        {type === "image" && renderImageUploader()}
        {type === "color" && renderColorPicker()}
        {type === "select" && renderSelect()}
        {type === "timeInterval" && renderTimeInterval()}
        {type === "shareLayoutSelect" && renderShareLayoutSelect()}
        {type === "bundleIdentityActionSelect" && renderBundleIdentityActionSelectSelect()}
      </div>
    </StandardContentContain>
  );
};

export default AppFeatureSettingsField;
