import React from 'react';
import { Card, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { entitlementsStore, usersStore } from 'stores';

import DrawerModal from 'components/DrawerModal';
import EntitlementsModalContents from 'components/Entitlements/EntitlementsModalContents';
import GridTable from 'components/GridTable';
import ExpandedRowContents from 'components/UserRoles/ExpandedRowContents';
import RolesRow from 'components/UserRoles/RolesRow';
import RowContents from 'components/UserRoles/RowContents';

import GridTableCardFooter from '../../components/GridTable/CardFooter';
import GridTableCardHeader from '../../components/GridTable/CardHeader';
import DrawerModalUsers from '../../components/Users/DrawerModalContents';
import { Action } from '../../models/action.model';
import { BaseProps } from '../../models/base.model';
import { HeaderColumnConfig } from '../../models/grid-table.model';

interface InternalState {
  isEditUsersDrawerOpen: false;
}

interface Props extends BaseProps {}

@inject('userRolesStore')
@inject('domainStore')
@inject('uiStore')
@observer
class UserRoles extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];
  constructor(props: Props) {
    super(props);

    this.headerColumnConfigs = [
      {
        colProps: {
          md: '7',
        },
        handleAction: (action: Action): void => {
          this.props.userRolesStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'name',
        store: this.props.userRolesStore,
        title: 'Name',
      },
      {
        colProps: {
          md: '2',
        },
        handleAction: (action: Action): void => {
          this.props.userRolesStore.handleTableAction(this.props, action);
        },
        isCentered: true,
        isSortable: false,
        itemKey: 'entitlements',
        store: this.props.userRolesStore,
        title: 'Entitlements',
      },
      {
        colProps: {
          md: '2',
        },
        handleAction: (action: Action): void => {
          this.props.userRolesStore.handleTableAction(this.props, action);
        },
        isCentered: true,
        isSortable: false,
        itemKey: 'users',
        store: this.props.userRolesStore,
        title: 'Users',
      },
      {
        colProps: { md: '1' },
        title: '',
      },
    ];
  }

  public async componentDidMount(): Promise<void> {
    this.props.userRolesStore.fetchItems(this.props);
  }

  public render(): JSX.Element {
    const { userRolesStore, domainStore } = this.props;

    return (
      <>
        <Container className="mt-4" fluid>
          <h4
            className="mb-4"
            style={{ color: this.props.uiStore.common.colors.primary }}
          >
            <i className="fas fa-user-crown" /> User Roles
          </h4>
          <Row>
            <div className="col">
              <Card className="p-1">
                <GridTableCardHeader
                  actionButtonTitle="User Roles"
                  actionButtonIcon="fa-plus"
                  actionButtonHandler={(): void => {
                    userRolesStore.createItem({ domainStore });
                  }}
                  store={userRolesStore}
                />

                <GridTable
                  headerColumnConfigs={this.headerColumnConfigs}
                  headerRowProps={
                    { className: 'mr-2 ml-2 mx-0 pl-4 pr-3' } as any
                  }
                  idKey={'contentId'}
                  items={Array.from(userRolesStore?.items?.values())}
                  store={userRolesStore}
                  RowContents={RowContents}
                  ExpandedRowContents={ExpandedRowContents}
                  isJustHeaders={true}
                />

                {Array.from(userRolesStore?.items?.values()).map(
                  (role, index) => {
                    return (
                      <RolesRow
                        key={`user-row-${role.id}-${index}`}
                        role={role}
                      />
                    );
                  },
                )}

                <GridTableCardFooter
                  store={userRolesStore}
                  storeInstance={this.props.userRolesStore}
                />
              </Card>
            </div>
          </Row>
        </Container>

        <DrawerModal
          surtitle="Users within"
          store={usersStore}
          storeKey={'UserRoles'}
        >
          <DrawerModalUsers
            id={usersStore.drawerModals.UserRoles?.id}
            count={usersStore.drawerModals.UserRoles?.count}
            parentModalStore={userRolesStore}
            filterBy="roleIds"
            filterByDisplayText="role"
          />
        </DrawerModal>

        <DrawerModal
          surtitle="Entitlements within"
          store={entitlementsStore}
          storeKey={'UserEntitlements'}
        >
          <EntitlementsModalContents
            roleId={entitlementsStore.drawerModals.UserEntitlements?.id}
          />
        </DrawerModal>
      </>
    );
  }
}

export default UserRoles;
