import React from 'react';

const DropzoneSimpleInputButton: any = (props: any) => {
  const {
    accept,
    className,
    disabled,
    files,
    getFilesFromEvent,
    multiple,
    onFiles,
    style,
    content
  } = props;

  return (
    <>
      {files.length === 0 && (
        <label style={styles.labelStyle}>
          <span className="text-center dropzone-button-wrapper">
            <span className="btn-neutral btn-icon btn btn-default">
              <span className="btn-inner--text" style={styles.text}>
                {content || "Upload file"}
              </span>
            </span>
          </span>

          <input
            className={className}
            style={style}
            type="file"
            accept={accept}
            multiple={multiple}
            disabled={disabled}
            onChange={async (e): Promise<any> => {
              const target: any = e.target;
              const chosenFiles: any = await getFilesFromEvent(e);
              onFiles(chosenFiles);
              target.value = null;
            }}
          />
        </label>
      )}
    </>
  );
};

const styles: any = {
  labelStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    marginBottom: 0,
  },
};

export default DropzoneSimpleInputButton;
