import { EdgeUrls } from 'enums/edge.enum';
import {
  action,
  autorun,
  computed,
  IMapEntry,
  observable,
  ObservableMap,
} from 'mobx';
import { DomainStoreModel } from 'models/domain.model';
import {
  SimpleList,
  SimpleListItem,
  SimpleListResponseItem,
  SimpleListsStoreModel,
} from 'models/simple-lists.model';
import { RehydrationTypes } from 'models/store.model';
import { fetchSimpleList } from 'services/bootstrap.service';

import { resolve } from '@storyslab/storyslab.common.helpers';

import {
  generateEmptyObservableMap,
  getStoredVersion,
} from '../helpers/store.helper';

const localStorageName: string = 'SimpleListsStore';

export class SimpleListsStore {
  @observable public categories: SimpleList = getStoredVersion(
    localStorageName,
    'categories',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );
  @observable public collections: SimpleList = getStoredVersion(
    localStorageName,
    'collections',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );
  @observable public contentItemStatuses: SimpleList = getStoredVersion(
    localStorageName,
    'contentItemStatuses',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );
  @observable public deviceTypes: SimpleList = getStoredVersion(
    localStorageName,
    'deviceTypes',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );
  @observable public userStatuses: SimpleList = getStoredVersion(
    localStorageName,
    'userStatuses',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );
  @observable public userRoles: SimpleList = getStoredVersion(
    localStorageName,
    'userRoles',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );
  @observable public userEntitlements: SimpleList = getStoredVersion(
    localStorageName,
    'userEntitlements',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );
  @observable public languages: SimpleList = observable.map(
    new Map([
      [1, { id: 1, name: 'English' }],
      [2, { id: 2, name: 'Mandarin' }],
      [3, { id: 3, name: 'Hindi' }],
      [4, { id: 4, name: 'Spanish' }],
      [5, { id: 5, name: 'French' }],
    ]),
  );
  @observable public tags: SimpleList = getStoredVersion(
    localStorageName,
    'tags',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );

  @observable public groups: SimpleList = getStoredVersion(
    localStorageName,
    'groups',
    generateEmptyObservableMap(),
    RehydrationTypes.MAP,
  );

  constructor() {
    autorun(() => {
      localStorage.setItem(
        localStorageName,
        JSON.stringify({
          categories: this.categories,
          collections: this.collections,
          contentItemStatuses: this.contentItemStatuses,
          deviceTypes: this.deviceTypes,
          groups: this.groups,
          languages: this.languages,
          tags: this.tags,
          userEntitlements: this.userEntitlements,
          userRoles: this.userRoles,
          userStatuses: this.userStatuses,
        }),
      );
    });
  }

  @computed public get groupsCore(): SimpleList {
    return observable.map(
      new Map(
        Array.from(this.groups.entries()).filter(
          (entry: IMapEntry<number, SimpleListItem>) => {
            const value: SimpleListItem = entry[1];

            return (
              value &&
              typeof value !== 'string' &&
              value.typeId &&
              value.typeId === 1
            );
          },
        ),
      ),
    );
  }

  @computed
  public get groupsOther(): ObservableMap {
    return observable.map(
      new Map(
        Array.from(this.groups.entries()).filter(
          (entry: IMapEntry<number, SimpleListItem>) => {
            const value: SimpleListItem = entry[1];

            return (
              value &&
              typeof value !== 'string' &&
              value.typeId &&
              value.typeId !== 1
            );
          },
        ),
      ),
    );
  }

  @action public async populateSimpleList({
    domainStore,
    key,
    url,
  }: {
    domainStore: DomainStoreModel;
    key: keyof SimpleListsStoreModel;
    url: EdgeUrls;
  }): Promise<void> {
    const [list] = await resolve<Array<SimpleListResponseItem>>(
      fetchSimpleList({ domainStore, url }),
    );

    if (list) {
      this.setSimpleList({ key, list: list });
    }
  }

  @action public setSimpleList({
    key,
    list,
  }: {
    key: keyof SimpleListsStoreModel;
    list: Array<{ id: number; name: string }>;
  }): void {
    if (list && list.length > 0) {
      this[key] = observable.map(
        new Map(
          list.map((responseItem: SimpleListResponseItem) => [
            +responseItem.id,
            responseItem,
          ]),
        ),
      );
    }
  }

  @action public clearSimpleList(key: keyof SimpleListsStoreModel): void {
    this[key] = generateEmptyObservableMap();
  }

  @action public clearAllSimpleLists(): void {
    this.categories = generateEmptyObservableMap();
    this.collections = generateEmptyObservableMap();
    this.contentItemStatuses = generateEmptyObservableMap();
    this.deviceTypes = generateEmptyObservableMap();
    this.languages = generateEmptyObservableMap();
    this.tags = generateEmptyObservableMap();
    this.groups = generateEmptyObservableMap();
    this.userRoles = generateEmptyObservableMap();
    this.userStatuses = generateEmptyObservableMap();
  }
}

export const simpleListsStore: SimpleListsStore = new SimpleListsStore();
