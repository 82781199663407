import { ContentItem } from '@storyslab/storyslab.common.models';

import { ContentService } from '../services/content.service';

import { BaseStore } from './base.store';

export class ContentStore extends BaseStore<ContentItem> {
  constructor() {
    super(new ContentService(), 'ContentStore', 'Content');
  }
}

export const contentStore: ContentStore = new ContentStore();
