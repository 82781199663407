import React from 'react';
import { DropzoneContexts } from 'enums/dropzone.enum';

import Dropzone from 'components/Dropzone/Dropzone';

interface InternalProps {
  closeModal: () => void;
  context: DropzoneContexts;
  urlId: number;
}

function InlineAssetUpload(props: InternalProps): JSX.Element {
  const { closeModal, context, urlId } = props;

  return (
    <>
      <Dropzone
        context={context}
        closeModal={closeModal}
        isInline={true}
        urlId={urlId}
      />
    </>
  );
}

export default InlineAssetUpload;
