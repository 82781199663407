import React from 'react';
import { Col } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { DomainStoreModel } from '../../../models/domain.model';
import TapToEditText from '../../Forms/TapToEditText';

interface InternalState {}

interface Props {
  canEdit: boolean;
  columnProps?: { [key: string]: string };
  domainStore?: DomainStoreModel;
  id: number;
  store: any;
  field?: string;
  value: string;
  Icon?: React.ElementType;
}

@inject('domainStore')
@observer
class EditableTextColumn extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { canEdit, columnProps, Icon, id, store, field, value } = this.props;

    return (
      <>
        <Col {...columnProps} className="item-row-horizontal">
          {/* <Input type="checkbox" className="item-row-checkbox mr-2" /> */}
          {Icon && <Icon />}
          <div
            style={{
              display: 'inline-block',
            }}
            className={`item-row-editable-text ${Icon ? 'ml-4' : 'ml-2'}`}
          >
            {!canEdit ? (
              <h5>{value}</h5>
            ) : (
              <>
                <TapToEditText
                  defaultValue={value}
                  handleUpdate={(value: string): void => {
                    // Update 'name' field by default if no field prop passed in
                    const body: any = {};
                    body[field ? field : 'name'] = value;

                    store.updateItem({
                      body: body,
                      domainStore: this.props.domainStore,
                      id: id,
                    });
                  }}
                />
              </>
            )}

            {/*
              Keep, someday there might be a subtitle

            {item.subtitle ? (
                  <h6 className="mt--1">{item.subtitle}</h6>
                ) : null} */}
          </div>
        </Col>
      </>
    );
  }
}

export default EditableTextColumn;
