import React, { useState } from 'react';

import { AssetSyncType } from '@storyslab/storyslab.common.models';
import {
  ContentItem,
  ContentItemType,
} from '@storyslab/storyslab.common.models';
import SourceMenu from 'components/Content/Source/SourceMenu';

interface Props {
  contentItem: any;
}

const CurrentSource: (props: { item: ContentItem }) => JSX.Element = (props: {
  item: ContentItem;
}) => {
  const { item } = props;

  if (item.type === ContentItemType.LINK) {
    return (
      <div style={styles.controlContent} className="source-contain">
        <i className={`fas fa-link`} style={styles.icon} /> Link
      </div>
    );
  }

  switch (props.item.target.syncType) {
    case AssetSyncType.PUBLIC_LINK:
      return (
        <div style={styles.controlContent} className="source-contain">
          <i className={`fas fa-link`} style={styles.icon} /> Public File on Web
        </div>
      );
    default:
      return (
        <div style={styles.controlContent} className="source-contain">
          <i className={`fas fa-upload`} style={styles.icon} /> Uploaded
        </div>
      );
  }
};

const SourceInput: (props: Props) => JSX.Element = (props: Props) => {
  const { contentItem } = props;

  const [isFocused, setIsFocused] = useState(false);

  function reset(): void {
    setIsFocused(false);
  }

  return (
    <>
      <div
        style={styles.controlWrapper}
        className={`source-input-wrapper ${isFocused && 'active'}`}
        onClick={(): void => {
          setIsFocused(true);
        }}
      >
        {!isFocused && <CurrentSource item={contentItem} />}
        {isFocused && (
          <div style={styles.controlContent} className="source-contain">
            Replace Content File
          </div>
        )}
      </div>
      {isFocused && (
        <>
          <>
            <div
              className="backsplash"
              onClick={(): void => {
                reset();
              }}
            ></div>
            <SourceMenu contentItem={contentItem} reset={reset} />
          </>
        </>
      )}
    </>
  );
};

const styles: any = {
  controlContent: {
    flex: 1,
    fontSize: '14px',
    fontWeight: 400,
    padding: '10px 15px',
  },
  controlWrapper: {
    alignItems: 'center',
    borderRadius: '3px',
    boxShadow: '0 0 10px #c3c3c3',
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    marginBottom: '10px',
    maxWidth: '259px',
    overflow: 'hidden',
  },
  icon: {
    fontSize: '18px',
  },
};

export default SourceInput;
