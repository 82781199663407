import { RestResponse } from '@storyslab/storyslab.common.models';

import {
  extractAuthDetailsFromDomainStore,
  generateHeaders,
} from '../helpers/util.helper';
import { domainStore } from '../stores';

export enum EdgeRequestMethods {
  DELETE = 'delete',
  GET = 'get',
  POST = 'post',
  PUT = 'put',
}

interface FetchHeaders {
  [key: string]: string;
}

export interface AuthPayload {
  accessToken: string;
  applicationId: number;
  idToken: string;
  tenantId: number;
  userDeviceId: number;
}

export interface EdgeRequestParams {
  auth: AuthPayload;
  body?: any;
  headers?: FetchHeaders;
  method: EdgeRequestMethods;
  url: string;
  includeMeta?: boolean;
}

export async function fetchFromEdge<T>(params: EdgeRequestParams): Promise<T> {
  let headers: FetchHeaders = params.headers;
  headers = {
    ...headers,
    ...generateHeaders(params.auth),
    'Content-Type': 'application/json',
  };

  return await fetch(
    `${process.env.REACT_APP_API_GATEWAY_CMS}${
      params.url.indexOf('/') === 0 ? params.url : `/${params.url}`
    }`,
    {
      body: params.body ? JSON.stringify(params.body) : null,
      headers: headers,
      method: params.method,
    },
  )
    .then((response: Response) => {
      return response.json();
    })
    .then((result: RestResponse<any>) => {
      if (params.includeMeta) {
        return result;
      }

      if (result.error) {
        throw result.error;
      }

      return result.data;
    });
}

export async function putToEdge<T>(params: EdgeRequestParams): Promise<T> {
  let headers: FetchHeaders = {
    ...generateHeaders(params.auth),
  };

  headers = {
    ...headers,
    'Content-Type': 'application/json',
  };

  return await fetch(params.url, {
    body: JSON.stringify(params.body),
    headers,
    method: params.method,
  })
    .then((response: Response) => response.json())
    .catch((error) => {
      console.log(error);
    });
}

export async function standardPost(params: {
  body: any;
  headers?: any;
  url: string;
}): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: params.body,
    headers: params.headers,
    method: EdgeRequestMethods.POST,
    url: params.url,
  });
}

export async function standardPut(params: {
  body: any;
  url: string;
}): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: params.body,
    method: EdgeRequestMethods.PUT,
    url: params.url,
  });
}

export async function standardGet(url: string): Promise<any> {
  return await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url,
  });
}
