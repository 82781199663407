import React from 'react';
import { ManageCollection } from 'enums/contentTree.enum';
import { EdgeUrls } from 'enums/edge.enum';
import { ManageCollectionProps } from 'interfaces';
import { inject, observer } from 'mobx-react';
import { BaseProps } from 'models/base.model';
import { manageCollection } from 'services/tree.service';
import { collectionsStore } from 'stores';
import { BaseStore } from 'stores/base.store';

import CollectionDrawerHeaders from 'components/ContentTree/Drawers/CollectionDrawerHeaders';
import CollectionDrawerRowContents from 'components/ContentTree/Drawers/CollectionDrawerRowContents';
import ContentDrawerHeaders from 'components/ContentTree/Drawers/ContentDrawerHeaders';
import ContentDrawerRowContents from 'components/ContentTree/Drawers/ContentDrawerRowContents';
import GridTableCardFooter from 'components/GridTable/CardFooter';
import GridTableCardHeader from 'components/GridTable/CardHeader';

interface InternalState {
  isCollection: boolean;
}

interface Props extends BaseProps {
  forceUpdate: () => void;
  store: BaseStore<any>;
}

@inject('domainStore')
@inject('uiStore')
@observer
class DrawerContents extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCollection: this.props.store === collectionsStore,
    };
  }

  public componentDidMount(): void {
    this.props.store.fetchItems(this.props);
  }

  public addChildToCollection(idToAdd: number): void {
    const activeList: number =
      this.props.store.drawerModals[
        this.state.isCollection
          ? 'ContentTreeExistingCollection'
          : 'ContentTreeExistingItem'
      ]?.id;
    if (idToAdd === activeList) {
      this.props.store.closeAllDrawerModals();
      return;
    }
    const params: ManageCollectionProps = {
      action: ManageCollection.ADD,
      activeList,
      targetID: idToAdd,
      url: `${EdgeUrls[
        this.state.isCollection ? 'Collection' : 'ContentItem'
      ].replace(':id', idToAdd.toString())}`,
    };
    manageCollection(params).then(() => {
      this.props.store.closeAllDrawerModals();
      this.props.forceUpdate();
    });

    // TODO refresh list
  }

  public render(): JSX.Element {
    const items: any = Array.from(this.props.store.items.values());
    return (
      <div className={'container-fluid p-0'}>
        <GridTableCardHeader actionButtonTitle={''} store={this.props.store} />

        {this.state.isCollection && <CollectionDrawerHeaders />}
        {!this.state.isCollection && <ContentDrawerHeaders />}

        {this.state.isCollection && (
          <CollectionDrawerRowContents
            items={items}
            handleAdd={(id): any => this.addChildToCollection(id)}
          />
        )}
        {!this.state.isCollection && (
          <ContentDrawerRowContents
            items={items}
            handleAdd={(id): any => this.addChildToCollection(id)}
          />
        )}

        <GridTableCardFooter
          store={this.props.store}
          storeInstance={this.props.store}
        />
      </div>
    );
  }
}

export default DrawerContents;
