import React, { useState } from 'react';
import { DropzoneContexts } from 'enums/dropzone.enum';

import S3TenantAssetImage from 'components/Assets/S3TenantAssetImage';
import InlineAssetUpload from 'components/Forms/FileFields/InlineAssetUpload';

interface Props {
  collection: any;
}

interface FileFieldProps extends Props {
  fieldType: string;
}

function FileField(props: FileFieldProps): JSX.Element {
  const [isAddingImage, setIsAddingImage] = useState(false);

  const hasImage: boolean =
    props.collection[props.fieldType].s3Key !== undefined;

  function closeModal(): void {
    setIsAddingImage(false);
  }

  const context: DropzoneContexts =
    props.fieldType === 'image'
      ? DropzoneContexts.CollectionAddBackground
      : DropzoneContexts.CollectionAddThumbnail;

  return (
    <>
      <div style={styles.wrapper}>
        <div
          className={`detail-button ${hasImage && 'populated'}`}
          onClick={(e): void => {
            setIsAddingImage(true);
          }}
        >
          {hasImage && (
            <>
              <i className="fas fa-check"></i> asset selected
            </>
          )}
          {!hasImage && 'upload'}
        </div>
        {hasImage && (
          <div
            onClick={(): void => {
              setIsAddingImage(true);
            }}
            style={styles.image}
          >
            <S3TenantAssetImage
              s3Key={props.collection[props.fieldType].s3Key}
              className={`image-field`}
            />
          </div>
        )}
      </div>
      {isAddingImage && (
        <InlineAssetUpload
          closeModal={closeModal}
          urlId={props.collection.id}
          context={context}
        />
      )}
    </>
  );
}

function AppearanceFields(props: Props): JSX.Element {
  return (
    <div className="content-contain">
      <div>
        <div className="form-field-contain">
          <div className="form-field-label">Main</div>
          <FileField {...props} fieldType="image" />
        </div>
      </div>
      <div>
        <div className="form-field-contain">
          <div className="form-field-label">thumbnail</div>
          <FileField {...props} fieldType="thumbnail" />
        </div>
      </div>
    </div>
  );
}

const styles: any = {
  image: {
    left: '200px',
    position: 'absolute',
    top: 0,
    zIndex: 1,
  },
  wrapper: {
    position: 'relative',
  },
};

export default AppearanceFields;
