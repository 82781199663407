import React from 'react';

import AppSettingsField from 'components/SettingsAndFeatures/DynamicFeatureCard/AppSettingsField';
import AppSubFeatureSection from 'components/SettingsAndFeatures/DynamicFeatureCard/AppSubFeatureSection';
import FeatureCard from 'components/SettingsAndFeatures/FeatureCard';

import {
  AppSettingsFieldConfig,
  AppSubFeatureSectionConfig,
  FeatureCardConfig,
} from './FeatureCardModels';

interface Props extends FeatureCardConfig {
  features: { [key: string]: boolean };
  settings: { [key: string]: any };
  handleFeatureToggle: (toggledState: boolean, field: string) => void;
  handleSettingsChange: (newValue: any, field: string) => void;
}

const DynamicFeatureCard: any = (props: Props) => {
  const {
    title,
    description,
    icon,
    hasToggle,
    featureKey,
    settings,
    features,
    subFeatureSections,
    handleFeatureToggle,
    handleSettingsChange,
  } = props;

  function renderModalContents(): JSX.Element {
    //Sub Features
    return (
      <>
        {subFeatureSections?.map(
          (sectionConfig: AppSubFeatureSectionConfig, i) => {
            const sectionKey: any = `section-${sectionConfig.featureKey}-${i}`;
            return (
              settings &&
              features && (
                <AppSubFeatureSection
                  key={sectionKey}
                  {...sectionConfig}
                  isLocked={
                    typeof features[sectionConfig.featureKey] === 'undefined'
                  }
                  initialValue={features[sectionConfig.featureKey] || false}
                  onToggle={handleFeatureToggle}
                >
                  {/*Fields*/}
                  {sectionConfig.fields?.length > 0 &&
                    sectionConfig.fields.map(
                      (fieldConfig: AppSettingsFieldConfig, i) => {
                        const fieldKey: any = `field-${fieldConfig.metaKey}-${i}`;
                        return (
                          <AppSettingsField
                            key={fieldKey}
                            {...fieldConfig}
                            initialValue={settings[fieldConfig.metaKey]?.value}
                            onChange={handleSettingsChange}
                          />
                        );
                      },
                    )}
                </AppSubFeatureSection>
              )
            );
          },
        )}
      </>
    );
  }

  return (
    <>
      <FeatureCard
        title={title}
        description={description}
        icon={icon}
        hasToggle={hasToggle}
        featureKey={featureKey}
        features={features}
        handleFeatureToggle={handleFeatureToggle}
        modalContents={renderModalContents()}
      />
    </>
  );
};
export default DynamicFeatureCard;
