import dayjs from 'dayjs';
import { inject, IWrappedComponent, observer } from 'mobx-react';

import Resolver from './Resolver';

const Injected: ((props: any) => JSX.Element) & IWrappedComponent<unknown> =
  inject((stores: any) => {
    return {
      // When all these conditions are true then @inject observable stores will be populated async
      isAuthenticated:
        !!stores.domainStore.user?.deviceId &&
        !!stores.domainStore.entitlements &&
        !!stores.domainStore.auth.accessToken &&
        !!stores.domainStore.auth.idToken &&
        dayjs().isBefore(stores.domainStore.auth.expiresAt),
    };
  })(observer(Resolver));

export default Injected;
