import React from 'react';
import { Button, Col } from 'reactstrap';

interface InternalState {}

interface Props {
  isCentered?: boolean;
  isLast?: boolean;
  colProps?: { [key: string]: any };
  type: string;
}

class Type extends React.Component<Props, InternalState> {
  private renderTitle(title: string): JSX.Element {
    return <h5>{title}</h5>;
  }

  private renderButton(icon: string, color: string = 'default'): JSX.Element {
    return (
      <Button
        className="close-btn btn-icon-only rounded-circle"
        color={`${color}`}
        type="button"
      >
        <span className="btn-inner--icon">
          <i className={`fas ${icon}`} />
        </span>
      </Button>
    );
  }

  private renderOrganizational(): JSX.Element {
    return (
      <>
        {this.renderTitle('Organizational')}
        {this.renderButton('fa-chart-line')}
      </>
    );
  }

  private renderCore(): JSX.Element {
    return (
      <>
        {this.renderTitle('Core')}
        {this.renderButton('fa-chart-line')}
        {this.renderButton('fa-folder-tree')}
        {this.renderButton('fa-tablet-alt')}
      </>
    );
  }

  private renderContent(): JSX.Element {
    return (
      <>
        {this.renderTitle('Content')}
        {this.renderButton('fa-chart-line')}
        {this.renderButton('fa-folder-tree')}
      </>
    );
  }

  private renderBasedOnType(): JSX.Element {
    switch (this.props.type) {
      case 'org':
        return this.renderOrganizational();
      case 'core':
        return this.renderCore();
      case 'content':
        return this.renderContent();

      default:
        break;
    }
  }

  public render(): JSX.Element {
    const { colProps, isCentered } = this.props;

    return (
      <>
        <Col {...colProps} className="item-row-horizontal ">
          <div
            className="item-row-type"
            style={{
              textAlign: isCentered ? 'center' : 'left',
            }}
          >
            {this.renderBasedOnType()}
          </div>
        </Col>
      </>
    );
  }
}

export default Type;
