import React from 'react';

interface InternalProps {}

function ContentDrawerHeaders(props: InternalProps): JSX.Element {
  return (
    <>
      <div className="drawer-row tree-drawer-row drawer-header">
        <div className="content-name">Display Name</div>
        <div className="content-category">Category</div>
        <div className="content-added-by">Added By</div>
        <div className="content-last-updated">Last Updated</div>
        <div className="content-add">Add Content File</div>
      </div>
    </>
  );
}

export default ContentDrawerHeaders;
