import React from 'react';
import { Container } from 'reactstrap';
import { uiStore } from 'stores';

import AppNavHome from 'components/ApplicationNavigation/Home/AppNavHome';

const NavigationHome: any = () => {
  return (
    <>
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <i className="fas fa-stream" /> Navigation
        </h4>
        <AppNavHome />
      </Container>
    </>
  );
};

export default NavigationHome;
