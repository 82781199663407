/* eslint-disable multiline-comment-style */
import React, { useEffect, useState } from 'react';
import { domainStore, simpleListsStore, userRolesStore } from 'stores';

import { PayloadActions } from '@storyslab/storyslab.common.models';
import ModalSearch from 'components/Entitlements/ModalSearch';
import SimpleToggle from 'components/Forms/SimpleToggle';

interface Props {
  roleId?: any;
}

function Entitlement(props: { entitlement: any; roleId: number }): JSX.Element {
  const { entitlement, roleId } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [isInStore, setIsInStore] = useState(false);

  useEffect(() => {
    for (const x in userRolesStore.items.get(roleId)?.entitlementIds) {
      if (
        entitlement.id === userRolesStore.items.get(roleId)?.entitlementIds[x]
      ) {
        setIsInStore(true);
        break;
      }
    }
    setIsLoading(false);
  }, [entitlement.id, roleId]);

  const updateEntitlements: (isAdding) => void = (isAdding): void => {
    userRolesStore
      .updateItem({
        body: {
          entitlementIds: {
            action: isAdding ? PayloadActions.ADD : PayloadActions.REMOVE,
            ids: entitlement.id.toString(),
          },
        },
        domainStore,
        id: roleId,
      })
      .then(() => {
        userRolesStore.fetchItems({ domainStore });
      });
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div className="storyslab-table-row">
        <div style={{ paddingRight: '1rem' }}>
          <SimpleToggle
            didCheckOnLoad={isInStore}
            handleChange={(value): void => {
              updateEntitlements(value);
            }}
          />
          {entitlement.id}
        </div>
        <div style={{ flex: 1 }}>{entitlement.area}</div>
        <div style={{ flex: 2, fontSize: '12px' }}>
          {entitlement.name.replaceAll(':', ': ')}
        </div>
        <div style={{ flex: 6, fontSize: '12px', fontWeight: 300 }}>
          {entitlement.description}
        </div>
      </div>
    </>
  );
}

function EntitlementsModalContents(props: Props): JSX.Element {
  const { roleId } = props;

  const [entitlements, setEntitlements] = useState([]);

  useEffect(() => {
    const allEntitlements: any = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [id, item] of simpleListsStore.userEntitlements) {
      allEntitlements.push(item);
    }
    setEntitlements(allEntitlements);
  }, []);

  const rows: Array<JSX.Element> = [];

  for (const entitlement of entitlements) {
    rows.push(
      <Entitlement
        key={`entitlement-${entitlement.id}`}
        entitlement={entitlement}
        roleId={roleId}
      />,
    );
  }

  return (
    <>
      <ModalSearch setEntitlements={setEntitlements} />
      {rows}
    </>
  );
}

export default EntitlementsModalContents;
