import React from 'react';
import { Col } from 'reactstrap';

interface InternalState {}

interface Props {
  columnProps?: { [key: string]: string };
  isCentered?: boolean;
  title: string;
  subtitle?: string;
}

class TextColumn extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { columnProps, isCentered, title, subtitle } = this.props;

    return (
      <>
        <Col {...columnProps} className="item-row-horizontal">
          {/* <Input type="checkbox" className="item-row-checkbox mr-2" /> */}

          <div
            style={{
              ...styles.textContain,
              textAlign: isCentered ? 'center' : 'left',
            }}
          >
            {subtitle && (
              <h6 style={styles.h6} className="text-column-subtitle">
                {subtitle}
              </h6>
            )}
            <h5 style={styles.h5}>{title}</h5>
          </div>
        </Col>
      </>
    );
  }
}

const styles: any = {
  h5: { marginBottom: 0 },
  h6: {},
  textContain: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
};

export default TextColumn;
