/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'mobx-react';

import { BrowserRouter } from 'react-router-dom';

import 'react-dates/initialize';

import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'react-dates/lib/css/_datepicker.css';

import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';

import { setSubdomain } from './services/subdomain.service';
import Injected from './components/Root/Injected';

import * as stores from './stores';

if (process.env.REACT_APP_STAGE !== 'local') {
  Sentry.init({
    environment: process.env.REACT_APP_STAGE,
    dsn: 'https://a80e1059f3924487a43310a46ed73762@o514778.ingest.sentry.io/5618552',
    release: 'storyslab.cms.frontend@' + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}

setSubdomain();

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <BrowserRouter>
      <Provider {...stores}>
        <>
          <Injected />
        </>
      </Provider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
