import React from 'react';

interface InternalState {}

interface Props {}

class ContentItemsAddSourceLink extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    return (
      <>
        <h2>Source Link</h2>
      </>
    );
  }
}

export default ContentItemsAddSourceLink;
