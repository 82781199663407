import React from 'react';

interface InternalProps {}

function CollectionDrawerHeaders(props: InternalProps): JSX.Element {
  return (
    <>
      <div className="drawer-row tree-drawer-row drawer-header">
        <div className="collection-name">Display Name</div>
        <div className="collection-added-by">Added By</div>
        <div className="collection-add">Add Collection</div>
      </div>
    </>
  );
}

export default CollectionDrawerHeaders;
