import React from 'react';

import IconBay from './Bay/IconBay';

interface InternalState {
  isOpen: boolean;
  rightAligned: boolean;
}

interface InternalProps {
  defaultIcon: string;
  iconName?: string;
  persistIconChange?: (iconName: string) => void;
  style?: any;
}

class StorySlabIconPicker extends React.Component<
  InternalProps,
  InternalState
> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      isOpen: false,
      rightAligned: false,
    };
  }

  public setOpenState(isOpen: boolean): void {
    this.setState({ isOpen: isOpen });
  }

  public setRightAlignment(target: HTMLElement): void {
    const windowWidth: number = window.innerWidth;
    const leftMostPixel: number = target.getBoundingClientRect().x;
    if (windowWidth - leftMostPixel <= 700) {
      this.setState({ rightAligned: true });
    }
  }

  public render(): JSX.Element {
    const { persistIconChange } = this.props;
    return (
      <>
        <div
          className={`icon-display-wrapper ignore-handle-click ${
            this.state.isOpen ? 'isOpen' : ''
          }`}
          onClick={(e): void => {
            this.setOpenState(true);
            this.setRightAlignment(e.target as HTMLElement);
          }}
          style={this.props.style}
        >
          <div className="icon-display-contain">
            <i
              className={`fa fa-${
                this.props.iconName !== undefined
                  ? this.props.iconName
                  : this.props.defaultIcon
              }`}
            ></i>
            <i className="fal fa-angle-down"></i>
          </div>
          {this.state.isOpen && (
            <IconBay
              setOpenState={this.setOpenState.bind(this)}
              persistIconChange={persistIconChange}
              isRightAligned={this.state.rightAligned}
            />
          )}
        </div>
      </>
    );
  }
}

export default StorySlabIconPicker;
