import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import dayjs from 'dayjs';
import { getDefaultIconForItem } from 'helpers/icon.helper';
import { AdminContext } from 'routes/Admin';
import { simpleListsStore, uiStore } from 'stores';

interface Props {
  handleAdd?: (value: number) => void;
  items: any;
}

function renderCategoryLabel(categories: any): string {
  if (categories.length === 0) {
    return '-';
  }
  if (categories.length === 1) {
    return simpleListsStore.categories.get(categories[0]).name;
  }
  return `${categories.length} categories`;
}

function ContentDrawerRowContents(props: Props): JSX.Element {
  const { userSimpleList } = useContext(AdminContext);

  return (
    <>
      {props.items.map((item: any, index: any) => {
        return (
          <div
            key={`table-row-${item.id}-${index}`}
            className={`drawer-row tree-drawer-row ${
              index % 2 === 0 ? 'dark-row' : ''
            }`}
          >
            <div className="content-name">
              <i className={`${getDefaultIconForItem(item)}`}></i>
              <span className="make-ellipsis">{item.name}</span>
            </div>
            <div className="content-category">
              {renderCategoryLabel(item.categoryIds)}
            </div>
            <div className="content-added-by">
              <span>{userSimpleList[item.updatedBy]}</span>
            </div>
            <div className="content-last-updated">
              {dayjs(item.updated).format('MM / DD / YYYY')}
            </div>
            <div className="content-add">
              <Button
                className="drawerButton"
                style={{
                  backgroundColor: uiStore.common.colors.primary,
                }}
                onClick={(): void => {
                  props.handleAdd(item.id);
                }}
              >
                add content file
              </Button>
            </div>
            {/* <div className="collection-ellipsis">
              <i className="fas fa-ellipsis-v"></i>
            </div> */}
          </div>
        );
      })}
    </>
  );
}

export default ContentDrawerRowContents;
