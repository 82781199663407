import { bundlesStore } from 'stores';

export const contentHeaderColumnConfig: any = [
  {
    colProps: {
      lg: '11',
      md: '11',
    },
    isFilterable: false,
    isSortable: true,
    store: bundlesStore,
    title: 'Display Name',
  },
  {
    colProps: {
      lg: '1',
      md: '1',
    },
    isFilterable: false,
    isSortable: false,
    store: bundlesStore,
    title: '',
  },
];
