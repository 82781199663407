/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import TimeAgo from 'react-timeago';
import { Col } from 'reactstrap';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { ActionTypes } from 'models/action.model';
import { AdminContext } from 'routes/Admin';
import { reSendInvite, sendInvite } from 'services/users.service';
import { domainStore, simpleListsStore, usersStore } from 'stores';

import BadgeColumn from 'components/GridTable/RowColumns/Badge';
import Loader from 'components/Indicators/Loader';
import BasicFields from 'components/Users/BasicFields';

interface Props {
  user: any;
}

interface InternalProps extends Props {
  activeMenu: string;
  isDropped: boolean;
  isMenuOpen: boolean;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

enum MenuOptions {
  BASIC = 'basic info',
  HISTORY = 'history',
}

function HamburgerMenu(props: {
  userId: number;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <>
      <div
        className="backsplash"
        onClick={(): void => {
          props.setIsMenuOpen(false);
        }}
      ></div>
      <div className={`hamburger-menu-card`}>
        <div
          className="menu-option"
          onClick={(): void => {
            window.siteWideComponent.show({
              approve: () => {
                usersStore
                  .deleteItem({
                    domainStore,
                    id: props.userId,
                  })
                  .then(() => {
                    usersStore.handleTableAction(
                      { domainStore },
                      {
                        payload: { page: usersStore.page.toString() },
                        type: ActionTypes.PAGINATE,
                      },
                    );
                  });
              },
              body: `Are you sure you want to delete this user?`,
              title: 'Delete Confirmation',
            });
          }}
        >
          delete
        </div>
      </div>
    </>
  );
}

function listRoles(roleIds: Array<string>): JSX.Element {
  let content: JSX.Element = <></>;
  if (roleIds.length === 1) {
    content = <>{simpleListsStore.userRoles.get(parseInt(roleIds[0]))?.name}</>;
  } else {
    content = (
      <>
        {roleIds.map((value, index) => {
          if (index === roleIds.length - 1) {
            return simpleListsStore.userRoles.get(parseInt(value))?.name;
          } else {
            return `${simpleListsStore.userRoles.get(parseInt(value))?.name}, `;
          }
        })}
      </>
    );
  }
  return <div style={{ textAlign: 'center' }}>{content}</div>;
}

function RowHeader(props: InternalProps): JSX.Element {
  const { userSimpleList } = useContext(AdminContext);
  const { isDropped, isMenuOpen, setIsDropped, setIsMenuOpen, user } = props;

  const [isInviting, setIsInviting] = useState(false);

  if (!userSimpleList) {
    return <Loader isFullPage={true} />;
  }

  return (
    <>
      <div
        className={`detail-row-wrapper ${props.isDropped && 'active'}`}
        onClick={(): void => {
          setIsDropped(!isDropped);
        }}
      >
        <div className="detail-row-body">
          {isDropped && (
            <>
              <Col md="3">
                <div className="detail-row-title">
                  <span>
                    {user.lastName ? user.lastName : 'undefined'},{' '}
                    {user.firstName ? user.firstName : 'undefined'}
                  </span>
                </div>
              </Col>
              <BadgeColumn label="user status" status={user.status} />
              <div style={{ flex: 3 }}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  last updated:
                </span>{' '}
                <span style={{ fontSize: '14px', fontWeight: 300 }}>
                  <TimeAgo date={user.updated} />
                </span>
              </div>
              <div
                style={{ display: 'flex', flex: 3, justifyContent: 'center' }}
              >
                {user.status === 5 && (
                  <>
                    {!isInviting && (
                      <div
                        className="btn storyslab-button"
                        onClick={(e): void => {
                          setIsInviting(true);
                          e.stopPropagation();
                          if (isInviting) {
                            return;
                          }
                          sendInvite(user.id.toString()).then(() => {
                            usersStore.fetchItemById(user.id, { domainStore });
                          });
                        }}
                      >
                        Activate & Email
                      </div>
                    )}
                    {isInviting && <Loader />}
                  </>
                )}
                {user.status === 7 && (
                  <div
                    className="btn storyslab-button"
                    onClick={(e): void => {
                      e.stopPropagation();
                      reSendInvite(user.id.toString());
                    }}
                  >
                    Resend Invite
                  </div>
                )}
              </div>
              <div
                className="detail-done"
                style={{ display: 'inline', flex: 1, textAlign: 'right' }}
              >
                Done
              </div>
            </>
          )}

          {!isDropped && (
            <>
              <Col md="3">
                <div className="detail-row-title">
                  <span>
                    {user.lastName ? user.lastName : 'undefined'},{' '}
                    {user.firstName ? user.firstName : 'undefined'}
                  </span>
                </div>
              </Col>
              <Col md="2">
                <div className="">{listRoles(user.roleIds)}</div>
              </Col>
              <BadgeColumn columnProps={{ md: '2' }} status={user.status} />
              <Col md="2"></Col>
              <Col md="2">
                <div className="detail-row-meta">
                  <div className="meta-text">
                    {user.lastSeen
                      ? dayjs(user.lastSeen).format('MM / DD / YYYY')
                      : '-'}
                  </div>
                </div>
              </Col>
            </>
          )}
          <Col md="1">
            <div>
              <div
                className="hamburger-menu-card-contain"
                onClick={(e): void => {
                  e.stopPropagation();
                }}
                style={{ textAlign: 'right' }}
              >
                <i
                  className="fal fa-ellipsis-v"
                  onClick={(e): void => {
                    e.stopPropagation();
                    setIsMenuOpen(!isMenuOpen);
                  }}
                ></i>
                {isMenuOpen && (
                  <HamburgerMenu
                    userId={user.id}
                    setIsMenuOpen={setIsMenuOpen}
                  />
                )}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </>
  );
}

function Menu(props: InternalProps): JSX.Element {
  const { activeMenu, setActiveMenu } = props;

  return (
    <>
      <div className="menu">
        <div
          className={`${activeMenu === MenuOptions.BASIC && 'active'}`}
          onClick={(): void => {
            setActiveMenu(MenuOptions.BASIC);
          }}
        >
          <i className="fas fa-id-card mr-2" /> Basic Info
        </div>
      </div>
    </>
  );
}

function Content(props: InternalProps): JSX.Element {
  const { activeMenu } = props;

  switch (activeMenu) {
    case MenuOptions.BASIC:
      return <BasicFields {...props} />;
    case MenuOptions.HISTORY:
      return <>history</>;
    default:
      return <>content</>;
  }
}

const DroppedContent: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const { isDropped } = props;

    return (
      <>
        <div
          className={`detail-row-dropped-content ${isDropped && ' dropped'}`}
          tabIndex={0}
        >
          <Menu {...props} />
          <Content {...props} />
        </div>
      </>
    );
  },
);

function UsersRow(props: Props): JSX.Element {
  const [isDropped, setIsDropped] = useState(false);
  const [hasDroppedClass, setHasDroppedClass] = useState(true);
  const [activeMenu, setActiveMenu] = useState(MenuOptions.BASIC);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isDropped) {
      setHasDroppedClass(true);
    } else {
      setTimeout((): void => {
        setHasDroppedClass(false);
      }, 400);
    }
  }, [isDropped]);

  useEffect(() => {
    if (usersStore.newItemId === props.user.id) {
      setIsDropped(true);
      setTimeout((): void => {
        setHasDroppedClass(true);
      }, 500);
    }
  }, [props.user.id]);

  return (
    <>
      {isDropped && (
        <div
          className="detail-backsplash"
          onClick={(): void => {
            setIsDropped(false);
          }}
        ></div>
      )}
      <div
        className={`detail-view-wrapper ${hasDroppedClass && 'dropped'} ${
          isMenuOpen && 'hamburger-open'
        }`}
        tabIndex={0}
        onKeyUp={(e): void => {
          if (e.key === 'Escape') {
            setIsDropped(false);
          }
        }}
      >
        <RowHeader
          {...props}
          activeMenu={activeMenu}
          isDropped={isDropped}
          isMenuOpen={isMenuOpen}
          setActiveMenu={setActiveMenu}
          setIsDropped={setIsDropped}
          setIsMenuOpen={setIsMenuOpen}
          user={props.user}
        />
        <DroppedContent
          {...props}
          activeMenu={activeMenu}
          isDropped={isDropped}
          isMenuOpen={isMenuOpen}
          setActiveMenu={setActiveMenu}
          setIsDropped={setIsDropped}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </>
  );
}

export default UsersRow;
