import React, { MouseEvent } from 'react';
import ReactDOM from 'react-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { BaseStore } from 'stores/base.store';

import { BaseProps } from '../../models/base.model';

const documentRoot: HTMLElement = document.getElementById('root');

interface InternalState {}

interface Props extends BaseProps {
  store: BaseStore<any>;
  storeKey: string;
  surtitle: string;
}

@inject('uiStore')
@observer
class DrawerModal extends React.Component<Props, InternalState> {
  private el: any;
  private drawerRef: any;

  constructor(props: Props) {
    super(props);
    this.handleExternalClick = this.handleExternalClick.bind(this);
    this.handleCloseRequest = this.handleCloseRequest.bind(this);
    this.drawerRef = React.createRef();

    this.el = document.createElement('div');
    this.el.classList.add('z-index-10000');
  }

  private handleExternalClick(event: any): void {
    // TODO - Refactor findDomNode
    // eslint-disable-next-line react/no-find-dom-node
    const domNode: Element | Text = ReactDOM.findDOMNode(this);

    if (!domNode || domNode.isSameNode(event.target)) {
      this.handleCloseRequest();
    }
  }

  private handleCloseRequest(): void {
    this.props.store.closeDrawerModal(this.props.storeKey);
  }

  public componentDidMount(): void {
    documentRoot.appendChild(this.el);
  }

  public componentWillUnmount(): void {
    documentRoot.removeChild(this.el);
  }

  public render(): JSX.Element {
    const { children, store, storeKey, surtitle } = this.props;
    if (store.drawerModals[storeKey]) {
      this.drawerRef.current.focus();
    }

    return ReactDOM.createPortal(
      <>
        <div
          className={`drawer-modal-wrapper${
            store.drawerModals[storeKey] ? ' open' : ''
          }`}
          onClick={(e: MouseEvent): void => {
            this.handleExternalClick(e);
          }}
          ref={this.drawerRef}
          tabIndex={0}
          onKeyDown={(e): void => {
            e.stopPropagation();
            if (e.keyCode === 27) {
              this.handleExternalClick(e);
            }
          }}
        >
          {store.drawerModals[storeKey] && (
            <Card className="drawer-modal">
              <CardHeader>
                <h6 className="surtitle">{surtitle}</h6>
                <h5
                  className="h3 mb-0"
                  style={{ color: this.props.uiStore.common.colors.primary }}
                >
                  {store.drawerModals[storeKey]?.title}
                </h5>
              </CardHeader>
              <CardBody className="p-0">{children}</CardBody>
            </Card>
          )}
        </div>
      </>,
      this.el,
    );
  }
}

export default DrawerModal;
