import { observable, ObservableMap } from 'mobx';

interface BaseItem {
  id?: number;
}

export function mapItemsToItemRows<T>(items: Array<T>): Array<T> {
  return items.map((item: T) => mapItemToItemRow<T>(item));
}

export function mapItemToItemRow<T>(item: T): T {
  return item;
}

export function mapItemsToObservableMap<T extends BaseItem>(
  items: Array<T>,
): ObservableMap<number, T> {
  if (!items) {
    return observable.map(new Map());
  }

  if (!Array.isArray(items)) {
    items = [items];
  }

  return observable.map(new Map(items.map((item: T) => [item.id, item])));
}
