/* eslint-disable max-lines-per-function */
import React, { ChangeEvent } from 'react';
import { Container, DropdownItem, FormGroup, Input } from 'reactstrap';
import { observer } from 'mobx-react';

import { LabeledCheckboxProps } from '../../../models/forms.model';
import LabeledCheckBoxGroup from '../../Forms/LabeledCheckBoxGroup';

interface InternalState {
  search: string;
}

interface Props {
  handleAction: any;
  itemKey: string;
  simpleList: any;
  store: any;
}

/**
 * @class CheckboxList
 */

@observer
class CheckboxList extends React.Component<Props, InternalState> {
  public state: InternalState = {
    search: '',
  };

  constructor(props: Props) {
    super(props);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  private handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
    this.setState({ search: e.target.value });
  }

  private searchFilterPredicate(
    search: string,
    simpleList: any,
  ): (value: string, index: number, array: Array<string>) => boolean {
    search = search.toLowerCase();

    return (itemId: string): boolean => {
      if (search.length === 0) {
        return true;
      }

      if (simpleList.get(itemId)) {
        return (
          simpleList.get(itemId)?.name?.toLowerCase()?.indexOf(search) !== -1
        );
      }

      return false;
    };
  }

  public render(): JSX.Element {
    const { simpleList, store } = this.props;

    const itemIds: Array<string> = Array.from(simpleList.keys());
    const activeIdArray: Array<string> =
      store.get(this.props.itemKey as any) || [];

    return (
      <>
        <Container className="dropdown-container">
          <FormGroup className="mb-1">
            <Input
              className="form-control-sm"
              placeholder="Search"
              type="text"
              onChange={this.handleSearchChange}
            />
          </FormGroup>
          <LabeledCheckBoxGroup
            inputs={[
              {
                handleChange: (): void => {
                  this.props.handleAction({
                    payload: { [this.props.itemKey]: null },
                    type: 'filter',
                  });
                },
                id: -1,
                index: 0,
                label: 'Show All',
                selected: activeIdArray?.length === 0,
              },
            ]}
          />
          <DropdownItem divider />
          {itemIds.map && (
            <LabeledCheckBoxGroup
              inputs={itemIds
                .filter(
                  this.searchFilterPredicate(this.state.search, simpleList),
                )
                .map((itemId: any): LabeledCheckboxProps => {
                  return {
                    handleChange: ({ id, selected }): void => {
                      this.props.handleAction({
                        payload: {
                          [this.props.itemKey]: { id, selected: !selected },
                        },
                        type: 'filter',
                      });
                    },
                    id: itemId,
                    index: 0,
                    label: (simpleList.get(itemId) as any)?.name || '',
                    selected:
                      activeIdArray &&
                      activeIdArray.length > 0 &&
                      activeIdArray.indexOf(itemId) !== -1,
                  };
                })}
            />
          )}
        </Container>
      </>
    );
  }
}

export default CheckboxList;
