import React from 'react';
import { collectionsStore, simpleListsStore, uiStore } from 'stores';

import { Components } from '@storyslab/storyslab.common.models';

interface Props {
  component: Components;
}

function renderButtonTitle(component: Components): JSX.Element {
  if (!component.target) {
    return <>Select a collection</>;
  }
  if (simpleListsStore.collections.get(parseInt(component.target.toString()))) {
    return (
      <>
        {
          simpleListsStore.collections.get(
            parseInt(component.target.toString()),
          ).name
        }
      </>
    );
  } else {
    return <>(deleted)</>;
  }
}

const CollectionSelect: (props: Props) => JSX.Element = (props: Props) => {
  const { component } = props;

  return (
    <>
      <div style={styles.label1}>Using this collection</div>
      <div>
        This is the description area for a setting and can contain a sentence of
        explanation or even just a word of what it does
      </div>

      <div
        style={{
          ...styles.button,
          backgroundColor: uiStore.common.colors.primary,
        }}
        onClick={(): void => {
          collectionsStore.openDrawerModal({
            key: 'navitem',
            params: {
              count: 0,
              id: 1,
              title: `Select Collection for ${component.name}`,
            },
          });
        }}
      >
        <span>{renderButtonTitle(component)}</span>
        <i className="far fa-angle-down" />{' '}
      </div>
    </>
  );
};

const styles: any = {
  button: {
    alignItems: 'center',
    borderRadius: '2rem',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '20px',
    justifyContent: 'space-between',
    margin: '1rem auto 2rem',
    padding: '.5rem 1rem',
    width: '75%',
  },
  label1: {
    color: '#999',
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '10px',
  },
};

export default CollectionSelect;
