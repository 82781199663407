/* eslint-disable max-lines-per-function */
/*
 *!
 *
 *=========================================================
 * Argon Dashboard PRO React - v1.1.0
 *=========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by Creative Tim
 *
 *=========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 */
import React from 'react';
import {
  Button,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { DomainStoreModel } from 'models/domain.model';
import { logout } from 'services/auth.service';
import {publishAllManifests} from 'services/publish.service';
import { domainStore } from 'stores';

interface InternalState {
  isSearching: boolean;
}
interface Props {
  brandText: string;
  domainStore: DomainStoreModel;
  sidenavOpen: boolean;
  theme: any;
  toggleSidenav: () => void;
}

@inject('domainStore')
@inject('uiStore')
@observer
class AdminNavBar extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSearching: false,
    };
  }
  // Function that on mobile devices makes the search open
  private openSearch(): void {
    document.body.classList.add('g-navbar-search-showing');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-showing');
      document.body.classList.add('g-navbar-search-show');
    }, 150);
    setTimeout(() => {
      document.body.classList.add('g-navbar-search-shown');
    }, 300);
  }

  // Function that on mobile devices makes the search close
  private closeSearch(): void {
    document.body.classList.remove('g-navbar-search-shown');
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-show');
      document.body.classList.add('g-navbar-search-hiding');
    }, 150);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hiding');
      document.body.classList.add('g-navbar-search-hidden');
    }, 300);
    setTimeout(() => {
      document.body.classList.remove('g-navbar-search-hidden');
    }, 500);
  }

  private renderUserMenu(): JSX.Element {
    return (
      <DropdownMenu right>
        <DropdownItem className="noti-title" header tag="div">
          <h6 className="text-overflow m-0">Welcome!</h6>
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={(e: MouseEvent): void => e.preventDefault()}
        >
          <i className="ni ni-single-02" />
          <span>My profile</span>
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={(e: MouseEvent): void => e.preventDefault()}
        >
          <i className="ni ni-settings-gear-65" />
          <span>Settings</span>
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={(e: MouseEvent): void => e.preventDefault()}
        >
          <i className="ni ni-calendar-grid-58" />
          <span>Activity</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          href="#"
          onClick={(e: MouseEvent): void => e.preventDefault()}
        >
          <i className="ni ni-support-16" />
          <span>Support</span>
        </DropdownItem>
      </DropdownMenu>
    );
  }

  private renderSidebarToggler(): JSX.Element {
    return (
      <div
        className={classnames(
          'pr-3 sidenav-toggler clickable',
          { active: this.props.sidenavOpen },
          { 'sidenav-toggler-dark': this.props.theme === 'dark' },
        )}
        onClick={this.props.toggleSidenav}
      >
        <div className="sidenav-toggler-inner">
          <i className="sidenav-toggler-line" />
          <i className="sidenav-toggler-line" />
          <i className="sidenav-toggler-line" />
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <Navbar
          className={classnames(
            'navbar-top navbar-expand border-bottom',
            { 'navbar-dark': this.props.theme === 'dark' },
            { 'navbar-light': this.props.theme === 'light' },
          )}
          style={{ backgroundColor: '#fff' }}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center" navbar>
                <NavItem className="">{this.renderSidebarToggler()}</NavItem>
              </Nav>

              <Nav
                className="align-items-center ml-auto"
                style={{ minHeight: '46px' }}
                navbar
              >

                <Button className="storyslab-button" onClick={():void=>{
                  publishAllManifests(domainStore).then((result:any)=>{
                    if (result.error){
                      console.error(result.error);
                      alert("Publish failed-" + result.error);
                    }
                  });
                }}>
                  Publish Changes
                </Button>

                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="nav-link pr-0 clickable"
                    color=""
                    tag="a"
                  >
                    <Media className="align-items-center">
                      <Media className="d-none d-md-block text-default mb-1 mr-3">
                        Hello,{' '}
                        {this.props.domainStore?.user ? (
                          <span className="mb-0 text-sm font-weight-bold">
                            {this.props.domainStore?.user?.nameFirst}{' '}
                            {this.props.domainStore?.user?.nameLast}
                          </span>
                        ) : null}
                      </Media>
                    </Media>
                  </DropdownToggle>

                  {this.renderUserMenu()}
                </UncontrolledDropdown>

                <Media
                  className="align-items-center clickable"
                  onClick={(): void => logout()}
                >
                  <Media className=" d-block text-default">
                    <i className="fal fa-sign-out-alt text-xl"></i>
                  </Media>
                </Media>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
/*
 * AdminNavbar.defaultProps = {
 *   toggleSidenav: () => {},
 *   sidenavOpen: false,
 *   theme: 'dark',
 * };
 * AdminNavbar.propTypes = {
 *   toggleSidenav: PropTypes.func,
 *   sidenavOpen: PropTypes.bool,
 *   theme: PropTypes.oneOf(['dark', 'light']),
 * };
 */

export default AdminNavBar;
