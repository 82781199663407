/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Routes from 'enums/routes.enum';
import { appNavigationStore, domainStore } from 'stores';

import NavItemStatus from 'components/ApplicationNavigation/Home/NavItemStatus';

interface Props {
  key: string;
  id: string;
  refreshList: () => void;
}

export function SortableItem(props: Props): any {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const viewId: string = appNavigationStore.items.get(
    parseInt(props.id),
  )?.viewId;

  const style: any = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Card className="nav-item-card-wrapper">
          <CardBody className="nav-item-card-contain">
            <div className="grip-icon">
              <i className="fas fa-grip-vertical"></i>
            </div>
            <i
              className={`fa fa-${
                appNavigationStore.items.get(parseInt(props.id))?.icon
              } item-icon`}
            />
            <p className="nav-item-title">
              {appNavigationStore.items.get(parseInt(props.id))?.name}
            </p>
            {!appNavigationStore.items.get(parseInt(props.id))?.viewId && (
              <NavItemStatus />
            )}
            <Link
              to={
                viewId
                  ? Routes.ApplicationConfigurationEdit.replace(':id', viewId)
                  : Routes.ApplicationLayout.replace(':id', props.id)
              }
            >
              <span className="edit-icon">
                <i className="fas fa-paint-roller"></i>
              </span>
            </Link>
            <i
              className="fas fa-trash"
              style={styles.delete}
              onClick={(): void => {
                window.siteWideComponent.show({
                  approve: () => {
                    appNavigationStore
                      .deleteItem({
                        domainStore,
                        id: parseInt(props.id),
                      })
                      .then(() => {
                        props.refreshList();
                      });
                  },
                  body: `Are you sure you want to delete ${
                    appNavigationStore.items.get(parseInt(props.id))?.name
                  }?`,
                  title: 'Delete Confirmation',
                });
              }}
            ></i>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

const styles: any = {
  delete: {
    cursor: 'pointer',
    padding: '.5rem .5rem .5rem 1.5rem',
  },
  menu: {
    position: 'absolute',
    right: '1rem',
    top: '100%',
    zIndex: 99,
  },
};
