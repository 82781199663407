import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { EdgeUrls } from 'enums/edge.enum';
import {
  ContentTreeCardStackBodyProps,
  ContentTreeCardStackProps,
} from 'interfaces/treeInterfaces';
import { standardFetch } from 'services/tree.service';
import { simpleListsStore } from 'stores';

import AddButton from 'components/ContentTree/AddTools/AddButton';
import EditLayoutConfigButton from 'components/ContentTree/EditConfiguration';
import Loader from 'components/Indicators/Loader';

import { TreeContext } from './ContentTree';
import EmptyStack from './EmptyStack';
import StackItem from './StackItem';

interface StackContents {
  id: number;
  name: string;
}

function RenderBody(props: ContentTreeCardStackBodyProps): any {
  const {
    activeItem,
    activeView,
    handleStackClick,
    isRoot,
    stackContents,
    stackId,
  } = props;

  if (isRoot) {
    let isEmptyView: boolean = true;
    stackContents.forEach((element) => {
      if (element.id !== null) {
        isEmptyView = false;
      }
    });
    if (isEmptyView) {
      return (
        <>
          <EmptyStack />
          <EditLayoutConfigButton viewId={activeView.viewId} />
        </>
      );
    }
  }
  if (stackContents.length === 0) {
    return (
      <>
        <EmptyStack />
        <AddButton stackId={stackId} />
      </>
    );
  }
  return (
    <>
      <Card inverse className="card-stack-list">
        <CardBody className="p-0 py-2">
          {stackContents.map((item, index) => {
            // Edge sets deleted id to 0. Filter it out here.
            if (item.id === 0) {
              return null;
            }
            if (
              isRoot &&
              simpleListsStore.collections.get(item.id)?.name === undefined
            ) {
              return null;
            }
            return (
              <StackItem
                component={isRoot && item.name}
                handleStackClick={handleStackClick}
                isActive={item.id === activeItem}
                isRoot={isRoot}
                item={item}
                key={`stack-item-${item.id}-${index}`}
                parentId={stackId}
              />
            );
          })}
        </CardBody>
      </Card>
      {isRoot && <EditLayoutConfigButton viewId={activeView.viewId} />}
      {!isRoot && <AddButton stackId={stackId} />}
    </>
  );
}

const ContentTreeCardStack: any = (props: ContentTreeCardStackProps) => {
  const { activeView, depth, handleBranching, isRoot, stackId } = props;
  const { treeUpdated }: any = useContext(TreeContext);

  const [activeItem, setActiveItem] = React.useState(0);
  const [stackContents, setStackContents] = useState<Array<StackContents>>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (isRoot) {
      standardFetch({
        url: EdgeUrls.ContentTreeRootView.replace(
          ':id',
          activeView.viewId.toString(),
        ),
      }).then((response) => {
        setStackContents(response);
        setIsLoading(false);
      });
    } else {
      standardFetch({
        url: EdgeUrls.CollectionChildren.replace(':id', stackId.toString()),
      }).then((response) => {
        setStackContents(response);
        setIsLoading(false);
      });
    }
  }, [activeView.viewId, isRoot, stackId, treeUpdated]);

  function handleStackClick(item): void {
    setActiveItem(item.id);
    handleBranching(item, depth);
  }

  return (
    <>
      <div className={`card-stack-wrapper ${isRoot && 'is-root'}`}>
        <div className="card-stack-title">{`${
          isRoot
            ? 'nav item: ' + activeView.name
            : simpleListsStore.collections.get(stackId)?.name
        }`}</div>
        {isLoading && (
          <div className="pt-5">
            <Loader />
          </div>
        )}
        {!isLoading && (
          <RenderBody
            {...props}
            activeItem={activeItem}
            stackContents={stackContents}
            handleStackClick={handleStackClick}
          />
        )}
      </div>
    </>
  );
};

export default ContentTreeCardStack;
