/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */

import * as URI from 'urijs';

export function getUrlParameter(name: string): string {
  const uri: URI = new URI();
  return uri.search(true)[name] || null;
}

export function getAuthUrl(
  provider: string,
  tenantId: number,
): string {
  const uri: URI = new URI(
    `${process.env.REACT_APP_API_GATEWAY_AUTH}/authorize`,
  );

  const queryParams: {
    identity_provider: string;
    redirect_uri: string
    tenant_id: number;
  } = {
    identity_provider: provider,
    redirect_uri: `${window.location.origin}/auth/code`,
    tenant_id: tenantId,
  };

  return uri.addQuery(queryParams).toString();
}

export function getStorySlabLoginUrl(
  subdomain: string,
  tenantId: number,
): string {
  return getAuthUrl('StorySlabGSuite', tenantId);
}

export function replaceRedirectUrl(url: string, redirectUrl: string): string {
  return url.replace('{{redirect_url}}', encodeURIComponent(redirectUrl));
}
