import React from 'react';
import classnames from 'classnames';

interface Props {
  saving: boolean;
}

function SaveStatus(props: Props): JSX.Element {
  const [saving, setIsSaving] = React.useState(null);
  const [hasChanged, setHasChanged] = React.useState(false);
  const updateTimer: React.MutableRefObject<any> = React.useRef(null);
  const isMounted: React.MutableRefObject<any> = React.useRef(false);

  function setUpdate(): void {
    setIsSaving(true);
    updateTimer.current = setTimeout(() => {
      setIsSaving(false);
      updateTimer.current = null;
    }, 1000);
  }

  React.useEffect(() => {
    if (!updateTimer.current && isMounted.current) {
      setUpdate();

      if (!hasChanged) {
        setHasChanged(true);
      }
    }
  }, [props.saving, hasChanged]);

  React.useEffect(() => {
    isMounted.current = true;
    return (): void => {
      isMounted.current = false;
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
      }
    };
  }, []);

  return (
    <>
      {isMounted.current && hasChanged && (
        <span className="indicator-save-status">
          <i
            className={classnames('far fa-check-circle', { active: !saving })}
          ></i>
          <i
            className={classnames('far fa-spinner fa-spin', {
              active: saving,
            })}
          ></i>
          {saving === true ? 'Saving' : 'Saved'}
        </span>
      )}
    </>
  );
}

export default SaveStatus;
