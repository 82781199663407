import React from 'react';
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import classnames from 'classnames';
import _ from 'lodash';

interface InternalState {
  searchTerm: string;
}

interface InternalProps {
  searchIcons: (string) => void;
}

class BayHeader extends React.Component<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      searchTerm: '',
    };
  }

  private renderSearch(): JSX.Element {
    const { searchIcons } = this.props;
    return (
      <Form className={classnames('navbar-search form-inline mr-sm-3')}>
        <FormGroup className="mb-0">
          <InputGroup className="input-group-alternative input-group-merge">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={this.state.searchTerm}
              placeholder="Search"
              type="text"
              onChange={(e): void => {
                this.setState({ searchTerm: e.target.value.toLowerCase() });
                searchIcons(_.kebabCase(e.target.value.toLowerCase()));
              }}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    );
  }

  public render(): JSX.Element {
    return <>{this.renderSearch()}</>;
  }
}

export default BayHeader;
