import React, { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import dayjs from 'dayjs';
import Routes from 'enums/routes.enum';
import { observer } from 'mobx-react';
import { collectionViewsStore, domainStore } from 'stores';

import { View } from '@storyslab/storyslab.common.models';

interface Props {
  collectionView: View;
}

function HamburgerMenu(props: {
  children: JSX.Element | Array<JSX.Element>;
  collectionId: number;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <>
      <div
        className="backsplash"
        onClick={(): void => {
          props.setIsMenuOpen(false);
        }}
      ></div>
      <div className={`hamburger-menu-card`}>{props.children}</div>
    </>
  );
}

function DeleteOption(props: { collectionView: any }): JSX.Element {
  return (
    <>
      <div
        className="menu-option"
        onClick={(): void => {
          window.siteWideComponent.show({
            approve: () => {
              collectionViewsStore.deleteItem({
                domainStore,
                id: props.collectionView.id,
              });
            },
            body: `Are you sure you want to delete ${props.collectionView.name}?`,
            title: 'Delete Confirmation',
          });
        }}
      >
        Delete
      </div>
    </>
  );
}

const CollectionViewRow: any = observer((props: Props) => {
  const { collectionView } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div className={`detail-view-wrapper ${isMenuOpen && 'hamburger-open'}`}>
        <Link
          to={Routes.ContentCollectionViewLayoutConfiguration.replace(
            ':id',
            collectionView.id.toString(),
          )}
        >
          <div className="detail-row-wrapper py-3">
            <div className="detail-row-body">
              <Col md="9" lg="9">
                <div style={styles.text}>{collectionView.name}</div>
              </Col>
              <Col md="2" lg="2">
                <div style={{ ...styles.colSentOn, ...styles.text }}>
                  {dayjs(collectionView.updated).format('MM / DD / YYYY')}
                </div>
              </Col>
              <Col
                md="1"
                lg="1"
                onClick={(e): void => {
                  e.preventDefault();
                }}
                style={{ color: '#808080', textAlign: 'right' }}
                className="hamburger-menu-card-contain"
              >
                <i
                  className="fal fa-ellipsis-v"
                  style={{ padding: ' 0 .5rem' }}
                  onClick={(): void => {
                    setIsMenuOpen(!isMenuOpen);
                  }}
                />
                {isMenuOpen && (
                  <HamburgerMenu
                    collectionId={collectionView.id}
                    setIsMenuOpen={setIsMenuOpen}
                  >
                    <DeleteOption collectionView={collectionView} />
                  </HamburgerMenu>
                )}
              </Col>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
});

const styles: any = {
  colExpiration: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  colFiles: {
    display: 'flex',
    justifyContent: 'center',
  },
  colRecipients: {},
  colSentBy: {
    display: 'flex',
    flexDirection: 'column',
  },
  colSentOn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  text: {
    color: '#808080',
    fontSize: '14px',
    fontWeight: 700,
  },
};

export default CollectionViewRow;
