import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Routes from 'enums/routes.enum';

import LinkSource from 'components/Content/Source/LinkSource';
import PublicSource from 'components/Content/Source/PublicSource';

interface Props {}

const DropDownMenu: (props: Props) => JSX.Element = (props: Props) => {
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [isAddingPublicFile, setIsAddingPublicFile] = useState(false);

  return (
    <>
      <div style={styles.wrapper}>
        {!isAddingLink && !isAddingPublicFile && (
          <>
            <Link to={Routes.ContentItemsAddSourceComputer}>
              <div style={styles.option}>
                <i style={styles.icon} className="fas fa-upload" />
                <span style={styles.text}>Upload</span>
              </div>
            </Link>
            <div
              style={styles.option}
              onClick={(): void => {
                setIsAddingLink(true);
                setIsAddingPublicFile(false);
              }}
            >
              <i style={styles.icon} className="fas fa-link" />
              <span style={styles.text}>Link</span>
            </div>
            <div
              style={styles.option}
              onClick={(): void => {
                setIsAddingLink(false);
                setIsAddingPublicFile(true);
              }}
            >
              <i style={styles.icon} className="fas fa-universal-access" />
              <span style={styles.text}>Public file on web</span>
            </div>
          </>
        )}
        {isAddingLink && (
          <div style={styles.viewWrap}>
            <LinkSource
              reset={(): void => {
                window.location.reload();
              }}
            />
          </div>
        )}
        {isAddingPublicFile && (
          <div style={styles.viewWrap}>
            <PublicSource
              reset={(): void => {
                window.location.reload();
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const styles: any = {
  icon: {
    color: '#4d4d4d',
    fontSize: '26px',
    marginRight: '10px',
  },
  option: {
    cursor: 'pointer',
    padding: '.5rem 1rem',
  },
  text: {
    color: '#7c7d80',
    fontSize: '12px',
    fontWeight: 400,
  },
  viewWrap: {
    padding: '1rem',
  },
  wrapper: {
    width: '338px',
  },
};

export default DropDownMenu;
