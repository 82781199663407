/* eslint-disable multiline-comment-style */
/* eslint-disable capitalized-comments */
import React, { useState } from 'react';
import { uiStore } from 'stores';

import SimpleToggle from 'components/Forms/SimpleToggle';

import { AppSubFeatureSectionConfig } from './FeatureCardModels';

interface Props extends AppSubFeatureSectionConfig{
  title: string,
  description: string,
  hasToggle: boolean,
  featureKey?: string,
  onToggle?: (toggleState:boolean, toggleField: string)=>void;
  initialValue: boolean;
  isLocked?: boolean;
}

const AppSubFeatureSection: any = (props: React.PropsWithChildren<Props>) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { description, hasToggle, title, featureKey, initialValue, isLocked, onToggle } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={'content-row' + (isLocked ? "" : " clickable")} style={styles.contain}>
      <div
        onClick={(): void => {
          !isLocked && setIsOpen(!isOpen);
        }}
        style={styles.collapsedControl}
      >
        {hasToggle && (
          <div
            style={styles.toggle}
            onClick={(e): void => {
              e.stopPropagation();
            }}
          >
            <SimpleToggle
              didCheckOnLoad={initialValue}
              handleChange={(value: boolean):void=>{onToggle && onToggle(value, featureKey)}}
            />
          </div>
        )}
        <div style={styles.title}>{title}</div>
        <div style={styles.description}>{description}</div>
        <div
          style={{
            ...styles.cog,
            color: isOpen ? uiStore.common.colors.primary : 'unset',
          }}
        >
          {props.children ? <i className="far fa-cog"></i> : ''}
        </div>
      </div>
      {isOpen && (
        <div className="collapsed-content" style={styles.collapsedContent}>
          {props.children}
        </div>
      )}
      {isLocked && <div style={styles.lockedOverlay}/>}
    </div>
  );
};

const styles: any = {
  cog: {
    fontSize: '24px',
    textAlign: 'right',
    width: '75px',
  },
  collapsedContent: {},
  collapsedControl: {
    alignItems: 'center',
    borderTop: '1px solid #d2d1d1',
    display: 'flex',
    flex: 1,
    padding: '18px 0',
  },
  contain: {
    cursor: 'pointer',
    paddingLeft: '40px',
    position:'relative'
  },
  description: {
    color: '#808080',
    flex: 2,
    fontSize: '12px',
    fontWeight: 400,
  },
  lockedOverlay: {
    background: 'white',
    bottom:0,
    left:0,
    opacity: .5,
    position:'absolute',
    right:0,
    top:0,
  },
  title: {
    color: '#999',
    flex: 1,
    fontSize: '10px',
    fontWeight: 700,
  },
  toggle: {
    width: '75px',
  },
};

export default AppSubFeatureSection;
