import { DomainStoreModel } from 'models/domain.model';
import { domainStore } from 'stores';

import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export class ContentService extends BaseService {
  constructor() {
    super({
      create: EdgeUrls.ContentItems,
      delete: EdgeUrls.ContentItem,
      fetch: EdgeUrls.ContentItems,
      update: EdgeUrls.ContentItem,
    });
  }
}

export async function uploadContentItemThumbnail({
  contentItemId,
  meta,
  domainStore,
}: {
  contentItemId: number;
  meta: { [key: string]: any };
  domainStore: DomainStoreModel;
}): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: meta,
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.ContentItemThumbnailUpload.replace(
      ':id',
      contentItemId.toString(),
    ),
  });
  return resp;
}

export async function syncContentSource(contentItemId: number): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.ContentItemSyncSource.replace(
      ':id',
      contentItemId.toString(),
    ),
  });
  return resp;
}
