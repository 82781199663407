import React from 'react';
import ReactDOM from 'react-dom';

import Modal from './Modal';

interface InternalProps {
  closeDrawer: () => void;
  newCollectionID: number;
  shouldStopPropagation?: boolean;
}

function ModalWrapper(props: InternalProps): JSX.Element {
  const { closeDrawer, newCollectionID, shouldStopPropagation } = props;

  const root: any = document.getElementById('root');
  const el: any = document.createElement('div');
  root.appendChild(el);

  return ReactDOM.createPortal(
    <Modal
      closeDrawer={closeDrawer}
      newCollectionID={newCollectionID}
      shouldStopPropagation={shouldStopPropagation}
    />,
    el,
  );
}

export default ModalWrapper;
