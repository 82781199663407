import { categoriesStore } from 'stores';

export const headerColumnConfig: any = [
  {
    colProps: {
      lg: '1',
      md: '1',
    },
    isFilterable: false,
    isSortable: false,
    store: categoriesStore,
    title: 'Icon',
  },
  {
    colProps: {
      lg: '4',
      md: '4',
    },
    isFilterable: false,
    isSortable: true,
    store: categoriesStore,
    title: 'Display Name',
  },
  {
    colProps: {
      lg: '4',
      md: '4',
    },
    isFilterable: false,
    isSortable: true,
    store: categoriesStore,
    title: 'Description',
  },
  {
    colProps: {
      lg: '3',
      md: '3',
    },
    isFilterable: true,
    isSortable: true,
    store: categoriesStore,
    title: 'Last Updated',
  },
];
