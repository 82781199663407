/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { appNavigationStore, domainStore, navItemViewsStore } from 'stores';

import GroupSelect from 'components/ApplicationNavigation/Configuration/GroupSelect';
import StorySlabIconPicker from 'components/IconPicker/StorySlabIconPicker';
import Loader from 'components/Indicators/Loader';

interface Props {
  viewId: string;
}

const LayoutMetaEditor: (props: Props) => JSX.Element = observer(
  (props: Props) => {
    const { viewId } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState<string>();
    const [navItemId, setNavItemId] = useState<number>();

    useEffect(() => {
      Promise.all([
        appNavigationStore.fetchItems({ domainStore }),
        navItemViewsStore.fetchItems({ domainStore }),
      ]).then(() => {
        for (const item of Array.from(appNavigationStore.items.values())) {
          if (item.viewId === parseInt(viewId)) {
            setNavItemId(item.id);
            setName(appNavigationStore.items.get(item.id).name);
            setIsLoading(false);
            break;
          }
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (isLoading) {
        return;
      }
      appNavigationStore.updateItem({
        body: { name },
        domainStore,
        id: navItemId,
      });
      navItemViewsStore.updateItem({
        body: { name },
        domainStore,
        id: parseInt(viewId),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    function validateLength(name: string): void {
      if (name.length < 3) {
        setName('');
      }
    }

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <div className="card-container" style={{ overflow: 'visible' }}>
          <div style={styles.header}>
            <div>
              <StorySlabIconPicker
                defaultIcon={'folder'}
                iconName={`far fa-${
                  appNavigationStore.items.get(navItemId).icon
                }`}
                persistIconChange={(icon): void => {
                  appNavigationStore.updateItem({
                    body: { icon },
                    domainStore,
                    id: navItemId,
                  });
                }}
                style={{ color: '#0065a3', fontSize: '1.5rem' }}
              />
            </div>
            <input
              className="layout-nav-item-name"
              onChange={(e): void => {
                setName(e.target.value);
              }}
              onBlur={(e): void => {
                validateLength(e.target.value);
              }}
              placeholder="must be 3 or more characters"
              size={name.length + 1}
              value={name}
            />
          </div>
          <div>
            <div style={styles.dropdownLabel}>shows up in these groups</div>
            <GroupSelect navItemId={navItemId} />
          </div>
        </div>
      </>
    );
  },
);

const styles: any = {
  dropdownLabel: {
    fontSize: '12px',
    fontWeight: 700,
    margin: '0 0 5px 3px',
    textTransform: 'uppercase',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
  },
};

export default LayoutMetaEditor;
