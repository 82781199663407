import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { DropzoneContexts } from 'enums/dropzone.enum';

import Dropzone from 'components/Dropzone/Dropzone';

interface InternalProps {
  activeList: number;
  closeDrawer: () => void;
}

interface ModalProps {}

function FileUploader(props: InternalProps): JSX.Element {
  const { activeList, closeDrawer } = props;
  const root: any = document.getElementById('root');
  const el: any = document.createElement('div');
  root.appendChild(el);
  const Modal: FunctionComponent<ModalProps> = (props) => {
    return (
      <div className="tree-uploader-wrapper">
        <div
          className="tree-uploader-bg"
          onClick={(): void => {
            closeDrawer();
          }}
        ></div>
        <div className="dropzone-wrapper dropzone">
          <Dropzone
            activeList={activeList}
            closeModal={closeDrawer}
            context={DropzoneContexts.ContentTree}
          />
        </div>
      </div>
    );
  };

  return ReactDOM.createPortal(<Modal />, el);
}

export default FileUploader;
