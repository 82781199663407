import { BundlesService } from '../services/bundles.service';

import { BaseStore } from './base.store';

export class BundlesStore extends BaseStore<any> {
  constructor() {
    super(new BundlesService(), 'BundlesStore', 'Bundles');
  }
}

export const bundlesStore: BundlesStore = new BundlesStore();
