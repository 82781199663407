import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import dayjs from 'dayjs';
import Routes from 'enums/routes.enum';
import { getByShortCode } from 'services/bundles.service';
import { uiStore } from 'stores';

import { Bundle } from '@storyslab/storyslab.common.models';
import Meta from 'components/Engagement/Bundles/Detail/Meta';
import Tabs from 'components/Engagement/Bundles/Detail/Tabs';
import GhostLoader from 'components/Indicators/GhostLoader';

interface Props {}

const BundleDetail: any = (props: Props) => {
  const { shortCode } = useParams<{ shortCode: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [bundle, setBundle] = useState<Bundle>();
  const [contents, setContents] = useState<Array<Bundle>>();

  useEffect(() => {
    getByShortCode(shortCode).then((response) => {
      console.log(response);
      setBundle(response.bundle);
      setContents(response.contentItems);
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <i className="fas fa-paper-plane" />{' '}
          {isLoading ? (
            <GhostLoader style={{ display: 'inline-block' }} />
          ) : (
            <>
              <Link to={Routes.EngagementBundles}>Share+ Bundles</Link> :{' '}
              {`${bundle.userData.firstName} ${
                bundle.userData.lastName
              } ${dayjs(bundle.sentOn).format('DD MMM YYYY')}`}
            </>
          )}
        </h4>
        <div style={styles.wrapper}>
          <>
            <Meta bundle={bundle} isLoading={isLoading} />
            <Tabs bundle={bundle} isLoading={isLoading} contentSet={contents} />
          </>
        </div>
      </Container>
    </>
  );
};

const styles: any = {
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: '1rem',
    boxShadow: '0 0 10px #c3c3c3',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    zIndex: 1,
  },
};

export default BundleDetail;
