import React, { useState } from 'react';

import { ContentItem } from '@storyslab/storyslab.common.models';
import FileUpload from 'components/Content/Source/FileUpload';
import LinkSource from 'components/Content/Source/LinkSource';
import PublicSource from 'components/Content/Source/PublicSource';
import SourceMenuList from 'components/Content/Source/SourceMenuList';

interface Props {
  contentItem: ContentItem;
  reset: () => void;
}

interface ViewForUploadProps {
  contentItem: any;
  reset: () => void;
  uploadViewKey: string;
}

const ViewForUpload: (props: ViewForUploadProps) => JSX.Element = (
  props: ViewForUploadProps,
) => {
  const { contentItem, reset, uploadViewKey } = props;

  switch (uploadViewKey) {
    case 'dropzone':
      return <FileUpload reset={reset} itemId={contentItem.id} />;
    case 'link':
      return <LinkSource item={contentItem} reset={reset} />;
    case 'public-file':
      return <PublicSource item={contentItem} reset={reset} />;
    default:
      return <></>;
  }
};

const SourceMenu: (props: Props) => JSX.Element = (props: Props) => {
  const { contentItem, reset } = props;

  const [uploadViewKey, setUploadViewKey] = useState('');

  return (
    <>
      <div className="card-container source-menu-wrapper">
        {uploadViewKey === '' && (
          <SourceMenuList setUploadViewKey={setUploadViewKey} />
        )}
        {uploadViewKey !== '' && (
          <ViewForUpload
            contentItem={contentItem}
            reset={reset}
            uploadViewKey={uploadViewKey}
          />
        )}
      </div>
    </>
  );
};

export default SourceMenu;
