export function knownFileTypes(): string {
  // Comma-delimited list for <Dropzone /> accept prop
  // eslint-disable-next-line no-multi-str
  return '.mp4,\
    .m4v,\
    .mov,\
    .mpeg,\
    .avi,\
    .jpg,\
    .jpeg,\
    .png,\
    .gif,\
    .bmp,\
    .pdf,\
    .doc,\
    .docx,\
    .odt,\
    .ods,\
    .rtf,\
    .xls,\
    .xlsx,\
    .csv,\
    .odf,\
    .ppt,\
    .pptx,\
    .pps,\
    .odp,\
    .zip,\
    .spn,\
    .rfa,\
    .dwg,\
    .txt,\
    .csv,\
  ';
}

export function knownImageFileTypes(): string {
  // Comma-delimited list for <Dropzone /> accept prop
  // eslint-disable-next-line no-multi-str
  return '.jpg,\
    .jpeg,\
    .png,\
    .gif,\
    .bmp,\
  ';
}
