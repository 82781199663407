/* eslint-disable max-lines-per-function */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { UiStoreModel } from 'models/ui.model';

import Loader from 'components/Indicators/Loader';

interface InternalState {}

interface Props {
  uiStore?: UiStoreModel;
  theme: any;
  input: any;
  previews: any;
  submitButton: any;
  dropzoneProps: any;
  inputLabelWithFiles: any;
  files: any;
  extra: { maxFiles: any };
}

@inject('uiStore')
@observer
class InlineLayout extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { input, dropzoneProps, files } = this.props;

    return (
      <div {...dropzoneProps}>
        {files.length > 0 && <Loader style={styles.loader} />}
        {files.length === 0 && input}
      </div>
    );
  }
}

const styles: any = {
  loader: {
    backgroundColor: '#fff',
    flex: 1,
    height: '100%',
    position: 'relative',
    width: '100%',
    zIndex: 3,
  },
};

export default InlineLayout;
