import React, { useEffect, useState } from 'react';
import { Card, Container } from 'reactstrap';
import { observer } from 'mobx-react';
import { categoriesStore, domainStore, uiStore } from 'stores';

import CategoryRow from 'components/Categories/CategoryRow';
import { headerColumnConfig } from 'components/Categories/HeaderColumnsConfig';
import EmptyTable from 'components/Forms/EmptyTable';
import GridTableCardFooter from 'components/GridTable/CardFooter';
import GridTableCardHeader from 'components/GridTable/CardHeader';
import Header from 'components/GridTable/Header';
import GhostLoader from 'components/Indicators/GhostLoader';

interface Props {}

const CategoriesTable: any = observer((props: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    categoriesStore.fetchItems({ domainStore }).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <i className="fas fa-shapes" /> Categories
        </h4>

        <Card className="p-1 pb-5">
          <GridTableCardHeader
            actionButtonTitle="Add Category"
            actionButtonIcon="fa-plus"
            actionButtonHandler={(): void => {
              categoriesStore.createItem({ domainStore });
            }}
            store={categoriesStore}
          />

          <>
            <Header columnConfigs={headerColumnConfig} />
            {isLoading && (
              <GhostLoader style={{ height: '2rem' }} width={'100%'} />
            )}
            {!isLoading && categoriesStore.count === 0 && (
              <EmptyTable
                title={'No categories yet...'}
                text={'Start by clicking add in the top right!'}
              />
            )}
            {!isLoading &&
              Array.from(categoriesStore.items.values()).map((category) => {
                return (
                  <CategoryRow
                    category={category}
                    key={`category-${category.id}`}
                  />
                );
              })}
          </>

          <GridTableCardFooter
            store={categoriesStore}
            storeInstance={categoriesStore}
          />
        </Card>
      </Container>
    </>
  );
});

export default CategoriesTable;
