import React from 'react';

export interface SimpleSelectOption{
  text: string,
  value: any
}

interface Props {
  handleChange: (boolean, field?: string) => void;
  initialValue?: any;
  options: Array<string | SimpleSelectOption>;
  propStyles?: any;
}

export default function SimpleSelect(props: Props): JSX.Element {
  const { options, propStyles, initialValue, handleChange } = props;

  return (
    <select style={{ ...styles.select, ...propStyles }} defaultValue={initialValue || ''}
      onChange={(e):void=>{handleChange && handleChange(e.target.value)}}>
      {options.map((item, index) => {
        if (typeof(item) === "string"){
          return <option key={`${item}-${index}`}>{item}</option>;
        }
        else{
          return <option key={`${item}-${index}`} value={item.value}>{item.text}</option>
        }
      })}
    </select>
  );
}

const styles: any = {
  select: {
    border: '1px solid #4d4d4d',
    borderRadius: '20px',
    flex: 1,
    minWidth: '200px',
    padding: '5px 15px',
  },
};
