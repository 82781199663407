/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
import React from 'react';
// import dayjs from 'dayjs';
import { domainStore } from 'stores';

import Global from '../../components/Global/Global';
import Authenticated from '../../routes/Authenticated';
import Unauthenticated from '../../routes/Unauthenticated';

interface Props extends React.Component {
  isAuthenticated: boolean;
}

const Resolver: (props: Props) => JSX.Element = (props: Props) => {
  // console.log('domainStore.user?.deviceId: ', !!domainStore.user?.deviceId);
  // console.log('domainStore.entitlements: ', !!domainStore.entitlements);
  // console.log('domainStore.auth.accessToken: ', !!domainStore.auth.accessToken);
  // console.log('domainStore.auth.idToken: ', !!domainStore.auth.idToken);
  // console.log(
  //   'auth.expiresAt: ',
  //   !!dayjs().isBefore(domainStore.auth.expiresAt),
  // );
  // console.log('refresh token?: ', !!domainStore.auth.refreshToken);
  if (domainStore.auth.refreshToken) {
    // refreshToken({
    //   grant_type: 'refresh_token',
    //   identity_provider: domainStore.identityProvider,
    //   redirect_uri: `${process.env.REACT_APP_API_GATEWAY_AUTH}/redirect`,
    //   refresh_token: domainStore.auth.refreshToken,
    //   tenant_id: domainStore.tenantId,
    // });
  }

  return (
    <>
      <Global />
      {props.isAuthenticated ? (
        <Authenticated {...(props as any)} />
      ) : (
        <Unauthenticated {...(props as any)} />
      )}
    </>
  );
};

export default Resolver;
