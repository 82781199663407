import React from 'react';

const PreviewComponent: (props: any) => JSX.Element = (props: any) => {
  const { name } = props.meta;

  return (
    <div style={styles.wrapper}>
      <span style={styles.text}>
        <b>Uploading:</b> {name}
      </span>
    </div>
  );
};

const styles: any = {
  text: {
    fontSize: '14px',
    fontWeight: 400,
  },
  wrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

export default PreviewComponent;
