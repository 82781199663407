import React from 'react';
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import { RenderKeyHelper } from '../../helpers/render-key.helper';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper('SplitButton');

interface InternalState {
  isDropdownOpen: boolean;
}

interface ButtonProps {
  handleClick: () => void;
  isDisabled?: boolean;
  title: string;
}

interface Props {
  color?: string;
  dropdownButtons: Array<ButtonProps>;
  primaryButtons: Array<ButtonProps>;
  validationNotice?: string;
}

class SplitButton extends React.Component<Props, InternalState> {
  public state: InternalState = {
    isDropdownOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  private toggle(): void {
    this.setState((prevState: InternalState) => {
      return {
        isDropdownOpen: !prevState.isDropdownOpen,
      };
    });
  }

  private renderPrimaryButtons(): JSX.Element {
    const { color, primaryButtons } = this.props;

    return (
      <>
        {primaryButtons.map(
          (button: ButtonProps, index: number): JSX.Element => {
            return (
              <Button
                className={`${button.isDisabled ? 'disabled' : ''}`}
                key={renderKeyHelper.generate(
                  {
                    ...button,
                    index,
                  },
                  'PrimaryButton',
                )}
                color={color || 'default'}
                type="button"
                onClick={(): void => !button.isDisabled && button.handleClick()}
              >
                {button.title}
              </Button>
            );
          },
        )}
      </>
    );
  }

  private renderDropdownButtons(): JSX.Element {
    const { color, dropdownButtons } = this.props;
    const { isDropdownOpen } = this.state;

    return (
      <ButtonDropdown isOpen={isDropdownOpen} toggle={this.toggle}>
        <DropdownToggle
          caret
          color={color || 'default'}
          type="button"
        ></DropdownToggle>
        <DropdownMenu right>
          {dropdownButtons.map(
            (button: ButtonProps, index: number): JSX.Element => (
              <DropdownItem
                disabled={button.isDisabled}
                key={renderKeyHelper.generate(
                  {
                    ...button,
                    index,
                  },
                  'DropdownButton',
                )}
                onClick={(): void => !button.isDisabled && button.handleClick()}
              >
                {button.title}
              </DropdownItem>
            ),
          )}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <ButtonGroup>
          {this.renderPrimaryButtons()}
          {this.renderDropdownButtons()}
        </ButtonGroup>
      </>
    );
  }
}

export default SplitButton;
