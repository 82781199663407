/* eslint-disable @typescript-eslint/no-require-imports */
/*
 *!
 *
 *=========================================================
 * Argon Dashboard PRO React - v1.1.0
 *=========================================================
 *
 * Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by Creative Tim
 *
 *=========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 */
import React from 'react';
// Nodejs library that concatenates classes
import { inject, observer } from 'mobx-react';
import { DomainStoreModel } from 'models/domain.model';

import ForgotPassword from 'components/Auth/ForgotPassword';
import { GenericLogin } from 'components/Auth/GenericLogin';
import RequestCredentials from 'components/Auth/RequestCredentials';
import { RequiredAttributes } from 'components/Auth/RequiredAttributes';
import Loader from 'components/Indicators/Loader';

import StorySlabLogo from '../../assets/img/brand/pi-16x16-w.png';
import { ProviderButtonList } from '../../components/Auth/ProviderButtonList';
import { StorySlabLogin } from '../../components/Auth/StorySlabLogin';
import { createBackgroundImageCss } from '../../helpers/component-color.helper';
import { UiStoreModel } from '../../models/ui.model';
import { toggleStorySlabLogin } from '../../services/auth.service';

interface InternalState {
  [key: string]: any;
  isError: boolean;
  isForgotPassword: boolean;
  isLoading: boolean;
  isRequestCredentials: boolean;
  isReset: boolean;
  password: string;
  primaryColor: string;
  requiredAttributes: Array<string>;
  shouldShowStorySlabLogin: boolean;
  titleText: string;
  username: string;
}

interface LoginProps {
  domainStore: DomainStoreModel;
  uiStore: UiStoreModel;
}

@inject('domainStore')
@inject('uiStore')
@observer
class Login extends React.Component<LoginProps, InternalState> {
  public state: InternalState = {
    isError: false,
    isForgotPassword: false,
    isLoading: false,
    isRequestCredentials: false,
    isReset: false,
    password: this.props?.domainStore?.credentials?.password || '',
    primaryColor: '',
    requiredAttributes: null,
    shouldShowStorySlabLogin: false,
    titleText: 'Sign in',
    username: this.props?.domainStore?.credentials?.username || '',
  };

  constructor(props: LoginProps) {
    super(props);
    this.handleShowStorySlabLogin = this.handleShowStorySlabLogin.bind(this);
  }

  private handleChangeFromChild(target: string, value): void {
    this.setState({ [target]: value });
  }

  private handleShowStorySlabLogin(e): void {
    toggleStorySlabLogin(true);
  }

  private loginFlow(): JSX.Element {
    if (this.state.isLoading) {
      return (
        <div className="mb-3 mt-3">
          <Loader isFullPage={false} />
        </div>
      );
    }

    if (this.state.isError) {
      return (
        <>
          <div className="login-simple-text-contain">{`There was a problem with your request.`}</div>
        </>
      );
    }

    if (this.state.requiredAttributes) {
      return (
        <RequiredAttributes
          username={this.state.username}
          password={this.state.password}
          requiredAttributes={this.state.requiredAttributes}
        />
      );
    }

    if (this.state.isReset) {
      return (
        <>
          <div className="login-simple-text-contain">{`We've sent you a little somethin' somethin' that will help you log in.`}</div>
        </>
      );
    }

    if (this.state.isForgotPassword) {
      return (
        <ForgotPassword
          username={this.state.username}
          onChange={(target: string, value: any): void =>
            this.handleChangeFromChild(target, value)
          }
        />
      );
    }

    if (this.state.isRequestCredentials) {
      return <RequestCredentials username={this.state.username} />;
    }

    if (this.props.uiStore.auth.shouldShowStorySlabLogin) {
      return <StorySlabLogin />;
    }

    return (
      <>
        <div className="pi" onDoubleClick={this.handleShowStorySlabLogin}>
          <img alt="pi" src={StorySlabLogo} />
        </div>
        {this.props.uiStore.auth.providers &&
          this.props.uiStore.auth.providers.length > 0 && (
            <div className="provider-button-list">
              <ProviderButtonList
                providers={this.props.uiStore.auth.providers}
              />
            </div>
          )}

        {this.props.uiStore.auth.providers &&
          this.props.uiStore.auth.providers.length > 0 &&
          this.props.uiStore.auth.shouldShowGenericLogin && (
            <div className="login-hr">
              <div>or</div>
            </div>
          )}

        {this.props.uiStore.auth.shouldShowGenericLogin && (
          <GenericLogin
            username={this.state.username}
            password={this.state.password}
            onChange={(target: string, value: any): void =>
              this.handleChangeFromChild(target, value)
            }
          />
        )}
      </>
    );
  }

  public async componentWillUnmount(): Promise<void> {
    this.setState({
      password: '',
      primaryColor: '',
      requiredAttributes: null,
      shouldShowStorySlabLogin: false,
      username: '',
    });
  }

  public render(): JSX.Element {
    return (
      <>
        <div
          className="login"
          style={createBackgroundImageCss(
            this.props.uiStore.auth.backgroundUrl,
          )}
        >
          <div className="login-card-wrapper">
            <div className="login-card">
              <div className="brand-icon-wrapper">
                <img
                  className="brand-icon"
                  src={this.props.uiStore.auth.logoUrl}
                  alt=""
                />
              </div>

              <div className="login-card-title">{this.state.titleText}</div>

              {this.loginFlow()}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Login;
