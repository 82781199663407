/* eslint-disable max-lines-per-function */
import React from 'react';
import { Card, CardFooter, Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import DropDownMenu from 'components/Content/Add/DropDownMenu';
import ContentRow from 'components/Content/ContentRow/ContentRow';
import GridTableCardHeader from 'components/GridTable/CardHeader';
import Header from 'components/GridTable/Header';

import DoubleDatePicker from '../../../components/GridTable/FilterDropdowns/DoubleDatePicker';
import PaginationComponent from '../../../components/GridTable/Pagination';
import { Action } from '../../../models/action.model';
// Core components
import { BaseProps } from '../../../models/base.model';
import { HeaderColumnConfig } from '../../../models/grid-table.model';

interface InternalState {}

interface Props extends BaseProps {}

@inject('simpleListsStore')
@inject('contentStore')
@inject('domainStore')
@inject('uiStore')
@observer
class ContentItems extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];

  constructor(props: Props) {
    super(props);

    this.headerColumnConfigs = [
      {
        colProps: {
          lg: '8',
          md: '6',
        },
        handleAction: (action: Action): void => {
          this.props.contentStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'name',
        store: this.props.contentStore,
        title: 'Display Name',
      },
      {
        colProps: {
          lg: '4',
          md: '6',
        },
        filterDropdown: (
          <DoubleDatePicker
            itemKey={'updated'}
            store={this.props.contentStore.filters}
            handleAction={(action: Action): void => {
              this.props.contentStore.handleTableAction(this.props, action);
            }}
          />
        ),

        handleAction: (action: Action): void => {
          this.props.contentStore.handleTableAction(this.props, action);
        },
        isFilterable: true,
        isSortable: true,
        itemKey: 'updated',
        store: this.props.contentStore,
        title: 'Last Updated',
      },
    ];
  }

  public async componentDidMount(): Promise<void> {
    this.props.contentStore.clearFilters();
    this.props.contentStore.fetchItems(this.props);
  }

  public render(): JSX.Element {
    const { contentStore } = this.props;
    return (
      <>
        <Container className="mt-4 items-page" fluid>
          <h4
            className="mb-4"
            style={{ color: this.props.uiStore.common.colors.primary }}
          >
            <i className="fas fa-photo-video" /> Content Items
          </h4>
          <Row>
            <div className="col">
              <Card className="p-1">
                <GridTableCardHeader
                  actionButtonTitle="Add Content"
                  actionButtonIcon="fa-plus"
                  ActionDropdownMenu={(): JSX.Element => <DropDownMenu />}
                  store={contentStore}
                />

                <Header columnConfigs={this.headerColumnConfigs} />

                {Array.from(contentStore?.items?.values()).map(
                  (item, index) => {
                    return (
                      <ContentRow
                        key={`content-item-row-${item.id}`}
                        contentItem={item}
                      />
                    );
                  },
                )}

                <CardFooter className="py-4">
                  <PaginationComponent
                    store={contentStore}
                    handleAction={(params: any): Promise<void> =>
                      this.props.contentStore.handleTableAction(
                        this.props,
                        params,
                      )
                    }
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ContentItems;
