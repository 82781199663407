import { GroupsService } from '../services/groups.service';

import { BaseStore } from './base.store';

export class GroupsStore extends BaseStore<any> {
  constructor() {
    super(new GroupsService(), 'GroupsStore', 'Groups');
  }
}

export const groupsStore: GroupsStore = new GroupsStore();
