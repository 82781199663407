/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React from 'react';
import {
  IFileWithMeta,
  IUploadParams,
} from 'react-dropzone-uploader';
import * as AppMetaService from 'services/appMeta.service';
import { domainStore } from 'stores';

import SimpleS3Uploader from 'components/Assets/SimpleS3Uploader';

const publicBucket: string = process.env.REACT_APP_PUBLIC_CONTENT_BUCKET;
const privateBucket: string = process.env.REACT_APP_CMS_DATA_BUCKET;

interface Props {
  uploadName?: string;
  isPublic: boolean;
  onComplete?: (s3Key: string) => void;
  onFailed?: (event: any) => void;
  initialS3Key?: string;
}

const AppMetaFileUpload: (props: Props) => JSX.Element = (props: Props) => {
  async function handleGetUploadParams(
    params: IFileWithMeta,
  ): Promise<IUploadParams> {
    const filename: string = params.file.name;

    let extension: string;
    if (filename || filename.indexOf('.') !== -1) {
      const nameParts: Array<string> = filename.split('.');
      extension = nameParts[nameParts.length - 1].toLowerCase();
    }

    return AppMetaService.getAppMetaPresignedPost(domainStore, {
      isPublic: props.isPublic,
      name: props.uploadName ? `${props.uploadName}.${extension}` : filename,
    })
  }

  return (
    <SimpleS3Uploader
      bucket={props.isPublic ? publicBucket : privateBucket}
      onComplete = {props.onComplete}
      onFailed = {props.onFailed}
      initialS3Key={props.initialS3Key}
      handleGetUploadParams={handleGetUploadParams}/>
  );
};

export default AppMetaFileUpload;
