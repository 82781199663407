import React from 'react';

import { RenderKeyHelper } from '../../helpers/render-key.helper';
import { LabeledCheckboxProps } from '../../models/forms.model';

import LabeledCheckbox from './LabeledCheckBox';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper(
  'LabeledCheckBoxGroup',
);

interface InternalState {}

interface Props {
  inputs?: Array<LabeledCheckboxProps>;
}

class LabeledCheckBoxGroup extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { inputs } = this.props;
    return (
      <>
        <div className="labeled-checkbox-group">
          {inputs.map((params: LabeledCheckboxProps, index: number) => {
            return (
              <LabeledCheckbox
                {...params}
                key={renderKeyHelper.generate({
                  id: params.id,
                  index,
                })}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default LabeledCheckBoxGroup;
