import { EdgeUrls } from '../enums/edge.enum';
import { SimpleListFetchConfig } from '../models/simple-lists.model';

export const simpleListsFetchConfig: Array<SimpleListFetchConfig> = [
  {
    key: 'categories',
    url: EdgeUrls.CategorySimpleLists,
  },
  {
    key: 'collections',
    url: EdgeUrls.CollectionSimpleLists,
  },
  {
    key: 'contentItemStatuses',
    url: EdgeUrls.ContentItemStatusesSimpleList,
  },
  {
    key: 'deviceTypes',
    url: EdgeUrls.DeviceTypeSimpleList,
  },
  {
    key: 'tags',
    url: EdgeUrls.TagsSimpleLists,
  },
  {
    key: 'groups',
    url: EdgeUrls.GroupSimpleList,
  },
  {
    key: 'userEntitlements',
    url: EdgeUrls.UserEntitlementsSimpleList,
  },
  {
    key: 'userStatuses',
    url: EdgeUrls.UserStatusesSimpleList,
  },
  {
    key: 'userRoles',
    url: EdgeUrls.UserRoleSimpleList,
  },
];
