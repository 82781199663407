import { mapCommaSeparatedStringToIntArray } from '@storyslab/storyslab.common.helpers';
import { PayloadActions } from '@storyslab/storyslab.common.models';

/**
 * @description Dear future self, Someday you are going to look at this and ask yourself
 * who the fuck wrote this? I am looking at you @JoshJ. This function was written to
 * fulfill a very simple purpose, to map a denormalized State based on a model like ContentItemUpdate
 * containing an ActionPayload for a key like collectionIds to a normalized State  based on
 * a model like <ContentItem>. The issue is that the local DB does not want an ActionPayload.
 *
 * TLDR: Map ActionPayload to normalized array of ids updating the original object.
 *
 * @param prevState The current or normalized state the local db expects.
 * @param actionState The denormalized state may include a PayloadAction for some of the keys.
 *
 * @example
 * public async updateContentItem(params: {
 *  body: ContentItemUpdate;
 *  domainStore: DomainStoreModel;
 *  itemId: number;
 *}): Promise<any> {
 *  const { body, itemId } = params;
 *
 *  const contentItem: ContentItem = this.contentItems.get(itemId);
 *  this.contentItems.set(itemId, {
 *    ...contentItem,
 *    ...normalizeActionPayloadItemState<ContentItem, ContentItemUpdate>(
 *      contentItem,
 *      body,
 *    ),
 *    updated: new Date(),
 *  });
 *
 *  await updateContentItem(params);
 *}
 *
 */
export function normalizeActionPayloadInState<
  NormalizedState,
  StateContainingActionPayload,
>(
  prevState: NormalizedState,
  actionState: StateContainingActionPayload,
): NormalizedState {
  type Keys = keyof typeof actionState;
  type Values = typeof actionState[Keys];

  // Set the item here as we are going to immutably update it
  let item: NormalizedState = prevState;

  // Loop over the items in the action or update state.
  for (const key in actionState) {
    if (Object.prototype.hasOwnProperty.call(actionState, key)) {
      const value: Values = actionState[key];

      // Hopefully we don't revisit this...
      if (value === null) {
        item = { ...item, viewId: null };
        return item;
      }

      // If this value is an ActionPayload map it to a normalized id array
      if ((value as any).action && (value as any).ids) {
        const ids: Array<number> = mapCommaSeparatedStringToIntArray(
          (value as any).ids,
        );

        if ((value as any).action === PayloadActions.ADD) {
          item = {
            ...item,
            [key]: [...(prevState[key as any] || []), ...ids],
          };
        }

        if ((value as any).action === PayloadActions.REMOVE) {
          item = {
            ...item,
            [key]: (prevState[key as any] || []).filter(
              (id: number) => ids.indexOf(id) === -1,
            ),
          };
        }
      } else {
        item = {
          ...item,
          [key]: value,
        };
      }
    }
  }

  return item;
}
