import React from 'react';
import { Card, Container, Row } from 'reactstrap';
import { SortOrder } from 'enums/grid-table.enum';
import { inject, observer } from 'mobx-react';
import { domainStore } from 'stores';

import CollectionRow from 'components/Collections/CollectionRow/CollectionRow';
import Header from 'components/GridTable/Header';

import GridTableCardFooter from '../../../components/GridTable/CardFooter';
import GridTableCardHeader from '../../../components/GridTable/CardHeader';
import DoubleDatePicker from '../../../components/GridTable/FilterDropdowns/DoubleDatePicker';
import { Action, ActionTypes } from '../../../models/action.model';
// Core components
import { BaseProps } from '../../../models/base.model';
import { HeaderColumnConfig } from '../../../models/grid-table.model';

interface InternalState {}

interface Props extends BaseProps {}

@inject('collectionsStore')
@inject('domainStore')
@inject('uiStore')
@observer
class ContentCollections extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];
  constructor(props: Props) {
    super(props);

    this.headerColumnConfigs = [
      {
        colProps: {
          lg: '8',
          md: '8',
        },
        handleAction: (action: Action): void => {
          this.props.collectionsStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'name',
        store: this.props.collectionsStore,
        title: 'Display Name',
      },
      {
        colProps: {
          lg: '4',
          md: '4',
        },
        filterDropdown: (
          <DoubleDatePicker
            itemKey={'updated'}
            store={this.props.collectionsStore.filters}
            handleAction={(action: Action): void => {
              this.props.collectionsStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.collectionsStore.handleTableAction(this.props, action);
        },
        isFilterable: true,
        isSortable: true,
        itemKey: 'updated',
        store: this.props.collectionsStore,
        title: 'Last Updated',
      },
    ];
  }

  public async componentDidMount(): Promise<void> {
    this.props.collectionsStore.clearFilters();
    this.props.collectionsStore.fetchItems(this.props);
  }

  public render(): JSX.Element {
    const { collectionsStore } = this.props;

    return (
      <>
        <Container className="mt-4" fluid>
          <h4
            className="mb-4"
            style={{ color: this.props.uiStore.common.colors.primary }}
          >
            <i className="fas fa-sitemap" /> Collections
          </h4>
          <Row>
            <div className="col">
              <Card className="p-1">
                <GridTableCardHeader
                  actionButtonTitle="Add Collection"
                  actionButtonIcon="fa-plus"
                  actionButtonHandler={(): void => {
                    collectionsStore.createItem({ domainStore });
                  }}
                  store={collectionsStore}
                />

                {collectionsStore.filters.size > 0 && (
                  <div className="clear-filter-contain">
                    <div
                      onClick={(): void => {
                        collectionsStore.clearFilters(this.props);
                        collectionsStore.handleTableAction(this.props, {
                          payload: { updated: SortOrder.ASC },
                          type: ActionTypes.SEARCH,
                        });
                        collectionsStore.handleTableAction(this.props, {
                          payload: '',
                          type: ActionTypes.SORT,
                        });
                      }}
                    >
                      <i className="fas fa-times"></i>{' '}
                      {`Remove ${collectionsStore.filters.size} filter${
                        collectionsStore.filters.size === 1 ? '' : 's'
                      }`}
                    </div>
                  </div>
                )}

                <Header columnConfigs={this.headerColumnConfigs} />

                {Array.from(collectionsStore?.items?.values()).map(
                  (item, index) => {
                    return (
                      <CollectionRow
                        key={`collection-row-${item.id}`}
                        collection={item}
                        isDroppedOnLoad={item.id === collectionsStore.newItemId}
                      />
                    );
                  },
                )}

                <GridTableCardFooter
                  store={collectionsStore}
                  storeInstance={this.props.collectionsStore}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default ContentCollections;
