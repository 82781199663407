import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { simpleListsStore } from 'stores';

interface Props {
  setEntitlements: Dispatch<SetStateAction<any>>;
}

function ModalSearch(props: Props): JSX.Element {
  const [searchText, setSearchText] = useState('');

  const debounceTextField: DebouncedFunc<(searchText: string) => void> =
    React.useMemo(
      () =>
        debounce((searchText) => {
          const allEntitlements: any = [];
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [id, item] of simpleListsStore.userEntitlements) {
            if (
              item.name.toLowerCase().includes(searchText) ||
              item.area.toLowerCase().includes(searchText)
            ) {
              allEntitlements.push(item);
            } else {
              if (item.description) {
                if (item.description.toLowerCase().includes(searchText)) {
                  allEntitlements.push(item);
                }
              }
            }
          }
          props.setEntitlements(allEntitlements);
        }, 750),
      [props],
    );

  useEffect(() => {
    debounceTextField(searchText.toLowerCase());
  }, [debounceTextField, searchText]);

  return (
    <div style={styles.wrapper}>
      <i className="far fa-search" />
      <input
        onChange={(e): void => {
          setSearchText(e.target.value);
        }}
        placeholder={'Search entitlements...'}
        style={styles.input}
        type="text"
        value={searchText}
      />
    </div>
  );
}

const styles: any = {
  input: {
    border: 'none',
    color: '#b3b3b3',
    paddingLeft: '15px',
    width: '300px',
  },
  wrapper: {
    borderRadius: '28px',
    boxShadow: '0 0 15px #999',
    color: '#b3b3b3',
    display: 'inline-block',
    margin: '20px',
    padding: '10px',
  },
};

export default ModalSearch;
