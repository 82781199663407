import React from 'react';
import { Col } from 'reactstrap';

import { UserStatus } from '@storyslab/storyslab.common.models';

interface InternalState {}

interface Props {
  columnProps?: { [key: string]: string };
  isCentered?: boolean;
  label?: string;
  status: keyof typeof UserStatus;
}

class BadgeColumn extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { columnProps, label, status } = this.props;

    return (
      <>
        <Col
          {...columnProps}
          className="item-row-horizontal"
          style={{ alignItems: 'center', display: 'flex', flex: 3 }}
        >
          {label && (
            <span
              style={{
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              {label}
            </span>
          )}
          <div className={`text-centered`}>
            {status && (
              <span
                className={`row-badge ${String(
                  UserStatus[status],
                ).toLowerCase()}`}
                style={{
                  fontSize: '0.8125rem',
                  textTransform: 'none',
                }}
              >
                {String(UserStatus[status]).toLowerCase()}
              </span>
            )}
          </div>
        </Col>
      </>
    );
  }
}

export default BadgeColumn;
