/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import { SortOrder } from 'enums/grid-table.enum';
import Routes from 'enums/routes.enum';
import { observer } from 'mobx-react';
import { ActionTypes } from 'models/action.model';
import { collectionViewsStore, domainStore, uiStore } from 'stores';

import CollectionViewRow from 'components/CollectionViews/CollectionViewRow';
import { headerColumnConfig } from 'components/CollectionViews/HeaderColumnsConfigs';
import EmptyTable from 'components/Forms/EmptyTable';
import GridTableCardHeader from 'components/GridTable/CardHeader';
import Header from 'components/GridTable/Header';
import Loader from 'components/Indicators/Loader';

interface Props {}

const CollectionViewsTable: any = observer((props: Props) => {
  const [didAddNew, setDidAddNew] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    collectionViewsStore.fetchItems({ domainStore }).then(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {didAddNew && (
        <Redirect push to={Routes.ContentCollectionViewLayoutsSelector} />
      )}
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <i className="fas fa-columns" /> Collection Views
        </h4>

        <Card className="p-1 pb-5">
          <GridTableCardHeader
            actionButtonTitle="Add Collection View"
            actionButtonIcon="fa-plus"
            actionButtonHandler={(): void => {
              setDidAddNew(true);
            }}
            store={collectionViewsStore}
            shouldNotPaginate={true}
            shouldHideSearch={true}
          />

          {collectionViewsStore.filters.size > 0 && (
            <div className="clear-filter-contain">
              <div
                onClick={(): void => {
                  collectionViewsStore.clearFilters(props);
                  collectionViewsStore.handleTableAction(props, {
                    payload: { updated: SortOrder.ASC },
                    type: ActionTypes.SEARCH,
                  });
                  collectionViewsStore.handleTableAction(props, {
                    payload: '',
                    type: ActionTypes.SORT,
                  });
                }}
              >
                <i className="fas fa-times"></i>{' '}
                {`Remove ${collectionViewsStore.filters.size} filter${
                  collectionViewsStore.filters.size === 1 ? '' : 's'
                }`}
              </div>
            </div>
          )}

          {isLoading && <Loader style={{ margin: '2rem' }} />}

          {!isLoading && collectionViewsStore.count === 0 && (
            <EmptyTable
              title={'No collection views yet...'}
              text={'Start by clicking add in the top right!'}
            />
          )}

          {/* Empty div to properly offset alt row coloring */}
          <div></div>

          {!isLoading && collectionViewsStore.count > 0 && (
            <>
              <Header columnConfigs={headerColumnConfig} />
              {Array.from(collectionViewsStore.items.values()).map(
                (collectionView) => {
                  return (
                    <CollectionViewRow
                      key={`collection-view-${collectionView.id}`}
                      collectionView={collectionView}
                    />
                  );
                },
              )}
            </>
          )}
        </Card>
      </Container>
    </>
  );
});

export default CollectionViewsTable;
