import React from 'react';
import { inject, observer } from 'mobx-react';
import { DomainStoreModel } from 'models/domain.model';
import { domainStore } from 'stores';

import logo from '../../assets/img/brand/logo.png';
import { createBackgroundImageCss } from '../../helpers/component-color.helper';
import { getUrlParameter } from '../../helpers/url.helper';
import { getAuthUrl } from '../../helpers/url.helper';
import { UiStoreModel } from '../../models/ui.model';
import {
  authenticateAppUser,
  getJWT,
  setJwtToDomainStore,
} from '../../services/auth.service';

interface Props {
  domainStore: DomainStoreModel;
  uiStore: UiStoreModel;
}

interface InternalState {
  error: boolean;
}

@inject('domainStore')
@inject('uiStore')
@observer
export class LoginCode extends React.Component<Props, InternalState> {
  private init(): void {
    this.handleCode();
  }

  private async handleCode(): Promise<void> {
    const code: string = getUrlParameter('code');
    const identityProvider: string = getUrlParameter('identity_provider');

    if (code) {
      const [jwt, err] = await getJWT({
        code,
        grantType: 'authorization_code',
        identityProvider: identityProvider,
        tenantId: this.props.domainStore.tenantId,
      });

      if (!err) {
        setJwtToDomainStore(jwt);
        domainStore.setIdentityProvider(identityProvider);
        const [, appAuthErr] = await authenticateAppUser();
        if (appAuthErr) {
          console.error(appAuthErr);
        }
      } else {
        console.error(err);
      }
    } else {
      // Redirect to oauth/authorize if code is empty
      window.location.href = getAuthUrl(
        identityProvider,
        +this.props.domainStore.tenantId,
      );
    }
  }

  public componentDidMount(): void {
    this.init();
  }

  public render(): JSX.Element {
    return (
      <>
        <div
          className="login-code"
          style={createBackgroundImageCss(
            this.props.uiStore.auth.backgroundUrl,
          )}
        >
          <img src={logo} className="storyslab-logo" alt="logo" />
        </div>
      </>
    );
  }
}
