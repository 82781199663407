import React, { useContext, useEffect, useState } from 'react';
import { collectionsStore, domainStore, uiStore } from 'stores';

import { TreeContext } from 'components/ContentTree/ContentTree';
import ContentTreeMenu from 'components/ContentTree/ContentTreeMenu';

const BackButton: any = () => {
  const { branchStack, setBranchStack }: any = useContext(TreeContext);
  return (
    <>
      <div
        className="back-button"
        onClick={(): void => {
          const newStack: any = [...branchStack];
          newStack.pop();
          setBranchStack(newStack);
        }}
      >
        <i className="fal fa-angle-left"></i>
      </div>
    </>
  );
};

const ContentTreeHeader: any = (props: any) => {
  const { activeView, branchesRendered, branchStack, maxColumns }: any =
    useContext(TreeContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    collectionsStore
      .fetchItems({ collectionsStore, domainStore, history: null })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  const Segments: any = () => {
    const segments: Array<[number, number]> = [];
    let viewId: number = activeView.viewId;
    let flexSize: number = 0;
    branchesRendered.forEach((item: number, count: number) => {
      if (collectionsStore.items.get(item)?.viewId) {
        segments.push([viewId, flexSize]);
        viewId = collectionsStore.items.get(item)?.viewId;
        flexSize = 1;
      } else {
        flexSize++;
      }
      if (count + 1 === branchesRendered.length) {
        segments.push([viewId, flexSize]);
      }
    });
    return segments.map((segment, count) => {
      const styles: any = {
        flex: segment[1].toString(),
        overflow: segment[1] === 0 ? 'hidden' : 'unset',
      };
      return (
        <div className="banner-segment" key={`segment-${count}`} style={styles}>
          <span className="segment-title">view {segment[0]}</span>
        </div>
      );
    });
  };

  return (
    <>
      {!isLoading && (
        <>
          <h4 style={{ color: uiStore.common.colors.primary }}>
            <i className="fas fa-folder-tree" /> Content Tree
          </h4>
          <ContentTreeMenu />
          <div className="banner-contain">
            {branchStack.length > maxColumns && <BackButton />}
            <Segments />
            {maxColumns > branchesRendered.length && (
              <div style={{ flex: maxColumns - branchesRendered.length }}></div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ContentTreeHeader;
