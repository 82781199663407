import React from 'react';
import { inject, observer } from 'mobx-react';

import { BaseProps } from '../../../models/base.model';

interface InternalState {}

interface InternalProps extends BaseProps {}

@inject('domainStore')
@inject('uiStore')
@observer
class NavItemStatus extends React.Component<InternalProps, InternalState> {
  public render(): JSX.Element {
    return <div className="nav-item-status">no layout</div>;
  }
}

export default NavItemStatus;
