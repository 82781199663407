import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import { DomainStoreModel } from 'models/domain.model';

import { EdgeUrls } from '../enums/edge.enum';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export async function fetchAppMetaSimpleList(domainStore: DomainStoreModel, metaKey?: string): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    includeMeta: true,
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.AppMeta + `/simple-list`,
  });
  return resp;
}

export async function fetchAllAppMeta(domainStore: DomainStoreModel): Promise<any>{
  return await fetchAppMetaByKey(domainStore, "")
}

export async function fetchAppMetaByKey(domainStore: DomainStoreModel, metaKey?: string): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    includeMeta: true,
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.AppMeta + `/${metaKey || ""}`,
  });
  return resp;
}

export async function updateAppMeta(domainStore: DomainStoreModel, payload: Array<any>): Promise<any>{
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: payload,
    includeMeta: true,
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.AppMeta,
  });
  return resp;
}

export async function getAppMetaPresignedPost(domainStore: DomainStoreModel, payload: {[key:string]: any}): Promise<any>{
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: payload,
    includeMeta: true,
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.AppMetaAsset,
  })
  return resp;
}
