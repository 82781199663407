/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { EdgeUrls } from 'enums/edge.enum';
import Routes from 'enums/routes.enum';
import _ from 'lodash';
import { standardGet, standardPut } from 'services/edge.service';
import {
  collectionsStore,
  collectionViewsStore,
  domainStore,
  navItemViewsStore,
  uiStore,
} from 'stores';
import { BaseStore } from 'stores/base.store';

import {
  Components,
  LayoutConfiguration,
  Props as ViewComponentProp,
  View,
} from '@storyslab/storyslab.common.models';
import DrawerModalContents from 'components/ApplicationNavigation/Configuration/DrawerModalContents';
import S3CmsDataImage from 'components/Assets/S3CmsDataImage';
import DrawerModal from 'components/DrawerModal';
import GhostLoader from 'components/Indicators/GhostLoader';
import CollectionSelect from 'components/ViewConfigurator/CollectionSelect';
import ComponentItemProp from 'components/ViewConfigurator/ComponentItemProp';

interface Props {}

interface ViewConfig {
  layoutConfiguration: LayoutConfiguration;
  view: View;
}

const Component: (props: Props) => JSX.Element = (props: Props) => {
  const { id, key } = useParams<{ id: string; key: string }>();

  const [component, setComponent] = useState<Components>();
  const [hasCollection, setHasCollection] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [primaryRoute, setPrimaryRoute] = useState<string>();
  const [secondaryRoute, setSecondaryRoute] = useState<string>();
  const [store, setStore] = useState<BaseStore<any>>();
  const [title, setTitle] = useState<string>();
  const [viewConfig, setViewConfig] = useState<ViewConfig>();

  /* Component did mount */
  useEffect(() => {
    standardGet(EdgeUrls.View.replace(':id', id))
      .then((response) => {
        setViewConfig(response);
      })
      .catch((error) => {
        console.error(error);
        setIsError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viewConfig === undefined) {
      return;
    }

    if (isInitialized) {
      standardPut({
        body: { layoutConfiguration: viewConfig.layoutConfiguration },
        url: EdgeUrls.View.replace(':id', id),
      });
    } else {
      for (const index in viewConfig.layoutConfiguration.components) {
        if (viewConfig.layoutConfiguration.components[index].key === key) {
          setComponent(viewConfig.layoutConfiguration.components[index]);
        }
      }

      switch (viewConfig.layoutConfiguration.viewType.toLowerCase()) {
        case 'collection':
          setPrimaryRoute(Routes.ContentCollectionViewLayouts);
          setSecondaryRoute(
            Routes.ContentCollectionViewLayoutConfiguration.replace(':id', id),
          );
          setStore(collectionViewsStore);
          setTitle('Collection Views');
          break;
        case 'navigationitem':
          setPrimaryRoute(Routes.ApplicationNavigation);
          setSecondaryRoute(
            Routes.ApplicationConfigurationEdit.replace(':id', id),
          );
          setStore(navItemViewsStore);
          setTitle('Navigation Item');
          setHasCollection(true);
          break;
        default:
          setIsError(true);
      }

      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewConfig]);

  useEffect(() => {
    if (store === undefined) {
      return;
    }
    store
      .fetchItems({ domainStore })
      .catch((error) => {
        console.error(error);
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [store]);

  function persistPropChange(
    localItem: ViewComponentProp,
    keyName: string,
  ): void {
    const newComponent: any = _.cloneDeep(component);
    for (const index in newComponent[keyName]) {
      if (newComponent[keyName][index].key === localItem.key) {
        newComponent[keyName][index] = localItem;
        break;
      }
    }
    const newConfig: ViewConfig = _.cloneDeep(viewConfig);
    for (const index in newConfig.layoutConfiguration.components) {
      if (newConfig.layoutConfiguration.components[index].key === key) {
        newConfig.layoutConfiguration.components[index] = newComponent;
      }
    }

    setViewConfig(newConfig);
  }

  function updateTarget(id: number): void {
    const newComponent: any = _.cloneDeep(component);
    newComponent.target = id;
    setComponent(newComponent);

    const newConfig: ViewConfig = _.cloneDeep(viewConfig);
    for (const index in newConfig.layoutConfiguration.components) {
      if (newConfig.layoutConfiguration.components[index].key === key) {
        newConfig.layoutConfiguration.components[index] = newComponent;
      }
    }

    setViewConfig(newConfig);
  }

  if (isError) {
    return <>There was a problem.</>;
  }

  return (
    <>
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          {isLoading ? (
            <>
              <GhostLoader inline={true} /> : <GhostLoader inline={true} /> :{' '}
              <GhostLoader />
            </>
          ) : (
            <>
              <Link to={primaryRoute}>
                <i className="fas fa-columns" /> {title}
              </Link>{' '}
              :
              <Link to={secondaryRoute}>
                {' '}
                {store.items.get(parseInt(id))?.name}
              </Link>{' '}
              : {component?.name}
            </>
          )}
        </h4>
        <div style={styles.wrapper}>
          <div style={styles.leftContain}>
            {isLoading ? (
              <GhostLoader height={'500px'} width={'100%'} />
            ) : (
              <>
                <S3CmsDataImage
                  style={styles.preview}
                  s3Key={component.previewS3key}
                />
                <Link to={secondaryRoute}>
                  <div className="component-button">Save & Finish</div>
                </Link>
              </>
            )}
          </div>

          <div style={styles.rightContain}>
            <div style={styles.name}>
              {isLoading ? (
                <GhostLoader width={'100%'} />
              ) : (
                <>{component.name}</>
              )}
            </div>
            <div style={styles.description}>
              {isLoading ? (
                <GhostLoader count={2} width={'100%'} />
              ) : (
                <>{component.description}</>
              )}
            </div>
            {hasCollection && !isLoading && (
              <CollectionSelect component={component} />
            )}
            {isLoading ? (
              <GhostLoader count={3} width={'100%'} height={'50px'} />
            ) : (
              component.props?.map((item: ViewComponentProp, index) => {
                return (
                  <ComponentItemProp
                    key={`prop-${index}`}
                    keyName={'props'}
                    item={item}
                    persistChange={persistPropChange}
                  />
                );
              })
            )}
            {isLoading ? (
              <GhostLoader count={3} width={'100%'} height={'50px'} />
            ) : (
              component.itemProps?.map((item: ViewComponentProp, index) => {
                return (
                  <ComponentItemProp
                    key={`item-prop-${index}`}
                    keyName={'itemProps'}
                    item={item}
                    persistChange={persistPropChange}
                  />
                );
              })
            )}
          </div>
        </div>
      </Container>
      {hasCollection && (
        <DrawerModal surtitle="" store={collectionsStore} storeKey={'navitem'}>
          <DrawerModalContents
            selectCollection={(id): void => {
              updateTarget(id);
            }}
          />
        </DrawerModal>
      )}
    </>
  );
};

const styles: any = {
  button: {
    alignItems: 'center',
    borderRadius: '2rem',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '20px',
    justifyContent: 'space-between',
    margin: '1rem auto 2rem',
    padding: '.5rem 1rem',
    width: '75%',
  },
  description: {
    borderBottom: '1px solid #ccc',
    fontSize: '12px',
    fontWeight: 700,
    paddingBottom: '30px',
  },
  label1: {
    color: '#999',
    fontSize: '14px',
    fontWeight: 700,
    paddingTop: '10px',
  },
  leftContain: {
    flex: 1,
    overflow: 'hidden',
    paddingRight: '1rem',
  },
  name: {
    color: '#4d4d4d',
    fontSize: '20px',
    fontWeight: 700,
  },
  preview: {
    width: '100%',
  },
  rightContain: {
    flex: 1,
    fontSize: '10px',
  },
  wrapper: {
    display: 'flex',
  },
};

export default Component;
