import React from 'react';

import NavigationViewSelector from 'components/ApplicationNavigation/Layout/NavigationViewLayoutSelector';

interface Props {}

function NavigationViewSelectorPage(props: Props): JSX.Element {
  return <NavigationViewSelector />;
}

export default NavigationViewSelectorPage;
