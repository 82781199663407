/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { action, autorun, computed, observable } from 'mobx';

import { TokenResponse } from '@storyslab/storyslab.common.models';

import { getStoredVersion } from '../helpers/store.helper';
import {
  DomainStoreAuthModel,
  DomainStoreCredentials,
  DomainStoreUserModel,
  TemporaryCredentials,
} from '../models/domain.model';

import { simpleListsStore } from './simple-lists.store';

const localStorageName: string = 'DomainStore';

const defaultAuthObj: DomainStoreAuthModel = {
  accessToken: null,
  expiresAt: null,
  expiresIn: 0,
  idToken: null,
  refreshToken: null,
  tokenType: 'Bearer',
};

export class DomainStore {
  @observable
  public temporaryCredentials: TemporaryCredentials = getStoredVersion(
    localStorageName,
    'temporaryCredentials',
    {},
  );

  @observable
  public tenantId: number = getStoredVersion(
    localStorageName,
    'tenantId',
    null,
  );

  @observable
  public applicationId: number = getStoredVersion(
    localStorageName,
    'applicationId',
    null,
  );
  @observable
  public auth: DomainStoreAuthModel = getStoredVersion(
    localStorageName,
    'auth',
    defaultAuthObj,
  );

  @observable
  public credentials: DomainStoreCredentials = getStoredVersion(
    localStorageName,
    'credentials',
    {},
  );

  @observable
  public entitlements: any = getStoredVersion(
    localStorageName,
    'entitlements',
    null,
  );

  @observable
  public subdomain: string = getStoredVersion(
    localStorageName,
    'subdomain',
    null,
  );

  @observable
  public user: DomainStoreUserModel = getStoredVersion(
    localStorageName,
    'user',
    null,
  );

  @observable
  public identityProvider: string = getStoredVersion(
    localStorageName,
    'identityProvider',
    null,
  );

  constructor() {
    autorun(() => {
      if (this.user && this.user.email) {
        Sentry.setUser({ email: this.user.email });
      }

      localStorage.setItem(
        localStorageName,
        JSON.stringify({
          applicationId: this.applicationId,
          auth: this.auth,
          credentials: this.credentials,
          entitlements: this.entitlements,
          identityProvider: this.identityProvider,
          subdomain: this.subdomain,
          temporaryCredentials: this.temporaryCredentials,
          tenantId: this.tenantId,
          user: this.buildUserObject(this.user),
        }),
      );
    });
  }

  // Auth
  @action public setOauthTokens(oauthResponse?: TokenResponse): void {
    this.auth = {
      accessToken: oauthResponse.access_token,
      expiresAt: oauthResponse.expires_in
        ? dayjs().add(oauthResponse.expires_in, 'second').toISOString()
        : null,
      expiresIn: oauthResponse.expires_in,
      idToken: oauthResponse.id_token,
      refreshToken: oauthResponse.refresh_token,
      tokenType: oauthResponse.token_type,
    };
  }

  @action public clearAuth(): void {
    this.auth = defaultAuthObj;
  }

  // Subdomain
  @action public setSubdomain(subdomain: string): void {
    this.subdomain = subdomain;
  }

  @action public clearSubdomain(): void {
    this.subdomain = null;
  }

  // Remember Me
  @action public setCredentials(username: string, password: string): void {
    if (username && password) {
      this.credentials = {
        password,
        username,
      };
    }
  }

  // Application Id
  @action public setApplicationId(applicationId: number): void {
    this.applicationId = applicationId;
  }

  // Tenant Id
  @action public setTenantId(tenantId: number): void {
    this.tenantId = tenantId;
  }

  @action public clearApplicationId(): void {
    this.applicationId = null;
  }

  @action public setTemporaryCredentials(
    temporaryCredentials: TemporaryCredentials,
  ): void {
    this.temporaryCredentials = temporaryCredentials;
  }

  @computed public get getTemporaryCredentials(): TemporaryCredentials {
    return this.temporaryCredentials;
  }

  // User
  @action public setUser(user: DomainStoreUserModel): void {
    this.user = this.buildUserObject(user);
  }

  @action public clearUser(): void {
    this.user = null;
  }

  @action public setUserId(id: number): void {
    this.user.id = id;
  }

  @action public setUserDeviceId(id: number): void {
    this.user.deviceId = id;
  }

  @action public setIdentityProvider(identityProvider: string): void {
    this.identityProvider = identityProvider;
  }

  // Entitlements
  @action public setEntitlements(entitlements: Array<string>): void {
    this.entitlements = entitlements;
  }

  @action public clearEntitlements(): void {
    this.entitlements = null;
  }

  @action public clearAll(): void {
    this.applicationId = null;
    this.auth = defaultAuthObj;
    this.credentials = null;
    this.entitlements = null;
    this.subdomain = null;
    this.user = null;

    simpleListsStore.clearAllSimpleLists();
  }

  @action public clearForLogout(): void {
    this.auth = defaultAuthObj;
    this.entitlements = null;
    this.user = null;

    simpleListsStore.clearAllSimpleLists();
  }

  private buildUserObject(user: any): any {
    if (user && user.nameFirst && user.nameLast) {
      user = {
        ...user,
        monogram: `${user.nameFirst.charAt(0)}${user.nameLast.charAt(0)}`,
      };
    }

    return user;
  }
}

export const domainStore: DomainStore = new DomainStore();
