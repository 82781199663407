import React from 'react';

const DropzoneInput: any = (props: any) => {
  const {
    accept,
    className,
    disabled,
    files,
    getFilesFromEvent,
    multiple,
    onFiles,
    style,
  } = props;

  return (
    <>
      {files.length === 0 && (
        <label style={styles.labelStyle}>
          <span className="text-center dropzone-button-wrapper">
            <p style={styles.text}>Drop Files to Upload</p>
            <p style={styles.text}>or</p>
            <span className="btn-neutral btn-icon btn btn-default">
              <span className="btn-inner--text" style={styles.text}>
                Upload file(s) from computer
              </span>
            </span>
          </span>

          <input
            className={className}
            style={style}
            type="file"
            accept={accept}
            multiple={multiple}
            disabled={disabled}
            onChange={async (e): Promise<any> => {
              const target: any = e.target;
              const chosenFiles: any = await getFilesFromEvent(e);
              onFiles(chosenFiles);
              target.value = null;
            }}
          />
        </label>
      )}
    </>
  );
};

const styles: any = {
  labelStyle: {
    alignItems: 'center',
    border: '1px solid #efefef',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem .5rem',
    width: '100%',
  },
  text: {
    marginBottom: 0,
  },
};

export default DropzoneInput;
