import React from 'react';

interface Props {
  title: string;
  text?: string;
}

export default function EmptyTable(props: Props): JSX.Element {
  return (
    <>
      <div style={styles.wrapper}>
        <div style={styles.title}>{props.title}</div>
        <div>{props.text}</div>
      </div>
    </>
  );
}

const styles: any = {
  title: {
    fontSize: '22px',
    fontWeight: 700,
  },
  wrapper: {
    margin: 'auto',
    padding: '1rem',
    textAlign: 'center',
    width: '500px',
  },
};
