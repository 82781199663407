/* eslint-disable no-underscore-dangle */
import { nanoid } from 'nanoid';

import { simpleHash } from '../helpers/util.helper';

export class RenderKeyHelper {
  private rootKey: string;
  private componentKey: string;
  private _log: boolean;

  constructor(componentKey: string) {
    this.rootKey = nanoid();
    this.componentKey = componentKey || '';
  }

  public set log(value: boolean) {
    this._log = !!value;
  }

  public generate(
    uniqueParams: string | { [key: string]: any },
    optionalKey?: string,
  ): string {
    const hashKey: string = simpleHash(uniqueParams);
    const key: string = `RKH-${this.rootKey}-${this.componentKey}${
      optionalKey ? '-' + optionalKey : ''
    }-${hashKey}`;

    if (this._log) {
      console.log(key);
    }

    return key;
  }
}
