import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'reactstrap';
import { appNavigationStore, domainStore } from 'stores';

interface Props {
  refreshList: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

/*
 * TODO: add menu for p2 -
 * const AddMenu: (props: AddMenuProps) => JSX.Element = (props: AddMenuProps) => {
 * const { setIsOpen } = props;
 *
 * function test(navItemType: string): void {
 *  console.log('test: ', navItemType);
 *  setIsOpen(false);
 * }
 *
 * return (
 *  <>
 *    <div className="card-container" style={styles.menuContain}>
 *      <div
 *        style={styles.menuOption}
 *        onClick={(): void => {
 *          test('layout');
 *        }}
 *      >
 *        Layout
 *      </div>
 *      <div
 *        style={styles.menuOption}
 *        onClick={(): void => {
 *          test('form');
 *        }}
 *      >
 *        Form
 *      </div>
 *      <div
 *        style={styles.menuOption}
 *        onClick={(): void => {
 *          test('calc');
 *        }}
 *      >
 *        Calculator
 *      </div>
 *    </div>
 *  </>
 * );
 * };
 */

const AddNavItemButton: any = (props: Props) => {
  // TODO: add menu for p2 - const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        className="storyslab-button"
        style={styles.button}
        onClick={(): void => {
          // TODO: add menu for p2 - setIsOpen(!isOpen);
          props.setIsLoading(true);
          appNavigationStore.createItem({ domainStore }).then(() => {
            props.refreshList();
          });
        }}
      >
        Add New Nav Item
      </Button>
      {/* TODO: add menu for p2 - {isOpen && <AddMenu setIsOpen={setIsOpen} />} */}
    </>
  );
};

const styles: any = {
  button: {
    display: 'block',
    margin: '1rem auto',
  },
  menuContain: {
    margin: '1rem auto',
    width: '285px',
  },
  menuOption: {
    color: '#0065A3',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 600,
    margin: '.5rem 0',
  },
};

export default AddNavItemButton;
