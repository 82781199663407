import React from 'react';

interface Props {
  contentItem: any;
}

function HistoryFields(props: Props): JSX.Element {
  return (
    <div className="content-contain">
      <div>
        <div className="form-field-contain">
          <div className="form-field-label">History</div>
        </div>
      </div>
      <div>
        <div className="form-field-contain"></div>
      </div>
    </div>
  );
}

export default HistoryFields;
