/* eslint-disable max-lines */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import dayjs from 'dayjs';
import { getDefaultIconForItem } from 'helpers/icon.helper';
import { observer } from 'mobx-react';
import { ActionTypes } from 'models/action.model';
import { contentStore, domainStore } from 'stores';

import { ContentItem } from '@storyslab/storyslab.common.models';
import S3TenantAssetImage from 'components/Assets/S3TenantAssetImage';
import AdditionalFields from 'components/Content/ContentRow/AdditionalFields';
import AppearanceFields from 'components/Content/ContentRow/ApperanceFields';
import BasicFields from 'components/Content/ContentRow/BasicFields';
import HistoryFields from 'components/Content/ContentRow/HistoryFields';
import SourceFields from 'components/Content/Source/SourceFields';

interface Props {
  contentItem: any;
  context?: string;
  isDroppedOnLoad?: boolean;
}

interface InternalProps extends Props {
  activeMenu: string;
  isDropped: boolean;
  isDroppedOnLoad?: boolean;
  isMenuOpen: boolean;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

enum MenuOptions {
  BASIC = 'basic info',
  APPEARANCE = 'appearance',
  ADDITIONAL = 'additional info',
  HISTORY = 'history',
  SOURCE = 'source',
}

function HamburgerMenu(props: {
  children: JSX.Element | Array<JSX.Element>;
  itemId: number;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <>
      <div
        className="backsplash"
        onClick={(): void => {
          props.setIsMenuOpen(false);
        }}
      ></div>
      <div className={`hamburger-menu-card`}>{props.children}</div>
    </>
  );
}

function DeleteOption(props: { item: ContentItem }): JSX.Element {
  return (
    <>
      <div
        className="menu-option"
        onClick={(): void => {
          window.siteWideComponent.show({
            approve: () => {
              contentStore
                .deleteItem({
                  domainStore,
                  id: props.item.id,
                })
                .then(() => {
                  contentStore.handleTableAction(
                    { domainStore },
                    {
                      payload: { page: contentStore.page.toString() },
                      type: ActionTypes.PAGINATE,
                    },
                  );
                });
            },
            body: `Are you sure you want to delete ${props.item.name}?`,
            title: 'Delete Confirmation',
          });
        }}
      >
        Delete
      </div>
    </>
  );
}

function MoreInfoOption(props: { itemId: number }): JSX.Element {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <>
      {isClicked && (
        <Redirect from="*" to={`/content/items/detail/${props.itemId}`} />
      )}
      <div
        className="menu-option"
        onClick={(): void => {
          setIsClicked(true);
        }}
      >
        More Info
      </div>
    </>
  );
}

function RowHeader(props: InternalProps): JSX.Element {
  const {
    contentItem,
    context,
    isDropped,
    isMenuOpen,
    setIsDropped,
    setIsMenuOpen,
  } = props;

  return (
    <>
      <div
        className={`detail-row-wrapper ${props.isDropped && 'active'}`}
        onClick={(): void => {
          if (context !== 'detail') {
            setIsDropped(!isDropped);
          }
        }}
      >
        <div className="detail-row-body">
          <div className="detail-row-thumbnail">
            {contentItem.thumbnail.s3Key !== undefined && (
              <S3TenantAssetImage s3Key={contentItem.thumbnail.s3Key} />
            )}
            {contentItem.thumbnail.s3Key === undefined && (
              <i className={`${getDefaultIconForItem(contentItem)}`} />
            )}
          </div>
          <div className="detail-row-title">
            <span>{contentItem.name}</span>
          </div>
          {isDropped && context !== 'detail' && (
            <div className="detail-done">Done</div>
          )}
          {!isDropped && (
            <div className="detail-row-meta">
              <div className="meta-text">
                {dayjs(contentItem.updated).format('MM / DD / YYYY')}
              </div>
            </div>
          )}
          <div>
            <div
              className="hamburger-menu-card-contain"
              onClick={(e): void => {
                e.stopPropagation();
              }}
            >
              <i
                className="fal fa-ellipsis-v"
                onClick={(e): void => {
                  e.stopPropagation();
                  setIsMenuOpen(!isMenuOpen);
                }}
              ></i>
              {isMenuOpen && (
                <HamburgerMenu
                  itemId={props.contentItem.id}
                  setIsMenuOpen={setIsMenuOpen}
                >
                  {!props.isDroppedOnLoad && (
                    <MoreInfoOption itemId={props.contentItem.id} />
                  )}
                  <DeleteOption item={props.contentItem} />
                </HamburgerMenu>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Menu(props: InternalProps): JSX.Element {
  const { activeMenu, setActiveMenu } = props;

  return (
    <>
      <div className="menu">
        <div
          className={`${activeMenu === MenuOptions.BASIC && 'active'}`}
          onClick={(): void => {
            setActiveMenu(MenuOptions.BASIC);
          }}
        >
          <i className="fas fa-file-check mr-2" /> Basic Info
        </div>
        <div
          className={`${activeMenu === MenuOptions.SOURCE && 'active'}`}
          onClick={(): void => {
            setActiveMenu(MenuOptions.SOURCE);
          }}
        >
          <i className="fas fa-cloud-download mr-2" /> Source
        </div>
        <div
          className={`${activeMenu === MenuOptions.APPEARANCE && 'active'}`}
          onClick={(): void => {
            setActiveMenu(MenuOptions.APPEARANCE);
          }}
        >
          <i className="fas fa-images mr-2" /> Appearance
        </div>
        <div
          className={`${activeMenu === MenuOptions.ADDITIONAL && 'active'}`}
          onClick={(): void => {
            setActiveMenu(MenuOptions.ADDITIONAL);
          }}
        >
          <i className="fas fa-info-circle mr-2" /> Additional Info
        </div>
      </div>
    </>
  );
}

function Content(props: InternalProps): JSX.Element {
  const { activeMenu } = props;

  switch (activeMenu) {
    case MenuOptions.BASIC:
      return <BasicFields {...props} />;
    case MenuOptions.APPEARANCE:
      return <AppearanceFields {...props} />;
    case MenuOptions.ADDITIONAL:
      return <AdditionalFields {...props} />;
    case MenuOptions.HISTORY:
      return <HistoryFields {...props} />;
    case MenuOptions.SOURCE:
      return <SourceFields {...props} />;
    default:
      return <>content</>;
  }
}

const DroppedContent: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const { isDropped } = props;

    return (
      <>
        <div
          className={`detail-row-dropped-content ${isDropped && ' dropped'}`}
        >
          <Menu {...props} />
          <Content {...props} />
        </div>
      </>
    );
  },
);

function ContentRow(props: Props): JSX.Element {
  const { isDroppedOnLoad } = props;

  const [isDropped, setIsDropped] = useState(isDroppedOnLoad ? true : false);
  const [hasDroppedClass, setHasDroppedClass] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(MenuOptions.BASIC);

  useEffect(() => {
    if (isDropped) {
      setHasDroppedClass(true);
    } else {
      setTimeout((): void => {
        setHasDroppedClass(false);
      }, 400);
    }
  }, [isDropped]);

  if (props.contentItem.contentItem) {
    return <></>;
  }

  return (
    <>
      {isDropped && (
        <div
          className="detail-backsplash"
          onClick={(): void => {
            !isDroppedOnLoad && setIsDropped(false);
          }}
        ></div>
      )}
      <div
        className={`detail-view-wrapper ${hasDroppedClass && 'dropped'} ${
          isMenuOpen && 'hamburger-open'
        }`}
        tabIndex={0}
        onKeyUp={(e): void => {
          if (e.key === 'Escape') {
            setIsDropped(false);
          }
        }}
      >
        <RowHeader
          {...props}
          activeMenu={activeMenu}
          isDropped={isDropped}
          isMenuOpen={isMenuOpen}
          setActiveMenu={setActiveMenu}
          setIsDropped={setIsDropped}
          setIsMenuOpen={setIsMenuOpen}
        />

        <DroppedContent
          {...props}
          activeMenu={activeMenu}
          isDropped={isDropped}
          isMenuOpen={isMenuOpen}
          setActiveMenu={setActiveMenu}
          setIsDropped={setIsDropped}
          setIsMenuOpen={setIsMenuOpen}
        />
      </div>
    </>
  );
}

export default ContentRow;
