/* eslint-disable max-lines-per-function */
import React, { Dispatch, SetStateAction, useState } from 'react';
import dayjs from 'dayjs';
import { Intervals } from 'enums/contentSource.enum';
import { getInterval } from 'helpers/content.helper';
import { syncContentSource } from 'services/content.service';
import { contentStore, domainStore } from 'stores';

import {
  AssetSyncType,
  ContentItem,
  ContentItemType,
} from '@storyslab/storyslab.common.models';
import Loader from 'components/Indicators/Loader';

interface Props {
  contentItem: ContentItem;
}

interface SyncIntervalListProps {
  itemId: number;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  syncParams;
}

const SyncIntervalList: (props: SyncIntervalListProps) => JSX.Element = (
  props: SyncIntervalListProps,
) => {
  const { itemId, setIsDropped, setIsLoading, syncParams } = props;

  const elem: Array<JSX.Element> = [];
  Object.keys(Intervals)
    .filter((key) => isNaN(Number(key)))
    .forEach((interval) => {
      elem.push(
        <div
          style={styles.menuOption}
          key={`interval-${Intervals[interval]}`}
          onClick={(): void => {
            setIsLoading(true);
            contentStore
              .updateItem({
                body: {
                  target: {
                    syncInterval: Intervals[interval].toString(),
                    syncParams,
                    syncType: AssetSyncType.PUBLIC_LINK,
                  },
                  type: ContentItemType.ASSET,
                },
                domainStore,
                id: itemId,
              })
              .finally(() => {
                setIsLoading(false);
                setIsDropped(false);
              });
          }}
        >
          {interval}
        </div>,
      );
    });
  return <>{elem}</>;
};

const SyncFields: (props: Props) => JSX.Element = (props: Props) => {
  const { contentItem } = props;

  const [isDropped, setIsDropped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  const params: any = contentItem.target.syncParams;
  const syncParamList: Array<JSX.Element> = [];

  for (const keyName in params) {
    syncParamList.push(
      <div key={keyName} style={styles.labelContain}>
        <div style={styles.label}>{keyName}</div>
        <div style={styles.value}>{params[keyName]}</div>
      </div>,
    );
  }

  return (
    <>
      {syncParamList}
      <div className="form-field-label" style={styles.frequencyLabel}>
        Update Frequency
      </div>

      {!isLoading && (
        <>
          <div
            style={styles.frequencyWrapper}
            className={`source-input-wrapper`}
            onClick={(): void => {
              if (!isSyncing) {
                setIsDropped(!isDropped);
              }
            }}
          >
            {!isSyncing && (
              <div style={styles.frequencyContent} className="source-contain">
                <>
                  <div>
                    {getInterval(contentItem.target.syncInterval)}{' '}
                    <i
                      className={`fas fa-angle-${isDropped ? 'up' : 'down'}`}
                    ></i>
                  </div>
                  <div
                    style={styles.syncNow}
                    onClick={(e): void => {
                      e.stopPropagation();
                      setIsSyncing(true);
                      syncContentSource(props.contentItem.id).then((): void => {
                        setIsSyncing(false);
                      });
                    }}
                  >
                    <i className="fas fa-sync"></i>
                  </div>
                </>
              </div>
            )}

            {isSyncing && (
              <Loader
                isFullPage={false}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              />
            )}
          </div>
        </>
      )}

      {isDropped && (
        <>
          <div
            className="backsplash"
            onClick={(): void => {
              setIsDropped(false);
            }}
          ></div>
          <div style={styles.menuWrapper} className="card-container">
            {!isLoading && (
              <SyncIntervalList
                itemId={contentItem.id}
                setIsDropped={setIsDropped}
                setIsLoading={setIsLoading}
                syncParams={contentItem.target.syncParams}
              />
            )}
            {isLoading && <Loader isFullPage={false} />}
          </div>
        </>
      )}

      <div style={styles.labelContain}>
        <div style={styles.label}>Checked On</div>
        <div style={styles.value}>
          {dayjs(props.contentItem.target.syncCheckedTimestamp).format(
            'MM / DD / YYYY',
          )}
        </div>
      </div>
      <div style={styles.labelContain}>
        <div style={styles.label}>Synced On</div>
        <div style={styles.value}>
          {props.contentItem.target.syncSuccessTimestamp}
        </div>
      </div>
    </>
  );
};

const styles: any = {
  frequencyContent: {
    display: 'flex',
    flex: 1,
    fontSize: '14px',
    fontWeight: 400,
    justifyContent: 'space-between',
    padding: '10px 15px',
  },
  frequencyLabel: {
    marginTop: '1rem',
  },
  frequencyWrapper: {
    alignItems: 'center',
    borderRadius: '3px',
    boxShadow: '0 0 10px #c3c3c3',
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    marginBottom: '10px',
    maxWidth: '259px',
    overflow: 'hidden',
  },
  label: {
    color: '#808080',
    display: 'inline-block',
    fontSize: '12px',
    fontWeight: 700,
    textTransform: 'uppercase',
    width: '150px',
  },
  labelContain: {
    fontSize: '12px',
    fontWeight: 400,
    maxWidth: '300px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  menuOption: {
    cursor: 'pointer',
    marginBottom: '5px',
  },
  menuWrapper: {
    borderRadius: '3px',
    marginTop: '0',
    position: 'absolute',
    width: '259px',
    zIndex: 100000,
  },
  syncNow: {
    borderBottomWidth: 0,
    borderColor: '#151515',
    borderLeftWidth: '1px',
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderTopWidth: 0,
    paddingLeft: '.5rem',
  },
  value: {
    display: 'inline',
  },
};

export default SyncFields;
