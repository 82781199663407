import React, { Dispatch, SetStateAction, useState } from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { observer } from 'mobx-react';
import { categoriesStore, domainStore } from 'stores';

import { Category } from '@storyslab/storyslab.common.models';
import StorySlabIconPicker from 'components/IconPicker/StorySlabIconPicker';

interface Props {
  category: Category;
}

interface InternalProps extends Props {
  isDropped: boolean;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
}

const BasicFields: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const { category } = props;

    const [title, setTitle] = useState(category.name);
    const [description, setDescription] = useState(category.description);

    const debounceName: DebouncedFunc<(name: any) => void> = React.useMemo(
      () =>
        debounce((name) => {
          categoriesStore.updateItem({
            body: { name },
            domainStore,
            id: category.id,
          });
        }, 750),
      [category.id],
    );

    const handleNameChange: (e) => void = React.useCallback(
      (e) => {
        setTitle(e.target.value);
        debounceName(e.target.value);
      },
      [debounceName],
    );

    const debounceDescription: DebouncedFunc<(description: any) => void> =
      React.useMemo(
        () =>
          debounce((description) => {
            categoriesStore.updateItem({
              body: { description },
              domainStore,
              id: category.id,
            });
          }, 750),
        [category.id],
      );

    const handleDescriptionChange: (e) => void = React.useCallback(
      (e) => {
        setDescription(e.target.value);
        debounceDescription(e.target.value);
      },
      [debounceDescription],
    );

    return (
      <div className="content-contain">
        <div>
          <div className="form-field-contain">
            <div className="form-field-label">display name</div>
            <input type="text" value={title} onChange={handleNameChange} />
          </div>
          <div className="form-field-contain">
            <div className="form-field-label">category icon</div>
            <StorySlabIconPicker
              defaultIcon="shapes"
              style={{ fontSize: '1.5rem' }}
            />
          </div>
        </div>
        <div>
          <div className="form-field-contain">
            <div className="form-field-label">description</div>
            <input
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Write your description here..."
            />
          </div>
        </div>
      </div>
    );
  },
);

export default BasicFields;
