import { GetObjectCommand, S3Client, S3ClientConfig } from '@aws-sdk/client-s3';
import { getSignedUrl as awsGetSignedUrl } from '@aws-sdk/s3-request-presigner';
import { EdgeUrls } from 'enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import { domainStore } from 'stores';

import { PresignedAssetCreateOrUpdate } from '@storyslab/storyslab.common.models';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export async function getSignedUrl({
  bucket,
  key,
  s3ClientConfig,
}: {
  bucket: string;
  key: string;
  s3ClientConfig: S3ClientConfig;
}): Promise<string> {
  const client: S3Client = new S3Client(s3ClientConfig);

  const command: GetObjectCommand = new GetObjectCommand({
    Bucket: bucket,
    Key: key,
  });

  return await awsGetSignedUrl(client, command, { expiresIn: 3600 });
}

export function getPublicUrlFromS3Key(s3Key: string): string {
  const s3PublicBucket: string = `${process.env.REACT_APP_PUBLIC_CONTENT_BUCKET}`;
  return `https://${s3PublicBucket}.s3.amazonaws.com/${s3Key}`;
}

export async function createOrUpdatePresignedAsset(
  payload: PresignedAssetCreateOrUpdate,
): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    body: payload,
    includeMeta: true,
    method: EdgeRequestMethods.POST,
    url: EdgeUrls.AssetUpload,
  });
  return resp;
}

export async function getAssetById(id: number): Promise<any> {
  const resp: any = await fetchFromEdge({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    includeMeta: true,
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.Asset.replace(':id', id.toString()),
  });
  return resp;
}
