/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Card, Container, DropdownItem, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { usersStore } from 'stores';

import DrawerModal from 'components/DrawerModal';

// import CheckboxList from 'components/GridTable/FilterDropdowns/CheckboxList';
import DrawerModalCollections from '../../components/Collections/DrawerModalContents';
import DrawerModalContentItems from '../../components/Content/DrawerModalContents';
import TitleSubtitleDropdownItem from '../../components/Dropdowns/TitleSubtitle';
import GridTable from '../../components/GridTable';
import GridTableCardFooter from '../../components/GridTable/CardFooter';
import GridTableCardHeader from '../../components/GridTable/CardHeader';
import ExpandedRowContents from '../../components/Groups/ExpandedRowContents';
import RowContents from '../../components/Groups/RowContents';
import DrawerModalUsers from '../../components/Users/DrawerModalContents';
import { GroupTypes } from '../../enums/groups.enum';
import { Action } from '../../models/action.model';
import { BaseProps } from '../../models/base.model';
import { HeaderColumnConfig } from '../../models/grid-table.model';

interface InternalState {}

interface Props extends BaseProps {}

@inject('collectionsStore')
@inject('contentStore')
@inject('domainStore')
@inject('groupsStore')
@inject('simpleListsStore')
@inject('uiStore')
@observer
class Groups extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];
  constructor(props: Props) {
    super(props);

    this.headerColumnConfigs = [
      {
        colProps: {
          md: '9',
        },
        handleAction: (action: Action): void => {
          this.props.groupsStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'name',
        store: this.props.groupsStore,
        title: 'Name',
      },
      {
        colProps: {
          md: '2',
        },
        handleAction: (action: Action): void => {
          this.props.groupsStore.handleTableAction(this.props, action);
        },
        isCentered: true,
        isSortable: true,
        itemKey: 'users',
        store: this.props.groupsStore,
        title: 'Users',
      },

      {
        colProps: { md: '1' },
        title: '',
      },
    ];
  }

  public async componentDidMount(): Promise<void> {
    this.props.groupsStore.fetchItems(this.props);
  }

  public render(): JSX.Element {
    const { domainStore, groupsStore, collectionsStore, contentStore } =
      this.props;

    return (
      <>
        <Container className="mt-4" fluid>
          <h4
            className="mb-4"
            style={{ color: this.props.uiStore.common.colors.primary }}
          >
            <i className="fas fa-users-class" /> Groups
          </h4>
          <Row>
            <div className="col">
              <Card>
                <GridTableCardHeader
                  actionButtonTitle="Add Group"
                  actionButtonIcon="fa-plus"
                  ActionDropdownMenu={(): JSX.Element => (
                    <>
                      <TitleSubtitleDropdownItem
                        title="Core Group"
                        subtitles={[
                          {
                            icon: 'fa-tablet-alt',
                            title: 'Root Views',
                          },
                          {
                            icon: 'fa-upload',
                            title: 'Content Sets',
                          },
                          {
                            icon: 'fa-chart-line',
                            title: 'Analytics',
                          },
                        ]}
                        clickHandler={(): void => {
                          groupsStore.createItem({
                            domainStore,
                            meta: {
                              typeId: GroupTypes.Core,
                            },
                          });
                        }}
                      />

                      <TitleSubtitleDropdownItem
                        title="Content Set Groups"
                        subtitles={[
                          {
                            icon: 'fa-upload',
                            title: 'Content Sets',
                          },
                          {
                            icon: 'fa-chart-line',
                            title: 'Analytics',
                          },
                        ]}
                        clickHandler={(): void => {
                          groupsStore.createItem({
                            domainStore,
                            meta: {
                              typeId: GroupTypes.Content,
                            },
                          });
                        }}
                      />

                      <TitleSubtitleDropdownItem
                        title="Organizational Groups"
                        subtitles={[
                          {
                            icon: 'fa-chart-line',
                            title: 'Analytics',
                          },
                        ]}
                        clickHandler={(): void => {
                          groupsStore.createItem({
                            domainStore,
                            meta: {
                              typeId: GroupTypes.Organizational,
                            },
                          });
                        }}
                      />
                    </>
                  )}
                  HelpDropdownMenu={(): JSX.Element => (
                    <>
                      <div className="help-dropdown-item">
                        <h4>There are 3 types of User Groups</h4>
                        <DropdownItem divider className="mb-3" />
                        <h5>
                          <span>Core Groups</span>
                          <i className="fal fa-chart-line"></i>
                          <i className="fal fa-folder-tree"></i>
                          <i className="fal fa-tablet-alt"></i>
                        </h5>
                        <p>
                          Each user needs to be a part of at least 1 core group,
                          a core group controls what root views user see when
                          they open the app as well as common collections +
                          content.
                        </p>

                        <h5>
                          <span>Content Set Groups</span>
                          <i className="fal fa-chart-line"></i>
                          <i className="fal fa-folder-tree"></i>
                        </h5>
                        <p>
                          Gives user access to additional collections and
                          content.
                        </p>

                        <h5>
                          <span>Organizational Group</span>
                          <i className="fal fa-chart-line"></i>
                        </h5>
                        <p>Only provides analytics for users.</p>
                      </div>
                    </>
                  )}
                  store={groupsStore}
                />

                <GridTable
                  headerColumnConfigs={this.headerColumnConfigs}
                  headerRowProps={
                    { className: 'mr-2 ml-2 mx-0 pl-4 pr-3' } as any
                  }
                  idKey={'contentId'}
                  items={Array.from(groupsStore?.items?.values())}
                  store={groupsStore}
                  RowContents={RowContents}
                  ExpandedRowContents={ExpandedRowContents}
                />

                <GridTableCardFooter
                  store={groupsStore}
                  storeInstance={this.props.groupsStore}
                />
              </Card>
            </div>
          </Row>
        </Container>

        <DrawerModal
          surtitle="Users within"
          store={usersStore}
          storeKey={'Groups'}
        >
          <DrawerModalUsers
            id={usersStore.drawerModals.Groups?.id}
            count={usersStore.drawerModals.Groups?.count}
            parentModalStore={groupsStore}
            filterBy="groupIds"
            filterByDisplayText="group"
          />
        </DrawerModal>

        <DrawerModal
          surtitle="Collections within"
          store={collectionsStore}
          storeKey={'Groups'}
        >
          <DrawerModalCollections
            id={collectionsStore.drawerModals.Groups?.id}
            count={collectionsStore.drawerModals.Groups?.count}
            parentModalStore={groupsStore}
          />
        </DrawerModal>

        <DrawerModal
          surtitle="Content Files within"
          store={contentStore}
          storeKey={'Groups'}
        >
          <DrawerModalContentItems
            id={contentStore.drawerModals.Groups?.id}
            count={contentStore.drawerModals.Groups?.count}
            parentModalStore={groupsStore}
          />
        </DrawerModal>
      </>
    );
  }
}

export default Groups;
