import { CollectionViewsService } from '../services/collection-views.service';

import { BaseStore } from './base.store';

export class CollectionViewsStore extends BaseStore<any> {
  constructor() {
    super(
      new CollectionViewsService(),
      'CollectionViewsStore',
      'CollectionViews',
    );
  }
}

export const collectionViewsStore: CollectionViewsStore =
  new CollectionViewsStore();
