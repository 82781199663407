import React from 'react';
import { Container } from 'reactstrap';
import { DropzoneContexts } from 'enums/dropzone.enum';
import { inject, observer } from 'mobx-react';

import Dropzone from '../../../../components/Dropzone/Dropzone';
import { UiStoreModel } from '../../../../models/ui.model';

interface InternalState {}

interface Props {
  uiStore?: UiStoreModel;
  theme: any;
}

@inject('uiStore')
@observer
class ContentItemsAddSourceComputer extends React.Component<
  Props,
  InternalState
> {
  public render(): JSX.Element {
    return (
      <>
        <Container className="dropzone mt-4" fluid>
          <Dropzone context={DropzoneContexts.ContentItemsPage} />
        </Container>
      </>
    );
  }
}

export default ContentItemsAddSourceComputer;
