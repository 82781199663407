/* eslint-disable max-lines-per-function */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import Routes from '../../enums/routes.enum';
import { UiStoreModel } from '../../models/ui.model';

const defaultTheme: string = 'dark';

interface InternalState {}

interface Props {
  uiStore?: UiStoreModel;
  theme: any;
  input: any;
  previews: any;
  submitButton: any;
  dropzoneProps: any;
  inputLabelWithFiles: any;
  files: any;
  extra: { maxFiles: any };
}

@inject('uiStore')
@observer
class DropzoneLayout extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { input, previews, dropzoneProps, files } = this.props;

    const theme: string = this.props.theme ? this.props.theme : defaultTheme;

    return (
      <div {...dropzoneProps}>
        {files.length > 0 && (
          <Container className="dropzone mt-4" fluid>
            <h4
              className="mb-4"
              style={{ color: this.props.uiStore.common.colors.primary }}
            >
              Content Items
            </h4>
            <Row>
              <div className="col">
                <Row className="mt-0 mb-4">
                  <Col md="4">
                    <Form
                      className={classnames(
                        'navbar-search form-inline mr-sm-3',
                        {
                          'navbar-search-light': theme === 'dark',
                        },
                        {
                          'navbar-search-dark': theme === 'light',
                        },
                      )}
                    >
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group-alternative input-group-merge">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Search" type="text" />
                        </InputGroup>
                      </FormGroup>
                      <button
                        aria-label="Close"
                        className="close"
                        type="button"
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </Form>
                  </Col>
                  <Col md="3"></Col>
                  <Col
                    md="5"
                    className="d-flex align-items-center justify-content-end"
                  >
                    {files.length > 0 && input}
                    <Link to={Routes.ContentItems}>
                      <Button className="storyslab-button">
                        <span className="btn-inner--text">Done</span>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row className="">
              <Col md="9">
                <h5 className="ml-5">Display Name</h5>
              </Col>

              <Col md="2">
                <h5>Category</h5>
              </Col>
              <Col md="1"></Col>
            </Row>
            {previews}
          </Container>
        )}
        {files.length === 0 && input}
      </div>
    );
  }
}

export default DropzoneLayout;
