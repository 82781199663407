/* eslint-disable max-lines-per-function */
/* eslint-disable multiline-comment-style */
import React, { Dispatch, SetStateAction, useState } from 'react';
import { debounce, DebouncedFunc } from 'lodash';
import { observer } from 'mobx-react';
import { domainStore, usersStore } from 'stores';

import SimpleToggle from 'components/Forms/SimpleToggle';
import DetailSelect from 'components/Users/DetailSelect';

interface Props {
  user: any;
}

interface InternalProps extends Props {
  activeMenu: string;
  setActiveMenu: Dispatch<SetStateAction<string>>;
  isDropped: boolean;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
}

const BasicFields: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const [firstName, setFirstName] = useState(props.user.firstName);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastName, setLastName] = useState(props.user.lastName);
    const [lastNameError, setLastNameError] = useState('');
    const [email, setEmail] = useState(props.user.email);

    const debounceTextField: DebouncedFunc<(value: any, field: any) => void> =
      React.useMemo(
        () =>
          debounce((value, field) => {
            usersStore.updateItem({
              body: { [field]: value },
              domainStore,
              id: props.user.id,
            });
          }, 750),
        [props.user.id],
      );

    const handleChangeFirstName: (e) => void = React.useCallback(
      (e) => {
        setFirstName(e.target.value);
        if (e.target.value.length < 3) {
          setFirstNameError('First name must be 3 or more characters');
        } else {
          setFirstNameError('');
          debounceTextField(e.target.value, 'firstName');
        }
      },
      [debounceTextField],
    );

    const handleChangeLastName: (e) => void = React.useCallback(
      (e) => {
        setLastName(e.target.value);
        if (e.target.value.length < 3) {
          setLastNameError('Last name must be 3 or more characters');
        } else {
          setLastNameError('');
          debounceTextField(e.target.value, 'lastName');
        }
      },
      [debounceTextField],
    );

    return (
      <div className="content-contain">
        <div>
          <div className="form-field-contain">
            <div className="form-field-label">first name</div>
            <input
              autoComplete="new-password"
              onChange={handleChangeFirstName}
              type="text"
              value={firstName}
            />
            <div style={styles.error}>{firstNameError}</div>
          </div>
          <div className="form-field-contain">
            <div className="form-field-label">last name</div>
            <input
              autoComplete="new-password"
              onChange={handleChangeLastName}
              type="text"
              value={lastName}
            />
            <div style={styles.error}>{lastNameError}</div>
          </div>

          <div className="form-field-contain">
            <div className="form-field-label">user role</div>
            <DetailSelect
              {...props}
              idList={'roleIds'}
              listType={'userRoles'}
            />
          </div>

          <div className="form-field-contain">
            <div className="form-field-label">deactivate</div>
            <SimpleToggle
              didCheckOnLoad={props.user.status === 2}
              handleChange={(isChecked): void => {
                if (isChecked) {
                  usersStore.updateItem({
                    body: { status: 2 },
                    domainStore,
                    id: props.user.id,
                  });
                } else {
                  usersStore.updateItem({
                    body: { status: 1 },
                    domainStore,
                    id: props.user.id,
                  });
                }
              }}
            />
          </div>
        </div>
        <div>
          <div className="form-field-contain">
            <div className="form-field-label">email</div>
            <input
              autoComplete="new-password"
              disabled={true}
              type="text"
              value={email}
              onChange={(e): void => setEmail(e.target.value)}
            />
          </div>
          <div className="form-field-contain">
            <div className="form-field-label">belongs to...</div>
            <DetailSelect {...props} idList={'groupIds'} listType={'groups'} />
          </div>
        </div>
      </div>
    );
  },
);

const styles: any = {
  error: {
    color: 'red',
    fontSize: '12px',
  },
};

export default BasicFields;
