import React from 'react';

const NavigationIndicator = () => {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x={0}
      y={0}
      viewBox="0 0 499 381"
      xmlSpace="preserve"
    >
      <style>{'.prefix__st12{fill:#6d7278}.prefix__st14{fill:#d8df25}'}</style>
      <defs>
        <path id="prefix__SVGID_1_" d="M0 0h499v381H0z" />
      </defs>
      <clipPath id="prefix__SVGID_2_">
        <use xlinkHref="#prefix__SVGID_1_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__SVGID_2_)">
        <defs>
          <path id="prefix__SVGID_3_" d="M0-1h500v383H0z" />
        </defs>
        <clipPath id="prefix__SVGID_4_">
          <use xlinkHref="#prefix__SVGID_3_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_4_)">
          <defs>
            <path
              id="prefix__SVGID_5_"
              d="M7.3 0h484.4c4 0 7.3 3.3 7.3 7.3v366.4c0 4-3.3 7.3-7.3 7.3H7.3c-4 0-7.3-3.3-7.3-7.3V7.3C0 3.3 3.3 0 7.3 0"
            />
          </defs>
          <clipPath id="prefix__SVGID_6_">
            <use xlinkHref="#prefix__SVGID_5_" overflow="visible" />
          </clipPath>
          <g clipPath="url(#prefix__SVGID_6_)">
            <defs>
              <path id="prefix__SVGID_7_" d="M0 0h499v381H0z" />
            </defs>
            <clipPath id="prefix__SVGID_8_">
              <use xlinkHref="#prefix__SVGID_7_" overflow="visible" />
            </clipPath>
            <path
              clipPath="url(#prefix__SVGID_8_)"
              fill="#333"
              d="M-5-5h509v391H-5z"
            />
          </g>
        </g>
      </g>
      <defs>
        <path id="prefix__SVGID_9_" d="M0 0h499v381H0z" />
      </defs>
      <clipPath id="prefix__SVGID_10_">
        <use xlinkHref="#prefix__SVGID_9_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__SVGID_10_)">
        <defs>
          <path id="prefix__SVGID_11_" d="M42 29h436v324H42z" />
        </defs>
        <clipPath id="prefix__SVGID_12_">
          <use xlinkHref="#prefix__SVGID_11_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_12_)">
          <defs>
            <path id="prefix__SVGID_13_" d="M42 30h436v323H42z" />
          </defs>
          <clipPath id="prefix__SVGID_14_">
            <use xlinkHref="#prefix__SVGID_13_" overflow="visible" />
          </clipPath>
          <path
            clipPath="url(#prefix__SVGID_14_)"
            fill="#f2f2f2"
            d="M37 25h446v333H37z"
          />
        </g>
      </g>
      <defs>
        <path id="prefix__SVGID_15_" d="M173 47h73v71h-73z" />
      </defs>
      <clipPath id="prefix__SVGID_16_">
        <use xlinkHref="#prefix__SVGID_15_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#prefix__SVGID_16_)">
        <defs>
          <path id="prefix__SVGID_17_" d="M-209-314h1506V710H-209z" />
        </defs>
        <clipPath id="prefix__SVGID_18_">
          <use xlinkHref="#prefix__SVGID_17_" overflow="visible" />
        </clipPath>
        <g clipPath="url(#prefix__SVGID_18_)">
          <defs>
            <path id="prefix__SVGID_19_" d="M172 46h74v73h-74z" />
          </defs>
          <clipPath id="prefix__SVGID_20_">
            <use xlinkHref="#prefix__SVGID_19_" overflow="visible" />
          </clipPath>
          <g clipPath="url(#prefix__SVGID_20_)">
            <defs>
              <path
                id="prefix__SVGID_21_"
                d="M215 113.2l-3.6 3.6c-1.5 1.5-4 1.5-5.5 0l-31.7-31.6c-1.5-1.5-1.5-4 0-5.5l31.7-31.6c1.5-1.5 4-1.5 5.5 0l3.6 3.6c1.5 1.5 1.5 4.1-.1 5.6L195.3 76h46.8c2.2 0 3.9 1.7 3.9 3.9v5.2c0 2.2-1.7 3.9-3.9 3.9h-46.8l19.6 18.7c1.6 1.5 1.6 4 .1 5.5"
              />
            </defs>
            <clipPath id="prefix__SVGID_22_">
              <use xlinkHref="#prefix__SVGID_21_" overflow="visible" />
            </clipPath>
            <g clipPath="url(#prefix__SVGID_22_)">
              <defs>
                <path id="prefix__SVGID_23_" d="M173 46h73v72h-73z" />
              </defs>
              <clipPath id="prefix__SVGID_24_">
                <use xlinkHref="#prefix__SVGID_23_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#prefix__SVGID_24_)"
                fill="#6d7278"
                d="M168 42h83v81h-83z"
              />
            </g>
          </g>
        </g>
      </g>
      <path className="prefix__st12" d="M41.4 29.4h113v324h-113z" />
      <path fill="#fff" d="M41.4 29.4h113v88h-113z" />
      <circle className="prefix__st14" cx={57.4} cy={47.4} r={8} />
      <circle className="prefix__st14" cx={57.4} cy={72.9} r={8} />
      <circle className="prefix__st14" cx={57.4} cy={98.4} r={8} />
      <path
        className="prefix__st12"
        d="M172.4 297.4h-118c-6.6 0-12 5.4-12 12v16c0 6.6 5.4 12 12 12h118c6.6 0 12-5.4 12-12v-16c0-6.6-5.4-12-12-12z"
      />
    </svg>
  );
};
export default NavigationIndicator;
