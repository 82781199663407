import React from 'react';
import Routes from 'enums/routes.enum';
import { collectionViewsStore } from 'stores';

import ViewConfigurator from 'components/ViewConfigurator';

interface Props {}

const CollectionViewConfigurator: (props: Props) => JSX.Element = (
  props: Props,
) => {
  return (
    <>
      <ViewConfigurator
        home={Routes.ContentCollectionViewLayouts}
        store={collectionViewsStore}
        title={'Collection Views'}
      />
    </>
  );
};

export default CollectionViewConfigurator;
