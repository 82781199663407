import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  setUploadViewKey: Dispatch<SetStateAction<string>>;
}

interface Source {
  iconName: string;
  key: string;
  text: string;
}

interface MenuOptionProps {
  setUploadViewKey: Dispatch<SetStateAction<string>>;
  source: Source;
}

const SourceOptions: Array<Source> = [
  { iconName: 'fa-upload', key: 'dropzone', text: 'Upload File' },
  { iconName: 'fa-link', key: 'link', text: 'Link' },
  {
    iconName: 'fa-universal-access',
    key: 'public-file',
    text: 'Public File on Web',
  },
];

const MenuOption: (props: MenuOptionProps) => JSX.Element = (
  props: MenuOptionProps,
) => {
  return (
    <>
      <div
        style={styles.option}
        onClick={(): void => {
          props.setUploadViewKey(props.source.key);
        }}
      >
        <i style={styles.icon} className={`fas ${props.source.iconName}`} />
        <span style={styles.text}>{props.source.text}</span>
      </div>
    </>
  );
};

const SourceMenuList: (props: Props) => JSX.Element = (props: Props) => {
  return (
    <>
      {SourceOptions.map((source, index): JSX.Element => {
        return (
          <MenuOption
            key={`source-${index}`}
            source={source}
            setUploadViewKey={props.setUploadViewKey}
          />
        );
      })}
    </>
  );
};

const styles: any = {
  icon: {
    color: '#4d4d4d',
    fontSize: '26px',
    marginRight: '10px',
  },
  option: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '15px',
  },
  text: {
    fontSize: '12px',
    fontWeight: 400,
  },
  wrapper: {
    borderRadius: '3px',
    marginTop: '0',
    position: 'absolute',
    width: '259px',
    zIndex: 100000,
  },
};

export default SourceMenuList;
