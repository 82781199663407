import { SimpleListsStoreModel } from 'models/simple-lists.model';
import { simpleListsStore } from 'stores';

import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { DomainStoreModel } from '../models/domain.model';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

interface BaseServiceUrls {
  create: EdgeUrls;
  delete: EdgeUrls;
  fetch: EdgeUrls;
  update: EdgeUrls;
}

export class BaseService {
  private urls: BaseServiceUrls;

  constructor(urls: BaseServiceUrls) {
    this.urls = urls;

    this.create = this.create.bind(this);
    this.delete = this.delete.bind(this);
    this.fetch = this.fetch.bind(this);
    this.update = this.update.bind(this);
  }

  public async create({
    meta,
    domainStore,
  }: {
    meta?: { [key: string]: any };
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      body: meta,
      method: EdgeRequestMethods.POST,
      url: this.urls.create,
    });
  }

  public async delete({
    id,
    domainStore,
  }: {
    id: number;
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      method: EdgeRequestMethods.DELETE,
      url: this.urls.delete.replace(':id', id.toString()),
    });
  }

  public async fetch(
    domainStore: DomainStoreModel,
    queryParams?: string,
    id?: number,
  ): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      includeMeta: true,
      method: EdgeRequestMethods.GET,
      url: `${this.urls.fetch}${id ? `/${id}` : ''}${
        queryParams ? `?${queryParams}` : ''
      }`,
    });
  }

  public async update({
    id,
    body,
    domainStore,
  }: {
    id: number;
    body: { [key: string]: any };
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      body: body,
      method: EdgeRequestMethods.PUT,
      url: this.urls.update.replace(':id', id.toString()),
    }).then((response): any => {
      let key: keyof SimpleListsStoreModel;
      let url: EdgeUrls;
      switch (this.constructor.name) {
        case 'CollectionsService':
          key = 'collections';
          url = EdgeUrls.CollectionSimpleLists;
          break;
        case 'ContentService':
          key = 'collections';
          url = EdgeUrls.CollectionSimpleLists;
          break;
        case 'CategoriesService':
          key = 'categories';
          url = EdgeUrls.CategorySimpleLists;
          break;
        case 'GroupsService':
          key = 'groups';
          url = EdgeUrls.GroupSimpleList;
          break;
        default:
          break;
      }
      simpleListsStore.populateSimpleList({
        domainStore,
        key,
        url,
      });
      return response;
    });
  }
}
