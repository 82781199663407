import { UserRolesService } from '../services/user-roles.service';

import { BaseStore } from './base.store';

export class UserRolesStore extends BaseStore<any> {
  constructor() {
    super(new UserRolesService(), 'RolesStore', 'Roles');
  }
}

export const userRolesStore: UserRolesStore = new UserRolesStore();
