import { EdgeUrls } from 'enums/edge.enum';
import { BaseService } from 'services/base.service';

export class NavItemViewService extends BaseService {
  constructor() {
    super({
      create: EdgeUrls.Views,
      delete: EdgeUrls.View,
      fetch: EdgeUrls.NavItemViews,
      update: EdgeUrls.View,
    });
  }
}
