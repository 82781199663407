import React, { KeyboardEvent } from 'react';
import { Container, UncontrolledDropdown } from 'reactstrap';
import { SimpleList, SimpleListItem } from 'models/simple-lists.model';

import { ActionPayload } from '@storyslab/storyslab.common.models';

import MultiEditButton from './MultiEditButton';
import MultiEditDropdownMenu from './MultiEditDropdownMenu';

interface InternalState {}

interface Props {
  activeIds: Array<number>;
  buttonTitle: string;
  icon?: string;
  items: SimpleList;
  label: string;
  parentId: number;
  shouldRemoveTopMargin?: boolean;
  theme?: string;
  handleUpdate: (params: { id: number; payload: ActionPayload }) => void;
  rowRef?: any;
}

class LabeledMultiEditDropdownMenu extends React.Component<
  Props,
  InternalState
> {
  private dropdownRef: any = React.createRef();

  private generateButtonTitle(): string {
    const { activeIds, items, buttonTitle } = this.props;

    if (!activeIds || activeIds.length === 0) {
      return `Add ${buttonTitle}`;
    }

    if (activeIds && activeIds.length === 1) {
      const item: SimpleListItem = items.get(activeIds[0]);

      if (!item || !item.name) {
        return `Add ${buttonTitle}`;
      }

      return item.name;
    }

    if (activeIds && activeIds.length > 1) {
      /*
       * Someday when I ask myself WhyTF did you do this,
       * remember that when we get back a filtered subset of items
       * the returned ActiveIds is ALL of the id's associated with the
       * row, so we need an intersection of the two Arrays to know the
       * true count.
       */

      const intersectionArray: Array<number> = Array.from(items.keys()).filter(
        (x) => activeIds.includes(x),
      );

      return `${intersectionArray.length} ${buttonTitle}`;
    }
  }

  private handleEsc(e: KeyboardEvent<HTMLDivElement>, rowRef: any): void {
    // Stop esc key from bubbling up to row listener
    e.stopPropagation();
    if (e.key === 'Escape') {
      // Dropdown visibility isn't handled by local state, so just remove class
      const openSelect: any = document.getElementsByClassName(
        'multi-edit-dropdown-menu show',
      );
      openSelect[0].classList.remove('show');
      // Blur this node so it doesn't get called again
      this.dropdownRef.current.blur();
      if (rowRef) {
        /*
         * A reference to the row node needs to be passed down. If it is passed, then
         * focus it. This will allow its keydown listener to behave normally.
         */
        rowRef.current.focus();
      }
    }
  }

  public render(): JSX.Element {
    const {
      activeIds,
      icon,
      items,
      label,
      shouldRemoveTopMargin,
      theme,
      rowRef,
    } = this.props;

    return (
      <div
        tabIndex={-1}
        ref={this.dropdownRef}
        onKeyDown={(e: KeyboardEvent<HTMLDivElement>): void => {
          this.handleEsc(e, rowRef);
        }}
      >
        <Container
          className={`multi-edit-wrapper ${
            shouldRemoveTopMargin ? '' : 'mt-3'
          } m-0 p-0`}
        >
          <div className="title-wrapper">
            <span>{label}</span>
          </div>
          <UncontrolledDropdown left="true">
            <MultiEditButton title={this.generateButtonTitle()} />
            <MultiEditDropdownMenu
              {...{
                activeIds,
                handleUpdate: this.props.handleUpdate,
                icon,
                items,
                parentId: this.props.parentId,
                theme,
              }}
            />
          </UncontrolledDropdown>
        </Container>
      </div>
    );
  }
}

export default LabeledMultiEditDropdownMenu;
