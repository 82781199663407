import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import _ from 'lodash';
import { toJS } from 'mobx';
import { DomainStoreModel } from 'models/domain.model';
import { simpleListsStore } from 'stores';

import { EdgeUrls } from '../enums/edge.enum';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export class GroupsService extends BaseService {
  private newItemDefaultName: string = 'New Group';

  constructor() {
    super({
      create: EdgeUrls.Groups,
      delete: EdgeUrls.Group,
      fetch: EdgeUrls.Groups,
      update: EdgeUrls.Group,
    });
  }

  public async create({
    meta,
    domainStore,
  }: {
    meta?: { [key: string]: any };
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      body: { name: this.newItemDefaultName, typeId: meta.typeId },
      method: EdgeRequestMethods.POST,
      url: EdgeUrls.Groups,
    }).then((newGroup) => {
      simpleListsStore.populateSimpleList({
        domainStore,
        key: 'groups',
        url: EdgeUrls.GroupSimpleList,
      });
      return newGroup;
    });
  }
}

export function getAutoGroups(): any {
  return _.filter(toJS(simpleListsStore.groups), {
    autoAssign: true,
  })
    .map((group) => group.id.toString())
    .join();
}
