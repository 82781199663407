import React, { useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

interface Props {
  colorCode: string;
  handleChange: (value) => void;
}

export default function HexCodePicker(props: Props): JSX.Element {
  const [hexCode, setHexCode] = useState(props.colorCode);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  function persistChange(value): void {
    setHexCode(value);
    props.handleChange(value);
  }

  return (
    <>
      <div style={styles.contain}>
        <span style={styles.title}>Color Hex Code</span>
        <div
          style={styles.pickerContain}
          onClick={(): void => {
            setIsPickerOpen(true);
          }}
        >
          {isPickerOpen && (
            <>
              <div
                className="backsplash"
                onClick={(e): void => {
                  e.stopPropagation();
                  setIsPickerOpen(false);
                }}
              ></div>
              <HexColorPicker
                style={styles.picker}
                color={hexCode}
                onChange={(value): void => persistChange(value)}
              />
              <HexColorInput
                style={{
                  ...styles.pickerInput,
                  ...styles.input,
                }}
                color={hexCode}
                onChange={(value): void => persistChange(value)}
              />
            </>
          )}
          <div style={styles.input}>{hexCode}</div>
          <div style={{ ...styles.preview, backgroundColor: hexCode }}></div>
        </div>
      </div>
    </>
  );
}

const styles: any = {
  contain: {
    alignItems: 'center',
    display: 'flex',
  },
  input: {
    borderBottom: '1px solid #808080',
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    cursor: 'pointer',
    margin: '0 5px',
    textAlign: 'center',
    width: '87px',
  },
  picker: {
    position: 'absolute',
    right: 0,
    top: '100%',
    zIndex: 100000,
  },
  pickerContain: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
  },
  pickerInput: {
    position: 'absolute',
    zIndex: 100000,
  },
  preview: {
    borderRadius: '5px',
    height: '33px',
    width: '33px',
  },
  title: {
    color: '#808080',
    fontSize: '12px',
    fontWeight: 700,
  },
};
