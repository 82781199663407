import { Action } from 'models/action.model';
import { collectionViewsStore } from 'stores';

export const headerColumnConfig: any = [
  {
    colProps: {
      lg: '9',
      md: '9',
    },
    handleAction: (action: Action): void => {
      console.log('action');
    },
    isSortable: false,
    itemKey: 'name',
    store: collectionViewsStore,
    title: 'Layout Title',
  },
  {
    colProps: {
      lg: '2',
      md: '2',
    },
    isFilterable: false,
    isSortable: false,
    store: collectionViewsStore,
    title: 'Last Updated',
  },
];
