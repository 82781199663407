import React from 'react';
import { Col } from 'reactstrap';
import { simpleListsStore } from 'stores';

interface InternalState {}

interface Props {
  columnProps?: { [key: string]: string };
  isCentered?: boolean;
  item: any;
  values: any;
}

class MultiValueTextColumn extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { columnProps, isCentered, values } = this.props;

    if (!values) {
      return <></>;
    }

    return (
      <>
        <Col {...columnProps} className="item-row-horizontal">
          {/* <Input type="checkbox" className="item-row-checkbox mr-2" /> */}

          <div
            style={{
              display: 'inline-block',
              textAlign: isCentered ? 'center' : 'left',
              width: '100%',
            }}
            className={`item-row-editable-text ${!isCentered && 'ml-2'}`}
          >
            <h5>
              {values.length === 1
                ? simpleListsStore.userRoles.get(values[0])?.name
                : values.map((value, index) => {
                    if (index === values.length - 1) {
                      return simpleListsStore.userRoles.get(value)?.name;
                    } else {
                      return `${simpleListsStore.userRoles.get(value)?.name}, `;
                    }
                  })}
            </h5>
          </div>
        </Col>
      </>
    );
  }
}

export default MultiValueTextColumn;
