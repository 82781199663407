export enum ActionTypes {
  FILTER = 'filter',
  PAGINATE = 'paginate',
  SEARCH = 'search',
  SORT = 'sort',
}

export interface Action {
  type: ActionTypes;
  payload:
    | { [key: string]: string }
    | {
        [key: string]: {
          id?: number | string;
          ids?: Array<number | string>;
          selected: boolean;
        };
      }
    | string;
}
