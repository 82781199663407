import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { appNavigationStore, domainStore } from 'stores';

import { SortableItem } from './SortableItem';

interface Props {
  navItems: Array<string>;
  refreshList: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const NavItemList: (props: Props) => JSX.Element = (props: Props) => {
  const { navItems, refreshList } = props;

  const [items, setItems] = useState(navItems);

  const sensors: any = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    items.forEach((targetItemID, order: number) => {
      appNavigationStore.updateItem({
        body: { navOrder: order + 1 },
        domainStore,
        id: parseInt(targetItemID),
      });
    });
  }, [items]);

  useEffect(() => {
    if (navItems !== items) {
      setItems(navItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navItems]);

  function handleDragEnd(event): any {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex: any = items.indexOf(active.id);
        const newIndex: any = items.indexOf(over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  return (
    <>
      <p className="nav-item-list-title">Nav Items</p>
      <div className="list-contain">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((id) => (
              <SortableItem key={id} id={id} refreshList={refreshList} />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </>
  );
};

export default NavItemList;
