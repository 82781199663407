import { EdgeUrls } from '../enums/edge.enum';
import { BaseService } from '../services/base.service';

export class EntitlementsService extends BaseService {
  constructor() {
    super({
      create: null,
      delete: null,
      fetch: EdgeUrls.Entitlements,
      update: null,
    });
  }
}
