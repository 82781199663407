import React from 'react';
import SiteWideAlert from 'layouts/SiteWideAlert';
import { observer } from 'mobx-react';
import { uiStore } from 'stores';

import AdminNavBar from '../components/NavBars/AdminNavBar';
import Sidebar from '../components/Sidebar/Sidebar';
import AdminRoutes from '../routes/Admin';

interface Props {}

declare global {
  interface Window {
    siteWideComponent: any;
  }
}

@observer
class Admin extends React.Component {
  public state: any = {
    isModalOpen: false,
    modalApprove: () => {
      // Initially empty. Overwritten when called elsewhere.
    },
    modalBody: '',
    modalTitle: '',
    sidenavOpen: false,
  };

  constructor(props: Props) {
    super(props);
    this.toggleSidenav = this.toggleSidenav.bind(this);
    this.closeSidenav = this.closeSidenav.bind(this);
    this.openSidenav = this.openSidenav.bind(this);
    window.siteWideComponent = this;

    this.state.sidenavOpen = this.shouldPinSidebar();
  }

  private shouldPinSidebar(): boolean {
    return window.innerWidth >= 1200;
  }

  private toggleSidenav(): void {
    if (
      !this.shouldPinSidebar() &&
      document.body.classList.contains('g-sidenav-show')
    ) {
      this.closeSidenav();
    } else {
      this.openSidenav();
    }
  }

  private closeSidenav(): void {
    if (!this.shouldPinSidebar()) {
      document.body.classList.add('g-sidenav-hidden');
      document.body.classList.remove('g-sidenav-show');
      this.setState({
        sidenavOpen: false,
      });
    }
  }

  private openSidenav(): void {
    document.body.classList.add('g-sidenav-show');
    document.body.classList.remove('g-sidenav-hidden');
    this.setState({
      sidenavOpen: true,
    });
  }

  public componentDidMount(): void {
    if (this.shouldPinSidebar()) {
      this.openSidenav();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public componentDidUpdate(e: any): void {
    // Check if we have navigated to different route
    if (e.history?.location?.pathname !== e.location?.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      // eslint-disable-next-line react/no-string-refs
      (this.refs.mainContent as any).scrollTop = 0;
      if (!this.shouldPinSidebar()) {
        this.closeSidenav();
      }
    }
  }

  public show(params: {
    approve: () => void;
    body: string;
    title: string;
  }): void {
    this.setState({ modalTitle: params.title });
    this.setState({ modalBody: params.body });
    this.setState({ isModalOpen: true });
    this.setState({ modalApprove: params.approve });
  }

  public render(): JSX.Element {
    return (
      <>
        <SiteWideAlert
          approve={this.state.modalApprove}
          body={this.state.modalBody}
          close={(): void => this.setState({ isModalOpen: false })}
          isOpen={this.state.isModalOpen}
          title={this.state.modalTitle}
        />
        <Sidebar
          {...(this.props as any)}
          toggleSidenav={this.toggleSidenav}
          openSidenav={this.openSidenav}
          closeSidenav={this.closeSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            imgAlt: '...',
            imgSrc: uiStore.auth.logoUrl,
            innerLink: '/',
          }}
        />
        <div
          className="main-content"
          // eslint-disable-next-line react/no-string-refs
          ref="mainContent"
          // Hold - onClick={this.closeSidenav}
        >
          <AdminNavBar
            {...(this.props as any)}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={'test'}
          />

          <AdminRoutes />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.closeSidenav} />
        ) : null}
      </>
    );
  }
}

export default Admin;
