import React from 'react';

interface Props {
  title: string;
  iconKey?: string;
  iconUrl?: string;
  url: string;
}

export class ProviderLoginButton extends React.Component<Props, null> {
  public render(): JSX.Element {
    return (
      <div
        className="provider-button"
        onClick={(): void => {
          window.open(this.props.url, '_blank', 'noopener');
        }}
      >
        {this.props.iconUrl && (
          <img alt={this.props.title} src={this.props.iconUrl} />
        )}
        {!this.props.iconUrl && (
          <span className="provider-button-bumper"></span>
        )}
        <span className="btn-inner--text">{this.props.title}</span>
        <span className="provider-button-bumper"></span>
      </div>
    );
  }
}
