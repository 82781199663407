import React from 'react';
import { Container, Row } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { UiStoreModel } from '../../models/ui.model';

interface InternalState {}

interface Props {
  uiStore?: UiStoreModel;
  theme: any;
  input: any;
  previews: any;
  submitButton: any;
  dropzoneProps: any;
  inputLabelWithFiles: any;
  files: any;
  extra: { maxFiles: any };
}

@inject('uiStore')
@observer
class DropzoneTreeLayout extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { input, previews, dropzoneProps, files } = this.props;

    return (
      <div {...dropzoneProps}>
        {files.length > 0 && (
          <Container className="dropzone mt-4" fluid>
            <div className="mt-0 mb-5 d-flex align-items-center justify-content-between">
              <h4
                style={{
                  color: this.props.uiStore.common.colors.primary,
                }}
              >
                Adding Content Files to ___
              </h4>
              {files.length > 0 && input}
            </div>

            <Row className="ml-4 mr-5">
              <h5 className="ml-5">Display Name</h5>
            </Row>
            {previews}
          </Container>
        )}
        {files.length === 0 && input}
      </div>
    );
  }
}

export default DropzoneTreeLayout;
