import 'react-loading-skeleton/dist/skeleton.css';

import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
  width?: string;
  [key: string]: any;
}

function GhostLoader(props: Props): JSX.Element {
  return (
    <>
      <Skeleton
        baseColor={'#ededed'}
        highlightColor={'#f5f5f5'}
        width={props.width ? props.width : '55px'}
        {...props}
      />
    </>
  );
}

export default GhostLoader;
