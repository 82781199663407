import React from 'react';

interface Props {
  description: string;
  iconName: string;
  titleText: string;
}

const HeaderRow: any = (props: Props) => {
  const { description, titleText, iconName } = props;

  return (
    <div className="header-row">
      <i className={`${iconName} modal-icon`}></i>
      <div className="modal-title">{titleText}</div>
      <div className="modal-text">{description}</div>
    </div>
  );
};

export default HeaderRow;
