/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import TimeAgo from 'react-timeago';
import { Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { AdminContext } from 'routes/Admin';
import { entitlementsStore, userRolesStore, usersStore } from 'stores';

import Loader from 'components/Indicators/Loader';

interface Props {
  role: any;
  context?: string;
}

interface InternalProps extends Props {
  isDropped: boolean;
  setIsDropped: Dispatch<SetStateAction<boolean>>;
}

function getRowIcon(role): string {
  if (role.isHidden) {
    return 'fa-user-secret';
  } else if (role.isEditable) {
    return 'fa-user-cog';
  }
  return 'fa-globe-americas';
}

const RowHeader: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const { userSimpleList } = useContext(AdminContext);
    const { role, context, isDropped, setIsDropped } = props;

    if (!userSimpleList) {
      return <Loader isFullPage={true} />;
    }

    return (
      <>
        <div
          className={`detail-row-wrapper ${props.isDropped && 'active'}`}
          onClick={(): void => {
            if (context !== 'detail') {
              setIsDropped(!isDropped);
            }
          }}
        >
          <div className="detail-row-body">
            <Col md="7" style={{ alignItems: 'center', display: 'flex' }}>
              <div
                className="detail-row-thumbnail"
                style={{ color: '#000', fontSize: '26px', padding: '10px' }}
              >
                <i
                  className={`far ${getRowIcon(role)}`}
                  style={{ paddingTop: '3px' }}
                />
              </div>
              <div className="detail-row-title">
                <span>{role.name}</span>
              </div>
            </Col>
            {isDropped && context !== 'detail' && (
              <>
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                    }}
                  >
                    last updated:
                  </span>{' '}
                  <span style={{ fontSize: '14px', fontWeight: 300 }}>
                    <TimeAgo date={role.updated} />
                  </span>
                </div>
                <Col md="2">
                  <div
                    className="detail-done"
                    style={{ marginRight: 0, textAlign: 'right' }}
                  >
                    Done
                  </div>
                </Col>
              </>
            )}
            {!isDropped && (
              <>
                <Col md="2">
                  <div style={styles.rowContent}>
                    {userRolesStore.items.get(role.id).entitlementsCount}
                  </div>
                </Col>
                <Col md="2">
                  <div style={styles.rowContent}>{role.usersCount}</div>
                </Col>
              </>
            )}
          </div>
        </div>
      </>
    );
  },
);

function Content(props: InternalProps): JSX.Element {
  return (
    <div style={{ flex: 1 }}>
      <div style={styles.contentContain}>
        <div className="card-container" style={styles.metaWrapper}>
          <div style={styles.metaContain}>
            <div style={styles.metaTitle}>users</div>
            <div style={styles.metaData}>{props.role.usersCount}</div>
          </div>
          <i className="fas fa-user-friends" style={styles.metaIcon} />
        </div>
        <div className="card-container" style={styles.metaWrapper}>
          <div style={styles.metaContain}>
            <div style={styles.metaTitle}>entitlements</div>
            <div style={styles.metaData}>{props.role.entitlementsCount}</div>
          </div>
          <i className="fas fa-user-lock" style={styles.metaIcon} />
        </div>
      </div>
      <div style={styles.contentContain}>
        <div
          style={styles.metaButton}
          onClick={(): void => {
            usersStore.openDrawerModal({
              key: 'UserRoles',
              params: {
                count: props.role.usersCount,
                id: props.role.id,
                title: props.role.name,
              },
            });
          }}
        >
          edit users
        </div>
        <div
          style={styles.metaButton}
          onClick={(): void => {
            entitlementsStore.openDrawerModal({
              key: 'UserEntitlements',
              params: {
                count: props.role.entitlementsCount,
                id: props.role.id,
                title: props.role.name,
              },
            });
          }}
        >
          edit entitlements
        </div>
      </div>
    </div>
  );
}

const DroppedContent: (props: InternalProps) => JSX.Element = observer(
  (props: InternalProps) => {
    const { isDropped } = props;

    return (
      <>
        <div
          className={`detail-row-dropped-content ${isDropped && ' dropped'}`}
          tabIndex={0}
          style={{ height: isDropped ? '200px' : 0 }}
        >
          <Content {...props} />
        </div>
      </>
    );
  },
);

const RolesRow: (props: Props) => JSX.Element = observer((props: Props) => {
  const [isDropped, setIsDropped] = useState(false);
  const [hasDroppedClass, setHasDroppedClass] = useState(true);

  useEffect(() => {
    if (isDropped) {
      setHasDroppedClass(true);
    } else {
      setTimeout((): void => {
        setHasDroppedClass(false);
      }, 400);
    }
  }, [isDropped]);

  return (
    <>
      {isDropped && (
        <div
          className="detail-backsplash"
          onClick={(): void => {
            if (props.context !== 'detail') {
              setIsDropped(false);
            }
          }}
        ></div>
      )}
      <div
        className={`detail-view-wrapper ${hasDroppedClass && 'dropped'}`}
        tabIndex={0}
        onKeyUp={(e): void => {
          if (e.key === 'Escape') {
            setIsDropped(false);
          }
        }}
      >
        <RowHeader
          {...props}
          isDropped={isDropped}
          setIsDropped={setIsDropped}
        />
        <DroppedContent
          {...props}
          isDropped={isDropped}
          setIsDropped={setIsDropped}
        />
      </div>
    </>
  );
});

const styles: any = {
  contentContain: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: 0,
  },
  metaButton: {
    backgroundColor: '#4d4d4d',
    borderRadius: '8px',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 800,
    margin: '0 1rem',
    padding: '10px',
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '220px',
  },
  metaContain: {
    margin: '0 .5rem',
  },
  metaData: {
    color: '#4d4d4d',
    fontSize: '24px',
    fontWeight: 800,
  },
  metaIcon: {
    backgroundColor: '#0065A3',
    borderRadius: '26px',
    color: '#fff',
    fontSize: '15px',
    padding: '15px 13px',
  },
  metaTitle: {
    color: '#0065A3',
    fontSize: '12px',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  metaWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem',
    width: '220px',
  },
  rowContent: {
    color: '#808080',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
  },
};

export default RolesRow;
