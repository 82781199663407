import React from 'react';
import { Button } from 'reactstrap';
import { RenderKeyHelper } from 'helpers/render-key.helper';
import { inject, observer } from 'mobx-react';
import { BaseProps } from 'models/base.model';

import GridTableCardFooter from 'components/GridTable/CardFooter';
import GridTableCardHeader from 'components/GridTable/CardHeader';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper('GridTable');

interface InternalState {}

interface Props extends BaseProps {
  selectCollection: (number) => void;
}

@inject('collectionsStore')
@inject('domainStore')
@inject('uiStore')
@observer
class DrawerModalContents extends React.Component<Props, InternalState> {
  public componentDidMount(): void {
    const { collectionsStore } = this.props;
    collectionsStore.fetchItems(this.props);
  }

  public render(): JSX.Element {
    const { collectionsStore, selectCollection } = this.props;
    const items: any = Array.from(collectionsStore.items.values());
    return (
      <div className={'container-fluid'}>
        <GridTableCardHeader actionButtonTitle={''} store={collectionsStore} />
        <div className={'row simpleDrawerHeader'}>
          <div className={'col-md-10'}>
            <div className="p-3">Name</div>
          </div>
        </div>
        {items.map((item: any, index: any) => {
          return (
            <div
              key={renderKeyHelper.generate(
                {
                  id: item.id,
                  index,
                },
                'TableRow',
              )}
              className={`row simpleDrawerRow ${
                index % 2 === 0 ? 'dark-row' : ''
              }`}
              tabIndex={0}
            >
              <div className={'col-md-10'}>
                <div className="p-3">{item.name}</div>
              </div>
              <div className={'col-md-2 py-3'}>
                <Button
                  className="drawerButton"
                  style={{
                    backgroundColor: this.props.uiStore.common.colors.primary,
                  }}
                  onClick={(): void => {
                    selectCollection(item.id);
                    collectionsStore.closeAllDrawerModals();
                  }}
                >
                  add collection
                </Button>
              </div>
            </div>
          );
        })}
        <GridTableCardFooter
          store={collectionsStore}
          storeInstance={collectionsStore}
        />
      </div>
    );
  }
}

export default DrawerModalContents;
