/* eslint-disable max-lines-per-function */
/* eslint-disable multiline-comment-style */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { EdgeUrls } from 'enums/edge.enum';
import _, { debounce, DebouncedFunc } from 'lodash';
import { observer } from 'mobx-react';
import { standardGet, standardPut } from 'services/edge.service';
import { domainStore, uiStore } from 'stores';
import { BaseStore } from 'stores/base.store';

import { LayoutConfiguration, View } from '@storyslab/storyslab.common.models';
import GhostLoader from 'components/Indicators/GhostLoader';
import ComponentList from 'components/ViewConfigurator/ComponentList';
import LayoutPreview from 'components/ViewConfigurator/LayoutPreview';

interface Props {
  customHeader?: any;
  home: string;
  store: BaseStore<any>;
  title: string;
}

interface ViewConfig {
  layoutConfiguration: LayoutConfiguration;
  view: View;
}

const ViewConfigurator: any = observer((props: Props) => {
  const { customHeader, home, store, title } = props;

  let { id } = useParams<{ id: any }>();
  id = parseInt(id);

  const [activePreviewKey, setActivePreviewKey] = useState<string>();
  const [invalidName, setInvalidName] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [viewName, setViewName] = useState<string>();
  const [viewConfig, setViewConfig] = useState<ViewConfig>();

  /* Component did mount */
  useEffect(() => {
    Promise.all([
      store.fetchItems({ domainStore }),
      standardGet(EdgeUrls.View.replace(':id', id)),
    ])
      .then(([, viewConfig]) => {
        setViewConfig(viewConfig);
        setActivePreviewKey(viewConfig.layoutConfiguration.previewS3key);
        setViewName(viewConfig.view.name);
      })
      .catch((error) => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Save config changes to edge */
  useEffect((): void => {
    if (isLoading) {
      return;
    }
    standardPut({
      body: {
        layoutConfiguration: viewConfig.layoutConfiguration,
      },
      url: EdgeUrls.View.replace(':id', id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, viewConfig]);

  const toggleVisibility: (value: boolean, index: number) => void = (
    value: boolean,
    index: number,
  ) => {
    const newConfig: ViewConfig = _.cloneDeep(viewConfig);
    // Typo in the model?
    (newConfig.layoutConfiguration.components[index] as any).visibility = value;
    setViewConfig(newConfig);
  };

  const debounceTextField: DebouncedFunc<(value: any) => void> = React.useMemo(
    () =>
      debounce((value) => {
        store.updateItem({ body: { name: value }, domainStore, id });
      }, 750),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewConfig],
  );

  const handleChangeViewName: (e) => void = React.useCallback(
    (e) => {
      setViewName(e.target.value);
      if (e.target.value.length < 3) {
        setInvalidName('Must be longer than 3 characters');
      } else {
        setInvalidName('');
        debounceTextField(e.target.value);
      }
    },
    [debounceTextField],
  );

  if (isError) {
    return <>There was a problem.</>;
  }

  return (
    <>
      <Container
        className="mt-4"
        fluid
        style={{ float: 'left', maxWidth: '1500px' }}
      >
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <Link to={home}>
            <i className="fas fa-columns" /> {title} :{' '}
          </Link>
          {isLoading ? <GhostLoader /> : store.items.get(id).name}
        </h4>
        <div style={styles.wrapper}>
          <div style={styles.leftContain}>
            {isLoading ? (
              <GhostLoader height={'600px'} width={'100%'} />
            ) : (
              <LayoutPreview
                activePreviewKey={activePreviewKey}
                components={viewConfig.layoutConfiguration.components}
                defaultKey={viewConfig.layoutConfiguration.previewS3key}
              />
            )}
          </div>
          <div style={styles.rightContain}>
            {customHeader ? (
              <>{isLoading ? <GhostLoader width={'100%'} /> : customHeader}</>
            ) : (
              <>
                <div className="card-container mb-4">
                  {isLoading ? (
                    <GhostLoader width={'100%'} />
                  ) : (
                    <>
                      <input
                        onChange={(e): void => handleChangeViewName(e)}
                        style={styles.input}
                        type="text"
                        value={viewName}
                      />
                      {invalidName}
                    </>
                  )}
                </div>
              </>
            )}

            {isLoading ? (
              <GhostLoader
                width={'calc(100% - 66px)'}
                count={4}
                height={'70px'}
                style={{ margin: '0 .5rem' }}
              />
            ) : (
              <ComponentList
                components={viewConfig.layoutConfiguration.components}
                defaultKey={viewConfig.layoutConfiguration.previewS3key}
                setKey={setActivePreviewKey}
                toggleVisibility={toggleVisibility}
                viewId={id}
              />
            )}
            {!isLoading && (
              <Link style={{ display: 'block', overflow: 'hidden' }} to={home}>
                <div
                  style={{
                    ...styles.button,
                    backgroundColor: uiStore.common.colors.primary,
                  }}
                >
                  Save & Finish
                </div>
              </Link>
            )}
          </div>
        </div>
      </Container>
    </>
  );
});

const styles: any = {
  button: {
    alignItems: 'center',
    borderRadius: '.5rem',
    color: '#fff',
    cursor: 'pointer',
    display: 'block',
    margin: '.5rem',
    padding: '1rem',
    textAlign: 'center',
    width: 'calc(100% - 66px)',
  },
  input: {
    borderBottomWidth: '2px',
    borderColor: '#3c3c3c',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    width: '100%',
  },
  leftContain: {
    flex: 3,
    height: '100%',
    overflow: 'hidden',
    paddingRight: '1rem',
  },
  rightContain: {
    flex: 2,
    minWidth: 0,
  },
  wrapper: {
    display: 'flex',
  },
};

export default ViewConfigurator;
