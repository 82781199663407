import React from 'react';
import { DropdownItem } from 'reactstrap';

import { RenderKeyHelper } from '../../helpers/render-key.helper';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper(
  'TitleSubtitleDropdown',
);

interface Subtitle {
  title: string;
  icon: string;
}

interface Props {
  title: string;
  subtitles: Array<Subtitle>;
  clickHandler: () => void;
}

function TitleSubtitleDropdown({
  clickHandler,
  title,
  subtitles,
}: Props): JSX.Element {
  return (
    <>
      <DropdownItem
        onClick={(): void => clickHandler()}
        className="titleSubtitleDropdown"
      >
        <h4 className="titleSubtitleDropdown--title">{title}</h4>
        {subtitles.map((subtitleParams: Subtitle, index: number) => (
          <span
            className={`titleSubtitleDropdown--subtitle${
              index === 0 ? ' first' : ''
            }`}
            key={renderKeyHelper.generate({
              index,
              subtitle: subtitleParams.title,
              title,
            })}
          >
            {index > 0 && '• '}
            <i className={`fal ${subtitleParams.icon}`}></i>
            {subtitleParams.title}{' '}
          </span>
        ))}
      </DropdownItem>
    </>
  );
}

export default TitleSubtitleDropdown;
