import React, { createContext, useCallback, useEffect, useState } from 'react';
import { ContentTreeProps } from 'interfaces/treeInterfaces';
import { collectionsStore, contentStore } from 'stores';

import ContentTreeBranches from 'components/ContentTree/ContentTreeBranches';
import ContentTreeHeader from 'components/ContentTree/ContentTreeHeader';
import DrawerContents from 'components/ContentTree/Drawers/DrawerContents';
import DrawerModal from 'components/DrawerModal';

export const TreeContext: any = createContext(null);

const ContentTree: any = (props: ContentTreeProps) => {
  const { hasLink, rootViews } = props;

  const [activeView, setActiveView] = useState(rootViews[0]);
  const [branchStack, setBranchStack] = useState([]);
  const [branchesRendered, setBranchesRendered] = useState([]);
  const [maxColumns, setMaxColumns] = useState(0);
  const [treeUpdated, setTreeUpdated] = useState(0);

  useEffect(() => {
    if (hasLink) {
      return;
    }
    for (const view of rootViews) {
      if (view.viewId) {
        setBranchStack([activeView.name]);
        setBranchesRendered([activeView.name]);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forceUpdate: () => void = useCallback(() => {
    setTreeUpdated(treeUpdated + 1);
  }, [treeUpdated]);

  return (
    <TreeContext.Provider
      value={{
        activeView,
        branchStack,
        branchesRendered,
        forceUpdate,
        maxColumns,
        rootViews,
        setActiveView,
        setBranchStack,
        setBranchesRendered,
        setMaxColumns,
        treeUpdated,
      }}
    >
      <div className="trunk">
        <ContentTreeHeader />
        <ContentTreeBranches hasLink={hasLink} />

        <DrawerModal
          surtitle=""
          store={collectionsStore}
          storeKey={'ContentTreeExistingCollection'}
        >
          <DrawerContents store={collectionsStore} forceUpdate={forceUpdate} />
        </DrawerModal>

        <DrawerModal
          surtitle=""
          store={contentStore}
          storeKey={'ContentTreeExistingItem'}
        >
          <DrawerContents store={contentStore} forceUpdate={forceUpdate} />
        </DrawerModal>
      </div>
    </TreeContext.Provider>
  );
};

export default ContentTree;
