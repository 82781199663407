import React from 'react';

interface InternalState {}

interface Props {}

class ContentItemsAddSourceUrl extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    return (
      <>
        <h2>Source Url</h2>
      </>
    );
  }
}

export default ContentItemsAddSourceUrl;
