import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Routes from 'enums/routes.enum';
import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import Analytics from 'pages/Analytics/Analytics';
import NavigationHome from 'pages/AppNavigation/NavigationHome';
import NavigationViewSelectorPage from 'pages/AppNavigation/NavigationViewSelector';
import CategoriesPage from 'pages/Content/Categories/CategoriesPage';
import ContentCollections from 'pages/Content/Collections';
import CollectionDetailPage from 'pages/Content/Collections/Detail';
import CollectionViewConfigurationPage from 'pages/Content/CollectionViews/CollectionViewConfigurationPage';
import CollectionViewSelectorPage from 'pages/Content/CollectionViews/CollectionViewSelectorPage';
import CollectionViewsPage from 'pages/Content/CollectionViews/CollectionViewsPage';
import ContentItems from 'pages/Content/Items';
import ContentItemsAddSourcesRoutes from 'pages/Content/Items/Add-Sources/Source.routes';
import ContentItemDetailPage from 'pages/Content/Items/Detail';
import ContentItem from 'pages/Content/Items/Item';
import ContentTreePage from 'pages/Content/Tree';
import BundleDetailPage from 'pages/Engagement/BundleDetailPage';
import ShareBundlesPage from 'pages/Engagement/ShareBundlesPage';
import Groups from 'pages/Groups';
import SettingsAndFeaturesPage from 'pages/SettingsAndFeatures/SettingsAndFeatures';
import UserRoles from 'pages/UserRoles';
import Users from 'pages/Users';
import { EdgeRequestMethods, fetchFromEdge } from 'services/edge.service';
import { domainStore } from 'stores';

import AppNavigationViewConfigurator from 'components/ApplicationNavigation/Configuration/AppNavigationViewConfigurator';
import Component from 'components/ViewConfigurator/Component';

interface InternalState {
  activeNav?: any;
  chartExample1Data?: any;
  storySlabStore: any;
}

interface Props {}

export const AdminContext: any = React.createContext(null);

class AdminRoutes extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
    this.state = { storySlabStore: {} };
  }

  public componentDidMount(): void {
    // Populate users simple list
    fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      method: EdgeRequestMethods.GET,
      url: '/users/simple-list',
    })
      .then((response: Array<any>) => {
        const usersObject: any = {};
        response.forEach((user) => {
          usersObject[user.id] = user.name;
        });
        this.setState({ storySlabStore: { userSimpleList: usersObject } });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public render(): JSX.Element {
    return (
      <AdminContext.Provider
        value={this.state ? this.state.storySlabStore : {}}
      >
        <Switch>
          <Route path={'/analytics'} component={Analytics} />
          <Route path={Routes.EngagementBundle} component={BundleDetailPage} />
          <Route path={Routes.EngagementBundles} component={ShareBundlesPage} />
          <Route
            path={Routes.ApplicationNavigation}
            component={NavigationHome}
          />
          <Route
            path={Routes.ApplicationLayout}
            component={NavigationViewSelectorPage}
          />
          <Route
            path={Routes.ApplicationConfigurationEdit}
            component={AppNavigationViewConfigurator}
          />
          <Route
            path={Routes.ApplicationSettingsFeatures}
            component={SettingsAndFeaturesPage}
          />

          <Route
            path={Routes.ContentTreeDeepLink}
            component={ContentTreePage}
          />
          <Route path={Routes.ContentTree} component={ContentTreePage} />

          <Route
            path={Routes.ContentItemsAdd}
            component={ContentItemsAddSourcesRoutes}
          />
          <Route
            path={Routes.ContentItemsDetail}
            component={ContentItemDetailPage}
          />
          <Route path={Routes.ContentItems} component={ContentItems} />
          <Route path={Routes.ContentItem} component={ContentItem} />

          <Route
            path={Routes.ContentCollectionsDetail}
            component={CollectionDetailPage}
          />
          <Route
            path={Routes.ContentCollections}
            component={ContentCollections}
          />

          <Route
            path={Routes.ContentCollectionViewLayoutsSelector}
            component={CollectionViewSelectorPage}
          />
          <Route
            path={Routes.ContentCollectionViewLayoutConfiguration}
            component={CollectionViewConfigurationPage}
          />
          <Route
            path={Routes.ContentCollectionViewLayouts}
            component={CollectionViewsPage}
          />

          <Route path={Routes.ContentCategories} component={CategoriesPage} />

          <Route path={Routes.Groups} component={Groups} />
          <Route path={Routes.UserRoles} component={UserRoles} />
          <Route path={Routes.Users} component={Users} />

          <Route
            path={Routes.ViewConfigurationComponentEdit}
            component={Component}
          />

          <Redirect from="*" to={Routes.ContentTree} />
        </Switch>
      </AdminContext.Provider>
    );
  }
}

export default AdminRoutes;
