import React, { useState } from 'react';

import { Bundle } from '@storyslab/storyslab.common.models';
import Contents from 'components/Engagement/Bundles/Detail/Contents';
import Loader from 'components/Indicators/Loader';

interface Props {
  bundle: Bundle;
  contentSet: Array<Bundle>;
  isLoading: boolean;
}

function loadTab(
  tab: number,
  bundle: Bundle,
  contentSet: Array<Bundle>,
): JSX.Element {
  switch (tab) {
    case 1:
      return <Contents bundle={bundle} contentSet={contentSet} />;
    case 2:
      return <>Timeline</>;
    case 3:
      return <>Viewers</>;
    case 4:
      return <>Analytics</>;
    default:
      return <></>;
  }
}

const BundleDetail: any = (props: Props) => {
  const { bundle, contentSet, isLoading } = props;

  const [tab, setTab] = useState(1);

  let tabStyle1: any, tabStyle2: any, tabStyle3: any, tabStyle4: any;
  if (tab === 1) {
    tabStyle2 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle3 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle4 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
  }
  if (tab === 2) {
    tabStyle1 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle3 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle4 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
  }
  if (tab === 3) {
    tabStyle1 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle2 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle4 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
  }
  if (tab === 4) {
    tabStyle1 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle2 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
    tabStyle3 = { boxShadow: 'inset 0 -5px 5px #c3c3c3' };
  }

  return (
    <>
      <div style={styles.wrapper}>
        <div style={styles.tabsWrapper}>
          <div
            style={{ ...styles.tab, ...tabStyle1 }}
            onClick={(): void => {
              setTab(1);
            }}
          >
            Contents
          </div>
          <div
            style={{ ...styles.tab, ...tabStyle2 }}
            onClick={(): void => {
              setTab(2);
            }}
          >
            Timeline
          </div>
          <div
            style={{ ...styles.tab, ...tabStyle3 }}
            onClick={(): void => {
              setTab(3);
            }}
          >
            Viewers
          </div>
          <div
            style={{ ...styles.tab, ...tabStyle4 }}
            onClick={(): void => {
              setTab(4);
            }}
          >
            Analytics
          </div>
        </div>
        {isLoading && <Loader />}
        {!isLoading && loadTab(tab, bundle, contentSet)}
      </div>
    </>
  );
};

const styles: any = {
  tab: {
    backgroundColor: '#fff',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    color: '#4d4d4d',
    cursor: 'pointer',
    fontSize: '18px',
    fontWeight: 700,
    marginRight: '.5rem',
    paddingBottom: '10px',
    paddingTop: '1rem',
    textAlign: 'center',
    width: '150px',
  },
  tabsWrapper: {
    display: 'flex',
    position: 'absolute',
    right: '2rem',
    top: '-53px',
  },
  wrapper: {
    backgroundColor: '#fff',
    borderRadius: '1rem',
    boxShadow: '0 0 10px #666',
    marginTop: '-1rem',
    padding: '2rem 1rem',
    position: 'relative',
  },
};

export default BundleDetail;
