import React from 'react';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import Routes from 'enums/routes.enum';
import { getDefaultIconForItem } from 'helpers/icon.helper';

import { getContentItemTargetType } from '@storyslab/storyslab.common.helpers';
import { Bundle, ContentItem } from '@storyslab/storyslab.common.models';
import S3TenantAssetImage from 'components/Assets/S3TenantAssetImage';
import { contentHeaderColumnConfig } from 'components/Engagement/Bundles/Detail/ContentHeaderColumnConfig';
import Header from 'components/GridTable/Header';

interface Props {
  bundle: Bundle;
  contentSet: Array<ContentItem>;
}

const BundleItems: any = (props: Props) => {
  const { contentSet } = props;

  return (
    <>
      <Header columnConfigs={contentHeaderColumnConfig} />

      <div className="mt-3">
        {/* Empty div to properly offset row colors */}
      </div>

      {contentSet.map((contentItem: ContentItem) => {
        return (
          <div
            className="detail-view-wrapper"
            key={`bundle-item-${contentItem.id}`}
          >
            <Link
              style={{ color: 'unset' }}
              to={Routes.ContentItemsDetail.replace(
                ':id',
                contentItem.id.toString(),
              )}
            >
              <div className="detail-row-wrapper">
                <div className="detail-row-body">
                  <Col md="8" lg="8">
                    <div style={styles.wrapper}>
                      <div style={styles.iconContain}>
                        {'s3Key' in contentItem.thumbnail ? (
                          <S3TenantAssetImage
                            s3Key={contentItem.thumbnail.s3Key}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <i
                            className={`${getDefaultIconForItem(contentItem)}`}
                          />
                        )}
                      </div>
                      <div style={styles.contain}>
                        <span style={styles.font2}>
                          {getContentItemTargetType(contentItem)}
                        </span>
                        <span style={styles.font1}>{contentItem.name}</span>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </>
  );
};

const styles: any = {
  contain: {
    display: 'flex',
    flexDirection: 'column',
  },
  font1: {
    fontSize: '14px',
    fontWeight: 700,
  },
  font2: {
    fontSize: '12px',
    fontWeight: 300,
  },
  iconContain: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '28px',
    justifyContent: 'center',
    marginRight: '15px',
    width: '35px',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
  },
};

export default BundleItems;
