/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button } from 'reactstrap';
import { addUserToApp, createOrResolve } from 'services/users.service';
import { domainStore, usersStore } from 'stores';

import Loader from 'components/Indicators/Loader';

interface Props {
  setState: (isAddingUser) => void;
}

interface ActionButtonProps {
  email: string;
  isExisting: boolean;
  setMalformedEmail: Dispatch<SetStateAction<Array<string>>>;
  setIsAdded: Dispatch<SetStateAction<boolean>>;
  setIsExisting: Dispatch<SetStateAction<boolean>>;
  setState: (isAddingUser) => void;
}

function ActionButton(props: ActionButtonProps): JSX.Element {
  const {
    email,
    isExisting,
    setIsExisting,
    setIsAdded,
    setMalformedEmail,
    setState,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [buttonText, setButtonText] = useState('Save & Continue');
  const [userSub, setUserSub] = useState('');

  return (
    <>
      {!isLoading && (
        <Button
          className="btn-default"
          onClick={(): void => {
            setIsLoading(true);
            if (isCanceling) {
              setState({ isAddingUser: false });
              return;
            }
            if (isExisting) {
              addUserToApp({
                cognitoSub: userSub,
                email,
              }).then((response): void => {
                usersStore.fetchItemById(response.id, { domainStore });
                setState({ isAddingUser: false });
              });
            } else {
              createOrResolve({
                email,
              })
                .then((response): void => {
                  if (response.error === 'Bad Request') {
                    // Failed Email Requirements
                    setMalformedEmail(response.message);
                    return;
                  } else {
                    // Remove email instructions
                    setMalformedEmail([]);
                  }
                  if (response.error === 'Conflict') {
                    // User already added to app
                    setIsAdded(true);
                    setButtonText('Cancel');
                    setIsCanceling(true);
                    return;
                  }
                  if (response.data.status === 'existing') {
                    // User exists in tenant, but not app
                    setUserSub(response.data.sub);
                    setIsExisting(true);
                    setButtonText('Add to app');
                    return;
                  }
                  if (response.data.status === 'new') {
                    // Adding new user
                    setState({ isAddingUser: false });
                    usersStore.fetchItems({ domainStore, usersStore });
                    usersStore.setNewItemId({ id: response.data.userId });
                  }
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }
          }}
        >
          {buttonText}
        </Button>
      )}
      {isLoading && <Loader />}
    </>
  );
}

const AddUsersModal: any = (props: Props) => {
  const [email, setEmail] = useState('');
  const [isAdded, setIsAdded] = useState(false);
  const [isExisting, setIsExisting] = useState(false);
  const [malformedEmail, setMalformedEmail] = useState([]);

  const renderRequirements: any = (): JSX.Element => {
    if (malformedEmail.length === 0) {
      return <></>;
    }
    return (
      <>
        {malformedEmail.map((item, index) => (
          <div key={`message-${index}`}>
            <i className="fas fa-circle" style={{ fontSize: '10px' }}></i>{' '}
            {item}
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <div style={styles.wrapper}>
        <div
          className="backsplash"
          style={styles.backSplash}
          onClick={(): void => {
            props.setState({ isAddingUser: false });
          }}
        ></div>
        <div style={styles.contain}>
          <div style={styles.header}>
            <div style={styles.title}>New User</div>
            <i
              className="far fa-times"
              onClick={(): void => {
                props.setState({ isAddingUser: false });
              }}
              style={{ cursor: 'pointer' }}
            ></i>
          </div>

          {malformedEmail.length > 0 && (
            <div style={styles.message}>{renderRequirements()}</div>
          )}

          <div style={styles.content}>
            {!isExisting && !isAdded && (
              <div style={styles.emailContain}>
                <div style={styles.email}>EMAIL</div>
                <input
                  autoComplete="new-password"
                  onChange={(e): void => setEmail(e.target.value)}
                  style={styles.emailInput}
                  type="text"
                  value={email}
                />
              </div>
            )}

            {isExisting && (
              <div>
                {`${email} already exists in the Tenant. Would you like to add them
              to this app?`}
              </div>
            )}

            {isAdded && (
              <div
                style={styles.message}
              >{`${email} already exists in this app.`}</div>
            )}

            <ActionButton
              email={email}
              isExisting={isExisting}
              setIsAdded={setIsAdded}
              setIsExisting={setIsExisting}
              setMalformedEmail={setMalformedEmail}
              setState={props.setState}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const styles: any = {
  backSplash: {
    backgroundColor: '#fff',
    opacity: '.55',
  },
  contain: {
    backgroundColor: '#4d4d4d',
    borderRadius: '8px',
    boxShadow: '0 0 10px #333',
    color: '#fff',
    padding: '1rem',
    width: '558px',
    zIndex: 100000,
  },
  content: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '3rem 0 1rem',
  },
  email: {
    fontSize: '12px',
    fontWeight: 700,
  },
  emailContain: {},
  emailInput: {
    backgroundColor: 'unset',
    borderBottomWidth: '1px',
    borderColor: '#fff',
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    color: '#fff',
    marginRight: '3rem',
    width: '342px',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: {
    marginTop: '3rem',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
};

export default AddUsersModal;
