import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  closeModal: Dispatch<SetStateAction<boolean>>;
}

const Modal: any = (props: React.PropsWithChildren<Props>) => {
  return (
    <>
      <div style={styles.contain}>
        <div
          style={styles.backsplash}
          onClick={(): void => {
            props.closeModal(false);
          }}
        ></div>
        <div style={styles.contents} className="modal-contents">
          {props.children}
        </div>
      </div>
    </>
  );
};

const styles: any = {
  backsplash: {
    backgroundColor: '#fff',
    height: '100%',
    left: 0,
    opacity: '.45',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  contain: {
    alignItems: 'flex-start',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    marginTop: '10%',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  contents: {
    backgroundColor: '#fff',
    borderRadius: '20px',
    boxShadow: '0 0 15px #999',
    maxWidth: '826px',
    padding: '40px',
    position: 'relative',
    width: '80%',
    zIndex: 3,
  },
};

export default Modal;
