import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { ContentApiType } from 'enums/contentTree.enum';
import { getDefaultIconForItem } from 'helpers/icon.helper';
import {
  collectionsStore,
  contentStore,
  domainStore,
  simpleListsStore,
} from 'stores';

import { Collection, ContentItem } from '@storyslab/storyslab.common.models';
import S3TenantAssetImage from 'components/Assets/S3TenantAssetImage';
import ContentItemPreview from 'components/Content/Preview/ContentItemPreview';
import StackItemMenu from 'components/ContentTree/StackItemMenu';
import Loader from 'components/Indicators/Loader';

interface ItemProps {
  id: number;
  thumbnail: {
    s3Key: string;
  };
  type: string;
}

interface Props {
  component?: any;
  handleStackClick: any;
  isActive: boolean;
  isRoot: boolean;
  item: ItemProps;
  parentId: number;
}

const StackItem: any = (props: Props) => {
  const { component, handleStackClick, isActive, isRoot, item, parentId } =
    props;

  const [storeItem, setStoreItem] = useState<ContentItem | Collection>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  useEffect(() => {
    if (item.type === ContentApiType.COLLECTION) {
      collectionsStore.fetchItemById(item.id, { domainStore }).then(() => {
        setStoreItem(collectionsStore.items.get(item.id));
        setIsLoading(false);
      });
    } else {
      contentStore.fetchItemById(item.id, { domainStore }).then(() => {
        setStoreItem(contentStore.items.get(item.id));
        setIsLoading(false);
      });
    }
  }, [item]);

  function handleItemClick(item: any): void {
    if (item.type === ContentApiType.COLLECTION) {
      handleStackClick(item);
    } else {
      setIsPreviewOpen(true);
    }
  }

  function renderIcon(): JSX.Element {
    if (item.thumbnail === undefined) {
      return <></>;
    }

    if (Object.keys(storeItem.thumbnail).length > 0) {
      return (
        <div className="thumbnail-container">
          <S3TenantAssetImage s3Key={storeItem.thumbnail.s3Key} />
        </div>
      );
    }

    if (item.type === ContentApiType.COLLECTION) {
      return <i className="fas fa-sitemap" />;
    } else {
      return <i className={`${getDefaultIconForItem(storeItem)}`} />;
    }
  }

  if (isLoading) {
    return <Loader isFullPage={false} />;
  }

  if (storeItem === undefined) {
    return <></>;
  }

  return (
    <>
      {isPreviewOpen && (
        <ContentItemPreview
          contentItem={storeItem}
          setIsPreviewOpen={setIsPreviewOpen}
        />
      )}
      <CardBody
        className={`stack-item ${isRoot && 'is-root'} ${
          isActive && 'is-active'
        }`}
        onClick={(): void => handleItemClick(item)}
      >
        {component && <span className="component-name">{component}</span>}
        <span className="stack-item-contents">
          {renderIcon()}
          <span className="item-name">
            {component && simpleListsStore.collections.get(item.id).name}
            {!component && storeItem.name}
          </span>
        </span>
        {!isRoot && <StackItemMenu item={item} parentId={parentId} />}
      </CardBody>
    </>
  );
};

export default StackItem;
