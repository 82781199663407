import React from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';

interface InternalState {}

interface Props {
  title: string;
  onApprove: () => void;
  onDeny: () => void;
}

class StorySlabAlert extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    return (
      <>
        <ReactBSAlert
          warning
          showCancel
          style={{ display: 'block' }}
          title={this.props.title}
          onConfirm={(): void => this.props.onApprove()}
          onCancel={(): void => this.props.onDeny()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Yes"
          cancelBtnText="Cancel"
          btnSize=""
        >
          {this.props.children}
        </ReactBSAlert>
      </>
    );
  }
}

export default StorySlabAlert;
