import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { DomainStoreModel, TemporaryCredentials } from '../models/domain.model';
import { EdgeRequestMethods, fetchFromEdge } from '../services/edge.service';

export async function fetchTemporaryCredentials(
  domainStore: DomainStoreModel,
): Promise<TemporaryCredentials> {
  return await fetchFromEdge<TemporaryCredentials>({
    auth: extractAuthDetailsFromDomainStore(domainStore),
    method: EdgeRequestMethods.GET,
    url: EdgeUrls.CognitoIdentityCredentials,
  });
}
