import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import {
  ContentEvent,
  ContentType,
  ManageCollection,
} from 'enums/contentTree.enum';
import { EdgeUrls } from 'enums/edge.enum';
import { ManageCollectionProps } from 'interfaces';
import { manageCollection } from 'services/tree.service';
import {
  collectionsStore,
  contentStore,
  domainStore,
  simpleListsStore,
} from 'stores';

import AddRow from 'components/ContentTree/AddTools/AddRow';
import FileUploader from 'components/ContentTree/AddTools/FileUploader';
import ModalWrapper from 'components/ContentTree/AddTools/ModalWrapper';
import { TreeContext } from 'components/ContentTree/ContentTree';

interface Props {
  stackId: number;
}

function handleAddClick(
  contentType: ContentType,
  contentEvent: ContentEvent,
  stackId: number,
  setNewItemId: any,
  setIsAddingNewContent?: any,
): void {
  if (contentEvent === ContentEvent.EXISTING) {
    if (contentType === ContentType.COLLECTION) {
      collectionsStore.openDrawerModal({
        key: 'ContentTreeExistingCollection',
        params: {
          count: 0,
          id: stackId,
          title: `Adding Collections to ${
            simpleListsStore.collections.get(stackId).name
          }`,
        },
      });
    } else {
      contentStore.openDrawerModal({
        key: 'ContentTreeExistingItem',
        params: {
          count: 0,
          id: stackId,
          title: `Adding Content to ${
            simpleListsStore.collections.get(stackId).name
          }`,
        },
      });
    }
  } else {
    if (contentType === ContentType.COLLECTION) {
      collectionsStore
        .createItem({
          domainStore,
        })
        .then(() => {
          setNewItemId(collectionsStore.newItemId);
          const params: ManageCollectionProps = {
            action: ManageCollection.ADD,
            activeList: stackId,
            targetID: collectionsStore.newItemId,
            url: EdgeUrls.Collection.replace(
              ':id',
              collectionsStore.newItemId.toString(),
            ),
          };
          manageCollection(params);
        });
    } else {
      setIsAddingNewContent(true);
    }
  }
}

function IsAddingContainer(props: Props): JSX.Element {
  const { stackId } = props;

  const [newItemId, setNewItemId] = useState(0);
  const [isAddingNewContent, setIsAddingNewContent] = useState(false);
  const [isAddingNewCollection, setIsAddingNewCollection] = useState(false);
  const { forceUpdate }: any = useContext(TreeContext);

  useEffect(() => {
    if (newItemId !== 0) {
      setIsAddingNewCollection(true);
    }
  }, [newItemId]);

  function closeDrawer(): void {
    setIsAddingNewContent(false);
    setIsAddingNewCollection(false);
    forceUpdate();
  }

  return (
    <>
      {isAddingNewCollection && (
        <ModalWrapper closeDrawer={closeDrawer} newCollectionID={newItemId} />
      )}
      {isAddingNewContent && (
        <FileUploader activeList={stackId} closeDrawer={closeDrawer} />
      )}
      <Card className={`is-adding-container`} inverse>
        <CardBody>
          <AddRow
            handleAddClick={handleAddClick}
            rowType={ContentType.COLLECTION}
            setIsAddingNewContent={setIsAddingNewContent}
            setNewItemId={setNewItemId}
            stackId={stackId}
          />
          <AddRow
            handleAddClick={handleAddClick}
            rowType={ContentType.ITEM}
            setIsAddingNewContent={setIsAddingNewContent}
            setNewItemId={setNewItemId}
            stackId={stackId}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default IsAddingContainer;
