/* eslint-disable max-lines-per-function */
import React, { Dispatch, SetStateAction, useState } from 'react';
import { appNavigationStore, domainStore, simpleListsStore } from 'stores';

import { PayloadActions } from '@storyslab/storyslab.common.models';

interface Props {
  navItemId: number;
}

function RenderOption(props: {
  checked: boolean;
  id: number;
  idList: string;
  listType: string;
  navItemId: number;
  view: any;
}): JSX.Element {
  const { id, listType } = props;
  const isChecked: boolean = props.checked;

  function renderIcon(): JSX.Element {
    const icon: string | undefined = simpleListsStore[listType].get(id).icon;
    if (listType === 'categories') {
      return (
        <>
          <i
            className={`fas fa-${
              icon === 'undefined' ? 'sitemap' : icon
            } list-icon`}
          ></i>
        </>
      );
    } else {
      return (
        <>
          {/* TODO put thumbnail src in store */}
          <i className={`fas fa-sitemap`}></i>
        </>
      );
    }
  }

  return (
    <>
      <div
        className="detail-checkbox"
        onClick={(): void => {
          appNavigationStore.updateItem({
            body: {
              groupIds: {
                action: isChecked ? PayloadActions.REMOVE : PayloadActions.ADD,
                ids: id.toString(),
              },
            },
            domainStore,
            id: props.navItemId,
          });
        }}
      >
        <div className="input-contain">
          {isChecked && <i className="fas fa-check"></i>}
        </div>
        {renderIcon()}
        <span>{simpleListsStore[props.listType].get(props.id).name}</span>
      </div>
    </>
  );
}

function getLabel(ids: Array<number>, listType: string): string {
  let label: string = '';

  if (ids.length === 0) {
    return '---';
  }

  if (ids.length === 1) {
    return simpleListsStore[listType].get(ids[0])?.name;
  }

  for (const [index, id] of ids.entries()) {
    if (index === ids.length - 1) {
      label += simpleListsStore[listType].get(id)?.name;
    } else {
      label += simpleListsStore[listType].get(id)?.name + ', ';
    }
  }

  return label;
}

function Search(props: {
  listType: string;
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}): JSX.Element {
  return (
    <>
      <div className="detail-search">
        <i className="fas fa-search pl-2 pr-2"></i>
        <input
          autoFocus={true}
          type="text"
          placeholder={`search ${props.listType}...`}
          value={props.searchTerm}
          onChange={(e): void => {
            props.setSearchTerm(e.target.value.toLowerCase());
          }}
        />
      </div>
    </>
  );
}

function GroupSelect(props: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  function Dropdown(props: Props): JSX.Element {
    return (
      <>
        <div
          className="backsplash"
          onClick={(): void => {
            setIsOpen(false);
          }}
        ></div>
        <div className="detail-dropdown" style={{ width: '100%' }}>
          <div className="detail-header">
            <Search
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              listType={'groups'}
            />
          </div>
          <div className="detail-body">
            {appNavigationStore.items
              .get(props.navItemId)
              .groupIds.map((id, index) => (
                <RenderOption
                  checked={true}
                  id={id}
                  idList={
                    appNavigationStore.items.get(props.navItemId).groupIds
                  }
                  key={index}
                  listType={'groups'}
                  navItemId={props.navItemId}
                  view={null}
                />
              ))}
            <div className="detail-separator"></div>
            {Array.from(simpleListsStore.groups.keys())
              .filter((id: number) =>
                appNavigationStore.items.get(props.navItemId).groupIds
                  ? appNavigationStore.items
                      .get(props.navItemId)
                      .groupIds.indexOf(id) === -1
                  : true,
              )
              .filter((id: number) => {
                return simpleListsStore.groups
                  .get(id)
                  .name.toLowerCase()
                  .indexOf(searchTerm) === -1
                  ? false
                  : true;
              })
              .map((id: number, index) => {
                return (
                  <RenderOption
                    checked={false}
                    view={null}
                    id={id}
                    idList={
                      appNavigationStore.items.get(props.navItemId).groupIds
                    }
                    key={index}
                    listType={'groups'}
                    navItemId={props.navItemId}
                  />
                );
              })}
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="storyslab-multiselect"
        id={`group-select`}
        style={{ position: 'relative' }}
      >
        <div className="button" onClick={(): void => setIsOpen(!isOpen)}>
          <span
            className="multi-edit-button-title"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {getLabel(
              appNavigationStore.items.get(props.navItemId).groupIds,
              'groups',
            )}
          </span>
          <i
            className={`fas fa-angle-${isOpen ? 'up' : 'down'}`}
            style={{ padding: '0 1rem' }}
          ></i>
        </div>
        {isOpen && <Dropdown {...props} />}
      </div>
    </>
  );
}

export default GroupSelect;
