/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { Button, DropdownToggle } from 'reactstrap';
import { getAutoGroups } from 'services/groups.service';
import { contentStore, domainStore } from 'stores';

import {
  AssetSyncType,
  ContentItem,
  ContentItemType,
  PayloadActions,
} from '@storyslab/storyslab.common.models';
import Loader from 'components/Indicators/Loader';

interface Props {
  item?: ContentItem;
  reset: () => void;
}

const PublicSource: (props: Props) => JSX.Element = (props: Props) => {
  const { item } = props;

  const defaultValue: string = item?.target?.syncParams
    ? (item.target.syncParams as any).url
    : '';

  const [value, setValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div style={styles.option}>
        <i style={styles.icon} className={`fas fa-universal-access`} />
        <span style={styles.text}>Public File on Web</span>
      </div>
      {!isLoading && (
        <>
          <div style={styles.dropWrap}>
            <p style={styles.text}>URL:</p>
            <input
              type="text"
              style={styles.input}
              onChange={(e): void => {
                setValue(e.target.value);
              }}
              placeholder="https://storyslab.com"
              value={value}
            />
          </div>
          <div style={styles.buttonsContain}>
            <DropdownToggle className="storyslab-button light" right>
              cancel
            </DropdownToggle>
            <Button
              className="storyslab-button"
              onClick={(): void => {
                setIsLoading(true);
                if (!props.item) {
                  contentStore
                    .createItem({
                      domainStore,
                      meta: {
                        name: value,
                        target: {
                          name: value,
                          syncInterval: 1604800017,
                          syncParams: {
                            url: value,
                          },
                          syncType: AssetSyncType.PUBLIC_LINK,
                        },
                        type: ContentItemType.ASSET,
                      },
                    })
                    .then(() => {
                      // Fetch reloads table. Default groups can't be added on create; add here.
                      contentStore.fetchItems({ domainStore }).then(() => {
                        contentStore.updateItem({
                          body: {
                            groupIds: {
                              action: PayloadActions.ADD,
                              ids: getAutoGroups(),
                            },
                          },
                          domainStore,
                          id: contentStore.newItemId,
                        });
                      });
                    });
                } else {
                  contentStore
                    .updateItem({
                      body: {
                        target: {
                          syncInterval: 1604800017,
                          syncParams: {
                            url: value,
                          },
                          syncType: AssetSyncType.PUBLIC_LINK,
                        },
                        type: ContentItemType.ASSET,
                      },
                      domainStore,
                      id: item.id,
                    })
                    .finally(() => {
                      props.reset();
                    });
                }
              }}
            >
              save
            </Button>
          </div>
        </>
      )}
      {isLoading && <Loader isFullPage={false} />}
    </>
  );
};

const styles: any = {
  buttonsContain: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropWrap: {},
  icon: {
    color: '#4d4d4d',
    fontSize: '26px',
    marginRight: '10px',
  },
  input: {
    border: '1px solid #c3c3c3',
    borderRadius: '8px',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '15px',
    width: '100%',
  },
  option: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '15px',
  },
  text: {
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: 0,
  },
};

export default PublicSource;
