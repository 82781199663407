import React from 'react';
import { ISubmitButtonProps } from 'react-dropzone-uploader';
import { Button } from 'reactstrap';

const SubmitButton: (props: ISubmitButtonProps) => JSX.Element = (
  props: ISubmitButtonProps,
) => {
  return (
    <>
      <Button
        className="storyslab-button"
        onClick={(): void => {
          props.onSubmit(props.files);
        }}
      >
        save
      </Button>
    </>
  );
};

export default SubmitButton;
