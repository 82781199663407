import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import Routes from 'enums/routes.enum';
import { observer } from 'mobx-react';
import { collectionsStore, domainStore, uiStore } from 'stores';

import CollectionRow from 'components/Collections/CollectionRow/CollectionRow';
import GhostLoader from 'components/Indicators/GhostLoader';
import Loader from 'components/Indicators/Loader';

interface InternalProps {}

const CollectionDetailPage: any = observer((props: InternalProps) => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);

  useEffect((): void => {
    collectionsStore.fetchItemById(id, { domainStore }).then(() => {
      setIsLoading(false);
    });
  }, [id]);

  return (
    <>
      <Container className="mt-4" fluid>
        <h4 className="mb-4" style={{ color: uiStore.common.colors.primary }}>
          <i className="fas fa-sitemap" />{' '}
          <Link to={Routes.ContentCollections}>Collections</Link> :{' '}
          {isLoading ? (
            <GhostLoader />
          ) : (
            `${collectionsStore.items.get(parseInt(id))?.name}`
          )}
        </h4>
        {isLoading && <Loader isFullPage={true} />}
        {!isLoading && (
          <CollectionRow
            collection={collectionsStore.items.get(parseInt(id))}
            isDroppedOnLoad={true}
            context="detail"
          />
        )}
      </Container>
    </>
  );
});

export default CollectionDetailPage;
