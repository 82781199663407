import React from 'react';

export const Recipients: any = (props: { recipients: Array<string> }) => {
  let recipients: string = '';
  let count: number = 1;
  for (const item of props.recipients) {
    if (count === props.recipients.length) {
      recipients += item;
    } else {
      recipients += item + ', ';
    }
    count++;
  }
  return <>{recipients}</>;
};
