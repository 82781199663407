/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */

// TODO: Tech Debt - Reduce file size by breaking into components

import React from 'react';
import { CardFooter, DropdownItem } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { BaseStore } from 'stores/base.store';

import GridTable from '../../components/GridTable';
import GridTableCardHeader from '../../components/GridTable/CardHeader';
import CheckboxList from '../../components/GridTable/FilterDropdowns/CheckboxList';
import DoubleDatePicker from '../../components/GridTable/FilterDropdowns/DoubleDatePicker';
import PaginationComponent from '../../components/GridTable/Pagination';
import RowContents from '../../components/Users/DrawerModalRowContents';
import { Action, ActionTypes } from '../../models/action.model';
import { BaseProps } from '../../models/base.model';
import { HeaderColumnConfig } from '../../models/grid-table.model';
import Checkbox from '../Forms/Checkbox';

const lastThreeMonths: string = `${moment()
  .subtract(2, 'months')
  .format('MMM')}\u00a0\u00a0\u00a0\u00a0\u00a0${moment()
  .subtract(1, 'months')
  .format('MMM')}\u00a0\u00a0\u00a0\u00a0\u00a0${moment().format('MMM')}`;

/*
 * TODO: these vars have 'group' in the name but are not related to just groups..
 *       some artifacts from when this modal was only used by groups, refactor in future
 */
interface InternalState {
  shouldShowItemsWithinGroup: boolean;
  shouldShowItemsNotWithinGroup: boolean;
  isDirty: boolean;
}

interface Props extends BaseProps {
  filterBy: string; // Todo: Tech Debt - Convert to Enum - groupIds | roleIds | etc..
  filterByDisplayText: string; // Todo: Tech Debt - Convert to Enum - group | role | etc..
  count: number;
  id: number;
  parentModalStore?: BaseStore<any>;
}

@inject('simpleListsStore')
@inject('usersStore')
@inject('domainStore')
@inject('uiStore')
@observer
class DrawerModalContents extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];

  public state: InternalState = {
    isDirty: false,
    shouldShowItemsNotWithinGroup: false,
    shouldShowItemsWithinGroup: true,
  };

  constructor(props: Props) {
    super(props);

    if (props.count === 0) {
      this.state = {
        ...this.state,
        shouldShowItemsNotWithinGroup: true,
        shouldShowItemsWithinGroup: true,
      };
    }

    this.handleFilter = this.handleFilter.bind(this);

    this.initHeaderColumnConfig();
  }

  private initHeaderColumnConfig(): void {
    this.headerColumnConfigs = [
      {
        colProps: {
          md: '4',
        },
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'name',
        store: this.props.usersStore,
        title: 'Name',
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <CheckboxList
            itemKey={'status'}
            simpleList={this.props.simpleListsStore.userStatuses}
            store={this.props.usersStore.filters}
            handleAction={(action: Action): void => {
              this.props.usersStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isFilterable: true,
        itemKey: 'status',
        store: this.props.usersStore,
        title: 'Status',
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <CheckboxList
            itemKey={'versions'}
            simpleList={[]}
            store={this.props.usersStore.filters}
            handleAction={(action: Action): void => {
              this.props.usersStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isFilterable: true,
        itemKey: 'version',
        store: this.props.usersStore,
        title: 'Version',
      },

      {
        colProps: {
          md: '2',
        },
        isCentered: true,
        subtitle: lastThreeMonths,
        title: 'Recent Activity',
      },
      {
        colProps: { md: '2' },
        filterDropdown: (
          <DoubleDatePicker
            itemKey={'last_seen'} // TODO: edge should map from camel case / Can't filter on virtual fields - [sc2931]
            store={this.props.usersStore.filters}
            handleAction={(action: Action): void => {
              this.props.usersStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.usersStore.handleTableAction(this.props, action);
        },
        isFilterable: false,
        isSortable: true,
        itemKey: 'last_seen',
        store: this.props.usersStore,
        title: 'Last Seen',
      },
    ];
  }

  private toggleShowItemsWithinGroup(): void {
    const { shouldShowItemsNotWithinGroup } = this.state;

    if (shouldShowItemsNotWithinGroup) {
      this.setState(
        (prevState: InternalState) => ({
          shouldShowItemsWithinGroup: !prevState.shouldShowItemsWithinGroup,
        }),
        () => {
          this.handleFilter();
        },
      );
    }
  }

  private toggleShowItemsNotWithinGroup(): void {
    const { shouldShowItemsWithinGroup } = this.state;

    if (shouldShowItemsWithinGroup) {
      this.setState(
        (prevState: InternalState) => ({
          shouldShowItemsNotWithinGroup:
            !prevState.shouldShowItemsNotWithinGroup,
        }),
        () => {
          this.handleFilter();
        },
      );
    }
  }

  private handleFilter(): void {
    const { usersStore } = this.props;
    const { shouldShowItemsWithinGroup, shouldShowItemsNotWithinGroup } =
      this.state;

    let idArray: Array<number | string> = [];

    usersStore.clearFilters();

    if (shouldShowItemsWithinGroup && shouldShowItemsNotWithinGroup) {
      usersStore.fetchItems(this.props);
    } else {
      if (this.state.shouldShowItemsWithinGroup) {
        idArray = [...idArray, this.props.id];
      }

      if (this.state.shouldShowItemsNotWithinGroup) {
        idArray = [`!${this.props.id}`];
      }
      const payload: {
        [key: string]: {
          id?: number | string;
          ids?: Array<number | string>;
          selected: boolean;
        };
      } = {};
      payload[this.props.filterBy] = {
        ids: idArray,
        selected: true,
      };
      usersStore.handleTableAction(this.props, {
        payload: payload,
        type: ActionTypes.FILTER,
      });
    }
  }

  private renderActionButtonTitle(): string {
    const { shouldShowItemsWithinGroup, shouldShowItemsNotWithinGroup } =
      this.state;
    if (shouldShowItemsWithinGroup && shouldShowItemsNotWithinGroup) {
      return `Showing all users`;
    }

    if (shouldShowItemsWithinGroup) {
      return `Showing users within ${this.props.filterByDisplayText}`;
    }

    if (shouldShowItemsNotWithinGroup) {
      return `Showing users not within ${this.props.filterByDisplayText}`;
    }
  }

  public componentDidMount(): void {
    const { usersStore, count } = this.props;
    if (count === 0) {
      this.props.usersStore.fetchItems(this.props);
    } else {
      const payload: {
        [key: string]: {
          id?: number | string;
          ids?: Array<number | string>;
          selected: boolean;
        };
      } = {};
      payload[this.props.filterBy] = {
        id: this.props.id,
        selected: true,
      };
      usersStore.handleTableAction(this.props, {
        payload: payload,
        type: ActionTypes.FILTER,
      });
    }
  }

  public async componentWillUnmount(): Promise<void> {
    const { usersStore, parentModalStore, id, domainStore } = this.props;

    usersStore.clearFilters();

    if (parentModalStore && this.state.isDirty) {
      parentModalStore.fetchItemById(id, { domainStore });
    }
  }

  public render(): JSX.Element {
    const { usersStore } = this.props;
    return (
      <>
        <GridTableCardHeader
          actionButtonIcon={'fa-chevron-down'}
          isActionButtonIconSuffix={true}
          actionButtonTitle={this.renderActionButtonTitle()} // "Showing Content Files Within Group"
          ActionDropdownMenu={(): JSX.Element => (
            <>
              <DropdownItem
                onClick={(): void => {
                  this.toggleShowItemsWithinGroup();
                }}
              >
                <Checkbox selected={this.state.shouldShowItemsWithinGroup} />
                <span>Show users within {this.props.filterByDisplayText}</span>
              </DropdownItem>
              <DropdownItem
                onClick={(): void => {
                  this.toggleShowItemsNotWithinGroup();
                }}
              >
                <Checkbox selected={this.state.shouldShowItemsNotWithinGroup} />
                <span>
                  Show users not within {this.props.filterByDisplayText}
                </span>
              </DropdownItem>
            </>
          )}
          store={usersStore}
        />

        <GridTable
          idKey={'id'}
          headerColumnConfigs={this.headerColumnConfigs}
          items={Array.from(usersStore?.items?.values())}
          store={usersStore}
          RowContents={(props: any): JSX.Element => (
            <RowContents
              {...props}
              filterBy={this.props.filterBy}
              parentModalId={this.props.id}
              handleUpdate={(): void => this.setState({ isDirty: true })}
            />
          )}
          isSimpleRow={true}
        />

        <CardFooter className="py-4">
          <PaginationComponent
            store={usersStore}
            handleAction={(params: any): Promise<void> =>
              this.props.usersStore.handleTableAction(this.props, params)
            }
          />
        </CardFooter>
      </>
    );
  }
}

export default DrawerModalContents;
