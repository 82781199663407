import React from 'react';

import CollectionViewsTable from 'components/CollectionViews/CollectionViewsTable';

interface Props {}

function CollectionViewsPage(props: Props): JSX.Element {
  return <CollectionViewsTable />;
}

export default CollectionViewsPage;
