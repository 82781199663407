import React from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
} from 'reactstrap';
import moment from 'moment';

import { PayloadActions } from '@storyslab/storyslab.common.models';

import BadgeColumn from '../../components/GridTable/RowColumns/Badge';
import TextColumn from '../../components/GridTable/RowColumns/Text';
import { RowProps } from '../../models/grid-table.model';

interface InternalState {}

interface Props extends RowProps {
  isEditing: boolean;
  parentModalId: number;
  handleUpdate?: () => void;
  filterBy: string; // Todo: Convert to ENUM - groupIds | roleIds | etc...
}

class RowContents extends React.Component<Props, InternalState> {
  private handleAddRequest(): void {
    this.handleUpdate(PayloadActions.ADD);
  }

  private handleRemoveRequest(): void {
    this.handleUpdate(PayloadActions.REMOVE);
  }

  private handleUpdate(payloadAction: PayloadActions): void {
    const {
      store,
      item,
      domainStore,
      handleUpdate,
      filterBy,
      parentModalId,
    } = this.props;

    const body: any = {};
    body[filterBy] = {
      action: payloadAction,
      ids: parentModalId.toString(),
    };

    store.updateItem({
      body: body,
      domainStore,
      id: item.id,
    });

    if (store.hasFilters) {
      store.deleteItem({
        domainStore: domainStore,
        id: item.id,
        isLocalOnly: true,
      });
    }

    handleUpdate && handleUpdate();
  }

  public render(): JSX.Element {
    const { item, filterBy, parentModalId }: Props = this.props;

    const filterByIds: any = item ? item[filterBy] : null;

    const isAddable: boolean =
      !filterByIds || filterByIds?.indexOf(parentModalId) === -1;

    return (
      <>
        <TextColumn
          columnProps={{ md: '4' }}
          title={`${item.lastName}${item.lastName?.length > 0 ? ', ' : ''} ${
            item.firstName
          }`}
          subtitle={item.email}
        />

        <BadgeColumn columnProps={{ md: '2' }} status={item.status} />
        <TextColumn columnProps={{ md: '2' }} title={item.version} />

        <TextColumn
          isCentered={true}
          columnProps={{ md: '2' }}
          title={`${
            (item.recentActivity &&
              item.recentActivity.join('\u00a0\u00a0\u00a0\u00a0\u00a0')) ||
            ''
          }`}
        />
        <Col md="3" lg="2" style={{ textAlign: 'right' }}>
          <h5>
            <TimeAgo date={moment(item.updated).toDate()} minPeriod="30" />
          </h5>

          <div className="float-right ml-4">
            {isAddable ? (
              <button
                className="addButton"
                onClick={(): void => this.handleAddRequest()}
              >
                <i className="fas fa-plus text-lg"></i>
              </button>
            ) : (
              <button
                className="removeButton"
                onClick={(): void => this.handleRemoveRequest()}
              >
                <i className="fas fa-times text-lg"></i>
              </button>
            )}
          </div>

          <UncontrolledDropdown nav className="float-right">
            <DropdownToggle className="nav-link pr-0" color="" tag="a">
              <Media>
                <i className="fas fa-ellipsis-v ml-3" />
              </Media>
            </DropdownToggle>
            <DropdownMenu right>
              <Link to={''}>
                <DropdownItem>
                  <span>More Info</span>
                </DropdownItem>
              </Link>
              <Link to={''}>
                <DropdownItem>
                  <span>Preview</span>
                </DropdownItem>
              </Link>
              {/* <DropdownItem divider /> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
      </>
    );
  }
}

export default RowContents;
