import React from 'react';

interface InternalState {}

interface Props {}

class ContentItemsAddSourceDropBox extends React.Component<
  Props,
  InternalState
> {
  public render(): JSX.Element {
    return (
      <>
        <h2>Source Dropbox</h2>
      </>
    );
  }
}

export default ContentItemsAddSourceDropBox;
