import React from 'react';
import { useParams } from 'react-router-dom';
import standardLayout from 'assets/img/appflow/standard_layout.png';
import Routes from 'enums/routes.enum';
import { navItemViewsStore } from 'stores';

import ViewLayoutSelector from 'components/ViewConfigurator/ViewLayoutSelector';

interface Props {}

const ApplicationViewLayoutSelector: (props: Props) => JSX.Element = (
  props: Props,
) => {
  const { id } = useParams<{ id: string }>();

  return (
    <ViewLayoutSelector
      createMeta={{
        layoutId: 2,
        name: 'New Nav Item',
        parentId: parseInt(id),
        parentType: 'navigationItem',
      }}
      home={Routes.ApplicationNavigation}
      layoutImage={standardLayout}
      redirection={Routes.ApplicationConfigurationEdit}
      store={navItemViewsStore}
      title={'Navigation'}
    />
  );
};

export default ApplicationViewLayoutSelector;
