import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Routes from '../../../../enums/routes.enum';

import ContentItemsAddSourceComputer from './Source-Computer';
import ContentItemsAddSourceDropbox from './Source-Dropbox';
import ContentItemsAddSourceGoogleDrive from './Source-Google-Drive';
import ContentItemsAddSourceLink from './Source-Link';
import ContentItemsAddSourceUrl from './Source-Url';

interface InternalState {
  activeNav: any;
  chartExample1Data: any;
}

interface Props {}

class ContentItemsAddSourcesRoutes extends React.Component<
  Props,
  InternalState
> {
  public render(): JSX.Element {
    return (
      <>
        <Switch>
          <Route
            path={Routes.ContentItemsAddSourceComputer}
            component={ContentItemsAddSourceComputer}
          />
          <Route
            path={Routes.ContentItemsAddSourceDropbox}
            component={ContentItemsAddSourceDropbox}
          />
          <Route
            path={Routes.ContentItemsAddSourceGoogleDrive}
            component={ContentItemsAddSourceGoogleDrive}
          />
          <Route
            path={Routes.ContentItemsAddSourceLink}
            component={ContentItemsAddSourceLink}
          />
          <Route
            path={Routes.ContentItemsAddSourceUrl}
            component={ContentItemsAddSourceUrl}
          />
        </Switch>
      </>
    );
  }
}

export default ContentItemsAddSourcesRoutes;
