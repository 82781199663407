import { UsersService } from '../services/users.service';

import { BaseStore } from './base.store';

export class UsersStore extends BaseStore<any> {
  public service: UsersService;

  constructor() {
    super(new UsersService(), 'UsersStore', 'User');

    this.service = new UsersService();
  }
}

export const usersStore: UsersStore = new UsersStore();
