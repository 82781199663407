import React from 'react';
import { DropdownItem } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { BaseStore } from 'stores/base.store';

import RowContents from '../../components/Collections/DrawerModalRowContents';
import GridTable from '../../components/GridTable';
import GridTableCardFooter from '../../components/GridTable/CardFooter';
import GridTableCardHeader from '../../components/GridTable/CardHeader';
import DoubleDatePicker from '../../components/GridTable/FilterDropdowns/DoubleDatePicker';
import { Action, ActionTypes } from '../../models/action.model';
import { BaseProps } from '../../models/base.model';
import { HeaderColumnConfig } from '../../models/grid-table.model';
import Checkbox from '../Forms/Checkbox';

interface InternalState {
  shouldShowItemsWithinGroup: boolean;
  shouldShowItemsNotWithinGroup: boolean;
  isDirty: boolean;
}

interface Props extends BaseProps {
  count: number;
  id: number;
  parentModalStore?: BaseStore<any>;
}

@inject('simpleListsStore')
@inject('collectionsStore')
@inject('domainStore')
@inject('uiStore')
@observer
class DrawerModalContents extends React.Component<Props, InternalState> {
  private headerColumnConfigs: Array<HeaderColumnConfig> = [];

  public state = {
    isDirty: false,
    shouldShowItemsNotWithinGroup: false,
    shouldShowItemsWithinGroup: true,
  };

  constructor(props: Props) {
    super(props);

    if (props.count === 0) {
      this.state = {
        ...this.state,
        shouldShowItemsNotWithinGroup: true,
        shouldShowItemsWithinGroup: true,
      };
    }

    this.handleFilter = this.handleFilter.bind(this);

    this.headerColumnConfigs = [
      {
        colProps: { className: {}, lg: '8', md: '7' },
        handleAction: (action: Action): void => {
          this.props.collectionsStore.handleTableAction(this.props, action);
        },
        isSortable: true,
        itemKey: 'displayName',
        store: this.props.collectionsStore,
        title: 'Display Name',
      },
      {
        colProps: { lg: '2', md: '2' },
        title: 'Preview',
      },
      {
        colProps: { lg: '2', md: '3' },
        filterDropdown: (
          <DoubleDatePicker
            itemKey={'updated'}
            store={this.props.collectionsStore.filters}
            handleAction={(action: Action): void => {
              this.props.collectionsStore.handleTableAction(this.props, action);
            }}
          />
        ),
        handleAction: (action: Action): void => {
          this.props.collectionsStore.handleTableAction(this.props, action);
        },
        isFilterable: true,
        isSortable: true,
        itemKey: 'updated',
        store: this.props.collectionsStore,
        title: 'Last Updated',
      },
    ];
  }

  /*
   * TECH DEBT!
   * This should be moved to a shared lib
   */
  private toggleShowItemsWithinGroup(): void {
    const { shouldShowItemsNotWithinGroup } = this.state;

    if (shouldShowItemsNotWithinGroup) {
      this.setState(
        (prevState: InternalState) => ({
          shouldShowItemsWithinGroup: !prevState.shouldShowItemsWithinGroup,
        }),
        () => {
          this.handleFilter();
        },
      );
    }
  }

  private toggleShowItemsNotWithinGroup(): void {
    const { shouldShowItemsWithinGroup } = this.state;

    if (shouldShowItemsWithinGroup) {
      this.setState(
        (prevState: InternalState) => ({
          shouldShowItemsNotWithinGroup:
            !prevState.shouldShowItemsNotWithinGroup,
        }),
        () => {
          this.handleFilter();
        },
      );
    }
  }

  private handleFilter(): void {
    const { shouldShowItemsWithinGroup, shouldShowItemsNotWithinGroup } =
      this.state;

    let idArray: Array<number | string> = [];

    this.props.collectionsStore.clearFilters();

    if (shouldShowItemsWithinGroup && shouldShowItemsNotWithinGroup) {
      this.props.collectionsStore.fetchItems(this.props);
    } else {
      if (this.state.shouldShowItemsWithinGroup) {
        idArray = [...idArray, this.props.id];
      }

      if (this.state.shouldShowItemsNotWithinGroup) {
        idArray = [`!${this.props.id}`];
      }

      this.props.collectionsStore.handleTableAction(this.props, {
        payload: {
          groupIds: { ids: idArray, selected: true },
        },
        type: ActionTypes.FILTER,
      });
    }
  }

  private renderActionButtonTitle(): string {
    const { shouldShowItemsWithinGroup, shouldShowItemsNotWithinGroup } =
      this.state;
    if (shouldShowItemsWithinGroup && shouldShowItemsNotWithinGroup) {
      return `Showing all collections`;
    }

    if (shouldShowItemsWithinGroup) {
      return `Showing collections within group`;
    }

    if (shouldShowItemsNotWithinGroup) {
      return `Showing collections not within group`;
    }
  }
  /* Tech Debt! */

  public componentDidMount(): void {
    const { collectionsStore, count } = this.props;
    if (count === 0) {
      this.props.collectionsStore.fetchItems(this.props);
    } else {
      collectionsStore.handleTableAction(this.props, {
        payload: {
          groupIds: { id: this.props.id, selected: true },
        },
        type: ActionTypes.FILTER,
      });
    }
  }

  public async componentWillUnmount(): Promise<void> {
    const { collectionsStore, parentModalStore, id, domainStore } = this.props;

    collectionsStore.clearFilters();

    if (parentModalStore && this.state.isDirty) {
      parentModalStore.fetchItemById(id, { domainStore });
    }
  }

  public render(): JSX.Element {
    const { collectionsStore } = this.props;
    return (
      <>
        <GridTableCardHeader
          actionButtonIcon={'fa-chevron-down'}
          isActionButtonIconSuffix={true}
          actionButtonTitle={this.renderActionButtonTitle()} // "Showing Content Files Within Group"
          ActionDropdownMenu={(): JSX.Element => (
            <>
              <DropdownItem
                onClick={(): void => {
                  this.toggleShowItemsWithinGroup();
                }}
              >
                <Checkbox selected={this.state.shouldShowItemsWithinGroup} />
                <span>Show collections within group</span>
              </DropdownItem>
              <DropdownItem
                onClick={(): void => {
                  this.toggleShowItemsNotWithinGroup();
                }}
              >
                <Checkbox selected={this.state.shouldShowItemsNotWithinGroup} />
                <span>Show collections not within group</span>
              </DropdownItem>
            </>
          )}
          store={collectionsStore}
        />

        <GridTable
          store={collectionsStore}
          idKey={'contentId'}
          headerColumnConfigs={this.headerColumnConfigs}
          items={Array.from(collectionsStore?.items?.values())}
          RowContents={(props: any): JSX.Element => (
            <RowContents
              {...props}
              parentModalId={this.props.id}
              parentModalStore={this.props.parentModalStore}
              handleUpdate={(): void => this.setState({ isDirty: true })}
            />
          )}
          isSimpleRow={true}
        />

        <GridTableCardFooter
          store={collectionsStore}
          storeInstance={this.props.collectionsStore}
        />
      </>
    );
  }
}

export default DrawerModalContents;
