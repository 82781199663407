import React from 'react';
import { Input, Label } from 'reactstrap';

import { LabeledCheckboxProps } from '../../models/forms.model';

interface InternalState {}

class LabeledCheckbox extends React.Component<
  LabeledCheckboxProps,
  InternalState
> {
  public standardizeIconName(iconName: string): string {
    const nameParts: Array<string> = iconName.split(/(?=[A-Z])/);
    let standardizedName: string = 'fa';
    for (let i: number = 1; i < nameParts.length; i++) {
      standardizedName += '-';
      standardizedName += nameParts[i].toLowerCase();
    }
    return standardizedName;
  }

  public render(): JSX.Element {
    const {
      handleChange,
      icon,
      id,
      index,
      label,
      // TODO: Tech Debt - change selected to isSelected
      // eslint-disable-next-line @typescript-eslint/naming-convention
      selected,
      iconName,
    } = this.props;

    return (
      <>
        <div className="labeled-checkbox" key={`${id}-${index}`}>
          <Label check className="clickable">
            <Input
              type="checkbox"
              className="clickable"
              checked={selected}
              onChange={(): void => {
                handleChange({
                  id: this.props.id,
                  selected,
                  // ...(selected ? { removedIds: [id] } : { addedIds: [id] }),
                });
              }}
            />
            {iconName && (
              <i className={`labeled-checkbox-icon fas fa-${iconName}`}></i>
            )}
            {!iconName && icon && icon.length > 0 && (
              <i className={`labeled-checkbox-icon fas fa-${icon}`}></i>
            )}
            <span className="labeled-checkbox-label">{label}</span>
          </Label>
        </div>
      </>
    );
  }
}

export default LabeledCheckbox;
