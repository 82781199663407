/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player';
import PSPDFKit from 'pspdfkit';
import { getSignedUrl } from 'services/asset.service';
import { contentStore, domainStore } from 'stores';

import { getContentItemTargetType } from '@storyslab/storyslab.common.helpers';
import {
  ContentItem,
  ContentItemTargetType,
} from '@storyslab/storyslab.common.models';
import ContentItemPreviewHeader from 'components/Content/Preview/ContentItemPreviewHeader';

interface Props {
  contentItem: ContentItem;
  setIsPreviewOpen: Dispatch<SetStateAction<boolean>>;
}

const ContentItemPreview: any = (props: Props) => {
  const containerRef: MutableRefObject<null> = useRef(null);

  const [itemType, setItemType] = useState<ContentItemTargetType>(null);
  const [itemUrl, setItemUrl] = useState('');
  const [shouldShowIframe, setShouldShowIframe] = useState(false);
  const [shouldShowVideo, setShouldShowVideo] = useState(false);

  useEffect(() => {
    contentStore
      .fetchItemById(props.contentItem.id, { domainStore })
      .then(() => {
        setItemType(
          getContentItemTargetType(
            contentStore.items.get(props.contentItem.id),
          ),
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (itemType) {
      case ContentItemTargetType.IMAGE:
      case ContentItemTargetType.PDF:
      case ContentItemTargetType.VIDEO:
        getSignedUrl({
          bucket: process.env.REACT_APP_TENANT_ASSETS_BUCKET,
          key: contentStore.items.get(props.contentItem.id).target.s3Key,
          s3ClientConfig: {
            ...domainStore.temporaryCredentials,
            Region: (domainStore.temporaryCredentials as any).region,
          },
        } as any).then((response) => {
          setItemUrl(response);
        });
        break;
      case ContentItemTargetType.LINK:
        setShouldShowIframe(true);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemType]);

  useEffect(() => {
    if (itemUrl !== '') {
      const container: any = containerRef.current;
      if (itemType === ContentItemTargetType.VIDEO) {
        setShouldShowVideo(true);
      } else {
        PSPDFKit.load({
          baseUrl: `${window.location.protocol}//${window.location.host}/`,
          container,
          document: itemUrl,
          licenseKey: process.env.REACT_APP_PSPDFKIT_LICENSE,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemUrl]);

  return (
    <>
      <div style={styles.wrapper}>
        <div
          style={styles.backDrop}
          onClick={(): void => {
            props.setIsPreviewOpen(false);
          }}
        ></div>
        <ContentItemPreviewHeader
          title={props.contentItem.name}
          setIsPreviewOpen={props.setIsPreviewOpen}
        />
        <div ref={containerRef} style={styles.container}></div>
        {shouldShowVideo && (
          <ReactPlayer
            url={itemUrl}
            controls={true}
            width="95%"
            height="90%"
            style={styles.container}
          />
        )}
        {shouldShowIframe && (
          <iframe
            title={contentStore.items.get(props.contentItem.id).name}
            style={styles.container}
            src={contentStore.items.get(props.contentItem.id).target.link}
          ></iframe>
        )}
      </div>
    </>
  );
};

const styles: any = {
  backDrop: {
    backgroundColor: '#000',
    height: '100%',
    opacity: '.5',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
  },
  container: {
    backgroundColor: '#fff',
    bottom: '10px',
    height: 'calc(100% - 70px)',
    marginLeft: '2.5%',
    position: 'fixed',
    width: '95%',
    zIndex: 2,
  },
  wrapper: {
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: '10000',
  },
};

export default ContentItemPreview;
