/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { extractAuthDetailsFromDomainStore } from 'helpers/util.helper';
import { DomainStoreModel } from 'models/domain.model';

import { EdgeUrls } from '../enums/edge.enum';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export class UserRolesService extends BaseService {
  private newItemDefaultName: string = 'New User Role';

  constructor() {
    super({
      create: EdgeUrls.UserRoles,
      delete: EdgeUrls.UserRole,
      fetch: EdgeUrls.UserRoles,
      update: EdgeUrls.UserRole,
    });
  }

  public async create({
    meta,
    domainStore,
  }: {
    meta?: { [key: string]: any };
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      body: { name: this.newItemDefaultName },
      method: EdgeRequestMethods.POST,
      url: EdgeUrls.UserRoles,
    });
  }
}
