import React from 'react';
import { CardFooter } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import { BaseProps } from '../../../models/base.model';
import PaginationComponent from '../Pagination';

interface InternalState {}

interface Props extends BaseProps {
  store: any;
  storeInstance: any;
}

@inject('domainStore')
@observer
class GridTableCardFooter extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const { store, storeInstance } = this.props;

    return (
      <>
        <CardFooter className="py-4">
          <PaginationComponent
            store={store}
            handleAction={(params: any): void =>
              storeInstance.handleTableAction(this.props, params)
            }
          />
        </CardFooter>
      </>
    );
  }
}

export default GridTableCardFooter;
