import React from 'react';

import BundleDetail from 'components/Engagement/Bundles/Detail/BundleDetail';

interface Props {}

function BundleDetailPage(props: Props): JSX.Element {
  return <BundleDetail />;
}

export default BundleDetailPage;
