import React, { ChangeEvent, FormEvent } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import { isEmpty } from '../../../../helpers/util.helper';
import { ActionTypes } from '../../../../models/action.model';
import { BaseProps } from '../../../../models/base.model';

interface InternalState {}

interface Props extends BaseProps {
  columnProps: { [key: string]: any };
  store: any;
  theme?: any;
}

@inject('uiStore')
@inject('domainStore')
@observer
class CardHeaderColumnSearch extends React.Component<Props, InternalState> {
  private handleSearchChange(e: ChangeEvent<HTMLInputElement>): void {
    this.props.store.handleTableAction(this.props, {
      payload: e.target.value,
      type: ActionTypes.SEARCH,
    });
  }

  private handleFormSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();
  }

  private renderInputGroup(): JSX.Element {
    const { store } = this.props;

    return (
      <InputGroup className="input-group-alternative input-group-merge">
        <InputGroupAddon addonType="prepend">
          <InputGroupText
            style={
              !isEmpty(store.search)
                ? {
                    color: this.props.uiStore.common.colors.primary,
                  }
                : {}
            }
          >
            <i className="fas fa-search" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          style={
            !isEmpty(store.search)
              ? {
                  color: this.props.uiStore.common.colors.primary,
                }
              : {}
          }
          placeholder="Search"
          type="text"
          defaultValue={store.search}
          onChange={this.handleSearchChange.bind(this)}
        />
      </InputGroup>
    );
  }

  public render(): JSX.Element {
    const { store } = this.props;

    return (
      <>
        <Col md="4">
          <Form
            onSubmit={this.handleFormSubmit.bind(this)}
            className={classnames(
              'navbar-search form-inline mr-sm-3',
              {
                'navbar-search-light': this.props.theme === 'dark',
              },
              {
                'navbar-search-dark': this.props.theme === 'light',
              },
            )}
          >
            <FormGroup className="mb-0">
              {this.renderInputGroup()}

              {store.isLoading && (
                <Spinner
                  className="ml-2"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </FormGroup>
            <button aria-label="Close" className="close" type="button">
              <span aria-hidden={true}>×</span>
            </button>
          </Form>
        </Col>
      </>
    );
  }
}

export default CardHeaderColumnSearch;
