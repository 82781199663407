import React, { Dispatch, SetStateAction } from 'react';

interface Props {
  title: string;
  setIsPreviewOpen: Dispatch<SetStateAction<boolean>>;
}

const ContentItemPreviewHeader: any = (props: Props) => {
  return (
    <>
      <div style={styles.wrapper}>
        <div
          style={styles.back}
          onClick={(): void => {
            props.setIsPreviewOpen(false);
          }}
        >
          <i className="fa fa-caret-left" style={styles.icon} />
          <span style={styles.backText}>Back</span>
        </div>
        <div style={styles.title}>{props.title}</div>
        <div style={styles.bump}></div>
      </div>
    </>
  );
};

const styles: any = {
  back: {
    alignItems: 'center',
    color: '#000',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 700,
    padding: '1rem',
    textDecoration: 'none',
    width: '200px',
  },
  backText: {
    marginTop: '-3px',
  },
  bump: {
    width: '200px',
  },
  icon: {
    fontSize: '24px',
    marginRight: '.5rem',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
  },
  wrapper: {
    alignItems: 'center',
    backgroundColor: '#fcfdfe',
    color: '#151515',
    display: 'flex',
    height: '50px',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 3,
  },
};

export default ContentItemPreviewHeader;
