import React from 'react';

import CollectionViewConfigurator from 'components/CollectionViews/CollectionViewConfigurator';

interface Props {}

function CollectionViewConfigurationPage(props: Props): JSX.Element {
  return <CollectionViewConfigurator />;
}

export default CollectionViewConfigurationPage;
