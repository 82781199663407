import React from 'react';
import standardLayout from 'assets/img/views/standard-layout.png';
import Routes from 'enums/routes.enum';
import { collectionViewsStore } from 'stores';

import ViewLayoutSelector from 'components/ViewConfigurator/ViewLayoutSelector';

interface Props {}

const CollectionViewSelector: (props: Props) => JSX.Element = (
  props: Props,
) => {
  return (
    <ViewLayoutSelector
      createMeta={{ layoutId: 5, name: 'New Collection View' }}
      home={Routes.ContentCollectionViewLayouts}
      layoutImage={standardLayout}
      redirection={Routes.ContentCollectionViewLayoutConfiguration}
      store={collectionViewsStore}
      title={'Collection View'}
    />
  );
};

export default CollectionViewSelector;
