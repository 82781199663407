import React from 'react';

interface InternalState {}

interface InternalProps {
  setOpenState: (isOpen: boolean) => void;
  persistIconChange: (iconName: string) => void;
  iconList: Array<string>;
}

class BayBody extends React.Component<InternalProps, InternalState> {
  public render(): JSX.Element {
    const { setOpenState, persistIconChange, iconList } = this.props;
    return (
      <>
        <div className="icon-body-contain">
          {iconList.map((name: string, index: number) => {
            return (
              <div key={`icon-${index}`} className="icon-contain">
                <i
                  className={`fa fa-${name}`}
                  onClick={(e: React.SyntheticEvent<EventTarget>): void => {
                    e.stopPropagation();
                    setOpenState(false);
                    persistIconChange(name);
                  }}
                />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default BayBody;
