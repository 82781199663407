import { AppNavigationService } from '../services/appNavigation.service';

import { BaseStore } from './base.store';

export class AppNavigationStore extends BaseStore<any> {
  constructor() {
    super(new AppNavigationService(), 'AppNavigationStore', 'AppNavigation');
  }
}

export const appNavigationStore: AppNavigationStore = new AppNavigationStore();
