import React from 'react';
import { Container } from 'reactstrap';

interface InternalState {
  activeNav: any;
  chartExample1Data: any;
}

interface Props {}

class Analytics extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    return (
      <>
        <Container className="mt-4" fluid>
          Analytics
        </Container>
      </>
    );
  }
}

export default Analytics;
