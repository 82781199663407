/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React from 'react';
import { Button, Col, Collapse, Container, Media, Row } from 'reactstrap';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ExpandedRowContentsProps } from 'models/grid-table.model';
import { UsersStore } from 'stores';

import { validateEmail } from '../../helpers/util.helper';
import SplitButton from '../Buttons/SplitButton';
import LabeledMultiEditDropdownMenu from '../Forms/LabeledMultiEditDropdownMenu';
import TextInput from '../Forms/TextInput';

interface InternalState {
  isValid: boolean;
  showPassword: boolean;
  passwordWasReset: boolean;

  email: string;
  lastName: string;
  firstName: string;
  password: string;
  phone: string;
}

interface Props extends ExpandedRowContentsProps {
  usersStore: UsersStore;
  store: UsersStore;
}

@inject('domainStore')
@inject('usersStore')
@inject('simpleListsStore')
@observer
class ExpandedRowContents extends React.Component<Props, InternalState> {
  public state: InternalState = {
    email: '',
    firstName: '',
    isValid: false,
    lastName: '',
    password: '',
    passwordWasReset: false,
    phone: '',
    showPassword: false,
  };

  constructor(props: Props) {
    super(props);

    const { firstName, lastName, email } = props.item;

    this.state = {
      ...this.state,
      email: email,
      firstName: firstName,
      lastName: lastName,
    };

    this.isDirty = this.isDirty.bind(this);
  }

  private isNew(): boolean {
    return this.props.item.id === this.props.store.newItemId;
  }

  private handleDone(clearWarning?: boolean): void {
    this.props.store.removeNewId();
    this.props.handleDone(clearWarning);
  }

  private areCredentialsDirty(): boolean {
    return (
      !!this.state.email !== !!this.props.item.email ||
      this.state.passwordWasReset
    );
  }

  private isDirty(): boolean {
    const item: any = toJS(this.props.item);
    const { email } = this.state;

    return email !== item.email;
  }

  private isValid(): boolean {
    const { firstName, lastName, email } = this.state;
    return (
      firstName?.length > 2 && lastName?.length > 2 && validateEmail(email)
    );
  }

  private renderDoneButtonState(): JSX.Element {
    const { item, domainStore, store } = this.props;

    if (item.status === 'deactivated') {
      return (
        <Media className="float-right mt-4">
          <SplitButton
            dropdownButtons={[
              {
                handleClick: (): void => {
                  this.handleDone();
                },
                title: 'Done',
              },
            ]}
            primaryButtons={[
              {
                handleClick: (): void => {
                  this.handleDone(true);
                },
                isDisabled: !this.isValid(),
                title: 'Activate',
              },
            ]}
          />
        </Media>
      );
    }

    return (
      <Media className="float-right mt-4">
        {this.isNew() ? (
          <>
            <Button
              color="default"
              outline
              type="button"
              onClick={(): void => {
                store.deleteItem({
                  domainStore,
                  id: item.id,
                });
              }}
            >
              Cancel
            </Button>
            <SplitButton
              dropdownButtons={[
                {
                  handleClick: (): void => {
                    this.handleDone();
                  },
                  title: 'Close',
                },
              ]}
              primaryButtons={[
                {
                  handleClick: (): void => {
                    this.handleDone(true);
                  },
                  isDisabled: !this.isValid(),
                  title: 'Send Invite',
                },
              ]}
            />
          </>
        ) : (
          <>
            {this.areCredentialsDirty() ? (
              <SplitButton
                dropdownButtons={[
                  {
                    handleClick: (): void => {
                      this.handleDone();
                    },
                    title: 'Close',
                  },
                ]}
                primaryButtons={[
                  {
                    handleClick: (): void => {
                      this.handleDone(true);
                      this.props.usersStore.updateItem(
                        {
                          body: {
                            email: this.state.email,
                          },
                          domainStore: domainStore,
                          id: item.id,
                        },
                        true,
                      );
                    },
                    isDisabled: !this.isValid(),
                    title: 'Resend Invite',
                  },
                ]}
              />
            ) : (
              <SplitButton
                dropdownButtons={[
                  {
                    handleClick: (): void => {
                      this.handleDone(true);
                    },
                    isDisabled: !this.isValid(),
                    title: 'Resent Invite',
                  },
                ]}
                primaryButtons={[
                  {
                    handleClick: (): void => {
                      this.handleDone(true);
                    },
                    title: 'Close',
                  },
                ]}
              />
            )}
          </>
        )}
      </Media>
    );
  }

  public componentWillUnmount(): void {
    if (this.isNew() && !this.isDirty() && !this.isValid()) {
      const { item, domainStore } = this.props;
      this.props.store.deleteItem({
        domainStore,
        id: item.id,
      });
    }
  }

  public render(): JSX.Element {
    const { isOpen, item, domainStore, simpleListsStore, store, usersStore } =
      this.props;
    return (
      <>
        <Collapse isOpen={isOpen}>
          {isOpen && (
            <Container
              className={`m-0 p-0 no-max-width invert-child-colors-on-new ${
                item?.isNew ? 'pt-2' : ''
              }`}
            >
              <Row className="grid-item-edit-drawer mt-2 ml-4 mr-4 pb-3">
                <Col xl="8" className="pl-0 mb-3">
                  <Row>
                    <Col md="12">
                      <Row>
                        <Col md="6">
                          <TextInput
                            inputProps={{ tabIndex: 0 }}
                            title="First Name"
                            handleChange={(value: string): void => {
                              this.setState({ firstName: value });
                            }}
                            handleUpdate={(value: string): void => {
                              usersStore.updateItem({
                                body: {
                                  firstName: value,
                                },
                                domainStore: domainStore,
                                id: item.id,
                              });
                            }}
                            defaultValue={item.firstName}
                          />
                        </Col>
                        <Col md="6">
                          <TextInput
                            inputProps={{ tabIndex: 0 }}
                            title="Last Name"
                            handleChange={(value: string): void => {
                              this.setState({ lastName: value });
                            }}
                            handleUpdate={(value: string): void => {
                              usersStore.updateItem({
                                body: {
                                  lastName: value,
                                },
                                domainStore: domainStore,
                                id: item.id,
                              });
                            }}
                            defaultValue={item.lastName}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <TextInput
                            inputProps={{ tabIndex: 0 }}
                            width="50%"
                            title="Phone"
                            handleChange={(value: string): void => {
                              this.setState({ phone: value });
                            }}
                            handleUpdate={(value: string): void => {
                              /*
                               * UsersStore.updateItem(
                               *   {
                               *     body: {
                               *       phone: value,
                               *     },
                               *     domainStore: domainStore,
                               *     id: item.id,
                               *   },
                               *   true,
                               * );
                               */
                            }}
                            defaultValue={item.phone}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="darker-row p-2">
                    <Col md="6">
                      <TextInput
                        inputProps={{ tabIndex: 0 }}
                        title="Email"
                        // Hold - handleUpdate={(): void => {}}
                        handleChange={(value: string): void => {
                          this.props.handleShouldWarn(item.email !== value);

                          this.setState({
                            email: value,
                          });
                        }}
                        defaultValue={item.email}
                      />
                    </Col>
                    <Col md="6">
                      <h5>
                        Password{' '}
                        {this.state.showPassword && (
                          <Button
                            tabIndex={5}
                            className="button-tiny"
                            size="sm"
                            color="default"
                            type="button"
                            onClick={(): void => {
                              this.setState({ showPassword: false });
                            }}
                          >
                            hide
                          </Button>
                        )}
                      </h5>

                      {this.isNew() ? (
                        <>
                          {!this.state.showPassword ? (
                            <Button
                              tabIndex={5}
                              color="default"
                              type="button"
                              onClick={(): void => {
                                this.setState({ showPassword: true });
                              }}
                            >
                              Show Password
                            </Button>
                          ) : (
                            <TextInput
                              inputProps={{ tabIndex: 5 }}
                              /*
                               * HandleUpdate={(params: any): void => {
                               *   console.log(params);
                               *   //
                               * }}
                               */
                              handleChange={(value: string): void => {
                                this.setState({ password: value });
                              }}
                              defaultValue={'$B`*$V3w-gX^A#a!qp&CCvHP'}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {!this.state.showPassword ? (
                            <Button
                              tabIndex={5}
                              color="default"
                              type="button"
                              onClick={(): void => {
                                this.props.handleShouldWarn(true);
                                this.setState({
                                  passwordWasReset: true,
                                  showPassword: true,
                                });
                              }}
                            >
                              Reset Password
                            </Button>
                          ) : (
                            <TextInput
                              inputProps={{ tabIndex: 5 }}
                              handleUpdate={(params: any): void => {
                                console.log(params);
                                //
                              }}
                              handleChange={(value: string): void => {
                                this.setState({ password: value });
                              }}
                              defaultValue={'$B`*$V3w-gX^A#a!qp&CCvHP'}
                            />
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col xl="4" className="pr-0 center-items">
                  <LabeledMultiEditDropdownMenu
                    parentId={item.id}
                    activeIds={item.roleIds}
                    buttonTitle="User Roles"
                    handleUpdate={(params: any): void => {
                      store.updateItem({
                        body: {
                          roleIds: params.payload,
                        },
                        domainStore,
                        id: params.id,
                      });
                    }}
                    icon={'sitemap'}
                    items={simpleListsStore.userRoles}
                    label="Which user role(s) does this user belong to?"
                    shouldRemoveTopMargin={true}
                  />

                  <LabeledMultiEditDropdownMenu
                    parentId={item.id}
                    activeIds={item.groupIds}
                    buttonTitle="Core Groups"
                    handleUpdate={(params: any): void => {
                      store.updateItem({
                        body: {
                          groupIds: params.payload,
                        },
                        domainStore,
                        id: params.id,
                      });
                    }}
                    icon={'sitemap'}
                    items={simpleListsStore.groupsCore}
                    label="Which core group(s) is this user in?"
                  />

                  <LabeledMultiEditDropdownMenu
                    parentId={item.id}
                    activeIds={item.groupIds}
                    buttonTitle="Other Groups"
                    handleUpdate={(params: any): void => {
                      store.updateItem({
                        body: {
                          groupIds: params.payload,
                        },
                        domainStore,
                        id: params.id,
                      });
                    }}
                    icon={'sitemap'}
                    items={simpleListsStore.groupsOther}
                    label="Which other group(s) is this user in?"
                  />

                  {this.renderDoneButtonState()}

                  {this.isNew() && !this.isValid() && (
                    <div className="validation-notice float-right mt-1">
                      <p>
                        {
                          'First name, last name & email are all required fields.'
                        }
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          )}
        </Collapse>
      </>
    );
  }
}

export default ExpandedRowContents;
