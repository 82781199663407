/* eslint-disable max-lines-per-function */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { domainStore } from 'stores';

import PaginationComponent from 'components/GridTable/Pagination';

import Routes from '../../../enums/routes.enum';
import { BaseProps } from '../../../models/base.model';

import CardHeaderColumnSearch from './Columns/Search';

interface InternalState {}

interface Props extends BaseProps {
  actionButtonIcon?: string;
  actionButtonTitle: string;
  actionButtonHandler?: () => void;
  ActionDropdownMenu?: React.PropsWithChildren<any>;
  helpButtonIcon?: string;
  HelpDropdownMenu?: React.PropsWithChildren<any>;
  isActionButtonIconSuffix?: boolean;
  shouldHideSearch?: boolean;
  shouldNotPaginate?: boolean;
  shouldShowChangeTable?: boolean;
  store: any;
  theme?: any;
}

class GridTableCardHeader extends React.Component<Props, InternalState> {
  public render(): JSX.Element {
    const {
      actionButtonHandler,
      actionButtonIcon,
      isActionButtonIconSuffix,
      actionButtonTitle,
      ActionDropdownMenu,
      helpButtonIcon,
      HelpDropdownMenu,
      shouldHideSearch,
      shouldNotPaginate,
      shouldShowChangeTable,
      store,
    } = this.props;

    return (
      <>
        <CardHeader className="border-0 pb-0 grid-table-card-header">
          <Row className="mt-0 mb-4">
            {shouldHideSearch ? (
              <Col md="4"></Col>
            ) : (
              <CardHeaderColumnSearch columnProps={{ md: '4' }} store={store} />
            )}
            <Col md="3"></Col>
            <Col md="5" className="last-column">
              <div>
                {shouldShowChangeTable && (
                  <UncontrolledDropdown nav className="">
                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                      <Media>
                        <Button
                          className="btn-icon"
                          color="secondary"
                          type="button"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fal fa-cog text-lg"></i>
                          </span>
                          <span className="btn-inner--text">Change Table</span>
                        </Button>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <Link to={Routes.ContentItemsAddSourceComputer}>
                        <DropdownItem>
                          <i className="fal fa-upload"></i>
                          <span>Some table setting</span>
                        </DropdownItem>
                      </Link>
                      <Link to={Routes.ContentItemsAddSourceDropbox}>
                        <DropdownItem>
                          <i className="fab fa-dropbox"></i>
                          <span>Some table setting 1</span>
                        </DropdownItem>
                      </Link>
                      <Link to={Routes.ContentItemsAddSourceGoogleDrive}>
                        <DropdownItem>
                          <i className="fab fa-google-drive"></i>
                          <span>Some table setting 2</span>
                        </DropdownItem>
                      </Link>
                      <Link to={Routes.ContentItemsAddSourceUrl}>
                        <DropdownItem>
                          <i className="fal fa-file-upload"></i>
                          <span>Some table setting 3</span>
                        </DropdownItem>
                      </Link>

                      <DropdownItem divider />

                      <Link to={Routes.ContentItemsAddSourceUrl}>
                        <DropdownItem>
                          <i className="fal fa-file-upload"></i>
                          <span>One more</span>
                        </DropdownItem>
                      </Link>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}

                {actionButtonTitle && actionButtonHandler && (
                  <div className="nav-link pr-0">
                    <Media>
                      <Button
                        className="btn-icon "
                        color="default"
                        type="button"
                        onClick={(): void => actionButtonHandler()}
                      >
                        {actionButtonIcon && !isActionButtonIconSuffix && (
                          <span className="btn-inner--icon mr-1">
                            <i
                              className={`fal ${
                                actionButtonIcon || ' fa-plus1'
                              } text-lg`}
                            ></i>
                          </span>
                        )}
                        <span className="btn-inner--text">
                          {this.props.actionButtonTitle}
                        </span>
                        {actionButtonIcon && isActionButtonIconSuffix && (
                          <span className="btn-inner--icon mr-1">
                            <i
                              className={`fal ${
                                actionButtonIcon || ' fa-plus'
                              } text-lg`}
                            ></i>
                          </span>
                        )}
                      </Button>
                    </Media>
                  </div>
                )}

                {actionButtonTitle && ActionDropdownMenu && (
                  <UncontrolledDropdown nav className="">
                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                      <Media>
                        <Button
                          className="btn-icon"
                          color="default"
                          type="button"
                        >
                          {actionButtonIcon && !isActionButtonIconSuffix && (
                            <span className="btn-inner--icon mr-1">
                              <i
                                className={`fal ${
                                  actionButtonIcon || ' fa-plus1'
                                } text-lg`}
                              ></i>
                            </span>
                          )}
                          <span className="btn-inner--text">
                            {this.props.actionButtonTitle}
                          </span>
                          {actionButtonIcon && isActionButtonIconSuffix && (
                            <span className="btn-inner--icon mr-1">
                              <i
                                className={`fal ${
                                  actionButtonIcon || ' fa-plus'
                                } text-lg`}
                              ></i>
                            </span>
                          )}
                        </Button>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu right className="action-menu">
                      <ActionDropdownMenu />
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}

                {HelpDropdownMenu && (
                  <UncontrolledDropdown nav className="">
                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                      <Media>
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="secondary"
                          type="button"
                        >
                          <span className="btn-inner--icon">
                            <i
                              className={`fas ${
                                helpButtonIcon || 'fa-question'
                              } text-md`}
                            ></i>
                          </span>
                        </Button>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <HelpDropdownMenu />
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}
              </div>
            </Col>
          </Row>
        </CardHeader>
        {!shouldNotPaginate && (
          <PaginationComponent
            styles={{
              borderBottomWidth: '1px',
              borderColor: '#3c3c3c',
              borderLeftWidth: 0,
              borderRightWidth: 0,
              borderStyle: 'solid',
              borderTopWidth: 0,
              marginBottom: '2rem',
              marginLeft: '25px',
              marginRight: '25px',
              paddingBottom: '1rem',
            }}
            store={store}
            handleAction={(params: any): void =>
              store.handleTableAction({ domainStore }, params)
            }
          />
        )}
      </>
    );
  }
}

export default GridTableCardHeader;
