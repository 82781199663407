/* eslint-disable capitalized-comments */
/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import {
  IFileWithMeta,
  IUploadParams,
} from 'react-dropzone-uploader';
import * as AssetService from 'services/asset.service';

import { PresignedAssetCreateOrUpdate } from '@storyslab/storyslab.common.models';
import SimpleS3Uploader from 'components/Assets/SimpleS3Uploader';

const bucket: string = process.env.REACT_APP_TENANT_ASSETS_BUCKET;

interface Props {
  uploadName?: string;
  onComplete?: (assetId: number, s3Key: string) => void;
  onFailed?: (event: any) => void;
  initialAssetId?: number;
}

const ViewAssetUploader: (props: Props) => JSX.Element = (props: Props) => {

  const {initialAssetId} = props;

  const [assetId, setAssetId] = useState<number>();
  const [s3Key, setS3Key] = useState<string>();

  useEffect(()=>{
    if (initialAssetId) {
      setAssetId(initialAssetId);
    }
  },[initialAssetId])

  useEffect(()=>{
    if (assetId){
      AssetService.getAssetById(assetId).then((result:any)=>{
        setS3Key(result?.data?.s3Key);
      });
    }
  },[assetId])

  async function handleGetUploadParams(
    params: IFileWithMeta,
  ): Promise<IUploadParams> {
    const payload: PresignedAssetCreateOrUpdate = {
      assetId: assetId,
      name: params.file.name,
      type: params.file.type,
    }
    const response: any = await AssetService.createOrUpdatePresignedAsset(payload)
    setAssetId(response?.data?.asset?.id);
    return new Promise((resolve)=>{
      resolve(response)
    })
  }

  return (
    <SimpleS3Uploader
      bucket={bucket}
      onComplete = {(newS3Key:string):void=>{
        props.onComplete && props.onComplete(assetId, newS3Key)
        setS3Key(newS3Key);
      }}
      onFailed = {props.onFailed}
      initialS3Key={s3Key}
      handleGetUploadParams={handleGetUploadParams}/> || null
  );
};

export default ViewAssetUploader;
