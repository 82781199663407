import React, { useEffect, useState } from 'react';
import { uiStore } from 'stores';

import SimpleToggle from 'components/Forms/SimpleToggle';
import Modal from 'components/SettingsAndFeatures/elements/Modal';

import HeaderRow from './elements/HeaderRow';

interface Props {
  title: string;
  description: string;
  icon: string;
  hasToggle?: boolean;
  featureKey?: string;
  modalContents: JSX.Element;

  features?: {[key:string]: boolean};
  settings?: {[key:string]: any};
  handleFeatureToggle: (toggleState: boolean, featureKey: string)=>void
  handleSettingsChange: (newValue: any, metaKey: string)=>void
}

const FeatureCard: any = (props: Props) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(false);

  useEffect(()=>{
    if (props.features && props.featureKey){
      // Lock the card if feature key is not in passed features
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const featureNotFound:boolean = typeof(props.features[props.featureKey]) === "undefined";
      setIsLocked(featureNotFound);
    }
  },[props.features, props.featureKey, props.hasToggle]);

  return (
    <>
      {isModalOpen && (
        <Modal closeModal={setIsModalOpen}>
            <HeaderRow
              description={props.description}
              iconName={props.icon}
              titleText={props.title}
            />
          {props.modalContents}
        </Modal>
      )}
      <div
        style={styles.contain}
        className={"card-container" + (isLocked ? "" : " clickable")  }
        onClick={(): void => {
          !isLocked && setIsModalOpen(true);
        }}
      >
        <div style={{flex:1}}>
          <div>
            <i
              className={`fal ${props.icon}`}
              style={{ ...styles.icon, color: isLocked ? "grey" : uiStore.common.colors.primary }}
            />
          </div>
          <div style={styles.title}>{props.title}</div>
          <div style={styles.text}>{props.description}</div>
        </div>

        <div>
          {props.features && props.hasToggle &&
            <SimpleToggle
              field={props.featureKey}
              stopPropagationOnChange={true}
              didCheckOnLoad={props.features[props.featureKey]===true}
              handleChange={props.handleFeatureToggle}
            />
          }
        </div>

        {isLocked && <div style={styles.lockedOverlay}/>}
      </div>
    </>
  );
};

const styles: any = {
  contain: {
    display: 'flex',
    height: '181px',
    position: 'relative',
    width: '100%',
  },
  icon: {
    fontSize: '30px',
  },
  lockedOverlay: {
    background: 'white',
    bottom:0,
    left:0,
    opacity: .5,
    position:'absolute',
    right:0,
    top:0,
  },
  text: {
    color: '#808080',
    fontSize: '12px',
    fontWeight: 400,
  },
  title: {
    color: '#808080',
    fontSize: '12px',
    fontWeight: '700',
    padding: '15px 0',
  },
};

export default FeatureCard;
