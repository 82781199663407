import React from 'react';
import { inject, observer } from 'mobx-react';
import { createGlobalStyle } from 'styled-components';

import { resolve } from '@storyslab/storyslab.common.helpers';

import { hexToRgb } from '../../helpers/util.helper';
import { DomainStoreModel } from '../../models/domain.model';
import { UiStoreModel } from '../../models/ui.model';
import { fetchPublicDetails } from '../../services/bootstrap.service';

interface InternalState {}

interface Props {
  domainStore?: DomainStoreModel;
  uiStore?: UiStoreModel;
}

@inject('domainStore')
@inject('uiStore')
@observer
class Global extends React.Component<Props, InternalState> {
  private async bootstrap(): Promise<void> {
    const [, err] = await resolve(
      fetchPublicDetails(this.props.domainStore.subdomain),
    );

    if (err) {
      console.log(err);
    }
  }

  public async componentDidMount(): Promise<void> {
    this.bootstrap();
  }

  public render(): JSX.Element {
    const primaryHex: string = this.props.uiStore.common.colors.primary;
    if (!primaryHex || primaryHex === '#') {
      return <></>;
    }

    const primaryRgb: { r: number; g: number; b: number } =
      hexToRgb(primaryHex);

    const GlobalStyles: string = createGlobalStyle`
    :root {
      --brand-primary-color: ${this.props.uiStore.common.colors.primary};
      --brand-primary-color-rgb: ${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}; 
      --theme-dark-text-color: white;
    }
  `;

    return <GlobalStyles />;
  }
}

export default Global;
