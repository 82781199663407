import { EdgeUrls } from '../enums/edge.enum';

import { BaseService } from './base.service';

export class CollectionViewsService extends BaseService {
  constructor() {
    super({
      create: EdgeUrls.Views,
      delete: EdgeUrls.View,
      fetch: EdgeUrls.CollectionViews,
      update: EdgeUrls.View,
    });
  }
}
