import React, { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';

interface Props {
  handleChange: (number) => void;
  options: Array<any>;
  selected: number;
}

interface OptionsProps {
  handleChange: (number) => void;
  options: Array<any>;
  selected: number;
  setActiveSelection: Dispatch<SetStateAction<number>>;
}

interface AppView {
  name: string;
  id: number;
}

interface OptionProps {
  handleChange: (number) => void;
  isChecked: boolean;
  setActiveSelection: Dispatch<SetStateAction<number>>;
  view: AppView;
}

function RenderOption(props: OptionProps): JSX.Element {
  const { handleChange, isChecked, setActiveSelection, view } = props;

  return (
    <>
      <div
        className="storyslab-select-checkbox"
        onClick={(): void => {
          setActiveSelection(view.id);
          handleChange(view.id);
        }}
      >
        {isChecked && <i className="fas fa-check-square"></i>}
        {!isChecked && <i className="far fa-square"></i>}
        <span>{view.name}</span>
      </div>
    </>
  );
}

function Search(props: {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}): JSX.Element {
  return (
    <>
      <div className="storyslab-select-search">
        <i className="fas fa-search pl-2 pr-2"></i>
        <input
          type="text"
          placeholder="Search..."
          value={props.searchTerm}
          onChange={(e): void => {
            props.setSearchTerm(e.target.value.toLowerCase());
          }}
        />
      </div>
    </>
  );
}

function SelectOptions(props: OptionsProps): JSX.Element {
  const { handleChange, options, selected, setActiveSelection } = props;

  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <div className="storyslab-select-options-contain">
        <div className="detail-header">
          <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <div className="storyslab-select-options">
            {options.map((view, index) => (
              <RenderOption
                handleChange={handleChange}
                isChecked={view.id === selected ? true : false}
                key={index}
                setActiveSelection={setActiveSelection}
                view={view}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default function Select(props: Props): JSX.Element {
  const { handleChange, options, selected } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [activeSelection, setActiveSelection] = useState(selected);

  return (
    <>
      <div
        className={`storyslab-select`}
        tabIndex={0}
        onBlur={(e): void => {
          if (!e.currentTarget.contains(e.relatedTarget as Node)) {
            setIsOpen(false);
          }
        }}
      >
        <div
          className="storyslab-select-control"
          onClick={(): void => setIsOpen(!isOpen)}
        >
          <span className="storyslab-select-control-title">
            {_.find(options, { id: activeSelection })?.name}
          </span>
          <i className={`fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>
        </div>
        {isOpen && (
          <SelectOptions
            handleChange={handleChange}
            options={options}
            selected={activeSelection}
            setActiveSelection={setActiveSelection}
          />
        )}
      </div>
    </>
  );
}
