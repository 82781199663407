import { CategoriesService } from '../services/categories.service';

import { BaseStore } from './base.store';

export class CategoriesStore extends BaseStore<any> {
  constructor() {
    super(new CategoriesService(), 'CategoriesStore', 'Categories');
  }
}

export const categoriesStore: CategoriesStore = new CategoriesStore();
