import React from 'react';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import Routes from 'enums/routes.enum';

interface InternalProps {
  viewId: number;
}

function EditLayoutConfigButton(props: InternalProps): JSX.Element {
  return (
    <div className="row">
      <div className="col-md-12 d-flex">
        <NavLink
          className="btn edit-config-button"
          to={`${Routes.ApplicationConfiguration}/${props.viewId}`}
          tag={NavLinkRRD}
        >
          Edit Layout Configuration
        </NavLink>
      </div>
    </div>
  );
}

export default EditLayoutConfigButton;
