import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { AdminContext } from 'routes/Admin';

interface Props {
  handleAdd: (value: number) => void;
  items: any;
}

function CollectionDrawerRowContents(props: Props): JSX.Element {
  const { userSimpleList } = useContext(AdminContext);

  return (
    <>
      {props.items.map((item: any, index: any) => {
        return (
          <div
            key={`table-row-${item.id}-${index}`}
            className={`drawer-row tree-drawer-row ${
              index % 2 === 0 ? 'dark-row' : ''
            }`}
          >
            <div className="collection-name">
              <i className="far fa-folder-tree"></i>
              <span>{item.name}</span>
            </div>
            <div className="collection-added-by">
              {userSimpleList[item.updatedBy]}
            </div>
            <div className="collection-add">
              <Button
                className="btn btn-default storyslab-button"
                onClick={(): void => {
                  props.handleAdd(item.id);
                }}
              >
                add collection
              </Button>
            </div>
            {/* <div className="collection-ellipsis">
              <i className="fas fa-ellipsis-v"></i>
            </div> */}
          </div>
        );
      })}
    </>
  );
}

export default CollectionDrawerRowContents;
