import React, { useEffect, useState } from 'react';

interface Props {
  didCheckOnLoad: boolean;
  field?: string;
  stopPropagationOnChange?: boolean;
  handleChange: (boolean, field?: string) => void;
}

export default function SimpleToggle(props: Props): JSX.Element {
  const { didCheckOnLoad, field, handleChange } = props;
  const [isChecked, setIsChecked] = useState(didCheckOnLoad);
  const [isPageLoad, setIsPageLoad] = useState(true);

  useEffect(() => {
    if (isPageLoad) {
      setIsPageLoad(false);
    } else {
      if (field) {
        handleChange(isChecked, field);
      } else {
        handleChange(isChecked);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <>
      <div
        className={`simple-toggle-switch ${isChecked && 'checked'}`}
        onClick={(e): void => {
          if (props.stopPropagationOnChange) {
            e.stopPropagation();
          }
          setIsChecked(!isChecked);
        }}
      >
        <div></div>
      </div>
    </>
  );
}
