import React from 'react';
import { DateRangePicker } from 'react-dates';
import { ANCHOR_RIGHT, START_DATE } from 'react-dates/src/constants';
import { Button } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { BaseProps } from 'models/base.model';
import moment, { Moment } from 'moment';

import { RenderKeyHelper } from '../../../helpers/render-key.helper';

const renderKeyHelper: RenderKeyHelper = new RenderKeyHelper(
  'DoubleDatePicker',
);

interface InternalState {
  startDate: any;
  endDate: any;
  focusedInput: any;
}

interface Props extends BaseProps {
  handleAction: any;
  itemKey: string;
  store: any;
}

@inject('uiStore')
@observer
class DoubleDatePicker extends React.Component<Props, InternalState> {
  private defaultButtons: any = [
    {
      handleClick: (): void => {
        this.handleShouldChangeState(null, null);
      },
      title: 'Clear Filter',
    },
    {
      handleClick: (): void => {
        this.handleShouldChangeState(moment().subtract(1, 'weeks'), moment());
      },
      title: 'Past Week',
    },
    {
      handleClick: (): void => {
        this.handleShouldChangeState(moment().subtract(1, 'month'), moment());
      },
      title: 'Past Month',
    },
    {
      handleClick: (): void => {
        this.handleShouldChangeState(moment().subtract(6, 'month'), moment());
      },
      title: 'Past 6 Months',
    },
  ];

  public state = {
    endDate: null,
    focusedInput: START_DATE,
    startDate: null,
  };

  private handleShouldChangeState(startDate: Moment, endDate: Moment): void {
    if (startDate && endDate) {
      this.props.handleAction({
        payload: {
          [this.props.itemKey]: {
            endDate,
            startDate,
          },
        },
        type: 'filter',
      });
    } else {
      this.props.handleAction({
        payload: {
          [this.props.itemKey]: null,
        },
        type: 'filter',
      });
    }
  }

  private renderStyledButton(
    {
      title,
      handleClick,
    }: {
      title: string;
      handleClick: () => void;
    },
    index: number,
  ): JSX.Element {
    return (
      <Button
        onClick={(): void => handleClick && handleClick()}
        color="default"
        outline
        type="button"
        style={{
          borderColor: this.props.uiStore.common.colors.primary,
        }}
        key={renderKeyHelper.generate(
          {
            index,
            title: encodeURIComponent(title),
          },
          'Button',
        )}
      >
        {title}
      </Button>
    );
  }

  public render(): JSX.Element {
    const { store } = this.props;

    const { startDate, endDate } = store.get(this.props.itemKey as any) || {};

    return (
      <>
        <div style={{ minWidth: '20rem' }}>
          <DateRangePicker
            keepOpenOnDateSelect
            block
            calendarInfoPosition="top"
            renderCalendarInfo={(): JSX.Element => (
              <div className="button-wrapper p-3">
                {this.defaultButtons.map((config: any, index: number) =>
                  this.renderStyledButton(config, index),
                )}
              </div>
            )}
            isOutsideRange={(day): boolean => moment().isBefore(day)}
            initialVisibleMonth={(): Moment =>
              moment().startOf('month').subtract(1, 'months')
            }
            anchorDirection={ANCHOR_RIGHT}
            startDate={startDate}
            startDateId="StartDateId"
            endDate={endDate}
            endDateId="EndDateId"
            onDatesChange={({ startDate, endDate }): void =>
              this.handleShouldChangeState(startDate, endDate)
            }
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput): void =>
              this.setState({ focusedInput })
            }
          />
        </div>
      </>
    );
  }
}

export default DoubleDatePicker;
