import React from 'react';

import { getIconList } from '../../../helpers/icon.helper';

import BayBody from './IconBayBody';
import BayHeader from './IconBayHeader';

interface InternalState {
  iconList: Array<string>;
}

interface InternalProps {
  setOpenState: (isOpen: boolean) => void;
  persistIconChange: (iconName: string) => void;
  isRightAligned: boolean;
}

class BayWindow extends React.Component<InternalProps, InternalState> {
  constructor(props: InternalProps) {
    super(props);
    this.state = {
      iconList: getIconList(),
    };
  }

  public searchIcons(term: string): void {
    const fullList: Array<string> = getIconList();
    const filteredList: Array<string> = fullList.filter((icon) =>
      icon.includes(term),
    );
    this.setState({ iconList: filteredList });
  }

  public render(): JSX.Element {
    const { setOpenState, persistIconChange } = this.props;
    return (
      <>
        <div
          className="icon-bay-backsplash"
          onClick={(e: React.SyntheticEvent<EventTarget>): void => {
            setOpenState(false);
            e.stopPropagation();
          }}
        ></div>
        <div
          className={`icon-bay-wrapper ${
            this.props.isRightAligned && 'make-right'
          }`}
        >
          <BayHeader searchIcons={this.searchIcons.bind(this)} />
          <BayBody
            setOpenState={setOpenState}
            persistIconChange={persistIconChange}
            iconList={this.state.iconList}
          />
        </div>
      </>
    );
  }
}

export default BayWindow;
