import React, { useContext, useState } from 'react';
import { ContentTreeMenuProps } from 'interfaces/treeInterfaces';

import { TreeContext } from './ContentTree';

const ContentTreeMenu: any = (props: ContentTreeMenuProps) => {
  const { activeView, rootViews, setActiveView }: any = useContext(TreeContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleChange: any = (item: any) => {
    setDropdownOpen(!isDropdownOpen);
    setActiveView(item);
  };

  return (
    <>
      <div className="content-tree-header">
        <div
          tabIndex={0}
          className="root-views-dropdown"
          onClick={(): void => {
            setDropdownOpen(!isDropdownOpen);
          }}
        >
          {`${activeView.name} View Tree`}
          <i className="fas fa-angle-down"></i>
        </div>
        {isDropdownOpen && (
          <>
            <div
              className="backsplash"
              onClick={(): void => setDropdownOpen(false)}
            ></div>
            <div className="root-views-dropdown-options">
              {rootViews.map((item, index) => {
                if (item.viewId) {
                  return (
                    <div
                      className="root-views-dropdown-option"
                      key={`root-view-${index}`}
                      onClick={(): void => handleChange(item)}
                    >
                      {item.name}
                    </div>
                  );
                } else {
                  return <div key={`root-view-${index}`}></div>;
                }
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ContentTreeMenu;
