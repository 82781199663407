import { EdgeUrls } from '../enums/edge.enum';
import { extractAuthDetailsFromDomainStore } from '../helpers/util.helper';
import { DomainStoreModel } from '../models/domain.model';
import { BaseService } from '../services/base.service';

import { EdgeRequestMethods, fetchFromEdge } from './edge.service';

export class CategoriesService extends BaseService {
  private newItemDefaultName: string = 'New Category';

  constructor() {
    super({
      create: EdgeUrls.Categories,
      delete: EdgeUrls.Category,
      fetch: EdgeUrls.Categories,
      update: EdgeUrls.Category,
    });
  }

  public async create({
    domainStore,
  }: // Hold - meta,
  {
    meta?: { [key: string]: any };
    domainStore: DomainStoreModel;
  }): Promise<any> {
    return await fetchFromEdge({
      auth: extractAuthDetailsFromDomainStore(domainStore),
      body: { name: this.newItemDefaultName },
      method: EdgeRequestMethods.POST,
      url: EdgeUrls.Categories,
    });
  }
}
