import React from 'react';
import { inject, observer } from 'mobx-react';
import { DomainStoreModel } from '../../models/domain.model';
import S3Image from './S3Image';

interface InternalState {}

interface Props {
  className?: string;
  domainStore?: DomainStoreModel;
  style?: any;
  s3Key: string;
}

const bucket: string = `${process.env.REACT_APP_TENANT_ASSETS_BUCKET}`; // -${process.env.REACT_APP_STAGE}

/**
 * Example:
 *
 * <S3AssetImage
 *   s3Key={'s3-key`}
 * />
 *
 */

@inject('domainStore')
@observer
class S3TenantAssetImage extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
  }

  public render(): JSX.Element {
    return <S3Image {...this.props} s3Bucket={bucket} />;
  }
}

export default S3TenantAssetImage;
